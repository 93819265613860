import React, { Fragment } from 'react'
import './payloadDetails.css'
import PayloadChart from './payloadChart'
import '../../style.css'
import Calender from '../calender/calender'
import * as d3 from 'd3'
// import * as PayloadMapComponent from '../map/payloadMap'
import * as HistoricChartComponent from '../charts/payloadHistoricChart'
import * as AmqpComponent from '../amqp/amqp'
import VideoRoomComponent from '../live-view/videoroom'
import * as BhsComponent from './bhs'

const isJsonString = str => {
    if (!str) return false
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export class LeftPanel extends React.Component {
    constructor(props) {
        super(props)
    }

    render = _ =>
        <Fragment>
            <h2><p>Payload Details for <code class='username'>{this.props.payload.codename}</code></p></h2>
            <div class={this.props.currPage == 'about' ? 'selected' : ''}
                onClick={ _ => this.props.setCurrPage('about')}
            ><p>About</p></div>
            <div class={this.props.currPage == 'live' ? 'selected' : ''}
                onClick={ _ => this.props.setCurrPage('live')}
            ><p>Live Data { ( this.props.amqpState != 'disconnected' && this.props.currData) ?
                <svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="green"/></svg>
                :
                this.props.amqpState == 'disconnected' ?
                    <svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="red"/></svg>
                    :
                    <svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="gold"/></svg>
                }​
            </p></div>
            <div class={this.props.currPage == 'historic' ? 'selected' : ''}
                onClick={ _ => this.props.setCurrPage('historic')}
            ><p>Historic Data</p></div>
            <div class={this.props.currPage == 'amqp' ? 'selected' : ''}
                onClick={ _ => this.props.setCurrPage('amqp')}
            ><p>Amqp Status</p></div>
            <div class={this.props.currPage == 'settings' ? 'selected' : ''}
                onClick={ _ => this.props.setCurrPage('settings')}
            ><p>Payload Settings</p></div>
            <div class={this.props.currPage == 'upload' ? 'selected' : ''}
                onClick={ _ => this.props.setCurrPage('upload')}
            ><p>Upload Rawdata</p></div>
            <div onClick={ _ => this.props.routePreviousPage()}><p>Back</p></div>
        </Fragment>
}

export class RightPanel extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            visibilities:{},
            currData:null,
            dataCount:null,
            whitelist:null,
            whitelist_state:'idle',
            whitelist_token:null,
            rawdata_formats:null,
            temp_rawdata_format_content:null,
            temp_rawdata_format_description:null,
            rawdata_format_state:'idle',
            selected_upload_rawdata_format_id: null,
            temp_upload_rawdatas:null,
            temp_rawdata_timestamp_conversion_script:null,
            temp_rawdata_sample: null,
            highlighted_timerange: null,
            historic_rawdata_statistics: null,
            historic_rawdata_query_date_range:{start:null,end:null},
            historic_rawdatas:null,
            historic_rawdatas_filtered:null,
            historic_rawdatas_loading: false,
            show_historic_rawdatas:false,
            historic_rawdata_pagination_id:1,
            historic_chart_visibilities: null,
            historic_chart_layer_visibilities: {heatmap:true,idw:false},
            conversionFormula:null
        }
        this.datas = []
        console.log('payload detail page: codename:', this.props.payload)
        window.history.pushState(null,'payload_details','#payload_details')
        window.onpopstate = _ => this.props.routePreviousPage()
        this.props.setCurrPage('about')
    }

    componentDidMount() {
        this.datas = []
        this.updatePayloadWhitelist()
        this.fetchDataFormats()
    }

    updatePayloadWhitelist = _ => {
        fetch( PUBLIC_PATH_ROOT + 'auth/payload_whitelist',{
            method: 'POST',
            headers: {'Content-Type': 'application/jsoncharset=utf-8'},
            body: JSON.stringify({token:this.props.credentials.token,payload_id: this.props.payload.id})
        })
        .then( res => res.json())
        .then( rs => {
            if (rs.success) {
                this.setState({whitelist: rs.whitelist})
            }
            else throw 'request failed'
        })
        .catch( err => {
            console.log('payload detail page: request failed:',err)
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.currPage != 'historic' && this.props.currPage == 'historic') {
            this.fetchHistoricDataStatistics()
        }
        if (prevState.daily_stats_data != this.state.daily_stats_data) {
            console.log('updating daily stats charts...')
            this.state.daily_stats_y_keys.map( y_key => {
                this.plotDailyStats(this.dailyStatsChart[y_key],this.state.daily_stats_data,this.state.daily_stats_x_key,y_key)
            })
        }
    }

    fetchDataFormats() {
        fetch( PUBLIC_PATH_ROOT + 'api/payload_rawdata_formats',{
            method: 'POST',
            headers: {'Content-Type': 'application/jsoncharset=utf-8'},
            body: JSON.stringify({token:this.props.credentials.token,payload_id: this.props.payload.id})
        })
        .then( res => res.json())
        .then( rs => {
            if (rs.success) {
                this.setState({rawdata_formats: rs.payload_rawdata_formats})
            }
            else throw 'request failed'
        })
        .catch( err => {
            console.log('payload detail page: payload_rawdata_formats: failed:',err)
        })
    }

    fetchHistoricDataStatistics(){
        console.log('fetching data statistics...')
        fetch( PUBLIC_PATH_ROOT + 'api/get_one_payload_rawdata_statistics',{
            method: 'POST',
            headers: {'Content-Type': 'application/jsoncharset=utf-8'},
            body: JSON.stringify({token:this.props.credentials.token,payload_id: this.props.payload.id})
        })
        .then( res => res.json())
        .then( rs => {
            if (! rs.success) throw 'request failed'
            let first = rs.results?.[0]?.date_trunc
            let last = rs.results?.[rs.results.length-1]?.date_trunc
            this.setState({historic_rawdata_statistics: rs.results, historic_rawdata_query_date_range:{start:first,end:last}})
            this.plotStatsChart(rs.results)
        })
        .catch( err => {
            console.log('payload detail page: get_one_payload_rawdata_statistics: failed:',err)
        })

        fetch( PUBLIC_PATH_ROOT + 'api/payload_statistics_daily',{
            method: 'POST',
            headers: {'Content-Type': 'application/jsoncharset=utf-8'},
            body: JSON.stringify({token:this.props.credentials.token,payload_id: this.props.payload.id})
        })
        .then( res => res.json())
        .then( rs => {
            if (! rs.success) throw 'request failed'
            console.log('daily stats:', rs.result)
            this.setState({
                daily_stats_data: rs.result,
                daily_stats_x_key: 'day',
                daily_stats_y_keys: [
                    'daily_avg_nox_g_kWh',
                    'daily_avg_co2_g_kWh',
                    'daily_distance_travelled_km',
                    'count'
                ]
            })
        })
        .catch( err => {
            console.log('payload detail page: get_one_payload_rawdata_statistics: failed:',err)
        })
    }

    plotStatsChart(data) {
        console.assert(Array.isArray(data))
        if (data.length==0) return
        // init the basic DOM elements such as svg, axis, trendline
        let chartArea = d3.select(this.statsChart).append('div')
        .attr('class','statsChartArea')
        .style('width','100%')
        .style('height','100%')

        let chartAreaRect = chartArea.node().getBoundingClientRect()
        let margin = {top: 20, right: 80, bottom: 30, left: 60}
        let plotAreaHeight = chartAreaRect.height - margin.top - margin.bottom
        let plotAreaWidth = chartAreaRect.width - margin.left - margin.right

        let getX = x => new Date(x['date_trunc']) // data -> value
        let getY = x => Number(x['count'])
        let scaleX = d3.scaleTime()
        .range([margin.left,margin.left + plotAreaWidth])
        let drawXAxis = d3.axisBottom(scaleX).ticks(6)
        let scaleY = d3.scaleLinear()
        .range([margin.top + plotAreaHeight, margin.top])
        let drawYAxis = d3.axisLeft(scaleY).ticks(6)

        // add the graph canvas to the body of the webpage
        let svg = chartArea.append('svg')
        .attr('width','100%')
        .attr('height','100%')
        // draw x axis
        svg.append("g")
        .attr("class", "xAxis")
        .attr("transform", `translate(0,${margin.top + plotAreaHeight})`)
        .call(drawXAxis)
        // draw y axis
        svg.append("g")
        .attr("class", "yAxis")
        .attr("transform", `translate(${margin.left},0)`)
        .call(drawYAxis)

        // draw graph
        // update domain
        scaleX.domain([d3.min(data, getX), d3.max(data, getX)])
        scaleY.domain([d3.min(data, getY), d3.max(data, getY)])

        //redraw axis
        svg.select('.xAxis').call(drawXAxis)
        svg.select('.yAxis').call(drawYAxis)

        // draw data points
        svg.selectAll('.dot')
        .data(data, x => x['date_trunc']) // use time as the data key
        .join(
            enter => enter.append('circle')
                .attr('class', 'dot ')
                .attr('r', 2)
                .attr('fill', 'blue'),
            update => update,
            exit => exit
                .remove()
        )
        .attr('cx', x => scaleX(getX(x)))
        .attr('cy', x => scaleY(getY(x)))

        // draw trendline function
        let drawLine = d3.line()
        .defined(x => !!getY(x)) // only plot points with valid y values
        .x(x => scaleX(getX(x)))
        .y(x => scaleY(getY(x)))

        // add brush
        let brushFunc = d3.brushX()
        .extent( [ [margin.left,margin.top], [margin.left+plotAreaWidth,margin.top+plotAreaHeight] ] )
        .on("end", e => {
            if (!e.selection) return
            let selectedTimeRange = e.selection.map(x=> scaleX.invert(x))
            selectedTimeRange[1].setDate(selectedTimeRange[1].getDate()+1) // till end of the day
            this.setState({highlighted_timerange:selectedTimeRange})
        })
        // draw brush
        svg.selectAll('.brush')
        .data([true])
        .enter()
        .append('g')
        .attr("class", "brush")
        .call(brushFunc)

        svg.append('path')
        .attr('class','trendline')
        .attr('d', drawLine(data))
        .style('stroke-width', 1)
        .style('stroke', 'blue')
        .style('fill', 'none')
    }

    plotDailyStats(chartParent, data, x_key, y_key) {
        console.log('plotDailyStats...')
        console.assert(Array.isArray(data))
        if (data.length==0) return
        // init the basic DOM elements such as svg, axis, trendline
        let chartArea = d3.select(chartParent).append('div')
        .attr('class','statsChartArea')
        .style('width','100%')
        .style('height','100%')

        let chartAreaRect = chartArea.node().getBoundingClientRect()
        let margin = {top: 20, right: 80, bottom: 30, left: 60}
        let plotAreaHeight = chartAreaRect.height - margin.top - margin.bottom
        let plotAreaWidth = chartAreaRect.width - margin.left - margin.right

        let getX = d => new Date(d[x_key]) // data -> value
        let getY = d => Math.round(d[y_key])
        let scaleX = d3.scaleTime()
        .range([margin.left,margin.left + plotAreaWidth])
        let drawXAxis = d3.axisBottom(scaleX).ticks(6).tickFormat(d3.timeFormat('%d %b'))
        let scaleY = d3.scaleLinear()
        .range([margin.top + plotAreaHeight, margin.top])
        let drawYAxis = d3.axisLeft(scaleY).ticks(6)

        console.log

        // add the graph canvas to the body of the webpage
        let svg = chartArea.append('svg')
        .attr('width','100%')
        .attr('height','100%')
        // draw x axis
        svg.append("g")
        .attr("class", "xAxis")
        .attr("transform", `translate(0,${margin.top + plotAreaHeight})`)
        .call(drawXAxis)
        // draw y axis
        svg.append("g")
        .attr("class", "yAxis")
        .attr("transform", `translate(${margin.left},0)`)
        .call(drawYAxis)

        // draw graph
        // update domain
        scaleX.domain([d3.min(data, getX), d3.max(data, getX)])
        scaleY.domain([d3.min(data, getY), d3.max(data, getY)])

        //redraw axis
        svg.select('.xAxis').call(drawXAxis)
        svg.select('.yAxis').call(drawYAxis)

        // draw data points
        svg.selectAll('.dot')
        .data(data, x => x['date_trunc']) // use time as the data key
        .join(
            enter => enter.append('circle')
                .attr('class', 'dot ')
                .attr('r', 2)
                .attr('fill', 'blue'),
            update => update,
            exit => exit
                .remove()
        )
        .attr('cx', x => scaleX(getX(x)))
        .attr('cy', x => scaleY(getY(x)))

        // draw trendline function
        let drawLine = d3.line()
        .defined(x => !!getY(x)) // only plot points with valid y values
        .x(x => scaleX(getX(x)))
        .y(x => scaleY(getY(x)))

        svg.append('path')
        .attr('class','trendline')
        .attr('d', drawLine(data))
        .style('stroke-width', 1)
        .style('stroke', 'blue')
        .style('fill', 'none')

        // legend
        svg.append('text')
        .attr('class', 'legend')
        .attr('x',margin.left + chartAreaRect.width + 3)
        .attr('y', scaleY(getY(data[data.length - 1]))) // move label close to last data point
        .style('stroke-width',1)
        .style('stroke', 'blue')
        .style('font-size','10px')
        .text(y_key)
    }

    updateOwnResponse(response) {
        console.log(response)
        this.setState({currResponse:response})
    }

    updateOtherBeacon(beacon) {
        // console.log('updateotherbeacon:',beacon)
        if ( beacon.codename != this.props.payload.codename) return

        this.datas.push(beacon)
        if (this.datas.length > 50) {
            this.datas.splice(0,1) // delete earliest datapoint
        }

        this.setState(s => {
            s.currData = beacon
            this.props.setCurrData(beacon)
            s.dataCount = this.datas.length
            return s
        })
    }

    mesuUpdateOtherBeacon(beacon) {
        if ( beacon.codename != this.props.payload.codename) return

        this.datas.push(beacon)
        if (this.datas.length > 50) {
            this.datas.splice(0,1) // delete earliest datapoint
        }

        this.setState(s => {
            // init visibilities
            MesuComponent.sensors.forEach( sensorType => {
                if (s.visibilities[sensorType]==undefined) {
                    s.visibilities[sensorType] = true
                }
            })
            s.currData = beacon
            this.props.setCurrData(beacon)
            s.dataCount = this.datas.length
            return s
        })
        this.allChart?.updateGraph(sensorTypes, this.datas, this.state.visibilities)
        this.gasChart?.updateGraph(['NO','NO2','SO2','CO2','pm25','pm10'], this.datas, {NO:true,NO2:true,SO2:true,CO2:true,pm25:true,pm10:true})
        this.temphumidChart?.updateGraph(['Temperature','Humidity'], this.datas, {Temperature:true,Humidity:true})

    }

    parseTimestamp(str) {
        if (!str) return 'invalid timestamp'
        let timestamp = new Date(str)
        return timestamp.toISOString()
    }

    resetSensorVisibilities() {
        let sensorTypes = Object.entries(JSON.parse(this.state.currData)).filter(x => typeof x[1] == 'object' && 'Raw Data' in x[1]).map(x => x[0])
        let checked = Object.fromEntries([...this.sensorTable.getElementsByClassName('visibilityCheckbox')].map(x => [x.name,x.checked]))
        this.setState({visibilities:checked}, _ => {
            this.allChart?.updateGraph(sensorTypes, this.datas, this.state.visibilities)
        })
    }

    viewOne(sensorType) {
        let obj = Object.fromEntries(Object.entries(this.state.visibilities).map( x => x[0]==sensorType ? [x[0],true]: [x[0],false]))
        let sensorTypes = Object.entries(JSON.parse(this.state.currData)).filter(x => typeof x[1] == 'object' && 'Raw Data' in x[1]).map(x => x[0])
        this.setState({visibilities:obj}, _ => {
            console.log('updating graph with data:', this.datas)
            this.allChart?.updateGraph(sensorTypes, this.datas, obj)
        })
    }

    removeUserFromWhitelist = user_id => {
        // fetch()
    }

    sharePayload = _ => {
        this.setState({settings_state:'waiting', whitelist_token: null}, _ =>{
            fetch( PUBLIC_PATH_ROOT + 'auth/share_payload',{
                method: 'POST',
                headers: {'Content-Type': 'application/jsoncharset=utf-8'},
                body: JSON.stringify({token:this.props.credentials.token,payload_id: this.props.payload.id})
            })
            .then( res => res.json())
            .then( res => {
                if (res.success) {
                    this.setState({whitelist_token:res.token, settings_state:'sharing_payload'})
                } else throw res.reason
            })
            .catch( err => {
                console.log('share payload failed:',err)
                alert('share payload failed:' + err)
                this.setState({settings_state:'idle'})
            })
        })
    }

    whitelistAddUser = (user_id, payload_id) => {

        this.setState({settings_state:'waiting', whitelist_token: null}, _ =>{
            fetch( PUBLIC_PATH_ROOT + 'auth/payload_whitelist_add_user',{
                method: 'POST',
                headers: {'Content-Type': 'application/jsoncharset=utf-8'},
                body: JSON.stringify({token:this.props.credentials.token,user_id:user_id,payload_id:payload_id})
            })
            .then( res => res.json())
            .then( res => {
                if (res.success) {
                    this.setState({settings_state:'idle',foundUsers:null,userQuery:null,selected_user_id:null},this.updatePayloadWhitelist)
                } else throw res.reason
            })
            .catch( err => {
                console.log('whitelistAddUser failed:',err)
                alert('whitelistAddUser failed:' + err)
                this.setState({settings_state:'idle'})
            })
        })
    }

    handleUserQueryChange(user) {
        // remove timer
        if (this.state.userQueryTimerId) clearTimeout(this.state.userQueryTimerId)
        // query users after timeout
        this.setState({
            userQuery: user,
            userQueryTimerId: setTimeout( _ => {
                fetch( PUBLIC_PATH_ROOT + 'auth/query_users', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json;charset=utf-8' },
                    body: JSON.stringify({ token: this.props.credentials.token, querystring:user})
                })
                .then(res => res.json())
                .then(res => {
                    if (res.success) {
                        let users = res.users
                        console.log('newMissiongPage: found users:',res.users)
                        this.setState({
                            foundUsers: users,
                            userQueryTimerId: null
                        }, _ => {
                            if (this.userSelector) this.userSelector.click()
                        })
                        return
                    }
                    console.log('found users failed, reason:', res.reason)
                })
                .catch(err => {
                    console.log('found users: error:',err)
                })
            }, 200)
        })
    }


    addDataFormat = _ => {
        console.log('adding data format...')
        this.setState({rawdata_format_state:'waiting'}, _ =>{
            fetch( PUBLIC_PATH_ROOT + 'api/create_payload_rawdata_format',{
                method: 'POST',
                headers: {'Content-Type': 'application/jsoncharset=utf-8'},
                body: JSON.stringify({
                    token:this.props.credentials.token,
                    rawdata_format_content:JSON.stringify(this.state.temp_rawdata_format_content),
                    rawdata_format_description:this.state.temp_rawdata_format_description
                })
            })
            .then(res => res.json())
            .then(res => {
                if (res.success) {
                    console.log('success')
                    return this.fetchDataFormats()
                } else throw res.reason
            })
            .catch( err => {
                console.log('create data format failed:',err)
                alert('create data format failed:'+err)
            })
            .finally( _ => {
                this.setState({rawdata_format_state:'idle'})
            })
        })
    }

    onChange = e => {
        this.setState({ [e.target.name] : e.target.value })
    }


    fetchHistoricRawdata = _ => {
        let start = new Date(this.state.highlighted_timerange?.[0] ?? this.state.historic_rawdata_query_date_range.start ?? null)
        let end = new Date(this.state.highlighted_timerange?.[1] ?? this.state.historic_rawdata_query_date_range.end ?? null)
        end.setDate(end.getDate()+1)
        this.setState({historic_rawdatas_loading: true}, _ => {
            fetch( PUBLIC_PATH_ROOT + 'api/get_one_payload_rawdata_in_timerange', {
                method: 'POST',
                headers: {'Content-Type': 'application/jsoncharset=utf-8'},
                body: JSON.stringify({
                    token:this.props.credentials.token,
                    payload_id: this.props.payload.id,
                    start: start.toISOString(),
                    end: end.toISOString()
                })
            })
            .then( res => res.json())
            .then( res => {
                if (!res.success) throw res.reason
                let historic_rawdatas = res.results.sort( (a,b) => a.timestamp.localeCompare(b.timestamp))
                let sensorTypes = Object.keys(historic_rawdatas[0].content)
                let visibilities = {}
                sensorTypes.map( x => visibilities[x] = true)
                this.setState({
                    historic_gasdatas:historic_rawdatas.filter( x => x.payload_type == 'gas_sensor'),
                    historic_aisdatas:historic_rawdatas.filter( x => x.payload_type == 'radio_receiver'),
                    historic_napadatas: historic_rawdatas.filter( x => x.payload_type == 'napa_log'),
                    historic_chart_visibilities:visibilities
                })
            })
            .catch( err => {
                console.log('get all historic rawdata failed:',err)
                alert('get all historic rawdata failed:' + err)
            })
            .finally( _ => this.setState({historic_rawdatas_loading:false}))
        })
    }

    uploadRawdatas = _ => {
        console.log('rawdata_format_id:',this.state.selected_upload_rawdata_format_id)
        this.setState({rawdata_upload_state: 'uploading'}, _ => {
            fetch( PUBLIC_PATH_ROOT + 'api/upload_payload_rawdata', {
                method: 'POST',
                headers: {'Content-Type': 'application/jsoncharset=utf-8'},
                body: JSON.stringify({
                    token:this.props.credentials.token,
                    rawdata_format_id: this.state.selected_upload_rawdata_format_id,
                    rawdatas: JSON.stringify(this.state.temp_upload_rawdatas)
                })
            })
            .then( res => res.json())
            .then( res => {
                if (res.success) {
                    this.setState({
                        temp_rawdata_format_content: null,
                        temp_upload_rawdatas: null,
                        selected_upload_rawdata_format_id: null,
                        rawdata_upload_state: 'success'
                    })
                } else throw res.reason
            })
            .catch( err => {
                console.log('share payload failed:',err)
                alert('share payload failed:' + err)
                this.setState({
                    rawdata_upload_state: 'failed'
                })
            })
        })
    }

    validateRawdata = rawdata => {
        let selectedRawdataFormat = this.state.rawdata_formats.find(x => x.id == this.state.selected_upload_rawdata_format_id)
        return 'created_at' in rawdata && 'content' in rawdata && JSON.stringify(Object.keys(rawdata.content).sort()) == JSON.stringify(selectedRawdataFormat.content.sort())
    }

    validateRawdatas(rawdatas_str) {
        let rawdatas
        try {
            rawdatas = JSON.parse(rawdatas_str)
            return rawdatas?.every(this.validateRawdata) ?? false
        } catch {
            return false
        }
    }

    render() {
        return (
            <div id='payloadDetailPage'>
                {this.props.currPage == 'about' && <div>
                    <h3>Payload Information</h3>
                    <table class='table-normal full-width'><tbody>
                        <tr><th>Codename</th><td><code class='username'>{this.props.payload.codename}</code></td></tr>
                        <tr><th>Manufacturer</th><td>{this.props.payload.manufacturer}</td></tr>
                        <tr><th>Payload Type</th><td>{this.props.payload.payload_type}</td></tr>
                        <tr><th>Email</th><td>{this.props.payload.email}</td></tr>
                        <tr><th>Phone</th><td>{this.props.payload.phone}</td></tr>
                        <tr><th>Registered</th><td>{this.props.payload.created_at}</td></tr>
                    </tbody></table>
                </div>}

                {this.props.currPage == 'live' && <div>
                    <AmqpComponent.Detail
                        amqpState={this.props.amqpState}
                        amqpMsg={this.props.amqpMsg}
                        connectAmqpServer={ this.props.connectAmqpServer }
                        disconnectAmqpServer={ this.props.disconnectAmqpServer }
                        credentials={this.props.credentials}
                    />
                    <h3 ref={x => this.liveStatusSection = x}>Live Status</h3>
                    { this.state.currData ?
                        <p><svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="green"/></svg> active</p>
                        :
                        this.props.amqpState == 'disconnected' ?
                            <p><svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="red"/></svg> disconnected</p>
                            :
                            <p><svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="yellow"/></svg> waiting for data</p>
                    }

                    <h3>Video Stream</h3>
                    <button
                        class='button-normal'
                        onClick={ _ => {
                            fetch(PUBLIC_PATH_ROOT + 'live/payload_start_stream',{
                                method: 'POST',
                                headers: {'Content-Type': 'application/jsoncharset=utf-8'},
                                body: JSON.stringify({token:this.props.credentials.token,codename:this.props.payload.codename})
                            })
                            .then( res => res.json())
                            .then( rs => {
                                if (rs.success) {
                                    console.log('start stream request sent.')
                                }
                                else throw rs
                            })
                            .catch( err => {
                                console.log('err:',err)
                            })
                        }}
                    >Start On-board Streaming</button>
                    <button
                        class='button-normal'
                        onClick={ _ => {
                            fetch(PUBLIC_PATH_ROOT + 'live/payload_stop_stream',{
                                method: 'POST',
                                headers: {'Content-Type': 'application/jsoncharset=utf-8'},
                                body: JSON.stringify({token:this.props.credentials.token,codename:this.props.payload.codename})
                            })
                            .then( res => res.json())
                            .then( rs => {
                                if (rs.success) {
                                    console.log('start stream request sent.')
                                }
                                else throw rs
                            })
                            .catch( err => {
                                console.log('err:',err)
                            })
                        }}
                    >Stop On-board Streaming</button>
                    <VideoRoomComponent
                        credentials={this.props.credentials}
                        janusRoomId={`payload_${this.props.payload.id}`}
                        janusUsername={this.props.credentials.username ||
                            this.props.credentials.codename
                        }
                    />

                    <h3>Control Panel</h3>
                    { this.props.payload.payload_type == 'bhs' &&
                        <BhsComponent.ControlPanel
                            credentials={this.props.credentials}
                            payload={this.props.payload}
                            response={this.state.currResponse}
                        />
                    }

                    <h3>Current Sensor Values</h3>
                    { this.state.currData ?
                        <div id='liveDataContainer'>
                            <div id='sensorBlock'>
                                <details>
                                    <summary>Raw Format</summary>
                                    {JSON.stringify(this.state.currData)}
                                </details>
                            </div>
                        </div>
                        :
                        <p>No Data Available</p>
                    }
                    { this.state.mesuCurrData ?
                        <div id='liveDataContainer'>
                            <div id='sensorBlock'>
                                <details>
                                    <summary>Raw Format</summary>
                                    {this.state.currData}
                                </details>
                                <table class='table-normal' id='sensorDetailTable' ref={x=>this.sensorTable = x}>
                                    <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th>Value</th>
                                            <th>Unit</th>
                                            <th>Preview</th>
                                            <th>Show
                                                <input
                                                    class='allCheckbox'
                                                    name='checkAll'
                                                    type='checkbox'
                                                    defaultChecked
                                                    onChange={ e => {
                                                        [...document.getElementsByClassName('visibilityCheckbox')].forEach( x=>{
                                                            x.checked = e.target.checked
                                                        })
                                                        this.resetSensorVisibilities()
                                                    }}
                                                />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr><td>Timestamp</td><td>{this.parseTimestamp(JSON.parse(this.state.currData)['Time'])}</td><td/></tr>
                                        { Object.entries(JSON.parse(this.state.currData)).filter(x => typeof x[1] == 'object' && 'Raw Data' in x[1]).map( x =>
                                            <tr key={x[0]}>
                                                <td>{x[0]}</td>
                                                <td>{x[1]['Raw Data']}</td>
                                                <td>{x[1]['Units']}</td>
                                                <td>
                                                    <button
                                                        class='hover-button-normal'
                                                        onMouseEnter={_ => this.viewOne(x[0])}
                                                        onMouseLeave={_ => this.resetSensorVisibilities()}
                                                    >preview</button>
                                                </td>
                                                <td>
                                                    <input
                                                        class='visibilityCheckbox'
                                                        name={x[0]}
                                                        type='checkbox'
                                                        defaultChecked
                                                        onChange={x => this.resetSensorVisibilities(x.target.name, x.target.checked)}
                                                    />
                                                </td>
                                            </tr>
                                        ) }
                                    </tbody>
                                </table>
                            </div>

                            <div id='chartBlock' ref={x => this.chartBlock = x}>
                                <h3>Plot of {this.state.dataCount} points</h3>

                                <h4>All Sensors Chart</h4>
                                <PayloadChart chartBlock={this.chartBlock} ref={x => this.allChart = x}/>

                                <h4>Gas Sensor Chart</h4>
                                <PayloadChart chartBlock={this.chartBlock} ref={x => this.gasChart = x}/>

                                <h4>Temperature/Humidity Chart</h4>
                                <PayloadChart chartBlock={this.chartBlock} ref={x => this.temphumidChart = x}/>
                            </div>

                        </div>
                        :
                        <p>No Data Available</p>
                    }


                </div>}

                {/* this.props.currPage == 'historic' && <div>
                    <h3 ref={x => this.historicDataSection = x}>Historic Data</h3>

                    <h4>Daily Data Count</h4>
                    <div id='statsChart' ref={x => this.statsChart = x}/>

                    <label for='date-range'>Show Data in the Time Range:</label>
                    <select
                        name='historic_rawdata_query_date_range'
                        value={JSON.stringify(this.state.historic_rawdata_query_date_range)}
                        onChange={e => {
                            let timerange = JSON.parse(e.target.value)
                            let start = new Date(timerange.start)
                            let end = new Date(timerange.end)
                            this.calender_start_picker.setYearMonth(start.getFullYear(),start.getMonth())
                            this.calender_start_picker.selectDate(start)
                            this.calender_end_picker.setYearMonth(end.getFullYear(),end.getMonth())
                            this.calender_end_picker.selectDate(end)
                            this.setState({
                                [e.target.name] : timerange
                            })
                        }}
                    >
                        <option
                            value={ {
                                start: null,
                                end: null
                            }}
                        >Select Range...</option>
                        <option
                            value={ JSON.stringify({
                                start: new Date(Date.now()-3600000).toISOString(),
                                end: new Date(Date.now()).toISOString()
                            })}
                        >Since the Last Hour</option>
                        <option
                            value={ JSON.stringify({
                                start: new Date(Date.now()-86400000).toISOString(),
                                end: new Date(Date.now()).toISOString()
                            })}
                        >Since the Last Day</option>
                        <option
                            value={ JSON.stringify({
                                start: new Date(Date.now()-604800000).toISOString(),
                                end: new Date(Date.now()).toISOString()
                            })}
                        >Since the Last Week</option>
                    </select>
                    <table><tbody><tr>
                        <td>
                            <label for='historic_rawdata_query_date_range.start'>Start Time</label>
                            <Calender
                                ref= { x=> this.calender_start_picker = x}
                                onSetYearMonth={ (year,month) => { console.debug('mont,year:', month,year) } }
                                onDateSelected={ date => this.setState( s => {
                                    s.historic_rawdata_query_date_range.start = date.toISOString()
                                    return s
                                })}
                            />
                            <input
                                name='historic_rawdata_query_date_range.start'
                                value={this.state.historic_rawdata_query_date_range?.start ?? ''}
                                onChange={e => this.setState( s=>{
                                    s.historic_rawdata_query_date_range.start = e.target.value
                                    return s
                                })}
                            />
                        </td>
                        <td>
                            <label for='historic_rawdata_query_date_range.end'>End Time</label>
                            <Calender
                                ref= { x=> this.calender_end_picker = x}
                                onDateSelected={ date => this.setState( s => {
                                    s.historic_rawdata_query_date_range.end = date.toISOString()
                                    return s
                                })}
                            />
                            <input
                                name='historic_rawdata_query_date_range.end'
                                value={this.state.historic_rawdata_query_date_range?.end ?? ''}
                                onChange={e => this.setState( s=>{
                                    s.historic_rawdata_query_date_range.end = e.target.value
                                    return s
                                })}
                            />
                        </td>
                    </tr></tbody></table>


                    <button
                        onClick={this.searchHistoricRawdata}
                        className='button-large'
                    >
                        Search Historic Data
                    </button>
                    <button
                        onClick={this.getAllHistoricRawdata}
                        className='button-large'
                    >
                        Get All Historic RawData
                    </button>
                    { this.state.historic_rawdatas && !this.state.show_historic_rawdatas &&
                        <button class='button-large' onClick={_ => this.setState({show_historic_rawdatas:true}) } >Show Rawdata</button>
                    }
                    { this.state.historic_rawdatas && this.state.show_historic_rawdatas &&
                        <button class='button-large' onClick={_ => this.setState({show_historic_rawdatas:false}) } >Hide Rawdata</button>
                    }
                    { this.state.historic_rawdatas && this.state.show_historic_rawdatas &&
                        <div id='search-result-container'>
                            <h3>Search Results</h3>
                            <table>
                                <thead><tr><th>Timestamp</th><th>Content</th></tr></thead>
                                <tbody>
                                    { this.state.historic_rawdatas == null ?
                                        <tr><td colSpan='2'>Rawdata Not Loaded</td></tr>
                                    :
                                        this.state.historic_rawdatas.map( (x,i) =>
                                            <tr key={i}><td>{x.timestamp}</td><td>{JSON.stringify(x.content)}</td></tr>
                                        )
                                    }
                                </tbody>
                            </table>
                            <input
                                name='historic_rawdata_pagination_id'
                                value={this.state.historic_rawdata_pagination_id ?? 1}
                                onChange={e => this.setState({historic_rawdata_pagination_id: parseInt(e.target.value)})}
                                onBlur={this.searchHistoricRawdata}
                            />
                            <button
                                disabled={this.state.historic_rawdata_pagination_id<=1}
                                onClick={ _ => {
                                    this.setState({historic_rawdata_pagination_id:this.state.historic_rawdata_pagination_id-1},
                                    this.searchHistoricRawdata)
                                }}
                                class='button-normal'
                            >Previous Page</button>
                            <button
                                onClick={ _ => {
                                    this.setState({historic_rawdata_pagination_id:this.state.historic_rawdata_pagination_id+1}
                                    ,this.searchHistoricRawdata)
                                }}
                                class='button-normal'
                                disabled={!!this.state.historic_rawdatas == false}
                            >Next Page</button>
                        <button
                            class='button-large'
                            onClick={ _ => this.convertData() }
                        >
                            Plot Graph
                        </button>
                        <PayloadChart ref={x => this.historicChart = x}/>

                        </div>
                    }
                    { this.state.historic_rawdatas &&
                        <div id='payloadMapContainer'>
                            <PayloadMapComponent historic_rawdatas={this.state.historic_rawdatas}/>
                        </div>
                    }
                    { this.state.historic_rawdatas &&
                        <PayloadHistoricChart historic_rawdatas={this.state.historic_rawdatas}/>
                    }
                </div> */}

                {this.props.currPage == 'historic' && <div>
                    <h3>Historic Data</h3>
                    <h4>Daily Data Count</h4>
                    <div id='statsChart' ref={x => this.statsChart = x}/>
                    <button
                        style={{display:'block'}}
                        disabled={!this.state.historic_rawdata_query_date_range.start || !this.state.historic_rawdata_query_date_range.end}
                        onClick={this.fetchHistoricRawdata}
                        className={'button-large' + (this.state.historic_rawdatas_loading ? ' busy' : '')}
                    >
                        Get All Historic RawData
                    </button>
                    { this.state.historic_rawdatas &&
                        <HistoricChartComponent.Chart
                            visibilities={this.state.historic_chart_visibilities}
                            historic_rawdatas={this.state.historic_rawdatas}
                            onDataFiltered={ x => this.setState({historic_rawdatas_filtered:x})}
                        />
                    }
                    { this.state.historic_rawdatas &&
                        <HistoricChartComponent.ControlPanel
                            visibilities={this.state.historic_chart_visibilities}
                            setVisibilities={x => this.setState({historic_chart_visibilities:x})}
                        />
                    }
                </div>}

                { this.props.currPage=='settings' && <div>
                    <h3 ref={x=>this.settingsSection=x}>Payload Settings</h3>
                    <h3>Conversion Formula</h3>
                    <textarea
                        type='text'
                        name='conversionFormula'
                        value={this.state.conversionFormula ?? ''}
                        onChange={this.onChange}
                    />

                    <div>
                        <h3>Whitelist</h3>
                        <table class='table-normal full-width'>
                            <thead><tr><th>Usernames</th><th>Actions</th></tr></thead>
                            <tbody>
                            { this.state.whitelist != null ?
                                this.state.whitelist.length ?
                                    this.state.whitelist.map( x =>
                                        <tr key={x.id}>
                                            <td>{x.username}</td>
                                            <td><button className='button-normal' onClick={this.removeUserFromWhitelist}>Remove</button></td>
                                        </tr>
                                    )
                                :
                                <tr><td colSpan='2'>Whitelist is empty</td></tr>
                            :
                                <tr><td colSpan='2'>Whitelist not accessible</td></tr>
                            }
                            </tbody>
                        </table>

                        { this.state.whitelist_state =='idle' && this.state.whitelist_token == null &&
                            <button
                                class='button-large'
                                onClick={this.addUserToWhitelist}
                            >
                                Add User to Whitelist
                            </button>
                        }
                        { this.state.whitelist_token &&
                            <div>
                                <input ref={x=>this.whitelist_token = x} readOnly value={this.state.whitelist_token}/>
                                <p>share this token to another user to access this payload.</p>
                                <button
                                    class='button-large'
                                    onClick={ _ => {
                                        this.whitelist_token.focus()
                                        this.whitelist_token.select()
                                        if (document.execCommand('copy')) {
                                            alert('token copied to clipboard')
                                        }
                                    }}
                                >
                                    Copy Token to Clipboard
                                </button>
                                <button
                                    class='button-large'
                                    onClick={ _ => this.setState({whitelist_state:'idle',whitelist_token:null})}
                                >
                                    Finish
                                </button>
                            </div>
                        }

                    </div>
                </div>}


                {this.props.currPage=='upload' && <div>
                    <h3>Rawdata Formats</h3>
                    <table class='table-normal full-width'>
                        <thead>
                            <tr><th>ID</th><th>Content</th><th>Description</th></tr>
                        </thead>
                        <tbody>
                        {   this.state.rawdata_formats != null ?
                            this.state.rawdata_formats.length ?
                                this.state.rawdata_formats.map( (x,i) =>
                                    <tr key={i}><td>{x.id}</td><td>{x.content.join(',')}</td><td>{x.description}</td></tr>
                                )
                            :
                                <tr><td colSpan='3'>Rawdata Format List is Empty</td></tr>
                        :
                            <tr><td colSpan='3'>Rawdata Formats Not Accesible</td></tr>
                        }
                        </tbody>
                    </table>
                    { this.state.rawdata_format_state == 'ready' && <div>
                        <label for='temp_rawdata_format_content'>Specify Rawdata Format. This must be a JSON array of strings that are identical to the keys of your data.</label>
                        <input
                            name='temp_rawdata_format_content'
                            placeholder='["Temperature","Pressure"]'
                            type='text'
                            value={this.state.temp_rawdata_format_content ?? ''}
                            onChange={this.onChange}
                        />
                        <label for='temp_rawdata_timestamp_conversion_script'>JavaScript code that returns the timestamp from rawdata</label>
                        <textarea
                            name='temp_rawdata_timestamp_conversion_script'
                            placeholder='x.Time'
                            value={this.state.temp_rawdata_timestamp_conversion_script ?? ''}
                            onChange={this.onChange}
                        />
                        <label for='temp_rawdata_format_description'>Description or Remarks</label>
                        <textarea
                            name='temp_rawdata_format_description'
                            placeholder='temperature sensor changed to brand B'
                            value={this.state.temp_rawdata_format_description ?? ''}
                            onChange={this.onChange}
                        />

                        <label for='temp_rawdata_sample'>Paste your Rawdata Sample JSON object here to test:</label>
                        <textarea
                            name='temp_rawdata_sample'
                            placeholder='paste your json object here'
                            value={this.state.temp_rawdata_sample ?? ''}
                            onChange={this.onChange}
                        />
                        <p>Timestamp parsed: {this.convertTimestamp(this.state.temp_rawdata_sample,this.state.temp_rawdata_timestamp_conversion_script)}</p>
                        <p>Valid: { isJsonString(this.state.temp_rawdata_format_content) && JSON.parse(this.state.temp_rawdata_format_content).every?.( x=> typeof x == 'string' && !!x ) && JSON.parse(this.state.temp_rawdata_format_content).length > 0 ? 'true' : 'false'}</p>
                        <button
                            class='button-large'
                            disabled={this.state.rawdata_format_state != 'ready' || !this.state.temp_rawdata_format_content}
                            onClick={this.addDataFormat}
                        >
                            Create Rawdata Format
                        </button>
                        <button
                            class='button-large'
                            onClick={ _ => this.setState({rawdata_format_state:'idle',temp_rawdata_format_content:null})}
                        >
                            Cancel
                        </button>
                    </div>}
                    { this.state.rawdata_format_state == 'idle' && this.props.credentials.user_role == 'payload' &&
                        <button
                            class='button-large'
                            onClick={ _ => this.setState({rawdata_format_state:'ready'})}
                        >
                            Add Rawdata Format
                        </button>
                    }

                    { this.props.credentials.user_role == 'payload' && <div>
                        <h3>Upload Raw Data</h3>
                        <label for='selected_upload_rawdata_format_id'>Select Rawdata Format</label>
                        <select
                            name='selected_upload_rawdata_format_id'
                            value={this.state.selected_upload_rawdata_format_id ?? ''}
                            onChange={this.onChange}
                        >
                            <option value=''>Select Rawdata Format</option>
                            { this.state.rawdata_formats && this.state.rawdata_formats.map( (x,i) =>
                                <option key={i} value={x.id}>{x.id + ':' + x.content}</option>
                            )}
                        </select>
                        { this.state.selected_upload_rawdata_format_id != null && <div>
                            <label for='temp_upload_rawdatas'>Rawdatas</label>
                            <textarea
                                name='temp_upload_rawdatas'
                                onChange={this.onChange}
                                placeholder='[{"timestamp":"2021-06-14T01:30:39.611Z","content":{"a":123}},{"timestamp":"2021-06-14T01:31:39.611Z","content":{"a":234}}]'
                            />
                            <button
                                onClick={this.uploadRawdatas}
                                className='button-large'
                                disabled={!this.state.selected_upload_rawdata_format_id || !isJsonString(this.state.temp_upload_rawdatas)}
                            >Upload Rawdata</button>
                        </div> }
                    </div>}
                </div>}
            </div>
        )
    }
}
