// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/dial_flat_base.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../assets/images/dial_flat_body.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialBase {
    height: 200px;
    width: 200px;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.dialBody {
    height: 100%;
    width: 100%;
    border-radius: 50%; /* set hit box to circle*/
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.dialRay {

}`, "",{"version":3,"sources":["webpack://./src/components/dial/dial.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,yDAAkE;IAClE,4BAA4B;IAC5B,2BAA2B;IAC3B,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB,EAAE,yBAAyB;IAC7C,yDAAkE;IAClE,4BAA4B;IAC5B,2BAA2B;IAC3B,sBAAsB;AAC1B;;AAEA;;AAEA","sourcesContent":[".dialBase {\n    height: 200px;\n    width: 200px;\n    background-image: url('../../../assets/images/dial_flat_base.svg');\n    background-repeat: no-repeat;\n    background-position: center;\n    background-size: cover;\n}\n\n.dialBody {\n    height: 100%;\n    width: 100%;\n    border-radius: 50%; /* set hit box to circle*/\n    background-image: url('../../../assets/images/dial_flat_body.svg');\n    background-repeat: no-repeat;\n    background-position: center;\n    background-size: cover;\n}\n\n.dialRay {\n\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
