// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#trafficViewer {
    position: relative;
}

#mapsContainer {
    height: 400px;
}

.flex {
    display: flex;
    flex-flow: wrap;
    gap: 8%;
}

#highZoomBlock {
    display: inline-block;
    /* to remove gap between divs */
    font-size: 0;
    height: 100%;
    width: 65%;
    margin: 0px;
}

#lowZoomBlock {
    float: right;
    top: 50px;
    /* to remove gap between divs */
    font-size: 0;
    height: 100%;
    width: 35%;
    margin: 0px;
}

#highZoomBlock .separatorBlock {
    background-color: white;
    display: inline-block;
    height: 50px;
    width: 50px;
}

#highZoomBlock #ruler_lon {
    display: inline-block;
    width: calc(100% - 50px);
    height: 50px;
}

#highZoomBlock #ruler_lat {
    display: inline-block;
    height: 350px;
    width: 50px;
}

#highZoomBlock .mapContainer {
    font-size: 12px;
    display: inline-block;
    width: calc(100% - 50px);
    height:350px;
}

#lowZoomBlock .mapContainer {
    font-size: 12px;
    display: inline-block;
    width: 100%;
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/trafficViewer.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,OAAO;AACX;;AAEA;IACI,qBAAqB;IACrB,+BAA+B;IAC/B,YAAY;IACZ,YAAY;IACZ,UAAU;IACV,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,SAAS;IACT,+BAA+B;IAC/B,YAAY;IACZ,YAAY;IACZ,UAAU;IACV,WAAW;AACf;;AAEA;IACI,uBAAuB;IACvB,qBAAqB;IACrB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,qBAAqB;IACrB,wBAAwB;IACxB,YAAY;AAChB;;AAEA;IACI,qBAAqB;IACrB,aAAa;IACb,WAAW;AACf;;AAEA;IACI,eAAe;IACf,qBAAqB;IACrB,wBAAwB;IACxB,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,qBAAqB;IACrB,WAAW;IACX,YAAY;AAChB","sourcesContent":["#trafficViewer {\n    position: relative;\n}\n\n#mapsContainer {\n    height: 400px;\n}\n\n.flex {\n    display: flex;\n    flex-flow: wrap;\n    gap: 8%;\n}\n\n#highZoomBlock {\n    display: inline-block;\n    /* to remove gap between divs */\n    font-size: 0;\n    height: 100%;\n    width: 65%;\n    margin: 0px;\n}\n\n#lowZoomBlock {\n    float: right;\n    top: 50px;\n    /* to remove gap between divs */\n    font-size: 0;\n    height: 100%;\n    width: 35%;\n    margin: 0px;\n}\n\n#highZoomBlock .separatorBlock {\n    background-color: white;\n    display: inline-block;\n    height: 50px;\n    width: 50px;\n}\n\n#highZoomBlock #ruler_lon {\n    display: inline-block;\n    width: calc(100% - 50px);\n    height: 50px;\n}\n\n#highZoomBlock #ruler_lat {\n    display: inline-block;\n    height: 350px;\n    width: 50px;\n}\n\n#highZoomBlock .mapContainer {\n    font-size: 12px;\n    display: inline-block;\n    width: calc(100% - 50px);\n    height:350px;\n}\n\n#lowZoomBlock .mapContainer {\n    font-size: 12px;\n    display: inline-block;\n    width: 100%;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
