import React, { Fragment } from 'react'
import '../../style.css'
import './poi.css'
import * as MissionEditor from '../missionEditor/missionEditor'

export class PoiCreator extends React.Component {

    constructor(props) {
        super(props)
        if (this.props.existing_poi) console.log('existing poi:',this.props.existing_poi)
        this.state = {
            poi_type: this.props.existing_poi?.poi_type ?? null,
            lonlat: this.props.existing_poi?.centroid.coordinates ?? null
        }
        this.props.map.plotTempPoiMarker(this.props.existing_poi?.centroid.coordinates)
    }

    componentDidMount() {
    }

    plotBridges = _ => {
        fetch(PUBLIC_PATH_ROOT + 'api/bridges',{
            method: 'POST',
            body: JSON.stringify({token:this.props.credentials.token,poi_id:poi_id})
        })
        .then(rs=>rs.json())
        .then(rs=>{
            if (!rs.success) throw rs.reason
            this.setState({existing_poi:null}, _ => this.props.routePreviousPage())
        })
        .catch(err=>console.log('err:',err))
    }

    deletePoi = poi_id => {
        console.log('delete poi...')
        fetch(PUBLIC_PATH_ROOT + 'api/delete_poi',{
            method: 'POST',
            body: JSON.stringify({token:this.props.credentials.token,poi_id:poi_id})
        })
        .then(rs=>rs.json())
        .then(rs=>{
            if (!rs.success) throw rs.reason
            this.setState({existing_poi:null}, _ => this.props.routePreviousPage())
        })
        .catch(err=>console.log('err:',err))
    }

    createPoi = _ => {
        console.log('creating poi...')
        Promise.resolve()
        .then( _ => {
            let lonlat = this.state.lonlat ?? this.state.selectedOsmPoi?.centroid.coordinates

            if (!this.state.poi_type) throw 'poi_type missing'
            if (!lonlat) throw 'lonlat missing'
            return fetch(PUBLIC_PATH_ROOT + 'api/create_poi',{
                method:'POST',
                body: JSON.stringify({
                    token:this.props.credentials.token,
                    mission_id: this.props.mission_id,
                    osm_id: this.state.selectedOsmPoi?.osm_id,
                    name: this.state.poi_name,
                    description: this.state.poi_description,
                    poi_type: this.state.poi_type,
                    lon: lonlat[0],
                    lat: lonlat[1]
                })
            })
        })
        .then(rs=>rs.json())
        .then(rs=>{
            if (!rs.success) throw rs.reason
            console.log('poi:',rs.poi)
            this.props.routePreviousPage()
        })
        .catch(err=>console.log('err:',err))
    }


    render() {

        return <div>
            <h3>New Poi</h3>

            <label>Poi Type</label>
            <select
                value={this.state.poi_type ?? 'null'}
                onChange={ e => {
                    this.props.map.eraseAllOsmPois()
                    this.setState({poi_type:e.target.value}, _ => this.props.map.triggerMoveEnd())
                }}
            >
                <option disable='true' hidden value={null}>Select Poi Type</option>
                <option value='building'>Building</option>
                <option value='bridge'>Bridge</option>
                <option value='aeroway'>Aeroway</option>
                <option value='quay'>Quay</option>
                <option value='lock'>Lock</option>
            </select>

            <div
                class='poiMapTooltip'
                style={{
                    left:`${((this.state.mapState?.event?.pageX - this.mapContainer?.getBoundingClientRect().left) || 0 )+ 10}px`,
                    top:`${((this.state.mapState?.event?.pageY - this.mapContainer?.getBoundingClientRect().top) || 0 ) + 10}px`,
                    opacity:this.state.mapState?.showTooltip ? 1:0
                }}>
                { this.state.mapState?.feature?.get('poi_type') == 'bridge' && <Fragment>
                    <p>id: <code>{this.state.mapState?.feature?.get('osm_id') ?? 'id'}</code></p>
                    <p>tags: <code>{this.state.mapState?.feature?.get('tags') ?? ''}</code></p>
                    <p>pos: <code>{this.state.mapState?.coordinates?.[0].toFixed(3) ?? ''},{this.state.mapState?.coordinates?.[1].toFixed(3) ?? ''}</code></p>
                    <p>name: <code>{this.state.mapState?.feature?.get('name') ?? ''} deg</code></p>
                </Fragment>}
            </div>

            { this.state.poi_type && <div>
                <label>POI Location</label>
                <MissionEditor.Viewer
                    map={this.props.map}
                    mapSize={'medium'}
                    ref={x=> this.missionEditor = x}
                    currPage='add_poi'
                    onClick= { x => {
                        this.setState({lonlat:x})
                    }}
                    onOsmPoiSelected={ x => {
                        this.setState({selectedOsmPoi:x, lonlat: null})
                    }}
                    onOsmPoiDeselected={ _ => {
                        this.setState({selectedOsmPoi:null})
                    }}
                    onMapMoveEnd={ x =>{
                        fetch(PUBLIC_PATH_ROOT + `api/${this.state.poi_type}s?xmin=${x.extent[0]}&ymin=${x.extent[1]}&xmax=${x.extent[2]}&ymax=${x.extent[3]}&srid=3857`)
                        .then(rs=>rs.json())
                        .then(rs=>{
                            if (!rs.success) throw rs.reason
                            rs[this.state.poi_type + 's'].map( x => this.props.map.plotOsmPoi(x))
                        })
                        .catch(err=>console.log('err:',err))
                    }}
                />

                <div class='flex'>
                { (this.state.lonlat ?? this.state.selectedOsmPoi?.centroid) &&
                    <div>
                        <label>Location</label>
                        <table class='table-normal'>
                            <thead>
                                <tr><th>Key</th><th>Value</th></tr>
                            </thead>
                            <tbody>
                                <tr><td>Lon</td><td>{this.state.lonlat?.[0] ?? this.state.selectedOsmPoi?.centroid.coordinates[0]}</td></tr>
                                <tr><td>Lat</td><td>{this.state.lonlat?.[1] ?? this.state.selectedOsmPoi?.centroid.coordinates[1]}</td></tr>
                            </tbody>
                        </table>
                    </div>
                }

                { this.state.selectedOsmPoi && <Fragment>
                    <div>
                        <label>osm properties</label>
                        <table class='table-normal'>
                            <thead>
                                <tr><th>Key</th><th>Value</th></tr>
                            </thead>
                            <tbody>
                                <tr><td>id</td><td>{this.state.selectedOsmPoi.osm_id}</td></tr>
                                {this.state.selectedOsmPoi.aeroway &&
                                    <tr><td>aeroway</td><td>{this.state.selectedOsmPoi.aeroway}</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    
                    <div>
                        <label>tags</label>
                        <table class='table-small'>
                            <thead>
                                <tr><th>Key</th><th>Value</th></tr>
                            </thead>
                            <tbody>
                                { Object.keys(this.state.selectedOsmPoi.tags).length ?
                                    Object.entries(this.state.selectedOsmPoi.tags).map( (x,i) =>
                                        <tr key={i}><td>{x[0]}</td><td>{x[1]}</td></tr>
                                    )
                                    :
                                    <tr><td colSpan='2'>There are no tags</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </Fragment>}
                </div>

                <label>Name</label>
                <input
                    class='input-fullwidth'
                    value={this.state.poi_name ?? ''}
                    onChange={e => this.setState({ poi_name: e.target.value})}
                />

                <label>Description</label>
                <input
                    class='input-fullwidth'
                    value={this.state.poi_description ?? ''}
                    onChange={e => this.setState({ poi_description: e.target.value})}
                />


            </div>}

            <br/>
            { this.props.existing_poi &&
                <button class='button-large' onClick={_ => this.deletePoi(this.props.existing_poi.id)}>Delete POI</button>
            }
            { this.props.existing_poi ?
                <button class='button-large' onClick={this.createPoi}>Overwrite POI</button>
                :
                <button
                    class='button-large'
                    onClick={this.createPoi}
                >Create POI</button>
            }

            <button class='button-large' onClick={this.props.routePreviousPage}>Cancel</button>
        </div>
    }

}