// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qrcode {
    margin: 100px 0;
}
.qrcode img {
    image-rendering: crisp-edges;
}`, "",{"version":3,"sources":["webpack://./src/components/qrcode/qrcode.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,4BAA4B;AAChC","sourcesContent":[".qrcode {\n    margin: 100px 0;\n}\n.qrcode img {\n    image-rendering: crisp-edges;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
