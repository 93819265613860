import React, {Fragment} from 'react'
import './phone.css'
import '../../style.css'

export default class RegisterPhoneComponent extends React.Component {
    constructor(props) {
        super(props)
        console.log('authenticator page, token:',this.props.token)
        this.state = {
            state:'idle',
            phone_url: null,
            sms_sent: null
        }
        window.history.pushState(null,'register_phone','#register_phone')
        window.onpopstate = () => this.props.routeLoginPage()
    }

    componentDidMount() {
    }

    goToLogin = _ => this.props.routeLoginPage()

    requestRegisterPhone = _ => {
        this.setState({
            state: 'sending'
        }, _ => {
            fetch(PUBLIC_PATH_ROOT + 'auth/request_register_phone', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify({
                    preauth_token: this.props.preauth_token,
                    phone: this.state.phone
                })
            })
            .then(res => res.json())
            .then(rs => {
                if (!rs.success) {
                    throw rs
                }
                this.setState({sms_sent: true})
            })
            .catch( err => {
                console.log('err:', err)
                alert('Request failed, reason: ' + err)
                this.setState({state: 'idle'})
            })
        })
    }

    confirmPhone() {
        this.setState({
            state: 'sending'
        }, _ => {
            fetch(PUBLIC_PATH_ROOT + 'auth/verify_phone_registration', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify({
                    preauth_token: this.props.preauth_token,
                    sms_code: this.state.sms_code
                })
            })
            .then(res => res.json())
            .then(rs => {
                // console.log('rs:',rs)
                if (!rs.success) {
                    throw rs
                }
                alert('Request for Phone registration successful.')
                return this.props.routeLoginPage()
            })
            .catch( err => {
                console.log('err:', err)
                alert('Request failed, reason: ' + err)
                this.setState({state: 'idle'})
            })
        })
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    render () {
        return (
            <div id='registerPhoneContainer'>
                <div className='formContainer'>

                <div id='titleContainer'>
                    <h2><b>Phone Registration</b></h2>
                </div>

                <p>Step 1: Enter your Phone Number.</p>
                    <label for='phone'>Phone Number</label>
                    <input
                        disabled={this.state.sms_sent}
                        class='input-fullwidth'
                        value={this.state.phone ?? ''}
                        onChange={this.onChange}
                        placeholder='+4917699999999'
                        name='phone'
                        type='text'
                    />
                    <button
                        disabled={this.state.sms_sent}
                        className='button-large fullWidthButton'
                        onClick={ _ => this.requestRegisterPhone()}>
                        Confirm
                    </button>

                { this.state.sms_sent && <Fragment>
                    <p>Step 2: Enter the 6-digit Phone Code to confirm.</p>
                    <label for='sms_code'>SMS Code</label>
                    <input
                        class='input-fullwidth'
                        value={this.state.sms_code ?? ''}
                        onChange={this.onChange}
                        placeholder='000000'
                        name='sms_code'
                        type='text'
                    />
                    <button
                        className='button-large fullWidthButton'
                        onClick={ _ => this.confirmPhone()}>
                        Confirm
                    </button>
                </Fragment>}

                <button
                    id = 'cancelButton'
                    className='button-large fullWidthButton'
                    onClick={ _ => this.goToLogin()}>
                    Go Back to Login
                </button>
                </div>
            </div>
        )
    }
}