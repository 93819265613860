import React, { Fragment } from 'react'

export class ControlPanel extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    sendRequest (request_type,content = null) {
        fetch(PUBLIC_PATH_ROOT + 'live/payload_bhs_send_request',{
            method: 'POST',
            headers: {'Content-Type': 'application/jsoncharset=utf-8'},
            body: JSON.stringify({
                token:this.props.credentials.token,
                codename:this.props.payload.codename,
                request_type:request_type,
                content: content
            })
        })
        .then( res => res.json())
        .then( rs => {
            if (!rs.success) throw rs
        })
        .catch( err => {
            console.log('err:',err)
        })
    }


    render () {
        return (<div>
            <h3>Config</h3>
            <code>{this.state.config}</code>
            <button class='button-normal' onClick={ _ => this.sendRequest('print_config')}
            >Print Config</button>
            <button class='button-normal'  onClick={ _ => this.sendRequest('init_pins')}
            >Setup GPIO Pins</button>
            <button class='button-normal'  onClick={ _ => this.sendRequest('status')}
            >Print GPIO Status</button>
            <button class='button-normal'  onClick={ _ => this.sendRequest('check')}
            >Check Components</button>
            <hr/>
            <label>Select Motor(s)</label>
            <select>
                <option value='1'>Motor 1</option>
                <option value='2'>Motor 2</option>
                <option value='2'>Motor 1+2</option>
                <option value='3'>Motor 3</option>
                <option value='4'>Motor 4</option>
                <option value='2'>Motor 3+4</option>
                <option value='5'>Motor 5</option>
                <option value='6'>Motor 6</option>
                <option value='7'>Motor 7</option>
            </select>
            <label>Steps</label>
            <input/>
            <button class='button-normal'  onClick={ _ => this.sendRequest('move')} 
            >Move Motor(s)</button>
            <hr/>
            <button class='button-normal'  onClick={ _ => this.sendRequest('unlock')}
            >Unlock Motors</button>
            <button class='button-normal'  onClick={ _ => this.sendRequest('lock')}
            >Lock Motors</button>
            <button class='button-normal'  onClick={ _ => this.sendRequest('calibrate')}
            >Calibrate</button>
            <button class='button-normal'  onClick={ _ => this.sendRequest('home')}
            >Home</button>
            <button class='button-normal'  onClick={ _ => this.sendRequest('dock')}
            >Dock</button>
            <button class='button-normal'  onClick={ _ => this.sendRequest('push_back')}
            >Push Back</button>

            <h2>Response</h2>
            <pre>{JSON.stringify(this.props.response)}</pre>
        </div>)
    }
}