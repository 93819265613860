import React, { Fragment } from 'react'
import * as Dial from '../dial/dial'

export class TimePicker extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            timezones: [{tz:'Etc/UTC'},{tz:Intl.DateTimeFormat().resolvedOptions().timeZone}],
            timeString: null,
            hour:null,
            minute: null,
            second: null,
            showTz: this.props.showTz ?? true,
            isEdit: this.props.isEdit ?? true
        }
    }

    componentDidMount() {
        // console.log('timepicker mounted:',this.props.presetTimestamp)
        fetch(PUBLIC_PATH_ROOT + 'api/timezones',{method:'GET'})
        .then( rs => rs.json())
        .then( rs => {
            if (!rs.success) throw rs.reason
            console.log('timezones:',rs.timezones)
            this.setState({timezones:rs.timezones})
        })
        .catch( err => {
            console.log('err:',err)
        })

        if (this.props.presetTimestamp) {
            this.setFromUtcStr(this.props.presetTimestamp)
        }
    }

    setFromUtcStr(timestamp) {
        let timeString = timestamp.slice(11,19)
        let hour = parseInt(timeString.slice(0,2))
        let minute = parseInt(timeString.slice(3,5))
        let second = parseInt(timeString.slice(6,8))
        console.log('set utc timestamp:',timestamp, hour, minute, second)
        this.setState({tz:'Etc/UTC',timeString:timeString, hour: hour, minute: minute, second: second},this.onUpdate)
    }

    onSnap = _ => {
        this.props.onSnap?.(this.state)
    }

    onUpdate = _ => {
        this.props.onUpdate?.(this.state)
    }

    render() {
        return <div>
            { this.state.isEdit || <Fragment>
                <p><code>{this.state.timeString}, {this.state.tz}</code></p>
                <button
                    class='button-normal'
                    onClick={ _ => {
                        this.setState({isEdit: !this.state.isEdit})
                    } }
                >Edit</button>
            </Fragment>}
            { this.state.isEdit &&
                <div><button
                    class='button-normal'
                    onClick={ _ => {
                        this.setState({isEdit: !this.state.isEdit})
                    } }
                >Hide</button></div>
            }
            { this.state.isEdit && <div class='timepicker flex'>
                
                { this.state.showTz && <div>
                <label>Time Zone</label>
                <select value={this.state.tz ?? 'null'} name='timezone' onChange={ e => this.setState({tz:e.target.value},this.onUpdate)}>
                    <option disabled hidden value='null'>Select Your Timezone Manually</option>
                    { this.state.timezones?.map( x =>
                        <option key={x.tz} value={x.tz}>{x.tz}</option>
                    )}
                </select>
                <button
                    class='button-normal'
                    onClick={ _ => this.setState({tz: 'Etc/UTC'})}
                >
                    Use UTC
                </button>
                </div>}

                <div>
                    <label>Time</label>
                    <div>
                        <input
                            type='time'
                            value={this.state.timeString ?? ''}
                            step={1}
                            onChange={ e => {
                                let timeString = e.target.value
                                let hour = parseInt(timeString.slice(0,2))
                                let minute = parseInt(timeString.slice(3,5))
                                let second = parseInt(timeString.slice(6,8))
                                // console.log('timeString:',timeString)
                                this.setState({timeString: timeString, hour: hour, minute: minute, second: second})
                            }}
                        />
                    </div>
                </div>
                
                <div class='container'>
                <label>Coarse Adjustment</label>
                <Dial.Dial
                    onEnd={this.onUpdate}
                    onSnap={ x => {

                        let newDayMinute = ((this.state.hour ?? 0) * 60 + (this.state.minute ?? 0) + x.increment * (this.props.coarseStepSizeMinute ?? 30) + 1440) % 1440
                        let dayQuotient = Math.floor(((this.state.hour ?? 0) * 60 + (this.state.minute ?? 0) + x.increment * (this.props.coarseStepSizeMinute ?? 30) ) / 1440)
                        // console.log('dayQ:', dayQuotient, x,((this.state.hour ?? 0) * 60 + (this.state.minute ?? 0) + x.increment * (this.props.coarseStepSizeMinute ?? 30) ),(this.state.hour ?? 0) * 60, (this.state.minute ?? 0), x.increment * (this.props.coarseStepSizeMinute ?? 30) )
                        if (dayQuotient != 0) {
                            this.props.onDayChanged?.( dayQuotient )
                        }
                        let second = this.state.second ?? 0
                        let minute = (newDayMinute + 60) % 60
                        let hour = parseInt(newDayMinute / 60) % 24
                        let timeString = `${hour.toString().padStart(2,'0')}:${minute.toString().padStart(2,'0')}:${second.toString().padStart(2,'0')}`
                        // console.log('timepicker',newDayMinute,hour,minute,second)
                        this.setState({timeString: timeString, minute: minute, hour: hour, second: second}, this.onSnap)
                    }}
                />
                </div>

                <div class='container'>
                <label>Fine Adjustment</label>
                <Dial.Dial
                    onEnd={this.onUpdate}
                    onSnap={ x => {
                        let minuteOffset = Math.floor( ( (this.state.second ?? 0) + x.increment * (this.props.fineStepSizeSecond ?? 0) ) / 60)
                        let newDayMinute = ((this.state.hour ?? 0) * 60 + (this.state.minute ?? 0) + minuteOffset + x.increment * (this.props.fineStepSizeMinute ?? 1) + 1440) % 1440
                        let dayQuotient = Math.floor(( (this.state.hour ?? 0) * 60 + (this.state.minute ?? 0) + minuteOffset + x.increment * (this.props.fineStepSizeMinute ?? 1)) / 1440)
                        if (dayQuotient != 0) {
                            this.props.onDayChanged?.( dayQuotient )
                        }
                        let second = ((this.state.second ?? 0) + x.increment * (this.props.fineStepSizeSecond ?? 0) + 60) % 60
                        // console.log(x.increment, this.props.fineStepSizeSecond, dayQuotient)
                        let minute = (newDayMinute + 60) % 60
                        let hour = parseInt(newDayMinute / 60) % 24
                        let timeString = `${hour.toString().padStart(2,'0')}:${minute.toString().padStart(2,'0')}:${second.toString().padStart(2,'0')}`
                        // console.log(timeString)
                        this.setState({timeString: timeString, minute: minute, hour: hour, second: second}, this.onSnap)
                    }}
                />
                </div>
                {/* <select value={this.state.time ?? 'null'} name='timezone' onChange={ e => this.setState({time:e.target.value})}>
                    <option disabled hidden value='null'>Select Time</option>
                    { this.state.[].map( x =>
                        <option key={x.tz} value={x.tz}>{x.tz}</option>
                    )}
                </select> */}
            </div>}
        </div>
    }
}