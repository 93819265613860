// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#accountContainer {
    overflow-y: auto;
    padding: 20px;
    margin: auto;
}

.avatar {
    object-fit: cover;
    clip-path: circle(50%);
    vertical-align: middle;
    cursor: pointer;
}

#navbar .avatar {
    vertical-align: middle;
}

#navbar .account {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/account/account.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,sBAAsB;IACtB,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,eAAe;AACnB","sourcesContent":["#accountContainer {\n    overflow-y: auto;\n    padding: 20px;\n    margin: auto;\n}\n\n.avatar {\n    object-fit: cover;\n    clip-path: circle(50%);\n    vertical-align: middle;\n    cursor: pointer;\n}\n\n#navbar .avatar {\n    vertical-align: middle;\n}\n\n#navbar .account {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
