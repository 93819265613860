import React from 'react'
import '../../style.css'
import './account.css'
import avatar_img from '../../../assets/images/avatar.svg'

console.log('avatar:',avatar_img)
function formatBytes(bytes, decimals = 0) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export class Status extends React.Component {
    constructor(props) {
        super(props)
    }

    isNearExpire() {
        return new Date(Date.now() + 1*60000) > new Date(this.props.credentials.expiresAt)
    }

    render = _ =>
        <span id='accountStatusContainer'>
            { this.props.credentials.expiresAt > new Date(Date.now()).toISOString() ?
                <span>{this.isNearExpire() ?
                    <svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="gold" /></svg>
                    :
                    <svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="green" /></svg>
                } <code>{ this.props.iconOnly || 'session active'}</code></span>
                :
                <span>
                    <svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="red" /></svg>
                    <code>{ this.props.iconOnly || 'session expired'}</code>
                </span>
            }
        </span>
}

export class Simple extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        console.log('this.props.credentials:',this.props.credentials)
    }

    isNearExpire() {
        return new Date(Date.now() + 1*60000) > new Date(this.props.credentials.expiresAt)
    }

    browseAvatar() {
        this.avatar_input.click()
    }

    browseDoc() {
        this.doc_input.click()
    }
    
    browsedAvatar(blob) {
        if (!blob) throw 'not a blob'
        console.log('blob to upload:',blob)
        let blob_url = window.URL.createObjectURL(blob)
        this.setState({temp_avatar_blob_url: blob_url})  
    }

    uploadAvatar = (token, file_ext, file_size, file_blob, start_byte, onProgress) => new Promise((resolve,reject) => {
        console.log(token, file_ext, file_size, file_blob, start_byte, onProgress)
        if (!token || !file_ext || !file_size || !file_blob || (start_byte==null)) throw 'missing argument'
    
        let xhr = new XMLHttpRequest() // FETCH not yet support upload progress callback. use XHR instead!
        xhr.open("POST", PUBLIC_PATH_ROOT + "file/upload_avatar_image?" + new URLSearchParams({
            token: token,
            file_size: file_size,
            start_byte: start_byte,
            ext: file_ext
        }).toString(), true)
        xhr.upload.onprogress = e => onProgress?.(start_byte + e.loaded, start_byte + e.total)
        xhr.onload = xhr.onerror = _ => {
            let res
            try {
                res = JSON.parse(xhr.response)
            } catch (err) {return reject(err)}
            console.log("uploader: xhr:", res)
            if (res.success)
                return resolve(true)
            return reject(res.reason)
        }
        xhr.onabort = _ => resolve(false)
        console.log("uploader: start sending the file, starting from", start_byte)
        xhr.send(file_blob.slice(start_byte))
    })

    browsedDoc(blob) {
        if (!blob) throw 'not a blob'
        console.log('blob to upload:',blob)
        this.setState({
            temp_doc_blob: blob
        })
    }

    uploadDoc = (token, file_alias, file_ext, file_size, file_blob, start_byte, onProgress) => new Promise((resolve,reject) => {
        if (!token || !file_alias || !file_ext || !file_size || !file_blob || (start_byte==null)) throw 'missing argument'
    
        let xhr = new XMLHttpRequest() // FETCH not yet support upload progress callback. use XHR instead!
        xhr.open("POST", PUBLIC_PATH_ROOT + "file/upload_user_doc?" + new URLSearchParams({
            token: token,
            file_alias: file_alias,
            file_size: file_size,
            start_byte: start_byte,
            ext: file_ext
        }).toString(), true)
        xhr.upload.onprogress = e => onProgress?.(start_byte + e.loaded, start_byte + e.total)
        xhr.onload = xhr.onerror = _ => {
            let res
            try {
                res = JSON.parse(xhr.response)
            } catch (err) {return reject(err)}
            console.log("uploader: xhr:", res)
            if (res.success)
                return resolve(true)
            return reject(res.reason)
        }
        xhr.onabort = _ => resolve(false)
        console.log("uploader: start sending the file, starting from", start_byte)
        xhr.send(file_blob.slice(start_byte))
    })

    render() { return (
        <div id='accountSimpleContainer'>
            <h2>About You</h2>
            <img
                id='avatar_preview'
                class='avatar'
                width='100px'
                height='100px'
                src={this.state.temp_avatar_blob_url ?? this.props.credentials.avatar_image_url}
                ref={x => this.avatar_viewer = x}
                alt="avatar"
            />
            <input
                style={{display:'None'}}
                type='file'
                id='avatar'
                ref={x => this.avatar_input = x}
                onChange={ e => this.browsedAvatar(e.target.files?.[0])}
            />
            { !this.state.temp_avatar_blob_url &&
                <p><button class='button-normal' onClick={ _ => this.browseAvatar()}>Edit Avatar</button></p>
            }
            { this.state.temp_avatar_blob_url &&
                <p><button class='button-normal' onClick={ _ => {
                    fetch(this.state.temp_avatar_blob_url)
                    .then(res => res.blob())
                    .then( blob => {
                        console.log('blob:',blob)
                        let file_ext = blob.name.match(/\.\w*$/)[0].slice(1)
                        if (!file_ext) throw 'no file extension'
                        return this.uploadAvatar(this.props.credentials.token,file_ext,blob.size,blob,0, _ => _)
                    })
                    .then( _ => {
                        console.log('upload success')
                        this.setState({temp_avatar_blob_url: null})
                    })
                }}>Upload Avatar</button></p>
            }

            <p>logged in as: <code className='username'> {this.props.credentials.username || this.props.credentials.codename} </code></p>
            <p>role: <code className='rolename'> {this.props.credentials.user_role} </code></p>
            { !this.state.showAmqpPassword && <button
                class='button-normal'
                onClick={ _ => {
                    this.setState({showAmqpPassword:5}, _ => {
                        let countdown = _ => setTimeout(_ => {
                            if (this.state.showAmqpPassword == 0) return
                            this.setState(
                                {showAmqpPassword:this.state.showAmqpPassword - 1},
                                countdown
                            )
                        },1000)
                        countdown()
                    })
                }}
            >Show AMQP credentials</button>}
            { this.state?.showAmqpPassword > 0 && <div>
                <p><button
                    class='button-normal'
                    onClick={ _ => {
                        console.log(this.props.credentials)
                        this.setState({showAmqpPassword:0})
                    }}
                >Hide AMQP credentials ({this.state.showAmqpPassword})</button></p>
                <label>AMQP Credentials</label>
                <p>Username: <code>{this.props.credentials.username || this.props.credentials.codename}</code></p>
                <p>Password: <code>{this.props.credentials.amqp_password}</code></p>
            </div>}

            <p>My Pilot Profile</p>
            <input
                style={{display:'None'}}
                type='file'
                id='doc'
                ref={x => this.doc_input = x}
                onChange={ e => this.browsedDoc(e.target.files?.[0])}
            />
            <table class='table-normal'>
                <thead>
                    <tr><th>Document</th><th>Size</th><th>Exipiry Date</th><th>Status</th><th>Action</th></tr>
                </thead>
                <tbody>
                    { this.state.docs?.map( x => {
                        <tr></tr>
                    }) || 
                        <tr><td colSpan='3'>No Documents</td></tr>
                    }
                    { !this.state.temp_doc_blob && 
                        <tr><td colSpan='3'>
                            <button
                                class='button-normal'
                                onClick={ _ => this.browseDoc()}
                            >
                                Submit New Documents
                            </button>
                        </td></tr>
                    }
                    { this.state.temp_doc_blob && <tr>
                        <td title={this.state.temp_doc_blob.name}>{this.state.temp_doc_blob.name}</td>
                        <td>{formatBytes(this.state.temp_doc_blob.size)}</td>
                        <td></td>
                        <td></td>
                        <td>
                            <button
                                class='button-normal'
                                onClick={ _ => {
                                    let blob = this.state.temp_doc_blob
                                    let file_ext = blob.name.match(/\.\w*$/)[0].slice(1)
                                    if (!file_ext) throw 'no file extension'
                                    this.uploadDoc(this.props.credentials.token,blob.name,file_ext,blob.size,blob,0, _ => _)
                                    .then( _ => this.setState({temp_doc_blob:null}))
                                }}>
                                    Upload
                                </button>
                            <button class='button-normal'>Cancel</button>
                        </td>
                    </tr>}
                </tbody>
            </table>



            { this.props.credentials.expiresAt > new Date(Date.now()).toISOString() ?
                <p>{this.isNearExpire() ?
                    <svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="gold"/></svg>
                    :
                    <svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="green"/></svg>
                    }
                    {' token active, expires at: '} <code> {this.props.credentials.expiresAt} </code></p>
                :
                <p><svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="red"/></svg> token expired</p>
            }

            
        </div>
    )}
}

export class NavBar extends React.Component {

    constructor(props) {
        super(props)
    }

    render = _ => (
        <div class='account' onClick={_ => this.props.onClick()}>
            <img class='avatar' height='32px' width='32px' src={this.props.credentials.avatar_image_url} alt="avatar"/>
            <div class="user">
                <code class='username'>{this.props.credentials.username || this.props.credentials.codename}</code>
                <code class='rolename'>{this.props.credentials.user_role} </code>
            </div>
        </div>
    )

}

/* Main Account Page. Different role has different possible actions */
export class MainPage extends React.Component {

    constructor(props) {
        super(props)
        this.roleSelector = null
        this.usecaseSelector = null
        this.state = {
            isLoadingRoles: true,
            isLoadingPayloads: true,
            isLoadingUxvs: true,
            isLoadingUsers: true,
            accounts: null,
            uxvs: null,
            payloads: null,
            isEditing: false,
            use_cases: ['app1','app2'],
            user_roles: ['role1','role2']
        }

        fetch(PUBLIC_PATH_ROOT + 'auth/all_roles_and_use_cases', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({ token: this.props.credentials.token })
        }).then(res => res.json())
        .then(res => {
            if (res.success) {
                console.log('all_roles_and_use_cases:', res)
                this.setState({
                    isLoadingRoles: false,
                    use_cases: res.use_cases,
                    user_roles: res.user_roles
                })
                return
            }
            console.log('account: all_roles_and_use_cases: failed, reason:', res.reason)
        })
        .catch(err => {
            console.log('account: all_roles_and_use_cases: error:',err)
        })
        // users
        fetch(PUBLIC_PATH_ROOT + 'auth/users', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({ token: this.props.credentials.token })
        }).then(res => res.json())
        .then(res => {
            if (res.success) {
                let users = res.users.filter(x => x)
                console.log('users:', users)
                users.map(x => x.isEditing = false)
                this.setState({
                    isLoadingUsers: false,
                    accounts: users,
                    isEditing: false
                })
                return
            }
            console.log('account: get users failed, reason:', res.reason)
        })
        .catch(err => {
            console.log('account: get users: error:',err)
        })
        // uxvs
        fetch(PUBLIC_PATH_ROOT + 'auth/uxvs', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({ token: this.props.credentials.token })
        }).then(res => res.json())
        .then(res => {
            if (res.success) {
                let uxvs = res.uxvs
                console.log('uxvs:', uxvs)
                this.setState({
                    isLoadingUxvs: false,
                    uxvs: uxvs,
                    isEditing: false
                })
                return
            }
            console.log('account: get uxvs failed, reason:', res.reason)
        })
        .catch(err => {
            console.log('account: get uxvs: error:',err)
        })
        // payload
        fetch(PUBLIC_PATH_ROOT + 'auth/payloads', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({ token: this.props.credentials.token })
        }).then(res => res.json())
        .then(res => {
            if (res.success) {
                let payloads = res.payloads
                console.log('payloads:', payloads)
                this.setState({
                    isLoadingPayloads: false,
                    payloads: payloads,
                    isEditing: false
                })
                return
            }
            throw res.reason
        })
        .catch(err => {
            console.log('account: get payloads: error:',err)
        })
        window.onpopstate = _ => this.props.routePreviousPage()
    }

    componentDidMount() {
        window.history.pushState(null,'login page','/accounts')
    }

    editAccount(user) {
        console.log('account: editing user:', user.username)
        this.setState(state => {
            let i = state.accounts.findIndex(x => x.username == user.username)
            state.isEditing = true
            state.accounts[i].isEditing = true
            return state
        })
    }

    cancelEdit(user) {
        this.setState(state => {
            let i = state.accounts.findIndex(x => x.username == user.username)
            state.isEditing = false
            state.accounts[i].isEditing = false
            return state
        })
    }

    confirmEdit(user) {
        let updatedUser = user
        updatedUser.use_cases = [...this.usercaseSelector.selectedOptions].map(x => x.value)
        updatedUser.user_role = this.roleSelector.selectedOptions[0].value
        updatedUser.email_verified = this.emailVerifiedSelector.selectedOptions[0].value
        console.log('updating user:', updatedUser)
        fetch(PUBLIC_PATH_ROOT + 'auth/user/update', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({
                token: this.props.credentials.token,
                user: updatedUser
            })
        })
        .then(res => res.json())
        .then(data => {
            if (data.success) {
                this.setState(state => {
                    let i = state.accounts.findIndex(x => x.username == user.username)
                    state.accounts[i] = data.user
                    state.isEditing = false
                    state.accounts[i].isEditing = false
                    return state
                })
            }
        })
    }

    render() {

        return ( <div id='accountContainer'>

            {this.state.accounts != null &&
                <div id="accounts">
                    <table class='table-normal full-width'>
                    <thead>
                        <tr>
                            <th>Avatar</th>
                            <th>Created at</th>
                            <th>Username</th>
                            <th>Given Name</th>
                            <th>Last Name</th>
                            <th>Company</th>
                            <th>Email</th>
                            <th>Email Verified</th>
                            <th>Phone</th>
                            <th>Phone Verified</th>
                            <th>TOTP Verified</th>
                            <th>User-role</th>
                            <th>Use-cases</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.accounts.map(acc => (
                            <tr key={acc.id}>
                                <td><img class='avatar' height='32px' width='32px' src={acc.avatar_image_url ?? avatar_img} alt="avatar"/></td>
                                <td>{new Date(acc.created_at).toLocaleDateString()}</td>
                                <td>{acc.username}</td>
                                <td>{acc.given_name}</td>
                                <td>{acc.family_name}</td>
                                <td>{acc.company}</td>
                                <td>{acc.email}</td>
                                {acc.isEditing ?
                                    <td><select
                                        ref={x => this.emailVerifiedSelector = x}
                                    >
                                        <option value={true} selected={acc.email_verified == true}>true</option>
                                        <option value={false} selected={acc.email_verified == false}>false</option>
                                    </select></td>
                                    :
                                    <td>{String(acc.email_verified)}</td>
                                }
                                {acc.isEditing ?
                                    <td><input value={acc.phone}/></td>
                                    :
                                    <td>{acc.phone}</td>
                                }
                                {acc.isEditing ?
                                    <td><select
                                        ref={x => this.phoneVerifiedSelector = x}
                                    >
                                        <option value={true} selected={acc.phone_verified == true}>true</option>
                                        <option value={false} selected={acc.phone_verified == false}>false</option>
                                    </select></td>
                                    :
                                    <td>{String(acc.phone_verified)}</td>
                                }
                                {acc.isEditing ?
                                    <td><select
                                        ref={x => this.totpVerifiedSelector = x}
                                    >
                                        <option value={true} selected={acc.totp_key_verified == true}>true</option>
                                        <option value={false} selected={acc.totp_key_verified == false}>false</option>
                                    </select></td>
                                    :
                                    <td>{String(acc.totp_key_verified)}</td>
                                }
                                {acc.isEditing ?
                                    <td><select
                                        ref={x => this.roleSelector = x}
                                    >
                                        {this.state.user_roles.map(role =>
                                            (<option key={role} value={role} selected={acc.user_role == role}>{role}</option>)
                                        )}
                                    </select></td>
                                    :
                                    <td>{acc.user_role}</td>
                                }
                                {acc.isEditing ?
                                    <td><select
                                        multiple
                                        ref={x => this.usercaseSelector = x}
                                    >
                                        {this.state.use_cases.map(uc => (
                                            <option
                                                key={uc.name} value={uc.name}
                                                selected={acc.use_cases.includes(uc.name)}
                                            >{uc.description}</option>
                                        )
                                        )}
                                    </select></td>
                                :
                                    <td>{acc.use_cases.join(',')}</td>
                                }
                                <td>
                                    {!acc.isEditing && acc.username != this.props.credentials.username &&
                                        <button
                                            disabled={this.state.isEditing || this.props.credentials.user_role != 'admin'}
                                            className='button-normal'
                                            onClick={_ => this.editAccount(acc)}
                                        >
                                        {acc.username == this.props.credentials.username ? "It's you" : 'Edit'}
                                        </button>
                                    }
                                    {acc.isEditing &&
                                        <button
                                            className='button-normal'
                                            onClick={_ => this.confirmEdit(acc)}
                                        >
                                            Confirm
                                        </button>
                                    }
                                    {acc.isEditing &&
                                        <button
                                            className='button-normal'
                                            onClick={_ => this.cancelEdit(acc)}
                                        >
                                            Cancel
                                        </button>
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    </table>
                    {/* uxvs */}
                    <br/>
                    { !! this.state.uxvs ?
                        <table class='table-normal full-width'>
                        <thead>
                            <tr>
                                <th>UXV ID</th>
                                <th>Created at</th>
                                <th>Codename</th>
                                <th>UXV Type</th>
                                <th>Manufacturer</th>
                                <th>Email</th>
                                <th>Email Verified</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.uxvs.length ? this.state.uxvs.map(x => (
                                <tr key={x.id}>
                                    <td>{x.id}</td>
                                    <td>{new Date(x.created_at).toLocaleDateString()}</td>
                                    <td>{x.codename}</td>
                                    <td>{x.uxv_type}</td>
                                    <td>{x.manufacturer}</td>
                                    <td>{x.email}</td>
                                    <td>{String(x.email_verified)}</td>
                                    <td>
                                    {!x.isEditing &&
                                        <button
                                            disabled
                                            className='button-normal'
                                        >
                                        Action
                                        </button>
                                    }
                                </td>
                                </tr>
                            ))
                            :
                            <tr><td colSpan='9'>You have no accessible UXVs.</td></tr>
                            }
                        </tbody>
                        </table>
                        :
                        <div> Cannot Load UXV List </div>
                    }
                    {/* payloads */}
                    <br/>
                    { !! this.state.payloads ?
                        <table class='table-normal full-width'>
                        <thead>
                            <tr>
                                <th>Payload ID</th>
                                <th>Created at</th>
                                <th>Codename</th>
                                <th>Payload Type</th>
                                <th>Company</th>
                                <th>Manufacturer</th>
                                <th>Email</th>
                                <th>Email Verified</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.payloads.length ? this.state.payloads.map(x => (
                                <tr key={x.id}>
                                    <td>{x.id}</td>
                                    <td>{x.created_at}</td>
                                    <td>{x.codename}</td>
                                    <td>{x.payload_type}</td>
                                    <td>{x.company}</td>
                                    <td>{x.manufacturer}</td>
                                    <td>{x.email}</td>
                                    <td>{String(x.email_verified)}</td>
                                    <td>
                                    {!x.isEditing &&
                                        <button
                                            disabled
                                            className='button-large'
                                        >
                                        Action
                                        </button>
                                    }
                                </td>
                                </tr>
                            ))
                            :
                            <tr><td colSpan='9'>You have no accessible Payloads.</td></tr>
                            }
                        </tbody>
                        </table>
                        :
                        <div> Cannot Load Payload List </div>
                    }
                </div>
            }
        </div>)
    }
}
