// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#loginFormContainer, #resendContainer , .buttonContainer{
    max-width: 500px;
    padding: 10px 40px;
    margin-left: auto;
    margin-right: auto;
}

#titleContainer {
    text-align: center;
    margin-bottom: 20px;
}

#loginButton {
    width : 100%;
    margin-top: 120px;
}

#resendButton, #cancelButton {
    width: 100%;
}

.fullwidth {
    width: 100%;
}

#registerButton{
    cursor: pointer;
}
#registerButton:hover{
    color: blue;
}

#resetPasswordButton{
    cursor: pointer;
}
#resetPasswordButton:hover{
    color: blue;
}

#verifyEmailButton {
    cursor: pointer;
}
#verifyEmailButton:hover{
    color: blue;
}

#verifyPhoneButton {
    cursor: pointer;
}
#verifyPhoneButton:hover{
    color: blue;
}

#registerTotpButton {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/login/login.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;AACA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;AACA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;AACA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;AACA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;AACnB","sourcesContent":["#loginFormContainer, #resendContainer , .buttonContainer{\n    max-width: 500px;\n    padding: 10px 40px;\n    margin-left: auto;\n    margin-right: auto;\n}\n\n#titleContainer {\n    text-align: center;\n    margin-bottom: 20px;\n}\n\n#loginButton {\n    width : 100%;\n    margin-top: 120px;\n}\n\n#resendButton, #cancelButton {\n    width: 100%;\n}\n\n.fullwidth {\n    width: 100%;\n}\n\n#registerButton{\n    cursor: pointer;\n}\n#registerButton:hover{\n    color: blue;\n}\n\n#resetPasswordButton{\n    cursor: pointer;\n}\n#resetPasswordButton:hover{\n    color: blue;\n}\n\n#verifyEmailButton {\n    cursor: pointer;\n}\n#verifyEmailButton:hover{\n    color: blue;\n}\n\n#verifyPhoneButton {\n    cursor: pointer;\n}\n#verifyPhoneButton:hover{\n    color: blue;\n}\n\n#registerTotpButton {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
