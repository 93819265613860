import React, {Fragment} from 'react'
import './calender.css'

export default class Calender extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            targetYear : null,
            targetMonth : null,
            firstRenderDateOffset : null,
            numOfWeeksToRender : null,
            selectedDate : null,
        }
    }

    onChange = e => {
        this.setState({[e.target.name]:e.target.value})
    }

    setYearMonth(year,month) { // where month can be any integer
        let firstMonthDate = new Date(Date.UTC(year, month, 1))  // first of target month
        let lastMonthDate = new Date(Date.UTC(year, month+1, 0)) // end of target month
        let numOfWeeksToRender = Math.ceil( (firstMonthDate.getUTCDay() + lastMonthDate.getUTCDate()) / 7 )
        this.setState({
            targetYear : firstMonthDate.getUTCFullYear(),
            targetMonth : firstMonthDate.getUTCMonth(),
            firstRenderDateOffset : 1 - firstMonthDate.getUTCDay(),
            numOfWeeksToRender: numOfWeeksToRender
        })
        this.props.onSetYearMonth?.(firstMonthDate.getUTCFullYear(),firstMonthDate.getUTCMonth())
    }

    setFromUtcStr(utcTimstampStr) {
        if (!utcTimstampStr) return false
        // console.log('utcTimstampStr:',utcTimstampStr)
        let time = utcTimstampStr.includes('Z') ? new Date(utcTimstampStr) : new Date(utcTimstampStr + '+00:00')
        this.setYearMonth(time.getUTCFullYear(),time.getUTCMonth())
        this.setState({selectedDate: time})
        console.log('selected date:', time)
        return true
    }

    incrementDays(dayOffset) {
        console.log('day offset:', dayOffset)
        this.setState( s => {
            let d = s.selectedDate
            d.setDate( d.getDate() + dayOffset)
            s.selectedDate = d
            return s
        })
    }

    unselectDate() {
        this.setState({selectedDate:null})
    }

    componentDidMount() {
        if (this.setFromUtcStr(this.props.presetTimestamp)) return
        let now = new Date()
        this.setYearMonth(now.getUTCFullYear(),now.getUTCMonth())
        this.setState({selectedDate: now})
    }

    render() {
        return (
            <div class='calender'>
                { this.state.isEdit || <Fragment>
                    <p><code>{this.state.selectedDate?.toISOString()}</code></p>
                    <button
                        class='button-normal'
                        onClick={ _ => {
                            this.setState({isEdit: !this.state.isEdit})
                        } }
                    >Edit</button>
                </Fragment>}
                { this.state.isEdit &&
                    <div><button
                        class='button-normal'
                        onClick={ _ => {
                            this.setState({isEdit: !this.state.isEdit})
                        } }
                    >Hide</button></div>
                }
                { this.state.isEdit &&
                <table>
                    <thead>
                        <tr><td colSpan='7'>{new Date(Date.UTC(this.state.targetYear, this.state.targetMonth, 1)).toLocaleString('default',{year:'numeric',month:'long'})}</td></tr>
                        <tr>
                        {[...'SMTWTFS'].map( (x,i) =>
                            <th key={i} day={i}>{x}</th>
                        )}
                        </tr>
                    </thead>
                    <tbody>
                        { Array(this.state.numOfWeeksToRender).fill().map((_,i) =>
                            <tr key={i}>
                                {Array(7).fill().map( (_ ,j) => {
                                    let d = new Date(Date.UTC(this.state.targetYear, this.state.targetMonth, this.state.firstRenderDateOffset + i * 7 + j ))
                                    return <td
                                        key={i*7 + j}
                                        inactive={ d.getUTCMonth() == this.state.targetMonth ? 'false' : 'true'}
                                        is_selected={ this.state.selectedDate?.toISOString().substring(0, 10) == d.toISOString().substring(0, 10) ? 'true' : 'false'}
                                        day={j}
                                        onClick={ _ => {
                                            if (this.props.disabled) {
                                                return
                                            }
                                            console.log('selected date:', d)
                                            this.setState({selectedDate:d})
                                        }}>
                                        {d.getUTCDate()}
                                    </td>
                                })}
                            </tr>
                        )}
                        <tr>
                            <td colSpan='7'>
                                <input
                                    disabled={this.props.disabled}
                                    type='date'
                                    value={this.state.selectedDate?.toISOString().substring(0, 10) ?? ''}
                                    onChange={ e => {
                                        let d = new Date(e.target.value)
                                        console.log(d.toISOString())
                                        this.setState({selectedDate:d, targetYear: d.getUTCFullYear(), targetMonth: d.getUTCMonth()})
                                    }}
                                />
                            </td>
                        </tr>
                        <tr><td colSpan='7'>
                            <div class='flex'>
                                <button
                                    disabled={this.props.disabled}
                                    class='calender-navigate-button'
                                    onClick={ _ => this.setYearMonth(this.state.targetYear - 1,this.state.targetMonth)}
                                >&lt;&lt;</button>
                                <button
                                    disabled={this.props.disabled}
                                    class='calender-navigate-button'
                                    onClick={ _ => this.setYearMonth(this.state.targetYear,this.state.targetMonth-1)}
                                >&lt;</button>
                                <button
                                    disabled={this.props.disabled}
                                    class='calender-navigate-button'
                                    onClick={ _ => this.setYearMonth(this.state.targetYear,this.state.targetMonth+1)}
                                >&gt;</button>
                                <button
                                    disabled={this.props.disabled}
                                    class='calender-navigate-button'
                                    onClick={ _ => this.setYearMonth(this.state.targetYear+1,this.state.targetMonth)}
                                >&gt;&gt;</button>
                            </div>
                        </td></tr>
                    </tbody>
                </table>}
            </div>
        )
    }
    
}