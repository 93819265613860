import React, { Fragment } from 'react'
import './sora.css'
import '../../style.css'


class RadioSelector extends React.Component {
    constructor(props) {
        super(props)
    }

    render = _ =>
        <div className='radioContainer'>
            { this.props.values.map( (value,i) => <Fragment key={i}>
                { i>0 && <div className='radioSeparater'/>}
                <div className='radioItem'>
                    <div>
                        <input checked={this.props.curr_value==value} type='radio'
                            onChange={_ => this.props.onChange(value)}
                        />
                        <label>{this.props.labels[i]}</label>
                    </div>
                </div>
            </Fragment>)}
        </div>
}

class AecFinder extends React.Component {
    constructor(props) {
        super(props)
        if (props.init) {
            this.state = props.init
            return
        }
        this.state = {currPage:'A'}
    }

    updateCurrPage = _ => {
        console.log('uppdatePage:', this.state.currPage)
        let page = 'A'
        console.log('isAtypicalAirspace:',this.state.isAtypicalAirspace)
        if (this.state.isAtypicalAirspace) {
            return this.setState({currPage:page,aec:12})
        }
        page = 'B'
        if (this.state.isAboveFl600Airspace == null) {
            return this.setState({currPage:page,aec:null})
        }
        if (this.state.isAboveFl600Airspace) {
            return this.setState({currPage:page,aec:11})
        }
        page = 'C'
        if (this.state.isAbove500BelowFl600Airspace == null) {
            return this.setState({currPage:page,aec:null})
        }
        if (this.state.isAbove500BelowFl600Airspace) {
            page = 'D'
            if (this.state.isAirportAirspace == null) {
                return this.setState({currPage:page,aec:null})
            }
            if (this.state.isAirportAirspace) {
                return this.setState({currPage:page,aec:2})
            }
            page = 'E'
            if (this.state.isCtrAirspace == null) {
                return this.setState({currPage:page,aec:null})
            }
            if (this.state.isCtrAirspace) {
                return this.setState({currPage:page,aec:1})
            }
            page = 'F'
            if (this.state.isUctrOrTmzAirspace == null) {
                return this.setState({currPage:page,aec:null})
            }
            if (this.state.isUctrOrTmzAirspace) {
                return this.setState({currPage:page,aec:3})
            }
            page = 'G'
            if (this.state.isUctrUrbanAirspace == null) {
                return this.setState({currPage:page,aec:null})
            }
            if (this.state.isUctrUrbanAirspace) {
                return this.setState({currPage:page,aec:4})
            }
            page = 'H'
            if (this.state.isUctrRuralAirspace == null) {
                return this.setState({currPage:page,aec:null})
            }
            if (this.state.isUctrRuralAirspace) {
                return this.setState({currPage:page,aec:5})
            }
            return this.setState({currPage:page,aec:'Error'})
        }
        page = 'J'
        if (this.state.isBelow500ftAirspace == null) {
            return this.setState({currPage:page,aec:null})
        }
        if (this.state.isBelow500ftAirspace==false) {
            return this.setState({currPage:page,aec:'Error'})
        }
        page = 'K'
        if (this.state.isAirportAirspace == null) {
            return this.setState({currPage:page,aec:null})
        }
        if (this.state.isAirportAirspace) {
            return this.setState({currPage:page,aec:7})
        }
        page = 'L'
        if (this.state.isCtrAirspace == null) {
            return this.setState({currPage:page,aec:null})
        }
        if (this.state.isCtrAirspace) {
            return this.setState({currPage:page,aec:6})
        }
        page = 'M'
        if (this.state.isUctrOrTmzAirspace == null) {
            return this.setState({currPage:page,aec:null})
        }
        if (this.state.isUctrOrTmzAirspace) {
            return this.setState({currPage:page,aec:1})
        }
        page = 'N'
        if (this.state.isUctrUrbanAirspace == null) {
            return this.setState({currPage:page,aec:null})
        }
        if (this.state.isUctrUrbanAirspace) {
            return this.setState({currPage:page,aec:1})
        }
        page = 'P'
        if (this.state.isUctrRuralAirspace == null) {
            return this.setState({currPage:page,aec:null})
        }
        if (this.state.currPage == 'P' && this.state.isUctrRuralAirspace) {
            return this.setState({currPage:page,aec:1})
        }
        return this.setState({currPage:page,aec:'Error'})
    }

    render = _ =>
        <Fragment>

            <p>
                <label>Name</label>
                <input class='input-fullwidth' value={this.state.name ?? ''} onChange={e => this.setState({name:e.target.value})}/>
            </p>

            <p>
                <label>Description</label>
                <input class='input-fullwidth' value={this.state.description ?? ''} onChange={e => this.setState({description:e.target.value})}/>
            </p>

            <details>
                <summary>Is the Operation in an Atypical airspace?</summary>
                <p>
                    A
                </p>
            </details>
            <RadioSelector curr_value={this.state.isAtypicalAirspace} labels={['Yes','No']} values={[true,false]} onChange={ x => this.setState({isAtypicalAirspace:x},this.updateCurrPage)}/>

            { 'BCDEFGHJKLMNP'.includes(this.state.currPage) && <Fragment>
                <details>
                    <summary>Is the Operation in an Airspace above FL600?</summary>
                    <p>
                        B
                    </p>
                </details>
                <RadioSelector curr_value={this.state.isAboveFl600Airspace} labels={['Yes','No']} values={[true,false]} onChange={ x => this.setState({isAboveFl600Airspace:x},this.updateCurrPage)}/>
            </Fragment>}
            
            { 'CDEFGHJKLMNP'.includes(this.state.currPage) && <Fragment>
                <details>
                    <summary>Is the Operation in an Airspace above 500ft AGL but below FL600?</summary>
                    <p>
                        C
                    </p>
                </details>
                <RadioSelector curr_value={this.state.isAbove500BelowFl600Airspace} labels={['Yes','No']} values={[true,false]} onChange={ x => this.setState({isAbove500BelowFl600Airspace:x},this.updateCurrPage)}/>
            </Fragment>}

            { 'JKLMNP'.includes(this.state.currPage) && <Fragment>
                <details>
                    <summary>Is the Operation in an Uncontrolled Airspace below 500ft AGL?</summary>
                    <p>
                        J
                    </p>
                </details>
                <RadioSelector curr_value={this.state.isBelow500ftAirspace} labels={['Yes','No']} values={[true,false]} onChange={ x => this.setState({isBelow500ftAirspace:x},this.updateCurrPage)}/>
            </Fragment>}

            { 'DEFGHKLMNP'.includes(this.state.currPage) && <Fragment>
                <details>
                    <summary>Is the Operation in an Airport Environment?</summary>
                    <p>
                        D/K
                    </p>
                </details>
                <RadioSelector curr_value={this.state.isAirportAirspace} labels={['Yes','No']} values={[true,false]} onChange={ x => this.setState({isAirportAirspace:x},this.updateCurrPage)}/>
            </Fragment>}

            { 'EFGHLMNP'.includes(this.state.currPage) && <Fragment>
                <details>
                    <summary>Is the Operation in an Controlled Airspace?</summary>
                    <p>
                        E/L
                    </p>
                </details>
                <RadioSelector curr_value={this.state.isCtrAirspace} labels={['Yes','No']} values={[true,false]} onChange={ x => this.setState({isCtrAirspace:x},this.updateCurrPage)}/>
            </Fragment>}

            { 'FGHMNP'.includes(this.state.currPage) && <Fragment>
                <details>
                    <summary>Is the Operation in an Uncontrolled Mode C veil/ TMZ Airspace?</summary>
                    <p>
                        F/M
                    </p>
                </details>
                <RadioSelector curr_value={this.state.isUctrOrTmzAirspace} labels={['Yes','No']} values={[true,false]} onChange={ x => this.setState({isUctrOrTmzAirspace:x},this.updateCurrPage)}/>
            </Fragment>}

            { 'GHNP'.includes(this.state.currPage) && <Fragment>
                <details>
                    <summary>Is the Operation in an Uncontrolled Airspace over Urban population?</summary>
                    <p>
                        G/N
                    </p>
                </details>
                <RadioSelector curr_value={this.state.isUctrUrbanAirspace} labels={['Yes','No']} values={[true,false]} onChange={ x => this.setState({isUctrUrbanAirspace:x},this.updateCurrPage)}/>
            </Fragment>}

            { 'HP'.includes(this.state.currPage) && <Fragment>
                <details>
                    <summary>Is the Operation in an Uncontrolled Airspace over Rural population?</summary>
                    <p>
                        H/P
                    </p>
                </details>
                <RadioSelector curr_value={this.state.isUctrRuralAirspace} labels={['Yes','No']} values={[true,false]} onChange={ x => this.setState({isUctrRuralAirspace:x},this.updateCurrPage)}/>
            </Fragment>}

            <h2><code>The determined AEC is {this.state.aec}</code></h2>

            <button
                class='button-normal'
                disabled={!this.state.aec}
                onClick={ _ => this.props.onConfirm(this.state)}
            >
                Confirm
            </button>

            <button
                class='button-normal'
                onClick={ _ => this.props.onCancel()}
            >
                Cancel
            </button>
        </Fragment>
}

export class Sora extends React.Component {
    constructor(props) {
        super(props)
        this.state = {currPage:'0'}
        this.fetchSora()

    }

    fetchSora() {
        fetch(PUBLIC_PATH_ROOT + `api/sora`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({
                token: this.props.credentials.token,
                mission_id: this.props.mission_id,
                sora: this.state
            })
        })
        .then(res => res.json())
        .then(res => {
            if (!res.success) throw res.reason
            console.log('sora:',res.sora)
            this.setState({
                ...res.sora
            }, _ => {
                if (this.isA1()) {
                    return this.setState({currPage:0})
                }
            })
        })
        .catch(err => {
            console.log('err:',err)
        })
    }

    saveSora() {
        fetch(PUBLIC_PATH_ROOT + `api/update_sora`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({
                token: this.props.credentials.token,
                mission_id: this.props.mission_id,
                sora: this.state
            })
        })
        .then(res => res.json())
        .then(res => {
            if (!res.success) throw res.reason
            this.setState({
                ...res.sora
            }, this.updateCurrPage)
        })
        .catch(err => {
            console.log('err:',err)
        })
    }

    getErpGrcAdaptation = x => ({'Low/None':1,'Medium':0,'High':-1})[x]
    getImpactReductionGrcAdaptation = x => ({'Low/None':0,'Medium':-1,'High':-2})[x]
    getContainmentGrcAdaptation = x => ({'Low/None':0,'Medium':-2,'High':-4})[x]
    
    getArc= aecs => {
        if (! (aecs?.length >0) ) return null
        let aec2arcDict = {
            1:4,2:4,3:4,4:3,5:3,6:3,7:4,8:3,9:3,10:2,11:2,12:1
        }
        let arc = Math.max(...aecs.map( x => aec2arcDict[x.aec]))
        return arc
    }

    getGrc(maxDim, maxKe, scenario){
        let dim_lvl = ['≤ 1m','≤ 3m','≤ 8m','> 8m'].indexOf(maxDim)
        let ke_lvl = ['≤ 700J','≤ 34kJ','≤ 1084kJ','> 1084kJ'].indexOf(maxKe)
        let scenario_lvl = ['vlos,ctr,sparse','bvlos,nctr,sparse','vlos,ctr,populated','vlos,nctr,populated','bvlos,ctr,populated','bvlos,nctr,populated','vlos,nctr,crowd','bvlos,nctr,crowd'].indexOf(scenario)
        let grc = {
            '00':1,'10':2,'20':3,'30':5,
            '01':2,'11':3,'21':4,'31':6,
            '02':3,'12':4,'22':6,'32':8,
            '03':4,'13':5,'23':7,'33':9,
            '04':5,'14':6,'24':8,'34':10,
            '05':6,'15':7,'25':9,'35':11,
            '06':7,'16':99,'26':99,'36':99 
        }[`${Math.max(dim_lvl,ke_lvl)}${scenario_lvl}`]
        return grc
    }

    getSail(lethality,grc,robust_erp,robust_impact,robust_contain) {
        console.log('get sail:',lethality,grc)
        let final_grc = grc + this.getErpGrcAdaptation(robust_erp) + this.getImpactReductionGrcAdaptation(robust_impact) + this.getContainmentGrcAdaptation(robust_contain)
        if (final_grc > 7) return 'Failed, as final GRC > 7'
        let sail = {
            'High7':6,'High6':6,'High5':5,'High4':4,'High3':3,'High2':2,'High1':1,
            'Average7':6,'Average6':5,'Average5':4,'Average4':3,'Average3':2,'Average2':1,'Average1':0,
            'Low7':5,'Low6':4,'Low5':3,'Low4':2,'Low3':1,'Low2':0,'Low1':0,
        }[`${lethality}${final_grc < 1 ? 1 : final_grc}`]
        return sail
    }

    isA1() {
        return (
            this.state.isVlos
            && (
                (this.state.isUrban)
                || (!this.state.isUrban)
            )
            && ['C0','C1'].includes(this.state.uavClass)
            && !this.state.hasDrop
            && !this.state.hasDangerousGoods
        )
    }

    isA2() {
        return (
            this.state.isVlos
            && (
                (this.state.isUrban )
                || (!this.state.isUrban)
            )
            && 'C2'==this.state.uavClass
            && !this.state.hasDrop
            && !this.state.hasDangerousGoods
        )
    }

    isA3() {
        return (
            this.state.isVlos
            &&  !this.state.isUrban
            && ['C2','C3','C4'].includes(this.state.uavClass)
            && !this.state.hasDrop
            && !this.state.hasDangerousGoods
        )
    }

    render() {
        return (
            <div id='sora'>

                <button class='button-normal' onClick={ _ => {
                    console.log('state:',this.state)
                    this.saveSora()
                }}
                >Save Progress</button>

                { this.isA1() || <div id='paginator'>
                    <div>
                        <button class='button-normal'
                            onClick={ _ => this.setState({
                                currPage: (this.state.currPage == '0' ? '0': `${parseInt(this.state.currPage)-1}`)
                            })}
                        >Back</button>
                    </div>
                    <span>{this.state.currPage}/12</span>
                    {/* { ['0','1','2','3','4','5','6','7','8','9','10','11','12'].map(x =>
                        <div key={x}>
                            <button class='button-normal' onClick={_ => this.setState({currPage:x})}>{x}</button>
                        </div>
                    )} */}
                    <div>
                        <button class='button-normal'
                            onClick={ _ => this.setState({
                                currPage: (this.state.currPage == '12' ? '12': `${parseInt(this.state.currPage)+1}`)
                            })}
                        >Next</button>
                    </div>
                </div>}

                { this.state.currPage=='0' && <Fragment>
                    <h2>Step 0: Initial Evaluation</h2>
                    
                    <label>Visual Line of Sight</label>
                    <details>
                        <summary>Is the UAV operated within Visual Line-Of-Sight (VLOS)?</summary>
                        <p>
                            (VLOS) means a type of operation in which the remote pilot maintains continuous unobstructed and unaided visual contact with the unmanned vehicle, allowing the remote pilot to monitor the flight path of the unmanned vehicle in relation to other aircraft, persons, and obstacles, for the purpose of maintaining separation from them and avoiding collisions.
                        </p>
                    </details>
                    <RadioSelector curr_value={this.state.isVlos} labels={['Yes','No']} values={[true,false]} onChange={ x => this.setState({isVlos:x})}/>

                    { (this.state.isVlos==false) && <Fragment>
                        <label>Beyond Visual Line of Sight</label>
                        <details>
                            <summary>Is the UAV operated Beyond Visual Line-Of-Sight (BVLOS)?</summary>
                            <p>
                                (VLOS) means a type of operation in which the remote pilot maintains continuous unobstructed and unaided visual contact with the unmanned vehicle, allowing the remote pilot to monitor the flight path of the unmanned vehicle in relation to other aircraft, persons, and obstacles, for the purpose of maintaining separation from them and avoiding collisions.
                            </p>
                        </details>
                        <RadioSelector curr_value={this.state.isBvlos} labels={['Yes','No']} values={[true,false]} onChange={ x => this.setState({isBvlos:x})}/>
                    </Fragment>}

                    { (this.state.isBvlos==true) && <Fragment><label>BVLOS Distance</label>
                        <details>
                            <summary>What is the BVLOS distance between the remote pilot and UAV?</summary>
                            <p>
                            </p>
                        </details>
                        <RadioSelector curr_value={this.state.bvlosMaxDistance} labels={['≤ 1km','≤ 2km','> 2km']} values={['≤ 1km','≤ 2km','> 2km']} onChange={ x => this.setState({bvlosMaxDistance:x})}/>

                        <label>BVLOS Observers</label>
                        <details>
                            <summary>Are there at least one airspace observer who is always within 1km Line of Sight with the UAV?</summary>
                            <p>
                            </p>
                        </details>
                        <RadioSelector curr_value={this.state.hasObservers} labels={['Yes','No']} values={[true,false]} onChange={ x => this.setState({hasObservers:x})}/>
                    </Fragment>}

                    <label>Operation Altitude</label>
                    <details>
                        <summary>Is the UAV operated below 120 meters in altitude?</summary>
                        <p>
                            Altitude as measured from ground.
                        </p>
                    </details>
                    <RadioSelector curr_value={this.state.isAbove120} labels={['Yes','No']} values={[true,false]} onChange={ x => this.setState({isAbove120:x})}/>


                    <label>Operation Region</label>
                    <details>
                        <summary>Is the UAV operated in Urban Area?</summary>
                        <p>
                            Urban area have high population density or economic activities. Examples include cities, residential area, and industrial area.
                        </p>
                    </details>
                    <RadioSelector curr_value={this.state.isUrban} labels={['Yes','No']} values={[true,false]} onChange={ x => this.setState({isUrban:x})}/>


                    <label>UAV Class</label>
                    <details>
                        <summary>What is the labelled Class Identification of the UAV?</summary>
                        <p>
                            This classification refers mainly to the maximum take-off mass (MTOM), the technical specifications of the aircraft, the systems implemented, the systems that control these unmanned aircraft or the capabilities to operate safely and reliably in the different categories designed in the new European regulation. Unmanned aircraft must meet the requirements of the class to which it is chosen, to be able to belong to it. Otherwise, a drone that does not have a class label will be considered a “Legacy drone”, or what is the same, a drone prior to the entry into force of the current regulations. In order to identify which class label each unmanned aircraft has, a label will be affixed so that it is clearly visible, legible and indelible. 
                        </p>
                    </details>
                    <select value={this.state.uavClass} onChange={ e => this.setState({uavClass:e.target.value})}>
                        <option value='null'>Please select</option>
                        <option value='Legacy'>Legacy</option>
                        <option value='C0'>C0 (under 250g, Open Category)</option>
                        <option value='C1'>C1 (under 900g, Open Category)</option>
                        <option value='C2'>C2 (under 4kg, Open Category)</option>
                        <option value='C3'>C3 (under 25kg, Open Category)</option>
                        <option value='C4'>C4 (under 25kg, Open Category)</option>
                        <option value='C5'>C5 (Specific Category STS-01)</option>
                        <option value='C6'>C6 (Specific Category STS-02)</option>
                        <option value='privately_built_below_25kg'>Privately Built (MTOM &lt; 25kg)</option>
                        <option value='privately_built_from_25kg'>Privately Built (MTOM from 25kg)</option>
                        <option value='Other'>Other</option>
                    </select>


                    <label>Controlled Ground Area</label>
                    <details>
                        <summary>Will any uninvolved person be present in the Controlled Ground Area?</summary>
                        <p>
                            Controlled ground area is the ground risk buffer where the drone is expected to land onto in case of emergency.
                            Uninvolved people are people who aren't directly involved with a drone operation and have been given a safety briefing from the remote pilot.
                        </p>
                    </details>
                    <RadioSelector curr_value={this.state.hasUninvolvedPerson} labels={['Yes','No']} values={[true,false]} onChange={ x => this.setState({hasUninvolvedPerson:x})}/>

                    <label>Dangerous Good</label>
                    <p>Will the UAV carry dangerous goods?</p>
                    <RadioSelector curr_value={this.state.hasDangerousGoods} labels={['Yes','No']} values={[true,false]} onChange={ x => this.setState({hasDangerousGoods:x})}/>

                    <label>Material Drop</label>
                    <p>Will the UAV drop any material during the operation?</p>
                    <RadioSelector curr_value={this.state.hasDrop} labels={['Yes','No']} values={[true,false]} onChange={ x => this.setState({hasDrop:x})}/>
                    


                    { this.isA1() && 
                        <code>This is classififed as Open Category A1.</code>
                    }
                    { this.isA2() && 
                        <code>This is classififed as Open Category A2.</code>
                    }
                    { this.isA3() && 
                        <code>This is classififed as Open Category A3.</code>
                    }
                </Fragment>}


                { this.state.currPage=='1' && <Fragment>
                    <h2>Step 1: ConOps</h2>
                    <label>Descrition of Operation</label>
                    <details>
                        <summary>Description of the Concept of Operation?</summary>
                        <p>
                            (JARUS SORA A.1.3.1(a) v1.0) Detailed description of the CONOPS: describe what types of normal operations the operator intends to carry out (cf. guidance [1] and [2]). The detailed description should contain all information to get a detailed understanding of how, where and under which limitations orconditions the operations shall be performed. Relevant charts and any other information helpful to visualize and understand the intended operation should be included in this section.
                        </p>
                    </details>
                    <textarea>

                    </textarea>
                    <details>
                        <summary>What is the population density to be overflown?</summary>
                        <p>
                            (JARUS SORA A.1.3.1(b) v1.0)  Provide specific details on the type of operations (e.g. VLOS, BLOS), the population density to be overflown (e.g. away from people, sparsely populated, crowds) and the airspace requirements (e.g. segregated area, fully integrated, etc.
                        </p>
                    </details>
                    <RadioSelector curr_value={this.state.conopsPopDensity} labels={['Away from people','Sparsely populated','crowds']} values={['Away from people','Sparsely populated','crowds']} onChange={ x => this.setState({conopsPopDensity:x})}/>

                    <details>
                        <summary>Does the above ConOps description mention harm barriers in detail?</summary>
                        <p>
                            The first approach to mitigate the risk of a UAV operation is by the application of harm barriers. This approach is to reduce the likelihood and/or mitigate the effects of the hazard – UAS operation out of control – for each category of harm.
                        </p>
                    </details>
                    <RadioSelector curr_value={this.state.hasConopsHarmBarrier} labels={['Yes','No']} values={[true,false]} onChange={ x => this.setState({hasConopsHarmBarrier:x})}/>

                    <details>
                        <summary>Does the above ConOps description mention threat barriers in detail?</summary>
                        <p>
                            The first approach to mitigate the risk of a UAV operation is by the application of threat barriers. This approach is to reduce the likelihood of the UAS operation being out of control through mitigations that control the threats and/or reduce their likelihood of occurrence.
                        </p>
                    </details>
                    <RadioSelector curr_value={this.state.hasConopsThreatBarrier} labels={['Yes','No']} values={[true,false]} onChange={ x => this.setState({hasConopsThreatBarrier:x})}/>

                </Fragment>}



                { this.state.currPage=='2' && <Fragment>
                    <h2>Step 2: Ground Risk Category (GRC)</h2>

                    <label>UAV Max. Dimension</label>
                    <details>
                        <summary>What is the maximum characteristics dimension the UAV?</summary>
                        <p>
                            Consider the maximum distance in meters between any two points of the the unmanned vehicle. 
                        </p>
                    </details>
                    <RadioSelector curr_value={this.state.uvMaxDim} labels={['≤ 1m','≤ 3m','≤ 8m','> 8m']} values={['≤ 1m','≤ 3m','≤ 8m','> 8m']} onChange={ x => this.setState({uvMaxDim:x})}/>

                    <label>UAV Typical Kinetic Energy </label>
                    <details>
                        <summary>What is the maximum kinetic energy of the UAV?</summary>
                        <p>
                            Consider the maximum distance in meters between any two points of the the unmanned vehicle. 
                        </p>
                    </details>
                    <RadioSelector curr_value={this.state.uvMaxKe} labels={['≤ 700J','≤ 34kJ','≤ 1084kJ','> 1084kJ']} values={['≤ 700J','≤ 34kJ','≤ 1084kJ','> 1084kJ']} onChange={ x => this.setState({uvMaxKe:x})}/>

                    <label>Operation Scenario</label>
                    <details>
                        <summary>Operation Scenario of the UAV?</summary>
                        <p>
                            Consider the maximum distance in meters between any two points of the the unmanned vehicle. 
                        </p>
                    </details>
                    <select value={this.state.opsScenario} onChange={ e => this.setState({opsScenario: e.target.value})}>
                        <option value='null'>Please select</option>
                        <option value='vlos,ctr,sparse'>VLOS over controlled area, located inside a sparsely populated environment</option>
                        <option value='bvlos,nctr,sparse'>BVLOS over sparsely populated environment (over-flown areas uniformly inhabited)</option>
                        <option value='vlos,ctr,populated'>VLOS over controlled area, located inside a populated environment</option>
                        <option value='vlos,nctr,populated'>VLOS over populated environment</option>
                        <option value='bvlos,ctr,populated'>BVLOS over controlled area, located inside a populated environment</option>
                        <option value='bvlos,nctr,populated'>BVLOS over populated environment</option>
                        <option value='vlos,nctr,crowd'>VLOS over gathering of people</option>
                        <option value='bvlos,nctr,crowd'>BVLOS over gathering of people</option>
                    </select>

                    <h2>
                        <code>The UAV Ground Risk Class is {this.getGrc(this.state.uvMaxDim,this.state.uvMaxKe,this.state.opsScenario)}</code>
                    </h2>
                </Fragment>}
                
                { this.state.currPage=='3' && <Fragment>
                    <h2>Step 3: Harm Barrier</h2>
                    <label>Harm Barrier</label>
                    <details>
                        <summary>Describe the harm barrier(s) to be applied.</summary>
                        <p>
                            The use of harm barriers is an effective way to reduce the risk intrinsic to any specific operation. This step of the process allows for adaptation of the GRC based on the harm barriers available for the operation.
                        </p>
                    </details>
                    <textarea>

                    </textarea>
                    
                    <details>
                        <summary>Are there any ERP (Emergency Response Plan)?</summary>
                        <p>
                            An Emergency Response Plan (ERP) is in place, operator validated and effective.
                        </p>
                    </details>
                    <RadioSelector curr_value={this.state.hasErp} labels={['Yes','No']} values={[true,false]} onChange={ x => this.setState({hasErp:x})}/>

                    <label>ERP (Emergency Response Plan)</label>
                    <details>
                        <summary>Describe the ERP (Emergency Response Plan) to be applied.</summary>
                        <p>
                        </p>
                    </details>
                    <textarea/>

                    <label>Robustness of ERP (Emergency Response Plan)</label>
                    <details>
                        <summary>Are the described ERP (Emergency Response Plan) robust?</summary>
                        <p>
                        </p>
                    </details>
                    <RadioSelector curr_value={this.state.robustnessErp} labels={['Low/None','Medium','High']} values={['Low/None','Medium','High']} onChange={ x => this.setState({robustnessErp:x})}/>
                    <label>Remarks</label>
                    <textarea/>
                    <h2><code>The GRC adaptation is {this.getErpGrcAdaptation(this.state.robustnessErp)}</code></h2>
                    

                    <details>
                        <summary>Are effects of ground impact reduced?</summary>
                        <p>
                            e.g. emergency parachute, shelter
                        </p>
                    </details>
                    <RadioSelector curr_value={this.state.hasImpactReduction} labels={['Yes','No']} values={[true,false]} onChange={ x => this.setState({hasImpactReduction:x})}/>


                    <label>Impact Reduction Measures</label>
                    <details>
                        <summary>Describe the Impact Reduction Measures to be applied.</summary>
                        <p>
                        </p>
                    </details>
                    <textarea/>

                    <label>Robustness of Impact Reduction Measures</label>
                    <details>
                        <summary>Are the described Impact Reduction Measures robust?</summary>
                        <p>
                        </p>
                    </details>
                    <RadioSelector curr_value={this.state.robustnessImpactReduction} labels={['Low/None','Medium','High']} values={['Low/None','Medium','High']} onChange={ x => this.setState({robustnessImpactReduction:x})}/>
                    <label>Remarks</label>
                    <textarea/>
                    <h2><code>The GRC adaptation is {this.getImpactReductionGrcAdaptation(this.state.robustnessImpactReduction)}</code></h2>


                    <details>
                        <summary>Are technical containment in place and effective?</summary>
                        <p>
                            e.g. tether
                        </p>
                    </details>
                    <RadioSelector curr_value={this.state.hasContainment} labels={['Yes','No']} values={[true,false]} onChange={ x => this.setState({hasContainment:x})}/>


                    <label>Details of Technical Containment</label>
                    <details>
                        <summary>Describe the Technical Containment measures to be applied.</summary>
                        <p>
                        </p>
                    </details>
                    <textarea/>
                    
                    <label>Robustness of Technical Containment</label>
                    <details>
                        <summary>Are the described Technical Containment robust?</summary>
                        <p>
                        </p>
                    </details>
                    <RadioSelector curr_value={this.state.robustnessContainment} labels={['Low/None','Medium','High']} values={['Low/None','Medium','High']} onChange={ x => this.setState({robustnessContainment:x})}/>
                    <label>Remarks</label>
                    <textarea/>
                    <h2><code>The GRC adaptation is {this.getContainmentGrcAdaptation(this.state.robustnessContainment)}</code></h2>
                </Fragment>}

                { this.state.currPage=='4' && <Fragment>
                    <h2>Step 4: Lethality determination</h2>
                    <details>
                        <summary>What is the Lethality Level of the UAV?</summary>
                        <p>
                            Different UA might have different lethality characteristics. The likelihood that a person would suffer fatal injuries if struck by an UA is, like many other parameters in this document, subject to extensive studies. At the time of writing, it has been agreed to define the lethality with three qualitative descriptors: High,Average or Low.
                            Due to the consideration of both size and energy during the ground risk determination, the nominal lethality of the crash area for most UAS can be anticipated. This lethality is expressed as AVERAGE. However, there are certain cases or design aspects that may not have been considered during the ground risk class that will have a significant effect on the lethality of the UAS such as fuel, high-energy rotors/props, frangibility, material, etc. These considerations may either increase or decrease the calculated SAIL.
                        </p>
                    </details>
                    <textarea/>
                    <RadioSelector curr_value={this.state.uavLethality} labels={['Low','Average','High']} values={['Low','Average','High']} onChange={ x => this.setState({uavLethality:x})}/>
                </Fragment>}

                { this.state.currPage=='5' && <Fragment>
                    <h2>Step 5: SAIL determination based on the final GRC</h2>
                    <details>
                        <summary><h2>SAIL (Specific Assurance and Integrity Levels)</h2></summary>
                        <p>
                            The SAIL represents the level of confidence that the UAS operation will stay under control. With the final ground risk class and the lethality parameter being determined, it is now possible to define the Specific Assurance and Integrity Level (SAIL) and the associated objectives to be met in order to establish a sufficient level of confidence that the likelihood of losing control of the UAS operation is commensurate with the proposed ConOps. The chosen parameter to consolidate all data and to drive the required activities is the SAIL. The SAIL represents the level of confidence that the UAS operation will stay under control.
                        </p>
                    </details>
                    <h2><code>The Ground Risk SAIL is determined to be {this.getSail(this.state.uavLethality,this.getGrc(this.state.uvMaxDim,this.state.uvMaxKe,this.state.opsScenario),this.state.robustnessErp,this.state.robustnessImpactReduction,this.state.robustnessContainment)}</code></h2>
                </Fragment>}

                
                { this.state.currPage=='6' && <Fragment>
                    <h2>Step 6: Determination of the Airspace Encounter Category (AEC)</h2>
                    <details>
                        <summary></summary>
                        <p>
                            The airspace encounter category (AEC) is a grouping of airspace types that best reflect perceived levels of collision risk. The AEC is grouped by operational altitude, airport environment, controlled airspace, uncontrolled Mode C veil/TMZ airspace, and in uncontrolled airspace over rural and/or urban populations, into 12 categorizations. An operation in which the UAS will operate in more than one AEC airspace will require an assessment for each AEC to insure that there is an acceptable collision risk throughout the entire operation.
                        </p>
                    </details>

                    { !this.state.isEditingAec && <table class='table-normal fullwidth'>
                        <thead><tr><th>Name</th><th>AEC</th><th>Description</th><th>Action</th></tr></thead>
                        <tbody>
                            { this.state.aecs?.map( (x,i) => 
                                <tr key={i}>
                                    <td>{x.name}</td>
                                    <td>{x.aec}</td>
                                    <td>{x.description}</td>
                                    <td>
                                        <button
                                            class='button-normal'
                                            onClick={ _ => this.setState({isEditingAec: true, tempAec:x, tempAecIndex:i})}
                                        >Edit</button>
                                    </td>
                                </tr>
                            )}
                            { !this.state.aecs &&
                                <tr><td colSpan='4'>No AECs listed</td></tr>
                            }
                            <tr>
                                <td colSpan='4'>
                                    <button
                                        class='button-normal'
                                        disabled={this.state.isEditingAec}
                                        onClick={ _ => {
                                            this.setState({isEditingAec:true,tempAecIndex:null})
                                        }}
                                    >
                                        Add a new AEC
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>}

                    { this.state.isEditingAec && <Fragment>
                        <h3>AEC Finder</h3>
                        <AecFinder
                            init={this.state.tempAec}
                            onCancel={_ => this.setState({isEditingAec:false})}
                            onConfirm={new_aec => {
                                if (this.state.tempAecIndex == null) {
                                    if (!this.state.aecs) {
                                        return this.setState({isEditingAec:false,aecs:[new_aec]})
                                    }
                                    return this.setState( s => {
                                        s.aecs.push(new_aec)
                                        s.isEditingAec = false
                                        return s
                                    })
                                }
                                this.setState(s => {
                                    s.isEditingAec = false
                                    s.aecs[s.tempAecIndex] = new_aec
                                    return s
                                })
                            }}
                        />
                    </Fragment>}

                </Fragment>}


                
                { this.state.currPage=='7' && <Fragment>
                    <h2>Step 7: Initial assessment of the Air-Risk Class (ARC)</h2>
                    
                    <label>ARC Determination</label>
                    <details>
                        <summary>What is the labelled Class Identification of the UAV?</summary>
                        <p>
                            The ARC is based on the assessment of the following three parameters: a. Rate of Proximity – (sometimes called “encounter rate”) The more aircraft in the airspace, the higher the rate of proximity, the greater the risk of collision. A collision requires two aircraft, and that those two aircraft are in proximity of each other. In general terms, the more aircraft in a given volume of airspace, the more opportunities there are for aircraft to be proximal to each other. The rate at which aircraft proximity happens is approximately equal to the square of the number of aircraft within that system. Therefore, the rate at which aircraft will be proximal to each other grows exponentially by the number of aircraft. b.c.Geometry - An airspace which sets or allows aircraft on collision courses increases risk of collision. One of the ways to reduce collision risk is to control the geometry of the aircraft within an airspace. An everyday example of this is a road. All cars on one side of the road go one direction, all cars on the other side of the road go the other direction. In this way, the geometries of the cars are controlled and collision risk between cars is greatly reduced. By controlling the geometry of aircraft through airspace structures, procedures, and regulations, collision risk between aircraft is greatly reduced. Dynamics - The faster the speed of the aircraft in the airspace the higher the rate of proximity, the greater the risk of collision. Simply put, the faster the closing speed of a collision pair, the faster they will collide. In general terms, over a single flight hour, a volume of airspace where the average closing speed of the collision pairs is 200 mph, will have twice as many collisions than if the average closing speed is 100 mph. Faster aircraft mean faster closing speeds, meaning more chances for proximity, which means more collision risk, over a set amount of time.
                        </p>
                        <table>
                            <thead>
                                <tr><th>Class</th><th>AEC</th><th>Operational Airspace</th><th>ARC</th></tr>
                            </thead>
                            <tbody>
                                <tr><td>Integrated Airspace Operations above 500ft.</td><td>1</td><td>Operations within Class A, B, C, D, or E airspace above 500 ft. AGL</td><td>4</td></tr>
                                <tr><td>Integrated Airspace Operations above 500ft.</td><td>2</td><td>Operations within an Airport Environment above 500 ft. AGL</td><td>4</td></tr>
                                <tr><td>Integrated Airspace Operations above 500ft.</td><td>3</td><td>Operations within Class G airspace above 500 ft. AGL within Mode C Veil/TMZ</td><td>4</td></tr>
                                <tr><td>Integrated Airspace Operations above 500ft.</td><td>4</td><td>Operations within Class G airspace above 500 ft. AGL over urban environment</td><td>3</td></tr>
                                <tr><td>Integrated Airspace Operations above 500ft.</td><td>5</td><td>Operations within Class G airspace above 500 ft. AGL over rural environment</td><td>3</td></tr>
                                <tr><td>VLL Airspace Operations below 500ft.</td><td>6</td><td>Operations within Class A, B, C, D, or E airspace below 500 ft. AGL</td><td>3</td></tr>
                                <tr><td>VLL Airspace Operations below 500ft.</td><td>7</td><td>Operations within an Airport Environment below 500 ft. AGL</td><td>4</td></tr>
                                <tr><td>VLL Airspace Operations below 500ft.</td><td>8</td><td>Operations within Class G airspace below 500 ft. AGL within Mode C Veil/TMZ</td><td>3</td></tr>
                                <tr><td>VLL Airspace Operations below 500ft.</td><td>9</td><td>Operations within Class G airspace below 500 ft. AGL over urban environment</td><td>3</td></tr>
                                <tr><td>VLL Airspace Operations below 500ft.</td><td>10</td><td>Operations within Class G airspace below 500 ft. AGL over rural environment</td><td>2</td></tr>
                                <tr><td>VHL</td><td>11</td><td>Operations in airspace above FL600</td><td>2</td></tr>
                                <tr><td>Any</td><td>12</td><td>Operations in Atypical Airspace</td><td>1</td></tr>
                            </tbody>
                        </table>
                    </details>
                    <h2>The determination of ARC is {this.getArc(this.state.aecs)}</h2>
                    <details>
                        <summary>ARC determination</summary>
                        <p>
                        ARC 1 is generally defined as airspace where the risk of collision between a UAS and manned aircraft is acceptably safe without the addition of any collision mitigation. However, even though the ARC is technically safe to fly from an air collision risk standpoint; it does not fulfil the 14 CFR 91.113, SERA 3201, or ICAO Annex 2 section 3.2 “See and Avoid” requirement. Those requirements must be addressed to the satisfaction of the competent authority.
                        ARC 2 is generally defined as airspace where the risk of collision between a UAS and manned aircraft is very low. This collision risk class requires some sort of collision mitigation but the amount of mitigation, and performance level of that mitigation will be low.
                        ARC 3 is generally defined as airspace where the risk of collision between a UAS and manned aircraft is low to moderate. This collision risk class requires some more collision mitigation than Class 2 but less than Class 4. The amount of mitigations and performance level of those mitigations will be low to moderate.
                        ARC 4 is defined as airspace where the risk of collision between a UAS and manned aircraft is moderate to high. This is airspace where manned aircraft are expected to be found operating. In this airspace, the UAS is operating in Integrated Airspace and must comply with existing operating rules and procedures for manned aircraft, without reducing existing capacity, decreasing safety, negatively affecting current operators, or increasing the risk to airspace users or persons and property on the ground, any more than the integration of comparable new and novel technologies.
                        </p>
                    </details>
                </Fragment>}


                { this.state.currPage=='8' && <Fragment>
                    <h2>Step 8: Establish Strategic Mitigations</h2>

                    <details>
                        <summary>Describe any Strategic Mitigation Measures in detail.</summary>
                        <p>
                            For the purposes of this assessment, Strategic Mitigations take the form of operational restriction of time or space taken prior to take-off aimed at reducing aircraft density and/or exposure time and thereby reducing encounter rates:
                            a. Restriction by time (e.g. fly at night, etc.)
                            b. Restriction by space (e.g. remain inside a prescribed airspace volume, etc.)
                            c. Restriction by time-of-exposure (e.g. the UA is predominantly intended to fly in an
                            airspace but it will cut the corner of another one for a very limited amount of time. Risk in the corner cutting airspace is limited because of the short amount of time in that airspace, etc.)
                            d. Strategic separation by proceduref - all participants in the airspace are equipped and follow procedures designed to control the geometry of all aircraft.
                        </p>
                    </details>
                    <textarea/>

                    <label>Robustness of Strategic Mitigation Measures</label>
                    <details>
                        <summary>Are the described Strategic Mitigation Measures robust?</summary>
                        <p>
                            This section is to be completed by a Competent Authority.
                            Example 1, the operator/applicant wishes to fly in airspace designated ARC 4. The operator/applicant puts together a compelling case and presents it to the competent authority. The competent authority looks at the operator/applicant data, does some independent investigation and concludes that the operator/applicant is correct. The competent authority lowers the ARC from ARC 4 to ARC 2. Because the ARC is lowered by 2 levels, the compelling case needs to be shown at a Medium level of robustness. Example 2, the operator/applicant wishes to fly in airspace designated ARC 4. The operator/applicant puts together a compelling case and presents it to the competent authority. The competent authority looks at the operator/applicant data, does some independent investigation and concludes that the operator/applicant is incorrect. The operator/applicant states that they are willing to except operational restrictions, which only allow UAS operations to fly at night when the local aircraft density is even lower. The competent authority agrees to the operational restriction and lowers the ARC from ARC 4 to ARC 3. Because the ARC is lowered by 1 level, the compelling case needs to be shown at a Low level of robustness.
                        </p>
                    </details>
                    <RadioSelector curr_value={this.state.robustnessErp} labels={['Low/None','Medium','High']} values={['Low/None','Medium','High']} onChange={ x => this.setState({robustnessErp:x})}/>
                    <label>Remarks</label>
                    <textarea/>
                    <h2><code>The GRC adaptation is {this.getErpGrcAdaptation(this.state.robustnessErp)}</code></h2>
                </Fragment>}

                
            
                {/* <h2>Mitigation</h2>

                <h2>Tatical Mitigation Performance Requirments</h2>
                <h3>Level of Robustness</h3>


                <h2></h2>



                <h2>Operational Safety Objectives (OSOs)</h2>


                <h2>Adjacent Area Considerations</h2>


                <h2>Comprehensive Safety Portfolio</h2> */}
            </div>
        )
    }
}