import React from 'react'
import '../../style.css'

export class Status extends React.Component{
    constructor(props) {
        super(props)
    }

    displayStatus(status) {
        if (this.props.iconOnly) {
            switch (status) {
            case 'authenticating':
                return <span><svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="gold"/></svg></span>
            case 'authenticated':
                return <span><svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="green"/></svg></span>
            case 'disconnected':
                return <span><svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="red"/></svg></span>
            }    
        }
        switch (status) {
        case 'authenticating':
            return <span><svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="gold"/></svg> amqp {status}</span>
        case 'authenticated':
            return <span><svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="green"/></svg> amqp {status}</span>
        case 'disconnected':
            return <span><svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="red"/></svg> amqp {status}</span>
        }
    }

    render = _ =>
        <code>{this.displayStatus(this.props.amqpState)}</code>
}

export class Simple extends React.Component {
    constructor(props) {
        super(props)
    }

    resetPassword() {
        let newPassword = prompt('Please enter new password', '')
        if (!newPassword) {
            return
        }
        if (newPassword == '') {
            alert('password empty')
            return
        }
        fetch(PUBLIC_PATH_ROOT + 'api/amqp/reset_password', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({
                token: this.props.credentials.token,
                password: newPassword
            })
        }).then(res => res.json())
            .then(data => {
                console.log('amqp: res:', data)
                if (data.success) alert('amqp: password resetted, permissions:' + JSON.stringify(data.permissions))
                else alert('amqp: password reset failed, reason:' + JSON.stringify(data.reason))
            })
            .catch(err => alert('failed with error:', err))
    }

    render() {
        return (
            <div>
                <h2>AMQP Settings</h2>
                <div id='amqpSimpleContainer' style={{ textAlign: "center" }}>
                    <p>username: <code className='username'> {this.props.credentials.username} </code></p>
                    <button
                        className='button-large'
                        onClick={_ => this.resetPassword()}
                    >
                        Reset Password
                    </button>
                </div>
            </div>
        )
    }
}

export class Detail extends React.Component {

    constructor(props) {
        super(props)
        this.state = {state:'idle'}
    }

    displayStatus(status) {
        switch (status) {
        case 'authenticating':
            return <span><svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="gold"/></svg> amqp {status}</span>
        case 'authenticated':
            return <span><svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="green"/></svg> amqp {status}</span>
        case 'disconnected':
            return <span><svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="red"/></svg> amqp {status}</span>
        }
    }

    reconnect = _ => {
        this.setState({state:'waiting'}, _ => {
            this.props.connectAmqpServer()
            setTimeout(_ => this.setState({state:'idle'}), 2000)
        })
    }

    disconnect = _ => {
        this.props.disconnectAmqpServer()
    }

    reconfigure = _ => {
        this.setState({state:'waiting'}, _ => {
            this.props.connectAmqpServer()
            setTimeout(_ => this.setState({state:'idle'}), 2000)
        })
        fetch( PUBLIC_PATH_ROOT + 'api/amqp/config', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({
                token: this.props.credentials.token
            })
        })
        .then(res => res.json())
        .then(data => {
            if (data.success) {
                alert('reconfigure success')
                return
            }
            throw 'reconfigure failed'
        })
        .catch()
    }


    render() {
        return (
            <div id='amqpStatus'>
                <p>{this.displayStatus(this.props.amqpState)}</p>
                { this.props.amqpState == 'disconnected' &&
                    <button disabled={this.state.state == 'waiting'} className='button-large' onClick={this.reconnect}>Reconnect</button>
                }
                { this.props.amqpState == 'authenticated' &&
                    <button className='button-large' onClick={this.disconnect}>Disconnect</button>
                }
                <p>Last Message</p>
                { this.props.amqpMsg ?
                    <p><code>{JSON.stringify(this.props.amqpMsg)}</code></p>
                :
                    <p><code>No Message Received</code></p>
                }
                <p>Beacons</p>
                { this.props.amqpMsg ?
                    <table>
                    <thead></thead>
                    <tbody>
                        { Object.entries(this.props.beacon ?? {}).map( x => <tr>
                            <td key={x[0]}><code>{JSON.stringify(x[1])}</code></td>
                        </tr>)}
                    </tbody>
                    </table>
                :
                    <p><code>No Message Received</code></p>
                }
                <button
                    className='button-large'
                    onClick={this.reconfigure}
                    disabled={this.state.state != 'idle'}
                >
                    Reconfigure Account
                </button>
            </div>
        )
    }
}