import React, { Component } from 'react'
import RegisterUserComponent from './registerUser'
import RegisterUxvComponent from './registerUxv'
import RegisterPayloadComponent from './registerPayload'
import './register.css'
import '../../style.css'

export default class RegisterComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user_type : ''
        }
        window.onpopstate = () => this.props.routeLoginPage()
    }

    componentDidMount() {
        window.history.pushState(null,'register','/register')
    }

    setType = type => {
        console.log('type:',type)
        this.setState({user_type : type})
    }

    goToLogin = _ => this.props.routeLoginPage()

    render() {
        return (
            <div id='registerContainer'>
                <div className='formContainer'>

                    <div id='titleContainer'>
                        <h2><b>Register</b></h2>
                    </div>

                    <label>Register Type</label>

                    <div id='registerType' className='radioContainer'>
                        <div className='radioItem'>
                            <div>
                                <input type='radio' id='user' name='user_type' value='user'
                                    onClick={e => this.setType(e.target.value)}
                                />
                                <label>User</label>
                            </div>
                        </div>
                        <div className='radioSeparater'/>
                        <div className='radioItem'>
                            <div>
                                <input type='radio' id='uxv' name='user_type' value='uxv'
                                    onClick={e => this.setType(e.target.value)}
                                />
                                <label>UXV</label>
                            </div>
                        </div>
                        <div className='radioSeparater'/>
                        <div className='radioItem'>
                            <div>
                                <input type='radio' id='payload' name='user_type' value='payload'
                                    onClick={e => this.setType(e.target.value)}
                                />
                                <label>Payload</label>
                            </div>
                        </div>
                    </div>
                </div>

                { this.state.user_type == 'user' && <RegisterUserComponent routeLoginPage={this.props.routeLoginPage}/>}
                { this.state.user_type == 'uxv' && <RegisterUxvComponent routeLoginPage={this.props.routeLoginPage}/>}
                { this.state.user_type == 'payload' && <RegisterPayloadComponent routeLoginPage={this.props.routeLoginPage}/>}

                <div className='formContainer'>
                    <button
                        id = 'cancelButton'
                        className='button-large fullWidthButton'
                        onClick={ _ => this.goToLogin()}>
                        Cancel
                    </button>
                </div>
            </div>
        )
    }
}