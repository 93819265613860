import React from 'react'
import '../../style.css'
import { TimePicker } from '../timepicker/timepicker'
import Calender  from '../calender/calender'
import * as MissionEditor from '../missionEditor/missionEditor'

export class TaskCreator extends React.Component {

    constructor(props) {
        super(props)
        if (this.props.existing_task) console.log('existing task:',this.props.existing_task)
        this.state = {
            task_types: null,
            task_type: this.props.existing_task?.task_type ?? null,
            timeszones: null,
            uxvs: null,
            selected_uxv: this.props.existing_task?.uxv?.id ?? null,
            lonlat: this.props.existing_task?.centroid?.coordinates ?? null
        }
        this.props.map.plotTempTaskMarker(this.props.existing_task?.centroid?.coordinates)

        fetch(PUBLIC_PATH_ROOT + 'api/timezones',{
            method: 'GET'
        })
        .then(res => res.json())
        .then(rs => {
            if (!rs.success) throw rs.reason
            this.setState({timeszones:rs.timeszones})
        })
        .catch( err => console.log('err:', err))

        fetch(PUBLIC_PATH_ROOT + 'auth/uxvs',{
            method: 'POST',
            body: JSON.stringify({token:this.props.credentials.token})
        })
        .then( res => res.json())
        .then( rs => {
            if (!rs.success) throw rs.reason
            this.setState({uxvs:rs.uxvs})
        })
        .catch( err => console.log('err:', err))
    }

    componentDidMount() {
        if (this.props.existing_task?.task_type == 'capture_photo' ) {
            this.setState({
                selected_enroute_task_id: this.props.existing_task.enroute_task_id,
                selected_lon:this.props.existing_task?.poi.coordinates[0],
                selected_lat:this.props.existing_task?.poi.coordinates[1],
                selected_alt:this.props.existing_task?.poi.coordinates[2]
            })
            this.props.map.selectTask(this.props.existing_task.enroute_task_id)
            this.props.map.plotTempPoiMarker(this.props.existing_task.poi.coordinates)
        }
    }

    deleteEnrouteTask = task_id => {
        console.log('delete enroute task...')
        fetch(PUBLIC_PATH_ROOT + 'api/delete_enroute_task',{
            method: 'POST',
            body: JSON.stringify({token:this.props.credentials.token,task_id:task_id})
        })
        .then(rs=>rs.json())
        .then(rs=>{
            if (!rs.success) throw rs.reason
            this.setState({existing_task:null}, _ => this.props.routePreviousPage())
        })
        .catch(err=>console.log('err:',err))
    }
    createEnrouteTask = _ => {
        console.log('creating task...')
        Promise.resolve()
        .then( _ => {
            let checkpoint_polygon = this.props.map.getTempTaskGeoJson()
            if (!this.timePicker.state.tz) throw 'tz missing'
            if (!this.timePicker.state.timeString) throw 'time missing'
            if (!this.state.selected_uxv) throw 'selected_uxv missing'
            if (!this.state.task_type) throw 'task_type missing'
            if (!this.datePicker.state.selectedDate) throw 'date missing'
            if (!checkpoint_polygon) throw 'geoJson missing'
            return fetch(PUBLIC_PATH_ROOT + 'api/create_enroute_task',{
                method:'POST',
                body: JSON.stringify({
                    token:this.props.credentials.token,
                    tz: this.timePicker.state.tz,
                    stay_until: this.datePicker.state.selectedDate.toISOString().substring(0, 10) + 'T' + this.timePicker.state.timeString,
                    uxv_id: this.state.selected_uxv,
                    mission_id: this.props.mission_id,
                    checkpoint_polygon: checkpoint_polygon
                })
            })
        })
        .then(rs=>rs.json())
        .then(rs=>{
            if (!rs.success) throw rs.reason
            console.log('task:',rs.task)
            this.props.routePreviousPage()
        })
        .catch(err=>console.log('err:',err))
    }
    deleteInsertionTask = task_id => {
        console.log('delete insertion task...')
        fetch(PUBLIC_PATH_ROOT + 'api/delete_insertion_task',{
            method: 'POST',
            body: JSON.stringify({token:this.props.credentials.token,task_id:task_id})
        })
        .then(rs=>rs.json())
        .then(rs=>{
            if (!rs.success) throw rs.reason
            this.setState({existing_task:null}, _ => this.props.routePreviousPage())
        })
        .catch(err=>console.log('err:',err))
    }
    createInerstionTask = _ => {
        console.log('creating task...')
        Promise.resolve()
        .then( _ => {
            let checkpoint_polygon = this.props.map.getTempTaskGeoJson()
            if (!this.timePicker.state.tz) throw 'tz missing'
            if (!this.timePicker.state.timeString) throw 'time missing'
            if (!this.state.selected_uxv) throw 'selected_uxv missing'
            if (!this.state.task_type) throw 'task_type missing'
            if (!this.datePicker.state.selectedDate) throw 'date missing'
            if (!checkpoint_polygon) throw 'geoJson missing'
            return fetch(PUBLIC_PATH_ROOT + 'api/create_insertion_task',{
                method:'POST',
                body: JSON.stringify({
                    token:this.props.credentials.token,
                    tz: this.timePicker.state.tz,
                    start_at: this.datePicker.state.selectedDate.toISOString().substring(0, 10) + 'T' + this.timePicker.state.timeString,
                    uxv_id: this.state.selected_uxv,
                    mission_id: this.props.mission_id,
                    checkpoint_polygon: checkpoint_polygon
                })
            })
        })
        .then(rs=>rs.json())
        .then(rs=>{
            if (!rs.success) throw rs.reason
            console.log('task:',rs.task)
            this.props.routePreviousPage()
        })
        .catch(err=>console.log('err:',err))
    }

    deleteCapturePhotoTask = task_id => {
        console.log('deleteCapturePhotoTask')
        fetch(PUBLIC_PATH_ROOT + 'api/delete_capture_photo_task',{
            method: 'POST',
            body: JSON.stringify({token:this.props.credentials.token,task_id:task_id})
        })
        .then(rs=>rs.json())
        .then(rs=>{
            if (!rs.success) throw rs.reason
            this.setState({existing_task:null}, _ => this.props.routePreviousPage())
        })
        .catch(err=>console.log('err:',err))
    }
    createCapturePhotoTask = _ => {
        console.log('createCapturePhotoTask')
        Promise.resolve()
        .then( _ => {
            let poi = {
                type: 'Point',
                coordinates: [this.state.selected_lon, this.state.selected_lat, this.state.selected_alt]
            }
            if (!this.timePicker.state.tz) throw 'tz missing'
            if (!this.timePicker.state.timeString) throw 'time missing'
            if (!this.state.selected_enroute_task_id) throw 'selected_enroute_task_id missing'
            if (!this.datePicker.state.selectedDate) throw 'date missing'
            return fetch(PUBLIC_PATH_ROOT + 'api/create_capture_photo_task',{
                method:'POST',
                body: JSON.stringify({
                    token:this.props.credentials.token,
                    tz: this.timePicker.state.tz,
                    start_at: this.datePicker.state.selectedDate.toISOString().substring(0, 10) + 'T' + this.timePicker.state.timeString,
                    mission_id: this.props.mission_id,
                    enroute_task_id: this.state.selected_enroute_task_id,
                    poi: JSON.stringify(poi)
                })
            })
        })
        .then(rs=>rs.json())
        .then(rs=>{
            if (!rs.success) throw rs.reason
            console.log('task:',rs.task)
            this.props.routePreviousPage()
        })
        .catch(err=>console.log('err:',err))
    }

    deleteCommenceMissionTask = task_id => {
        fetch(PUBLIC_PATH_ROOT + 'api/delete_commence_mission_task',{
            method: 'POST',
            body: JSON.stringify({token:this.props.credentials.token,task_id:task_id})
        })
        .then(rs=>rs.json())
        .then(rs=>{
            if (!rs.success) throw rs.reason
            this.setState({existing_task:null}, _ => this.props.routePreviousPage())
        })
        .catch(err=>console.log('err:',err))
    }

    deleteConcludeMissionTask = task_id => {
        fetch(PUBLIC_PATH_ROOT + 'api/delete_conclude_mission_task',{
            method: 'POST',
            body: JSON.stringify({token:this.props.credentials.token,task_id:task_id})
        })
        .then(rs=>rs.json())
        .then(rs=>{
            if (!rs.success) throw rs.reason
            this.setState({existing_task:null}, _ => this.props.routePreviousPage())
        })
        .catch(err=>console.log('err:',err))
    }

    createCommenceMissionTask = _ => {
        Promise.resolve()
        .then( _ => {
            if (!this.timePicker.state.tz) throw 'tz missing'
            if (!this.timePicker.state.timeString) throw 'time missing'
            if (!this.datePicker.state.selectedDate) throw 'date missing'
            if (!this.state.selected_uxv) throw 'uxv_id missing'
            return fetch(PUBLIC_PATH_ROOT + 'api/create_commence_mission_task',{
                method:'POST',
                body: JSON.stringify({
                    token:this.props.credentials.token,
                    tz: this.timePicker.state.tz,
                    start_at: this.datePicker.state.selectedDate.toISOString().substring(0, 10) + 'T' + this.timePicker.state.timeString,
                    mission_id: this.props.mission_id,
                    uxv_id: this.state.selected_uxv
                })
            })
        })
        .then(rs=>rs.json())
        .then(rs=>{
            if (!rs.success) throw rs.reason
            console.log('task:',rs.task)
            this.props.routePreviousPage()
        })
        .catch(err=>console.log('err:',err))
    }

    createConcludeMissionTask = _ => {
        Promise.resolve()
        .then( _ => {
            if (!this.timePicker.state.tz) throw 'tz missing'
            if (!this.timePicker.state.timeString) throw 'time missing'
            if (!this.datePicker.state.selectedDate) throw 'date missing'
            if (!this.state.selected_uxv) throw 'uxv_id missing'
            return fetch(PUBLIC_PATH_ROOT + 'api/create_conclude_mission_task',{
                method:'POST',
                body: JSON.stringify({
                    token:this.props.credentials.token,
                    tz: this.timePicker.state.tz,
                    end_at: this.datePicker.state.selectedDate.toISOString().substring(0, 10) + 'T' + this.timePicker.state.timeString,
                    mission_id: this.props.mission_id,
                    uxv_id: this.state.selected_uxv
                })
            })
        })
        .then(rs=>rs.json())
        .then(rs=>{
            if (!rs.success) throw rs.reason
            console.log('task:',rs.task)
            this.props.routePreviousPage()
        })
        .catch(err=>console.log('err:',err))
    }

    render() {

        return <div>
            <h3>New Task</h3>

            <label>Task Type</label>
            <select
                value={this.state.task_type ?? 'null'}
                onChange={ e => this.setState({task_type:e.target.value})}
            >
                <option disable='true' hidden value={null}>Select Task Type</option>
                <option value='commence_mission'>Commence Mission</option>
                <option value='conclude_mission'>Conclude Mission</option>
                <option value='enroute'>En Route</option>
                <option value='insertion'>Insertion</option>
                <option value='health_check'>Health Check</option>
                <option value='extraction'>Extraction</option>
                <option value='capture_photo'>Capture Photo</option>
                <option value='capture_video_tasks'>Capture Video</option>
                <option value='set_video_poi'>Set Video POI</option>
                <option value='land_on_tracker'>Land on Tracker</option>
                {/* <option value='hold_position'>Hold Position</option> */}
                {/* <option value='crack_detection'>Crack Detection</option> */}
                {/* <option value='photogammetry'>Photogammetry</option> */}
                {/* <option value='emission_monitor'>Emission Monitoring</option> */}
            </select>
            { this.state.task_type == 'commence_mission' && <div>
                <label>Commence At</label>
                <p>Date</p>
                <Calender
                    ref={x=>this.datePicker=x}
                    presetTimestamp={this.props.existing_task?.start_at ?? this.props.itinerary?.[0].key_time}
                />
                <p>Time</p>
                <TimePicker
                    ref={x => this.timePicker=x}
                    presetTimestamp={this.props.existing_task?.start_at ?? this.props.itinerary?.[0].key_time}
                />

                <label>UXV</label>
                <select value={this.state.selected_uxv ?? 'null'} onChange={ e => this.setState({selected_uxv: e.target.value})}>
                    <option hidden value='null'>Select UXV</option>
                    { this.state.uxvs?.map( x =>
                        <option key={x.id} value={x.id}>{x.id} {x.codename} ({x.uxv_type})</option>
                    )}
                </select>

                { this.props.existing_task &&
                    <button class='button-large' onClick={_ => this.deleteCommenceMissionTask(this.props.existing_task.id)}>Delete Task</button>
                }
                { this.props.existing_task ?
                    <button class='button-large'
                        onClick={ _ => {
                            this.deleteCommenceMissionTask(this.props.existing_task.id)
                            this.createCommenceMissionTask()
                        }}>Overwrite Task</button>
                    :
                    <button class='button-large' onClick={this.createCommenceMissionTask}>Create Task</button>
                }
            </div>}
            { this.state.task_type == 'conclude_mission' && <div>
                <label>Conclude At</label>
                <p>Date</p>
                <Calender
                    ref={x=>this.datePicker=x}
                    presetTimestamp={this.props.existing_task?.start_at ?? this.props.itinerary?.[0].key_time}
                />
                <p>Time</p>
                <TimePicker
                    ref={x => this.timePicker=x}
                    presetTimestamp={this.props.existing_task?.start_at ?? this.props.itinerary?.[0].key_time}
                    onDayChanged={ x => { this.datePicker.incrementDays(x)}}
                />

                <label>UXV</label>
                <select value={this.state.selected_uxv ?? 'null'} onChange={ e => this.setState({selected_uxv: e.target.value})}>
                    <option hidden value='null'>Select UXV</option>
                    { this.state.uxvs?.map( x =>
                        <option key={x.id} value={x.id}>{x.id} {x.codename} ({x.uxv_type})</option>
                    )}
                </select>

                { this.props.existing_task &&
                    <button class='button-large' onClick={_ => this.deleteConcludeMissionTask(this.props.existing_task.id)}>Delete Task</button>
                }
                { this.props.existing_task ?
                    <button class='button-large'
                        onClick={ _ => {
                            this.deleteConcludeMissionTask(this.props.existing_task.id)
                            this.createConcludeMissionTask()
                        }}>Overwrite Task</button>
                    :
                    <button class='button-large' onClick={this.createConcludeMissionTask}>Create Task</button>
                }
            </div>}
            { this.state.task_type == 'enroute' && <div>
                <label>CheckPoint Location</label>
                <MissionEditor.Viewer
                    map={this.props.map}
                    mapSize={'medium'}
                    ref={x=> this.missionEditor = x}
                    currPage='add_task'
                    onClick= { x => {
                        this.setState({lonlat:x})
                        fetch(PUBLIC_PATH_ROOT + `api/search_timezone?lon=${x[0]}&lat=${x[1]}`,{
                            method: 'GET'
                        })
                        .then(res => res.json())
                        .then( rs => {
                            if (!rs.success) throw 'timezone not found'
                            this.timePicker.setState({tz:rs.tz})
                        })
                        .catch( err => console.log('err:',err))
                    }}
                />
                <table class='table-normal'>
                    <thead>
                        <tr><th>Lon</th><th>Lat</th><th>Radius</th></tr>
                    </thead>
                    <tbody>
                        <tr><td>{this.state.lonlat?.[0]}</td><td>{this.state.lonlat?.[1]}</td><td>2</td></tr>
                    </tbody>
                </table>


                <label>Stay Until</label>
                <p>Date</p>
                <Calender
                    ref={x=>this.datePicker=x}
                    presetTimestamp={this.props.existing_task?.stay_until ?? this.props.itinerary?.[0].key_time}
                />
                <p>Time</p>
                <TimePicker
                    ref={x => this.timePicker=x}
                    presetTimestamp={this.props.existing_task?.stay_until ?? this.props.itinerary?.[0].key_time}
                />

                <label>UXV</label>
                <select value={this.state.selected_uxv ?? 'null'} onChange={ e => this.setState({selected_uxv: e.target.value})}>
                    <option hidden value='null'>Select UXV</option>
                    { this.state.uxvs?.map( x =>
                        <option key={x.id} value={x.id}>{x.id} {x.codename} ({x.uxv_type})</option>
                    )}
                </select>
                { this.props.existing_task &&
                    <button class='button-large' onClick={_ => this.deleteEnrouteTask(this.props.existing_task.id)}>Delete Task</button>
                }
                { this.props.existing_task ?
                    <button class='button-large'
                        onClick={ e => {
                            this.deleteEnrouteTask(this.props.existing_task.id)
                            this.createEnrouteTask(e)
                        }}>Overwrite Task</button>
                    :
                    <button class='button-large' onClick={this.createEnrouteTask}>Create Task</button>
                }
            </div>}
            { this.state.task_type == 'insertion' && <div>
                <label>Insertion Location</label>
                <MissionEditor.Viewer
                    map={this.props.map}
                    mapSize={'medium'}
                    ref={x=> this.missionEditor = x}
                    currPage='add_task'
                    onClick= { x => {
                        this.setState({lonlat:x})
                        fetch(PUBLIC_PATH_ROOT + `api/search_timezone?lon=${x[0]}&lat=${x[1]}`,{
                            method: 'GET'
                        })
                        .then(res => res.json())
                        .then( rs => {
                            if (!rs.success) throw 'timezone not found'
                            this.timePicker.setState({tz:rs.tz})
                        })
                        .catch( err => console.log('err:',err))
                    }}
                />
                <table class='table-normal'>
                    <thead>
                        <tr><th>Lon</th><th>Lat</th><th>Radius</th></tr>
                    </thead>
                    <tbody>
                        <tr><td>{this.state.lonlat?.[0]}</td><td>{this.state.lonlat?.[1]}</td><td>2</td></tr>
                    </tbody>
                </table>


                <label>Start At</label>
                <p>Date</p>
                <Calender
                    ref={x=>this.datePicker=x}
                    presetTimestamp={this.props.existing_task?.stay_until ?? this.props.itinerary?.[0].key_time}
                />
                <p>Time</p>
                <TimePicker
                    ref={x => this.timePicker=x}
                    presetTimestamp={this.props.existing_task?.stay_until ?? this.props.itinerary?.[0].key_time}
                />

                <label>UXV</label>
                <select value={this.state.selected_uxv ?? 'null'} onChange={ e => this.setState({selected_uxv: e.target.value})}>
                    <option hidden value='null'>Select UXV</option>
                    { this.state.uxvs?.map( x =>
                        <option key={x.id} value={x.id}>{x.id} {x.codename} ({x.uxv_type})</option>
                    )}
                </select>
                { this.props.existing_task &&
                    <button class='button-large' onClick={_ => this.deleteInsertionTask(this.props.existing_task.id)}>Delete Task</button>
                }
                { this.props.existing_task ?
                    <button class='button-large'
                        onClick={ e => {
                            this.deleteInsertionTask(this.props.existing_task.id)
                            this.createInerstionTask(e)
                        }}>Overwrite Task</button>
                    :
                    <button class='button-large' onClick={this.createInerstionTask}>Create Task</button>
                }
            </div>}

            

            { this.state.task_type == 'capture_photo' && <div>
                <label>Checkpoint</label>
                <select
                    value={this.state.selected_enroute_task_id ?? 'null'}
                    onChange={ e => {
                        let task_id = e.target.value
                        this.setState({selected_enroute_task_id: task_id})
                        this.props.map.selectTask(task_id)
                    }}
                >
                    <option disable='true' hidden value={null}>Select Task</option>
                    {this.props.itinerary?.filter(x => x.task_type == 'enroute').map(x => (
                        <option key={x.id} value={x.id}>{x.row_id} {x.uxv?.codename}</option>
                    ))}
                </select>
                <MissionEditor.Viewer
                    map={this.props.map}
                    mapSize={'medium'}
                    ref={x=> this.missionEditor = x}
                    currPage='add_capture_photo_task'
                    onTaskSelected={task_id => {
                        console.log('task_id:',task_id)
                        let task = this.props.map.source_task.getFeatureById(task_id)
                        console.log('task:',task)
                        this.datePicker.setFromUtcStr(task.get('stay_until'))
                        this.timePicker.setFromUtcStr(task.get('stay_until'))
                        this.setState({selected_task_id: task_id, selected_uxv: task.get('uxv_id')})}
                    }
                    onClick= { x => {
                        this.setState({selected_lon:x[0], selected_lat:x[1]})
                        fetch(PUBLIC_PATH_ROOT + `api/search_timezone?lon=${x[0]}&lat=${x[1]}`,{
                            method: 'GET'
                        })
                        .then(res => res.json())
                        .then( rs => {
                            if (!rs.success) throw 'timezone not found'
                            this.timePicker.setState({tz:rs.tz})
                        })
                        .catch( err => console.log('err:',err))

                        fetch(PUBLIC_PATH_ROOT + `api/elevation_query_alt?x_4326=${x[0]}&y_4326=${x[1]}`,{
                            method: 'GET'
                        })
                        .then(res => res.json())
                        .then( rs => {
                            if (!rs.success) throw rs.reason
                            this.setState({selected_alt: rs.alt_msl_m})
                        })
                        .catch( err => console.log('err:',err))
                    }}
                />

                <label>POI</label><br/>
                <label>Lon</label><br/>
                <input
                    value={this.state.selected_lon ?? ''}
                    onChange={ e => {
                        if (/[+-]?([0-9]*[.])?[0-9]+/.test(e.target.value)) {
                            this.setState({selected_lon:e.target.value})
                        }
                    }}
                /><br/>
                <label>Lat</label><br/>
                <input
                    value={this.state.selected_lat ?? ''}
                    onChange={ e => {
                        if (/[+-]?([0-9]*[.])?[0-9]+/.test(e.target.value)) {
                            this.setState({selected_lat:e.target.value})
                        }
                    }}
                /><br/>
                <label>Alt</label><br/>
                <input
                    value={this.state.selected_alt ?? ''}
                    onChange={ e => {
                        if (/[+-]?([0-9]*[.])?[0-9]+/.test(e.target.value)) {
                            this.setState({selected_alt:e.target.value})
                        }
                    }}
                /><br/>

                <hr/>
                <p><label>Start At</label></p>
                <Calender
                    ref={x=>this.datePicker=x}
                    presetTimestamp={this.props.existing_task?.start_at ?? this.props.itinerary?.[0].key_time}
                />
                <TimePicker
                    ref={x => this.timePicker=x}
                    presetTimestamp={this.props.existing_task?.start_at ?? this.props.itinerary?.[0].key_time}
                />
                <hr/>

                <label>UXV</label>
                <select
                    disabled={true}
                    value={this.state.selected_uxv ?? 'null'}
                    onChange={ e => this.setState({selected_uxv: e.target.value})}>
                    <option hidden value='null'>Select UXV</option>
                    { this.state.uxvs?.map( x =>
                        <option key={x.id} value={x.id}>{x.id} {x.codename} ({x.uxv_type})</option>
                    )}
                </select>
                { this.props.existing_task &&
                    <button class='button-large' onClick={_ => this.deleteCapturePhotoTask(this.props.existing_task.id)}>Delete Task</button>
                }
                { this.props.existing_task ?
                    <button class='button-large'
                        onClick={ e => {
                            this.deleteCapturePhotoTask(this.props.existing_task.id)
                            this.createCapturePhotoTask(e)
                        }}>Overwrite Task</button>
                    :
                    <button class='button-large' onClick={this.createCapturePhotoTask}>Create Task</button>
                }
            </div>}

            <option value='capture_video_tasks'>Capture Video</option>
            <option value='set_video_poi'>Set Video POI</option>
            <option value='land_on_tracker'>Land on Tracker</option>

            <button class='button-large' onClick={this.props.routePreviousPage}>Cancel</button>
        </div>
    }

}