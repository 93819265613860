import React, { Fragment } from "react"
import RegisterTotpComponent from '../totp/totp'
import RegisterPhoneComponent from '../phone/phone'
import '../../style.css'
import './login.css'

export const decodeToken = token => new Promise( (resolve,reject) => {
    fetch(PUBLIC_PATH_ROOT + 'auth/decode', {
        method: 'POST',
        headers: {'Content-Type': 'application/jsoncharset=utf-8'},
        body: JSON.stringify({token:token})
    })
    .then(res => res.json())
    .then(data => {
        if (data.success){
            return resolve(data.decoded)
        }
        else {
            return reject(data)
        }
    })
    .catch( err => {
        console.log('loign: decode: failed:', err)
        return reject(err)
    })
})

export class LoginComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            username: null,
            password: null,
            user_type : 'user',
            state: 'idle'
        }
    }

    componentDidMount() {
        window.history.pushState(null,'login page','#login')
    }

    setType = type => {
        this.setState({user_type : type})
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value })
    }

    onSubmitPreauth(e) {
        e.preventDefault()
        let url
        let user
        switch (this.state.user_type) {
        case 'user':
            url = PUBLIC_PATH_ROOT + 'auth/preauthenticate_user'
            user = {
                username: this.state.username,
                password: this.state.password,
            }
            break
        case 'uxv':
            url = PUBLIC_PATH_ROOT + 'auth/authenticate_uxv'
            user = {
                codename: this.state.username,
                password: this.state.password,
            }
            this.setState({state:'verifying'}, _ => {
                 fetch(url, {
                    method: 'POST',
                    headers: {'Content-Type': 'application/jsoncharset=utf-8'},
                    body: JSON.stringify(user)
                })
                .then(rs => rs.json())
                .then(rs => {
                    if ( (!rs.success) && (rs.reason == 'email not verified')) {
                        alert('email not verified. press "Resend" to resend verification email.')
                        this.setState({state:'verify_email'})
                        return
                    }
                    if (!rs.success) throw rs
                    if (!rs.token) throw 'missing token'
                    let cred = rs.content
                    cred.token = rs.token
                    cred.expiresAt = rs.expiresAt
                    this.props.onLoginSuccess(cred)
                })
                .catch( err => {
                    console.log('login: err:', err)
                    alert(`login failed, reason: ${JSON.stringify(err)}`)
                    this.setState({state:'idle'})
                })
            })
            return
        case 'payload':
            url = PUBLIC_PATH_ROOT + 'auth/authenticate_payload'
            user = {
                codename: this.state.username,
                password: this.state.password,
            }
            this.setState({state:'verifying'}, _ => {
                 fetch(url, {
                    method: 'POST',
                    headers: {'Content-Type': 'application/jsoncharset=utf-8'},
                    body: JSON.stringify(user)
                })
                .then(rs => rs.json())
                .then(rs => {
                    if ( (!rs.success) && (rs.reason == 'email not verified')) {
                        alert('email not verified. press "Resend" to resend verification email.')
                        this.setState({state:'verify_email'})
                        return
                    }
                    if (!rs.success) throw rs
                    if (!rs.token) throw 'missing token'
                    let cred = rs.content
                    cred.token = rs.token
                    cred.expiresAt = rs.expiresAt
                    this.props.onLoginSuccess(cred)
                })
                .catch( err => {
                    console.log('login: err:', err)
                    alert(`login failed, reason: ${JSON.stringify(err)}`)
                    this.setState({state:'idle'})
                })
            })
            return
        default:
            throw 'unhandled user_type'
        }

        this.setState({state:'preauthenticating'})
        fetch(url, {
            method: 'POST',
            headers: {'Content-Type': 'application/jsoncharset=utf-8'},
            body: JSON.stringify(user)
        })
        .then(rs => rs.json())
        .then(rs => {
            if ( (!rs.success) && (rs.reason == 'email not verified')) {
                alert('email not verified. press "Resend" to resend verification email.')
                this.setState({state:'verify_email'})
                return
            }
            if (!rs.success) throw rs
            console.log('data:',rs)
            if (!rs.token) throw 'missing token'
            if (rs.content.has_totp_key == null) throw 'missing has_totp_key'
            if (rs.content.phone_verified == null) throw 'missing phone_verified'
            // let cred = rs.content
            // cred.token = rs.token
            // cred.expiresAt = rs.expiresAt
            // this.props.onLoginSuccess(cred)
            this.setState({state:'preauthenticated', preauth_token:rs.token, has_totp_key: rs.content.has_totp_key, phone_verified: rs.content.phone_verified})
        })
        .catch( err => {
            console.log('login: err:', err)
            alert(`login failed, reason: ${JSON.stringify(err)}`)
            this.setState({state:'idle'})
        })
    }

    onConfirmOtpType(e) {
        e.preventDefault()
        this.setState({state:'selecting_otp_type'})
        fetch(PUBLIC_PATH_ROOT + 'auth/create_otp', {
            method: 'POST',
            headers: {'Content-Type': 'application/jsoncharset=utf-8'},
            body: JSON.stringify({preauth_token:this.state.preauth_token, otp_type: this.state.otp_type})
        })
        .then(rs => rs.json())
        .then(rs => {
            if (!rs.success) throw rs
            this.setState({state:'otp_type_confirmed'})
        })
        .catch( err => {
            console.log('login: err:', err)
            alert(`login failed, reason: ${JSON.stringify(err)}`)
            this.setState({state:'idle'})
        })
    }

    verifyOtp = _ => {
        let url
        switch (this.state.user_type) {
            case 'user':
                url = PUBLIC_PATH_ROOT + 'auth/authenticate_user'
                break
            case 'uxv':
                url = PUBLIC_PATH_ROOT + 'auth/authenticate_uxv'
                break
            case 'payload':
                url = PUBLIC_PATH_ROOT + 'auth/authenticate_payload'
                break
            default:
                throw 'unhandled user_type'
        }
        this.setState({state:'verifying'}, _ => {
            fetch(url, {
                method: 'POST',
                headers: {'Content-Type': 'application/jsoncharset=utf-8'},
                body: JSON.stringify({
                    preauth_token: this.state.preauth_token,
                    otp_type: this.state.otp_type,
                    otp_code: this.state.otp_code
                })
            })
            .then(rs => rs.json())
            .then(rs => {
                if ( (!rs.success) && (rs.reason == 'email not verified')) {
                    alert('email not verified. press "Resend" to resend verification email.')
                    this.setState({state:'verify_email'})
                    return
                }
                if (!rs.success) throw rs
                if (!rs.token) throw 'missing token'
                let cred = rs.content
                cred.token = rs.token
                cred.expiresAt = rs.expiresAt
                this.props.onLoginSuccess(cred)
            })
            .catch( err => {
                console.log('login: err:', err)
                alert(`login failed, reason: ${JSON.stringify(err)}`)
                this.setState({state:'idle'})
            })
        })
    }

    send_verification_email = _ => {
        this.setState({state:'requesting'}, _ => {
            fetch(PUBLIC_PATH_ROOT + 'auth/request_verification_email', {
                method: 'POST',
                headers: {'Content-Type': 'application/jsoncharset=utf-8'},
                body: JSON.stringify({username:this.state.username, user_type: this.state.user_type})
            })
            .then(res => res.json())
            .then(data => {
                if (data.success){
                    alert('Verification email sent.')
                    this.setState({state:'idle'})
                    return
                }
                throw data.reason
            })
            .catch( err => {
                console.log('send verification email failed:', err)
                this.setState({state:'idle'})
            })
        })
    }


    render() {
        if (this.state.state == 'loading') return
        return (
            <div id='loginContainer'>
                { ['idle','logging-in'].includes(this.state.state) && <div id='loginFormContainer'>
                    <div id='titleContainer'>
                        <h2><b>Login</b></h2>
                        <p>
                            Do not have an account?
                            <a onClick={() => this.props.routeRegisterPage()} id='registerButton'> Register</a>
                        </p>
                        <p>
                            Forgot password?
                            <a onClick={() => this.props.routeResetPasswordPage()} id='resetPasswordButton'> Reset</a>
                        </p>
                        <p>
                            Email not verified?
                            <a onClick={() => this.props.routeVerifyEmailPage()} id='verifyEmailButton'> Verify</a>
                        </p>
                    </div>

                    <label>Login Type</label>

                    <div className='radioContainer'>
                        <div className='radioItem'>
                            <div>
                                <input checked={this.state.user_type=='user'} type='radio' id='user' name='user_type' value='user'
                                    onChange={e => this.setType(e.target.value)}
                                />
                                <label>User</label>
                            </div>
                        </div>
                        <div className='radioSeparater'/>
                        <div className='radioItem'>
                            <div>
                                <input checked={this.state.user_type=='uxv'} type='radio' id='uxv' name='user_type' value='uxv'
                                    onChange={e => this.setType(e.target.value)}
                                />
                                <label>UXV</label>
                            </div>
                        </div>
                        <div className='radioSeparater'/>
                        <div className='radioItem'>
                            <div>
                                <input checked={this.state.user_type=='payload'} type='radio' id='payload' name='user_type' value='payload'
                                    onChange={e => this.setType(e.target.value)}
                                />
                                <label>Payload</label>
                            </div>
                        </div>
                    </div>

                    <form noValidate onSubmit={e => this.onSubmitPreauth(e)}>
                        <div className="input-field">
                            <label htmlFor="username">{this.state.user_type == 'user' ? 'Username':'Codename'}</label>
                            <input
                                class='input-fullwidth'
                                onChange={this.onChange}
                                value={this.state.username ?? ''}
                                id="username"
                                type="text"
                            />
                        </div>
                        <div className="input-field">
                            <label htmlFor="password">Password</label>
                            <input
                                class='input-fullwidth'
                                onChange={this.onChange}
                                value={this.state.password ?? ''}
                                id="password"
                                type="password"
                            />
                        </div>
                        
                        <button id='loginButton'
                            disabled={!this.state.username || !this.state.password || 'logging-in' == this.state.state}
                            type="submit"
                            className='button-large'
                        >
                            {this.state.state == 'logging-in' ? 'Logging In...' : 'Log In'}
                        </button>

                    </form>

                </div>}

                { this.state.state == 'registerTotp' &&
                    <RegisterTotpComponent
                        preauth_token={ this.state.preauth_token }
                        routeLoginPage={ _ => this.setState({state: 'idle'})}
                    />
                }

                { this.state.state == 'registerPhone' &&
                    <RegisterPhoneComponent
                        preauth_token={ this.state.preauth_token }
                        routeLoginPage={ _ => this.setState({state: 'idle'})}
                    />
                }

                {['preauthenticated'].includes(this.state.state) && <div class='formContainer'>
                    <div id='titleContainer'>
                        <h2><b>One Time Password</b></h2>

                        <p>
                            Register Phone?
                            <a onClick={() => this.setState({state:'registerPhone'})} id='verifyPhoneButton'> Register </a>
                        </p>

                        <p>
                            Register TOTP?
                            <a onClick={() => this.setState({state:'registerTotp'})} id='registerTotpButton'> Register</a>
                        </p>
                        
                    </div>

                    <p>Please select how you would like to receive your One Time Password.</p>
                    <label>OTP Type</label>
                    <div className='radioContainer'>
                        <div className='radioItem'>
                            <div>
                                <input
                                    checked={this.state.otp_type=='email'} type='radio' id='user' name='otp_type' value='email'
                                    onChange={e => this.setState({otp_type:e.target.value})}
                                />
                                <label>Email OTP</label>
                            </div>
                        </div>
                        <div className='radioSeparater'/>
                        <div className='radioItem'>
                            <div>
                                <input
                                    disabled={!this.state.phone_verified}
                                    checked={this.state.otp_type=='sms'} type='radio' id='uxv' name='otp_type' value='sms'
                                    onChange={e => this.setState({otp_type:e.target.value})}
                                />
                                <label>SMS OTP</label>
                            </div>
                        </div>
                        <div className='radioSeparater'/>
                        <div className='radioItem'>
                            <div>
                                <input
                                    disabled={!this.state.has_totp_key}
                                    checked={this.state.otp_type=='app'} type='radio' id='payload' name='otp_type' value='app'
                                    onChange={e => this.setState({otp_type:e.target.value})}
                                />
                                <label>APP OTP</label>
                            </div>
                        </div>
                    </div>

                    { this.state.otp_type == 'app' && <Fragment>
                        <fieldset disabled={this.state.state == 'verifying_otp'}>
                        <label for='otp_code'>OTP Code</label>
                        <input
                            required
                            class='input-fullwidth'
                            value={this.state.otp_code ?? ''}
                            onChange={this.onChange}
                            id='otp_code'
                            placeholder='000000'
                            name='otp_code'
                            type='text'
                        />
                        </fieldset>
                    </Fragment>}
                    <div class='buttonContainer'>
                        { ['email','sms'].includes(this.state.otp_type) && <button
                                className='button-large fullwidth'
                                disabled={!this.state.otp_type}
                                onClick={e => this.onConfirmOtpType(e)}
                            >
                                Confirm
                        </button>}
                        { this.state.otp_type == 'app' && <button
                            className='button-large fullWidthButton'
                            onClick={ this.verifyOtp }
                            disabled={!this.state.otp_code}
                        >
                            {this.state.state == 'verifying' ? 'Verifying' : 'Verify'}
                        </button>}
                        <button
                                className='button-large fullwidth'
                                onClick={_ => this.setState({otp_type:null, state:'idle'})}
                            >
                                Cancel
                        </button>
                    </div>
                </div>}

                { this.state.state == 'otp_type_confirmed' && <div class='formContainer'><form onSubmit={this.verifyOtp}>
                    <div id='titleContainer'>
                        <h2><b>Enter One Time Password</b></h2>
                    </div>
                    { this.state.otp_type == 'email' && <p>We have sent the OTP Code to your Email Account. Please enter the OTP below:</p> }
                    { this.state.otp_type == 'sms' && <p>We have sent the OTP Code to your registered phone number. Please enter the OTP below:</p> }
                    <fieldset disabled={this.state.state == 'verifying_otp'}>
                        <label for='otp_code'>OTP Code</label>
                        <input
                            required
                            class='input-fullwidth'
                            value={this.state.otp_code ?? ''}
                            onChange={this.onChange}
                            id='otp_code'
                            placeholder='000000'
                            name='otp_code'
                            type='text'
                        />

                        <button
                            id='submitButton'
                            className='button-large fullWidthButton'
                            type='submit'
                            disabled={!this.state.otp_code}
                        >
                                {this.state.state == 'verifying' ? 'Verifying' : 'Verify'}
                        </button>
                    </fieldset>
                </form></div> }

                { ['verify_email', 'requesting'].includes(this.state.state) &&
                    <div id='resendContainer'>
                        <button
                            id='resendButton'
                            className='button-large'
                            disabled={this.state.state == 'requesting'}
                            onClick={this.send_verification_email}
                        >
                            {this.state.state == 'requesting' ? 'Requesting' : 'Send Verification Email'}
                        </button>
                        <button
                            id='cancelButton'
                            className='button-large'
                            onClick={_ => this.setState({state:'idle'})}
                        >
                            Cancel
                        </button>
                    </div>
                }
            </div>
        )
    }
}
