// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#sora > label {
    font-size: larger;
}

#paginator {
    display: flex;
    justify-content: space-between;
    overflow: scroll;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/sora/sora.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":["#sora > label {\n    font-size: larger;\n}\n\n#paginator {\n    display: flex;\n    justify-content: space-between;\n    overflow: scroll;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
