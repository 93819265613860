import React from 'react'

import '../../style.css'
import './newMissionPage.css'

const redCircle = <svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="red" /></svg>
const goldCircle = <svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="gold" /></svg>
const greenCircle = <svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="green" /></svg>

export class LeftPanel extends React.Component {
    constructor(props) {
        super(props)
    }

    render = _ =>
        <div>
            <h2>New Mission Page</h2>
            { this.props.currPage == 'loading' &&
                <p>Loading...</p>
            }
            { this.props.currPage == 'select_use_case' &&
                <p class='selected'>Select Use Case</p>
            }
            { ['loading', 'select_use_case'].includes(this.props.currPage) ||
                [
                    <p key={1} class={this.props.currPage == 'general' ? 'selected' : ''}
                        onClick={ _ => this.props.setCurrPage('general')}
                    >General { this.props.general_ready ? greenCircle : goldCircle }</p>,
                    <p key={2} class={this.props.currPage == 'geofence' ? 'selected' : ''}
                        onClick={ _ => this.props.setCurrPage('geofence')}
                    >Geofence { this.props.geofence_ready ? greenCircle : goldCircle }​</p>,
                    <p key={3} class={this.props.currPage == 'nfz' ? 'selected' : ''}
                        onClick={ _ => this.props.setCurrPage('nfz')}
                    >No-fly-zone { this.props.nfz_ready ? greenCircle : goldCircle }​</p>,
                    <p key={4} class={this.props.currPage == 'poi' ? 'selected' : ''}
                        onClick={ _ => this.props.setCurrPage('poi')}
                    >Point-of-interest { this.props.poi_ready ? greenCircle : goldCircle }​</p>,
                    <p key={5} class={this.props.currPage == 'itinerary' ? 'selected' : ''}
                        onClick={ _ => this.props.setCurrPage('itinerary')}
                    >Itinerary { this.props.itinerary_ready ? greenCircle : goldCircle }​</p>,
                    <p key={6} class={this.props.currPage == 'sora' ? 'selected' : ''}
                        onClick={ _ => this.props.setCurrPage('sora')}
                    >SORA { this.props.geofence_sora ? greenCircle : goldCircle }​</p>,
                    <p key={7} class={this.props.currPage == 'review' ? 'selected' : ''}
                        onClick={ _ => this.props.setCurrPage('review')}
                    >Review and Submit</p>
                ]
            }
            <p onClick={ _ => this.props.routePreviousPage()}>Cancel</p>
        </div>
}

export class RightPanel extends React.Component {

    updateCurrPage = _ => {
        this.props.setCurrPage(this.state.currPage)
    }
    routePage(page) {
        this.setState({currPage: page}, this.updateCurrPage)
    }

    constructor(props) {
        super(props)
        this.state = {
            currPage: 'loading',
            use_cases: null,
            locationQueryTimerId: null,
            foundLocations: [],
            locationQuery: null,
            mission: null
        }
        this.updateCurrPage()
        window.onpopstate = _ => this.props.routePreviousPage()

        console.log('newMissionPage: fetching use_cases...')
        fetch(PUBLIC_PATH_ROOT + 'api/use_cases', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({ token: this.props.credentials.token })
        }).then(res => res.json())
        .then(res => {
            if (res.success) {
                let use_cases = res.use_cases
                console.log('newMissiongPage: use_cases:',use_cases)
                this.setState({
                    currPage: 'select_use_case',
                    use_cases: use_cases
                }, this.updateCurrPage)
                return
            }
            console.log('account: get users failed, reason:', res.reason)
        })
        .catch(err => {
            console.log('account: get users: error:',err)
        })
    }

    componentDidMount(){
        window.history.pushState(null, 'new mission', '/newMission')
    }

    createMission = e => {
        e.preventDefault()
        console.log('creating mission with selected use case...')
        fetch(PUBLIC_PATH_ROOT + 'api/create_mission', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({
                token: this.props.credentials.token,
                use_case_id: e.target.use_case_id.value,
                name: e.target.name.value,
                description: e.target.description.value
            })
        }).then(res => res.json())
        .then(res => {
            if (res.success) {
                let mission = res.mission
                console.log('newMissiongPage: mission created:',mission)
                this.props.routePreviousPage()
                return
            }
            console.log('newMissiongPage: mission create failed, reason:', res.reason)
        })
        .catch(err => {
            console.log('newMissiongPage: mission create error:',err)
        })
    }

    handleLocationQueryChange(loc) {
        // remove timer
        if (this.state.locationQueryTimerId) clearTimeout(this.state.locationQueryTimerId)
        // query locations after timeout
        this.setState({
            locationQuery: loc,
            locationQueryTimerId: setTimeout( _ => {
                fetch(PUBLIC_PATH_ROOT + 'api/query_locations?querystring=' + loc, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json;charset=utf-8' }
                })
                .then(res => res.json())
                .then(res => {
                    if (res.success) {
                        let locations = res.locations
                        console.log('newMissiongPage: found locations:',res.locations)
                        this.setState({
                            foundLocations: locations,
                            locationQueryTimerId: null
                        }, _ => {
                            if (this.locationSelector) this.locationSelector.click()
                        })
                        return
                    }
                    console.log('newMissiongPage: found locations failed, reason:', res.reason)
                })
                .catch(err => {
                    console.log('newMissiongPage: found locations: error:',err)
                })
            }, 200)
        })
    }

    handleLocationClicked(x) {
        let lat = x.centroid.coordinates[1]
        let lon = x.centroid.coordinates[0]
        let newLocationString = `${x.name}, ${x.country}`
        console.log('handleLocationClicked', newLocationString)
        this.setState({locationQuery: newLocationString})
        this.locationInput.value = newLocationString
        this.props.map.zoomTo( x.centroid, _ => {
            console.log('flyTo done.')
        })
    }

    handleTaskSet(tasks) {
        this.setState(s => {
            s.mission.itinarary = tasks
            return s
        })
    }

    backToDashboard(){
        window.history.back()
    }

    getMapSize(currPage) {
        switch(currPage) {
        case 'general': case 'sora': case 'submit': return 'small'
        case 'itinerary': return 'medium'
        default: return 'medium'
        }
    }


    render() {
        return ( <div id='newMissionContainer'>

            { this.state.currPage == 'loading' && <h2>Loading...</h2> }
            { this.state.currPage == 'select_use_case' && <h2>Create New Mission</h2> }
            { this.state.currPage == 'general' && <h2>General Infomation</h2> }
            { this.state.currPage == 'geofence' && <h2>Edit Geofence</h2> }
            { this.state.currPage == 'nfz' && <h2>Edit No-fly-zone</h2> }
            { this.state.currPage == 'poi' && <h2>Edit Point-of-interest</h2> }
            { this.state.currPage == 'itinerary' && <h2>Edit Itinerary</h2> }
            { this.state.currPage == 'sora' && <h2>Risk Assessment</h2> }
            { this.state.currPage == 'submit' && <h2>Summary of Mission</h2> }

            {this.state.currPage == 'select_use_case' && <div>
                <form onSubmit={this.createMission}>
                    <label>Select Use-case</label>
                    <select
                        name='use_case_id'
                        disabled={!this.state.use_cases}
                        value={ this.state.selected_use_case}
                        onSelect={ e => this.setState({selected_use_case: e.target.value})}
                    > { this.state.use_cases ?
                        this.state.use_cases.map( x =>
                            <option key={x.id} value={x.id}>{x.description}</option>
                        )
                    :
                        <option value='null'>No available Use Cases. Please ask the administrator to activate your account.</option>
                    }</select>

                    <label>Name</label>
                    <input
                        class='input-fullwidth'
                        required
                        name='name'
                        placeholder='name of mission'
                    />
                    <label>Description</label>
                    <textarea
                        required
                        name='description'
                        placeholder='description of mission'
                    />
                    <button
                        type='submit'
                        class='button-large'
                    >Create Mission</button>
                </form>
            </div>}


            { this.state.foundLocations.length > 0 &&
                <table id='location-selector' class='table-dropdown' ref={x=>this.locationSelector = x}><tbody>
                    {this.state.foundLocations.map( (x,i) =>
                    <tr key={i} onClick={ _ => this.handleLocationClicked(x)}>
                        <td>{`${x.name}, ${x.country} ( lat: ${x.centroid.coordinates[1]},lon: ${x.centroid.coordinates[0]})`}</td>
                    </tr>)}
                </tbody></table>
            }

        </div>)
    }
}