import * as olLayer from 'ol/layer'
import * as olProj from 'ol/proj'
import olMap from 'ol/Map'
import * as olGeomPolygon from 'ol/geom/Polygon'
import {fromLonLat} from 'ol/proj'
import * as olCondition from 'ol/events/condition'
import * as ol from 'ol'
import * as olGeom from 'ol/geom'

import View from 'ol/View'

import TileLayer from 'ol/layer/Tile'
import * as olInteraction from 'ol/interaction'
import * as olSource from 'ol/source'

import GeoJSON from 'ol/format/GeoJSON'

import XYZ from 'ol/source/XYZ'
import 'ol/ol.css'
import '../../style.css'
import * as Styles from './styles'
import './map.css'
import Style from 'ol/style/Style'


export class Map {

    constructor(props) {

        this.props = props ?? {}

        // sources
        this.source_geofence = new olSource.Vector()
        this.source_nfz = new olSource.Vector()
        this.source_poi = new olSource.Vector()
        this.source_poi.on('addfeature', _ => {
            this.props.onPoiAdd?.()
        })
        this.source_osm_poi = new olSource.Vector()
        this.source_route = new olSource.Vector()
        this.source_task = new olSource.Vector()
        this.source_task.on('addfeature', evt => {
            this.props.onTaskAdd?.()
        })
        this.source_base = new olSource.Vector()
        this.source_temp = new olSource.Vector()
        this.source_ais = new olSource.Vector()
        this.source_adsb = new olSource.Vector()
        this.source_eez = new olSource.Vector()
        this.source_beacon = new olSource.Vector()
        this.source_uxv_track = new olSource.Vector()
        this.source_track_beacon = new olSource.Vector()
        this.source_traffic_track = new olSource.Vector()
        this.source_traffic_track_beacon = new olSource.Vector()
	    this.source_user = new olSource.Vector()
        this.source_marker = new olSource.Vector()
        // layers
        switch (this.props.rasterStyle ?? 'osm') {
            case 'arcgis':
                this.raster = new TileLayer({
                    className: 'bw',
                    source: new XYZ({
                        url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                    })
                })
                break
            case 'tile':
                this.raster = new TileLayer({
                    className: 'bw',
                    source: new XYZ({
                        url: PUBLIC_PATH_ROOT + 'tile/osm/{z}/{x}/{y}.png',
                    })
                })
                break
            case 'osm':
            default:
                this.raster = new TileLayer({
                    className: 'bw',
                    // source: new olSource.OSM()
                    source: new XYZ({
                        url: PUBLIC_PATH_ROOT + 'tile/osm/{z}/{x}/{y}.png',
                    })
                })
        }
        // project tile rasters
        this.orthophoto_raster = new TileLayer({
            source: new XYZ({
                url: PUBLIC_PATH_ROOT + 'tiles/harburg_lock_tiles/{z}/{x}/{y}.png',
            })
        })
        this.elevation_raster = new TileLayer({
            source: new XYZ({
                url: PUBLIC_PATH_ROOT + 'tiles/elevation_tiles/{z}/{x}/{y}.png',
            })
        })
        

        this.layer_geofence = new olLayer.Vector({
            source: this.source_geofence,
            style: _ => Styles.styles.Geofence,
            visible: true,
            zIndex: 0
        })
        this.layer_openseamap = new TileLayer({
            source: new XYZ({
                url: 'https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png'
            }),
            zIndex: 0
        })
        this.layer_base = new olLayer.Vector({
            id: 'layer_base',
            source: this.source_base,
            style: feature => Styles.styles.Base(feature),
            visible: true,
            zIndex: 0
        })
        this.layer_nfz = new olLayer.Vector({
            source: this.source_nfz,
            style: feature => Styles.styles[feature.getGeometry().getType()],
            visible: true,
            zIndex: 0
        })
        this.layer_route = new olLayer.Vector({
            source: this.source_route,
            style: feature => Styles.styles.Route(feature),
            visible: true,
            zIndex: 0
        })
        this.layer_task = new olLayer.Vector({
            source: this.source_task,
            style: feature => Styles.styles.waypoint(feature),
            visible: true,
            zIndex: 0
        })
        this.layer_poi = new olLayer.Vector({
            source: this.source_poi,
            style: (feature,resolution) => Styles.styles.OsmPoi(feature,resolution),
            visible: true,
            zIndex: 0
        })
        this.layer_osm_poi = new olLayer.Vector({
            source: this.source_osm_poi,
            style: (feature,resolution) => Styles.styles.OsmPoi(feature,resolution),
            visible: true,
            zIndex: 0
        })
        this.layer_temp = new olLayer.Vector({
            source: this.source_temp,
            visible: false,
            zIndex: 0
        })
        this.layer_ais = new olLayer.WebGLPoints({
            source: this.source_ais,
            style: Styles.symbols.ship,
            zIndex: 0
        })
        this.layer_adsb = new olLayer.WebGLPoints({
            source: this.source_adsb,
            style: Styles.symbols.airliner,
            zIndex: 0
        })
        this.layer_beacon = new olLayer.Vector({
            source: this.source_beacon,
            style: feature => Styles.styles.beacon(feature),
            visible: true,
            zIndex: 0
        })
	    this.layer_user = new olLayer.Vector({
            source: this.source_user,
            style: feature => Styles.styles.User(feature),
            visible: true,
            zIndex: 0
        })
        this.layer_marker = new olLayer.Vector({
            source: this.source_marker,
            style: feature => Styles.styles.User(feature),
            visible: true,
            zIndex: 0
        })
        this.layer_uxv_track = new olLayer.Vector({
            source: this.source_uxv_track,
            style: feature => Styles.styles.uxv_path(feature),
            visible: true,
            zIndex: 0
        })
        this.layer_track_beacon = new olLayer.Vector({
            source: this.source_track_beacon,
            style: feature => Styles.styles.track_beacon(feature),
            visible: true,
            zIndex: 0
        })
        this.layer_traffic_track = new olLayer.Vector({
            source: this.source_traffic_track,
            style: feature => Styles.styles.traffic_path(feature),
            visible: true,
            zIndex: 0
        })
        this.layer_traffic_track_beacon = new olLayer.Vector({
            source: this.source_traffic_track_beacon,
            // style: feature => Styles.styles.traffic_track_beacon(feature),
            style: Styles.webgl_styles.traffic_track_beacon,
            visible: true,
            zIndex: 0
        })

        // map object
        this.map = new olMap({
            moveTolerance: 3,
            // renderer:'webgl',
            interactions: olInteraction.defaults({ dragPan: false, mouseWheelZoom: false, pinchRotate: false, pinchZoom: false, onFocusOnly: true }),
            layers: [
                this.raster,
                this.orthophoto_raster,
                this.elevation_raster,
                this.layer_openseamap,
                this.layer_base,
                this.layer_geofence,
                this.layer_nfz,
                this.layer_route,
                this.layer_task,
                this.layer_osm_poi,
                this.layer_poi,
                this.layer_temp,
                this.layer_ais,
                this.layer_adsb,
                this.layer_beacon,
		        this.layer_user,
                this.layer_marker,
                this.layer_uxv_track,
                this.layer_track_beacon,
                this.layer_traffic_track,
                this.layer_traffic_track_beacon
            ],
            view: new View({
                projection: 'EPSG:3857', // 2D tile is rendered in 3857
                center: olProj.transform([9.9937, 53.5511], 'EPSG:4326', 'EPSG:3857'), //Hamburg coordinated lon/lat
                zoom: 10
            }),
            controls: [],
            moveTolerance: 5
        })

        // interactions

        this.poiDrawer = new olInteraction.Draw({
            source: this.source_poi,
            style: feature => Styles.styles.Poi_edited(feature),
            type: 'Point',
            stopClick: true
        })
        this.poiDrawer.setActive(false)

        this.taskDrawer = new olInteraction.Draw({
            source: this.source_task,
            style: feature => Styles.styles.Task_edited(feature),
            type: 'Point',
            stopClick: true
        })
        this.taskDrawer.setActive(false)

        this.lineDrawer = new olInteraction.Draw({
            source: this.source_route,
            type: 'LineString'
        })
        this.lineDrawer.setActive(false)

        this.geofenceDrawer = new olInteraction.Draw({
            source: this.source_geofence,
            style: Styles.styles.Geofence_edited,
            type: 'Polygon'
        })
        this.geofenceDrawer.on('drawend', evt => {
            evt.feature.setId('temp_geofence')
            evt.feature.setStyle(Styles.styles.Geofence_temp)
            this.props.onGeofenceDrawEnd(evt.feature)
        })
        this.geofenceDrawer.setActive(false)

        this.locationMarker = new olInteraction.Draw({
            source: this.source_marker,
            type:'Point',
            maxPoints: 1
        })
        this.locationMarker.on('drawstart', evt => {
            this.source_marker.clear()
        })
        this.locationMarker.on('drawend', evt => {
            evt.feature.setId('temp_marker')
            this.props.onMarkerDrawEnd?.(evt.feature)
        })
        this.locationMarker.setActive(false)

        this.tooltipHoverSelector = new olInteraction.Select({
            layers:[this.layer_beacon,this.layer_ais,this.layer_adsb],
            style: null,
            condition: olCondition.pointerMove
        })
        this.tooltipHoverSelector.setActive(false)
        this.tooltipHoverSelector.on('select', this.handlePointerMove)
        this.beaconSelector = new olInteraction.Select({
            layers: [this.layer_beacon],
            style: feature => Styles.styles.beacon_focused(feature),
            condition: evt => olCondition.singleClick(evt)
        })
        this.beaconSelector.setActive(false)
        this.beaconSelector.on('select', evt => {
            // console.log('beacon select:',evt.selected)
            if (evt.selected.length) {
                this.props.onBeaconSelected?.(evt.selected[0].getProperties())
            } else
                this.props.onBeaconSelected?.()
        })
        this.osmPoiSelector = new olInteraction.Select({
            layers: [this.layer_osm_poi],
            style: feature => Styles.styles.Osm_poi_focused(feature),
            condition: evt => olCondition.singleClick(evt) && !olCondition.shiftKeyOnly(evt)
        })
        this.osmPoiSelector.setActive(false)
        this.osmPoiSelector.on('select', evt => {
            if (evt.selected.length) {
                this.props.onOsmPoiSelected(evt.selected[0].getProperties())
                this.eraseAllTemp()
            } else
                this.props.onOsmPoiDeselected()
        })

        this.taskSelector = new olInteraction.Select({
            layers: [this.layer_task],
            maxPoints: 1,
            style: feature => Styles.styles.waypoint_focused(feature),
            condition: evt => olCondition.singleClick(evt) && !olCondition.shiftKeyOnly(evt)
        })
        this.taskSelector.setActive(false)
        this.taskSelector.on('select', evt => {
            console.log('select:',evt)
            if (evt.selected.length)
                this.props.onTaskSelected?.(evt.selected[0].get('id'),evt.selected[0].get('uxv_id'))
            else
                this.props.onTaskDeselected?.()
        })

        this.trackSelector = new olInteraction.Select({
            layers: [this.layer_uxv_track],
            maxPoints: 1,
            style: feature => Styles.styles.uxv_path_focused(feature),
            condition: evt => olCondition.singleClick(evt) && !olCondition.shiftKeyOnly(evt)
        })
        this.trackSelector.setActive(false)
        this.trackSelector.on('select', evt => {
            console.log('select:',evt)
            if (evt.selected.length)
                this.props.onTrackSelected?.(evt.selected[0].get('id'),evt.selected[0].get('uxv_id'))
            else
                this.props.onTrackDeselected?.()
        })

        this.trackBeaconSelector = new olInteraction.Select({
            layers: [this.layer_track_beacon],
            // maxPoints: 1,
            style: feature => Styles.styles.track_beacon_focused(feature),
            condition: evt => olCondition.singleClick(evt) && !olCondition.shiftKeyOnly(evt)
        })
        this.trackBeaconSelector.setActive(false)
        this.trackBeaconSelector.on('select', evt => {
            console.log('select:',evt)
            if (evt.selected.length)
                this.props.onTrackBeaconSelected?.(evt.selected[0].get('id'),evt.selected[0].get('uxv_id'))
            else
                this.props.onTrackBeaconDeselected?.()
        })

        this.trafficTrackBeaconSelector = new olInteraction.Select({
            layers: [this.layer_traffic_track_beacon],
            // maxPoints: 1,
            style: feature => Styles.styles.traffic_track_beacon_focused(feature),
            condition: evt => olCondition.singleClick(evt) && !olCondition.shiftKeyOnly(evt)
        })
        this.trafficTrackBeaconSelector.setActive(false)
        this.trafficTrackBeaconSelector.on('select', evt => {
            console.log('select:',evt)
            if (evt.selected.length)
                this.props.onTrafficTrackBeaconSelected?.(evt.selected[0].get('id'),evt.selected[0].get('ident'))
            else
                this.props.onTrafficTrackBeaconDeselected?.()
        })

        this.routeSelector = new olInteraction.Select({
            layers: [this.layer_route],
            maxPoints: 1,
            style: feature => Styles.styles.route_focused(feature),
            condition: evt => olCondition.singleClick(evt) && !olCondition.shiftKeyOnly(evt)
        })
        this.routeSelector.setActive(false)
        this.routeSelector.on('select', evt => {
            console.log('select:',evt)
            if (evt.selected.length)
                this.props.onRouteSelected?.(evt.selected[0].get('id'))
            else
                this.props.onRouteDeselected?.()
        })

        this.baseitemSelector = new olInteraction.Select({
            layers: [this.layer_base],
            style: feature => Styles.styles.Baseitem_focused(feature),
            condition: evt => olCondition.singleClick(evt) && !olCondition.shiftKeyOnly(evt)
        })
        this.baseitemSelector.setActive(false)
        this.baseitemSelector.on('select', evt => {
            console.log('select:',evt)
            if (evt.selected.length)
                this.props.onBaseitemSelected?.(evt.selected[0].getId())
            else
                this.props.onBaseitemDeselected?.()
        })

        // this.selected_geofences = []
        this.geofenceSelector = new olInteraction.Select({
            layers: [this.layer_geofence],
            style: Styles.styles.Geofence_focused,
            features: this.selected_geofences,
            // removeCondition: evt => {
            //     console.log('this.selected_geofences:',evt)
            //     console.log('this.selected_geofences:',evt,this.selected_geofences,evt.selected[0])
            //     return olCondition.singleClick(evt) && this.selected_geofences?.includes(evt.selected[0])
            // },
            condition: evt => olCondition.singleClick(evt) && !olCondition.shiftKeyOnly(evt),
            // filter: (feature) => {
            //     console.log('this.selected_geofences:', this.selected_geofences)
            //     return !this.selected_geofences?.includes(feature) ?? true
            // }
        })
        this.geofenceSelector.setActive(false)
        this.geofenceSelector.on('select', evt => {
            console.log('evt:', evt)
            if (evt.deselected.length > 0) {
                // console.log('geofence deselected.')
                this.props.onGeofenceDeselected?.()
                // this.geofenceSelector.getFeatures().clear()
                // this.lastGeofenceSelected = null
                // return
            }
            if (evt.selected.length == 0 && evt.deselected.length == 0) {
                // console.log('same item clicked')
                let lastGeofenceSelected = this.geofenceSelector.getFeatures().item(0)
                let pixel = evt.pixel
                let overlap_features = this.map.getFeaturesAtPixel(pixel)
                if (overlap_features.length > 1) {
                    overlap_features.sort( (a,b) => parseInt(a.getId()) - parseInt(b.getId()))
                    // console.log('overlap geofence ids:',overlap_features.map(x => x.getId()))
                    let adjusted_idx = (overlap_features.indexOf(lastGeofenceSelected) + 1) % overlap_features.length
                    let adjusted_geofence_id = overlap_features[adjusted_idx].getId()
                    // console.log('adjusted_geofence_id:',adjusted_idx,adjusted_geofence_id)
                    // this.lastGeofenceSelected = overlap_features[adjusted_idx]
                    this.props.onGeofenceDeselected?.()
                    this.selectGeofence(adjusted_geofence_id)
                    // this.geofenceSelector.dispatchEvent({type:'select',selected:[overlap_features[adjusted_idx]],deselected:[lastGeofenceSelected]})
                }
                return
            }
            // console.log('evt:',evt)
            // console.log('only one selected:', evt.selected[0].getId())
            if (evt.selected.length){ 
                this.props.onGeofenceSelected?.(evt.selected[0].getId())
            }
            // this.lastGeofenceSelected = evt.selected[0]

            // console.log('this.map.getFeaturesAtPixel(pixel):',this.map.getFeaturesAtPixel(pixel))
        })
        this.map.on('movestart', e => {
            this.props.hideTooltip?.()
        })
        this.map.on('moveend', e => {
            let extent = e.map.getView().calculateExtent(e.map.getSize())
            this.props.onMoveEnd?.({extent:extent})
        })
        this.map.on('click', e => {
            let pixel = this.map.getEventPixel(e.originalEvent)
            if (!this.map.hasFeatureAtPixel(pixel)) {
                this.props.onClick?.(olProj.toLonLat(e.coordinate))
            }
            // if (this.geofenceSelector.getFeatures())
            if (this.geofenceSelector.getActive()) {
                // this.geofenceSelector.dispatchEvent({type:'select',selected:[],deselected:this.geofenceSelector.getFeatures()})
                console.log('this.geofenceSelector.getFeatures()',this.geofenceSelector.getFeatures())
                if (this.geofenceSelector.getFeatures().getArray().length) {
                    if (this.map.getFeaturesAtPixel(pixel).includes(this.geofenceSelector.getFeatures().item(0))) {
                        this.geofenceSelector.dispatchEvent({type:'select',selected:[],deselected:[],pixel:pixel})
                    }
                }
            }
        })

        this.geofenceModifier = new olInteraction.Modify({
            features: this.geofenceSelector.getFeatures(),
            deleteCondition: evt => {
                return olCondition.shiftKeyOnly(evt) && olCondition.singleClick(evt)
            },
            insertVertexCondition: false
        })
        this.geofenceModifier.setActive(false)
        this.geofenceModifier.on('change:active', _ => {})

        this.poiModifier = new olInteraction.Modify({
            features: this.osmPoiSelector.getFeatures()
        })
        this.poiModifier.setActive(false)
        this.poiModifier.on('change:active', _ => {})

        this.lineSelector = new olInteraction.Select({ layers: [this.layer_route] })
        this.lineSelector.setActive(false)
        var selectedRouteFeatures = this.lineSelector.getFeatures()
        this.lineSelector.on('change:active', _ => {
            selectedRouteFeatures.forEach(x => selectedRouteFeatures.remove(x))
        })
        this.lineModifier = new olInteraction.Modify({
            features: this.lineSelector.getFeatures(),
            insertVertexCondition: false
        })
        this.lineModifier.setActive(false)
        this.lineModifier.on('modifyend', evt => {
            let lineFeature = evt.features.item(0)
            var lineCoordinates = lineFeature.getGeometry().getCoordinates()
            console.log('map: line modified:', lineCoordinates)
            this.route = lineCoordinates.map(x => {
                let coords = olProj.toLonLat(x)
                let lat = coords[1]
                let lon = coords[0]
                return { 'lat': lat, 'lon': lon }
            })
        })

        this.snap = new olInteraction.Snap({
            source: this.source_geofence
        })

        this.mouseWheelZoom = new olInteraction.MouseWheelZoom()
        this.pinchZoom = new olInteraction.PinchZoom()
        this.dragPan = new olInteraction.DragPan()

        this.map.addInteraction(this.mouseWheelZoom)
        this.map.addInteraction(this.pinchZoom)
        this.map.addInteraction(this.dragPan)

        this.map.addInteraction(this.beaconSelector)
        this.map.addInteraction(this.tooltipHoverSelector)
        this.map.addInteraction(this.osmPoiSelector)
        this.map.addInteraction(this.poiDrawer)
        this.map.addInteraction(this.poiModifier)
        this.map.addInteraction(this.taskSelector)
        this.map.addInteraction(this.trackSelector)
        this.map.addInteraction(this.trackBeaconSelector)
        this.map.addInteraction(this.trafficTrackBeaconSelector)
        this.map.addInteraction(this.routeSelector)
        this.map.addInteraction(this.baseitemSelector)
        this.map.addInteraction(this.taskDrawer)
        this.map.addInteraction(this.lineDrawer)
        this.map.addInteraction(this.lineModifier)
        this.map.addInteraction(this.lineSelector)
        this.map.addInteraction(this.geofenceDrawer)
        this.map.addInteraction(this.geofenceSelector)
        this.map.addInteraction(this.geofenceModifier)
        this.map.addInteraction(this.snap)
        this.map.addInteraction(this.locationMarker)
        console.log('watching user position...')   
        this.watchUserPosition()
    }


    plotTempGeofence = geom => {
        if (!geom) return
        let id = 'temp_geofence'
        let feature = this.source_temp.getFeatureById(id)
        if (feature) {
            feature.setGeometry(geom)
            return
        }
        let featuresGEOJSON = {
            'type': 'Feature',
            'geometry': geom
        }
        feature =(new GeoJSON()).readFeature(featuresGEOJSON)
        feature.setId(id)
        feature.setStyle(Styles.styles.Geofence_temp)
        this.source_temp.addFeature(feature)
    }
    plotTempBaseItemMarker = lonlat => {
        if (!lonlat) return
        let id = 'temp_baseitem'
        let coordinates = olGeomPolygon.circular(lonlat, 2).transform('EPSG:4326', 'EPSG:3857').getCoordinates()
        console.log('coordinates:',coordinates)
        let feature = this.source_temp.getFeatureById(id)
        if (feature) {
            feature.getGeometry().setCoordinates(coordinates)
            return
        }
        let featuresGEOJSON = {
            'type': 'Feature',
            'geometry': {
                'type': 'Polygon',
                'coordinates': coordinates
            }
        }
        feature = (new GeoJSON()).readFeature(featuresGEOJSON)
        feature.setId(id)
        feature.setStyle(Styles.styles.Baseitem_temp)
        this.source_temp.addFeature(feature)
    }
    getTempBaseitemGeoJson = _ => {
        let feature = this.source_temp.getFeatureById('temp_baseitem')
        let geoJson = (new GeoJSON()).writeFeatureObject(feature)
        console.log('geojson:',geoJson)
        return geoJson.geometry
    }

    plotTempPoiMarker = lonlat => {
        if (!lonlat) return
        let id = 'temp_poi'
        let coordinates = new olGeom.Point(lonlat).transform('EPSG:4326', 'EPSG:3857').getCoordinates()
        let feature = this.source_temp.getFeatureById(id)
        if (feature) {
            console.log(feature)
            feature.getGeometry().setCoordinates(coordinates)
            return
        }
        let featuresGEOJSON = {
            'type': 'Feature',
            'geometry': {
                'type': 'Point',
                'coordinates': coordinates
            }
        }
        feature = (new GeoJSON()).readFeature(featuresGEOJSON)
        feature.setId(id)
        feature.setStyle(Styles.styles.Poi_temp)
        this.source_temp.addFeature(feature)
    }
    getTempPoiGeoJson = _ => {
        let feature = this.source_temp.getFeatureById('temp_baseitem')
        let geoJson = (new GeoJSON()).writeFeatureObject(feature)
        console.log('geojson:',geoJson)
        return geoJson.geometry
    }

    dimLayers = _ => {
        this.layer_base.setOpacity(0.7)
        this.layer_geofence.setOpacity(0.7)
        this.layer_nfz.setOpacity(0.7)
        this.layer_poi.setOpacity(0.7)
        this.layer_task.setOpacity(0.7)
    }

    onUserPosition = _ => {
        this.props.onUserMarkerPlot?.(this.userMarkerCoords)
        setTimeout( this.onUserPosition, 10000)
    }

    watchUserPosition = _ => {
        navigator.geolocation.watchPosition(position => {
            let coord = olProj.transform([position.coords.longitude, position.coords.latitude], 'EPSG:4326', 'EPSG:3857')
            if (!this.source_user.getFeatureById('user_location')) {
                let geoJson = {
                    "type": "Feature",
                    "geometry": {
                        "type": "Point",
                        "coordinates": coord
                    }
                }
                let feature = (new GeoJSON()).readFeature(geoJson)
                feature.setId('user_location')
                this.source_user.addFeature(feature)
            }
            let geom = new olGeom.Point(coord)
            this.source_user.getFeatureById('user_location').setGeometry(geom)
            this.userMarkerCoords = position.coords
            // console.log('userlocation:', position.coords)
	    }, err => {console.log('error:',err)}
        , {enableHighAccuracy:false})

        this.onUserPosition()
    }


    plotTempTaskMarker = lonlat => {
        if (!lonlat) return
        let id = 'temp_task'
        let coordinates = olGeomPolygon.circular(lonlat, 2).transform('EPSG:4326', 'EPSG:3857').getCoordinates()
        console.log('coordinates:',coordinates)
        let feature = this.source_temp.getFeatureById(id)
        if (feature) {
            feature.getGeometry().setCoordinates(coordinates)
            return
        }
        let featuresGEOJSON = {
            'type': 'Feature',
            'geometry': {
                'type': 'Polygon',
                'coordinates': coordinates
            }
        }
        feature = (new GeoJSON()).readFeature(featuresGEOJSON)
        feature.setId(id)
        feature.setStyle(Styles.styles.Task_temp)
        this.source_temp.addFeature(feature)
    }

    getTempGeofenceitemGeoJson = _ =>{
        let feature = this.source_geofence.getFeatureById('temp_geofence')
        let geoJson = (new GeoJSON()).writeFeatureObject(feature)
        console.log('geojson:',geoJson)
        return geoJson.geometry
    }

    getSelectedGeofenceGeoJson = _ => {
        let feature = this.geofenceSelector.getFeatures().item(0)
        if (!feature) {
            return null
        }
        let geoJson = (new GeoJSON()).writeFeatureObject(feature)
        console.log('geojson:',geoJson)
        return geoJson.geometry
    }

    getGeofenceGeoJson = geofence_id =>{
        let feature = this.source_geofence.getFeatureById(geofence_id)
        let geoJson = (new GeoJSON()).writeFeatureObject(feature)
        console.log('geojson:',geoJson)
        return geoJson.geometry
    }

    getTempTaskGeoJson = _ => {
        let feature = this.source_temp.getFeatureById('temp_task')
        let geoJson = (new GeoJSON()).writeFeatureObject(feature)
        console.log('geojson:',geoJson)
        return geoJson.geometry
    }

    handlePointerMove = e => {
        // console.log('feature:',e.selected)
        if (e.selected.length==0) {
            if (this.pointerMoveTimerId) {
                return
            }
            this.pointerMoveTimerId = setTimeout( _ => {
                this.props.setState?.({showTooltip:false})
            }, 500)
            return
        }
        if (this.pointerMoveTimerId) {
            clearTimeout(this.pointerMoveTimerId)
            this.pointerMoveTimerId = null
        }
        this.props?.setState?.({
            feature: e.selected[0],
            coordinates: olProj.transform(e.selected[0].getGeometry().getCoordinates(),'EPSG:3857', 'EPSG:4326'),
            showTooltip: true,
            event: e.mapBrowserEvent.originalEvent,
            tooltipOffset: {x: e.mapBrowserEvent.pixel[0], y: e.mapBrowserEvent.pixel[1]}
        })
    }

    updateSize() {
        this.map.updateSize()
    }

    zoomTo(geoJson, callback) {
        this.map.getView().fit((new GeoJSON()).readFeature(geoJson).getGeometry().transform('EPSG:4326','EPSG:3857').getExtent(),_ => callback())
    }

    flyTo(lon,lat, callback) {
        this.map.getView().animate({center:fromLonLat([lon,lat])},_ => callback())
    }

    triggerMoveEnd() {
        let extent = this.map.getView().calculateExtent(this.map.getSize())
        this.props.onMoveEnd?.({extent:extent})
    }

    getGeofences() {
        let features = this.source_geofence.getFeatures()
        if (features.length == 0) return null

        let geofences = features.map(x => {
            return x.getGeometry().getCoordinates()[0].map(x => {
                let coords = olProj.toLonLat(x)
                let lat = coords[1]
                let lon = coords[0]
                return { 'lat': lat, 'lon': lon }
            })
        })
        console.log('map: get geofences:', geofences)
        return geofences
    }


    startEditPoi() {
        this.osmPoiSelector.getFeatures().item(0).setStyle(Styles.styles.Poi_edited)
    }

    finishEditPoi() {
        console.log('poiSelector',this.osmPoiSelector)
        this.osmPoiSelector.getFeatures().item(0).setStyle(feature => Styles.styles.Osm_Poi_focused(feature))
    }

    startEditGeofence() {
        this.geofenceSelector.getFeatures().item(0).setStyle(Styles.styles.Geofence_edited)
    }

    finishEditGeofence() {
        this.geofenceSelector.getFeatures().item(0).setStyle(Styles.styles.Geofence_focused)
    }

    //Map Interactions
    activateGeofenceSelectInteraction() {
        this.geofenceSelector.setActive(true)
    }

    activateTrackBeaconSelectInteraction(){
        this.trackBeaconSelector.setActive(true)
    }
    activateTrafficTrackBeaconSelectInteraction() {
        this.trafficTrackBeaconSelector.setActive(true)
    }
    activateTaskSelectInteraction() {
        this.taskSelector.setActive(true)
    }
    activateTrackSelectInteraction() {
        this.trackSelector.setActive(true)
    }
    activateRouteSelectInteraction() {
        this.routeSelector.setActive(true)
    }
    activateBaseitemSelectInteraction() {
        this.baseitemSelector.setActive(true)
    }

    activatePoiSelectInteraction() {
        this.osmPoiSelector.setActive(true)
    }

    activateTaskDrawingInteraction() {
        this.taskDrawer.setActive(true)
    }

    activatePoiDrawingInteraction() {
        this.poiDrawer.setActive(true)
    }

    clearTrackBeaconSelection() {
        this.trackBeaconSelector.getFeatures().clear()
    }
    clearTrafficTrackBeaconSelection() {
        this.trafficTrackBeaconSelector.getFeatures().clear()
    }
    clearTaskSelection() {
        this.taskSelector.getFeatures().clear()
    }
    clearTrackSelection() {
        this.trackSelector.getFeatures().clear()
    }
    clearRouteSelection(){
        this.routeSelector.getFeatures().clear()
    }

    clearPoiSelection() {
        this.osmPoiSelector.getFeatures().clear()
    }

    clearGeofenceSelection() {
        this.geofenceSelector.getFeatures().clear()
    }

    acivateGeofenceDrawingInteraction() {
        this.geofenceDrawer.setActive(true)
    }

    acivateLineDrawingInteraction() {
        this.lineDrawer.setActive(true)
    }

    activateGeofenceEdit() {
        this.geofenceModifier.setActive(true)
    }

    activatePoiEdit() {
        this.poiModifier.setActive(true)
    }

    finishGeofenceDraw() {
        this.geofenceDrawer.finishDrawing()
    }


    deactivateAllInteractions() {
        this.beaconSelector.setActive(false)
        this.poiDrawer.setActive(false)
        this.osmPoiSelector.setActive(false)
        this.poiModifier.setActive(false)
        this.taskDrawer.setActive(false)
        this.taskSelector.setActive(false)
        this.trackSelector.setActive(false)
        this.trackBeaconSelector.setActive(false)
        this.trafficTrackBeaconSelector.setActive(false)
        this.routeSelector.setActive(false)
        this.baseitemSelector.setActive(false)
        this.geofenceDrawer.setActive(false)
        this.geofenceModifier.setActive(false)
        this.geofenceSelector.setActive(false)
        this.tooltipHoverSelector.setActive(false)
        this.lineDrawer.setActive(false)
        this.lineModifier.setActive(false)
        this.lineSelector.setActive(false)
        this.tooltipHoverSelector.setActive(false)
        this.locationMarker.setActive(false)
    }

    eraseAllTemp() {
        this.source_temp.clear()
    }

    eraseSelectedArea() {
        this.source_geofence.removeFeature(this.geofenceSelector.getFeatures().item(0))
    }

    eraseSelectedTask() {
        this.source_task.removeFeature(this.taskSelector.getFeatures().item(0))
    }

    eraseSelectedTrack() {
        this.source_track.removeFeature(this.trackSelector.getFeatures().item(0))
    }

    eraseSelectedPoi() {
        this.source_poi.removeFeature(this.osmPoiSelector.getFeatures().item(0))
    }

    eraseAllGeofences() {
        this.source_geofence.clear()
    }

    eraseAllRoutes() {
        this.route = []
        this.source_route.clear()
    }

    eraseAllTasks() {
        this.source_task.clear()
        this.eraseAllRoutes()
    }

    eraseAllBaseitems() {
        this.source_base.clear()
    }

    eraseAllPois() {
        this.source_poi.clear()
    }

    eraseAllOsmPois() {
        this.source_osm_poi.clear()
    }

    plotBaseitem(baseitem) {
        let featureGEOJSON = {
            'type': 'Feature',
            'geometry': baseitem.polygon,
            'properties': baseitem
        }
        let feature = (new GeoJSON()).readFeature(featureGEOJSON)
        feature.getGeometry().transform('EPSG:4326','EPSG:3857')
        feature.setId(baseitem.id.toString())
        this.source_base.addFeature(feature)
    }

    plotPoi(poi) {
        let featureGEOJSON = {
            'type': 'Feature',
            'geometry': poi.polygon || poi.point,
            'properties': poi
        }
        let feature = (new GeoJSON()).readFeature(featureGEOJSON)
        feature.getGeometry().transform('EPSG:4326','EPSG:3857')
        feature.setId(poi.id.toString())
        this.source_poi.addFeature(feature)
        console.log(this.layer_poi.getStyle())
    }

    plotOsmPoi = x => {
        if (!!this.source_poi.getFeatureById(x.osm_id)) return
        let featureGEOJSON = {
            'type': 'Feature',
            'geometry': x.geom,
            'properties': x
        }
        let feature = (new GeoJSON()).readFeature(featureGEOJSON)
        feature.getGeometry().transform('EPSG:4326','EPSG:3857')
        feature.setId(x.osm_id)
        this.source_osm_poi.addFeature(feature)
    }


    plotTask(task) {
        if (!task.checkpoint_polygon) {
            console.debug('task has no polygon')
            return
        }
        let featureGEOJSON = {
            'type': 'Feature',
            'geometry': task.checkpoint_polygon,
            'properties': task
        }
        let feature = (new GeoJSON()).readFeature(featureGEOJSON)
        feature.getGeometry().transform('EPSG:4326','EPSG:3857')
        feature.setId(task.id.toString())
        this.source_task.addFeature(feature)
    }

    plotGeofence(geofence) {
        let featureGEOJSON = {
            'type': 'Feature',
            'geometry': geofence.polygon,
            'properties': {
                geofence_id: geofence.id
            }
        }
        let feature = (new GeoJSON()).readFeature(featureGEOJSON)
        feature.getGeometry().transform('EPSG:4326','EPSG:3857')
        feature.setId(geofence.id.toString())
        this.source_geofence.addFeature(feature)
    }

    plotSwarmRoute(route) {
        console.log('route:',route)
        let featureGEOJSON = {
            'type': 'Feature',
            'geometry': route.line,
            'properties': {
                uxv_id: route.uxv_id + '_' + route.cluster_id
            }
        }
        let feature = (new GeoJSON()).readFeature(featureGEOJSON)
        feature.getGeometry().transform('EPSG:4326','EPSG:3857')
        feature.setId(route.uxv_id.toString())
        this.source_route.addFeature(feature)
    }

    plotRoute(route) {
        // console.log('uxv_id:', route.uxv_id)
        let featureGEOJSON = {
            'type': 'Feature',
            'geometry': route.line,
            'properties': {
                uxv_id: route.uxv_id,
                uxv_type: route.uxv_type,
                codename: route.codename,
            }
        }
        let feature = (new GeoJSON()).readFeature(featureGEOJSON)
        feature.getGeometry().transform('EPSG:4326','EPSG:3857')
        feature.setId(route.uxv_id.toString())
        this.source_route.addFeature(feature)
    }

    plotUxvTrack(track) {
        console.log('mission_uxv_path:', track)
        let featureGEOJSON = {
            'type': 'Feature',
            'geometry': track.line,
            'properties': {
                uxv_id: track.uxv_id,
                uxv_type: track.uxv_type,
                codename: track.codename,
                start_at: track.start_at,
                end_at: track.end_at,
            }
        }
        let feature = (new GeoJSON()).readFeature(featureGEOJSON)
        feature.getGeometry().transform('EPSG:4326','EPSG:3857')
        feature.setId(track.uxv_id.toString())
        this.source_uxv_track.addFeature(feature)
        if (!this.track_uxv_ids) {
            this.track_uxv_ids = new Set()
        }
        this.track_uxv_ids.add(track.uxv_id.toString())
    }
    plotTrackBeacons = track => {
        let featureGEOJSON = {
            'type':'FeatureCollection',
            'features': track.points.map( x => ({
                'type': 'Feature',
                'geometry': x.point,
                'properties': {
                    uxv_id: track.uxv_id,
                    uxv_type: track.uxv_type,
                    codename: track.codename,
                    created_at: x.created_at,
                    created_at_adjusted: x.created_at_adjusted,
                }
            }))
        }
        let features = (new GeoJSON()).readFeatures(featureGEOJSON)
        features.forEach( x => {
            x.getGeometry().transform('EPSG:4326','EPSG:3857')
            x.setId( `temp_beacon_${x.get('uxv_id')}_${x.get('created_at_adjusted')}`)
        })
        this.source_track_beacon.addFeatures(features)
    }
    selectTrackBeacons(timestamp) {
        // console.log('showTrackBeacons:',timestamp)
        this.trackBeaconSelector.getFeatures().clear()
        let features = [...this.track_uxv_ids].map( x => this.source_track_beacon.getFeatureById(`temp_beacon_${x}_${timestamp}`)).filter(x => x ?? false)
        // console.log('features:',features)
        this.trackBeaconSelector.getFeatures().extend(features)
        // this.trackBeaconSelector.dispatchEvent({type:'select',selected:features, deselected:[]})
    }

    plotTrafficTrack(traffic_type,track) {
        // console.log('mission_traffic_path:', traffic_type, track)
        let featureGEOJSON
        if (traffic_type == 'adsb') {
            featureGEOJSON = {
                'type': 'Feature',
                'geometry': track.line,
                'properties': {
                    ident: track.ident,
                    traffic_type: 'adsb'
                }
            }
        } else if (traffic_type == 'ais') {
            featureGEOJSON = {
                'type': 'Feature',
                'geometry': track.line,
                'properties': {
                    mmsi: track.mmsi,
                    traffic_type: 'ais'
                }
            }
        } else {
            console.log('unhandled traffic type')
            return
        }
        let feature = (new GeoJSON()).readFeature(featureGEOJSON)
        feature.getGeometry().transform('EPSG:4326','EPSG:3857')
        if (traffic_type == 'adsb')
            feature.setId('adsb_' + track.ident.toString())
        if (traffic_type == 'ais')
            feature.setId('ais_' + track.mmsi.toString())
        this.source_traffic_track.addFeature(feature)
        if (!this.traffic_track_ids) {
            this.traffic_track_ids = new Set()
        }
        // console.log('feature:',feature)
        if (traffic_type == 'adsb')
            this.traffic_track_ids.add('adsb_' + track.ident.toString())
        if (traffic_type == 'ais')
            this.traffic_track_ids.add('ais_' + track.mmsi.toString())
    }
    plotTrafficTrackBeacons = (traffic_type,track) => {
        // console.log('plotTrafficTrackBeacons:', track)
        let featureGEOJSON
        if (traffic_type == 'adsb'){
            featureGEOJSON = {
                'type':'FeatureCollection',
                'features': track.points.map( x => ({
                    'type': 'Feature',
                    'geometry': x.point,
                    'properties': {
                        ident: track.ident,
                        traffic_type: 'adsb',
                        created_at: x.created_at,
                        created_at_adjusted: x.created_at_adjusted,
                    }
                }))
            }
        } else if (traffic_type == 'ais') {
            featureGEOJSON = {
                'type':'FeatureCollection',
                'features': track.points.map( x => ({
                    'type': 'Feature',
                    'geometry': x.point,
                    'properties': {
                        mmsi: track.mmsi,
                        traffic_type: 'ais',
                        created_at: x.created_at,
                        created_at_adjusted: x.created_at_adjusted,
                    }
                }))
            }
        } else {
            console.log('unhandled traffic type')
            return
        }
        let features = (new GeoJSON()).readFeatures(featureGEOJSON)
        features.forEach( x => {
            x.getGeometry().transform('EPSG:4326','EPSG:3857')
            if (traffic_type == 'adsb') {
                x.setId( `temp_beacon_adsb_${x.get('ident')}_${x.get('created_at_adjusted')}`)
            }
            if (traffic_type == 'adsb') {
                x.setId( `temp_beacon_ais_${x.get('mmsi')}_${x.get('created_at_adjusted')}`)
            }
        })
        // console.log('feautres:',features)
        this.source_traffic_track_beacon.addFeatures(features)
    }
    selectTrafficTrackBeacons(timestamp) {
        // console.log('showTrackBeacons:',timestamp)
        this.trafficTrackBeaconSelector.getFeatures().clear()
        let features = [...this.traffic_track_ids].map(x => this.source_traffic_track_beacon.getFeatureById(`temp_beacon_${x}_${timestamp}`)).filter(x => x ?? false)
        // console.log('features:',features)
        this.trafficTrackBeaconSelector.getFeatures().extend(features)
        // this.trackBeaconSelector.dispatchEvent({type:'select',selected:features, deselected:[]})
    }
    

    selectGeofence(geofence_id) {
        let deselected = geofence_id == null ? this.geofenceSelector.getFeatures().getArray() : []
        console.log('deselected:',deselected)
        if (deselected.length) {
            this.geofenceSelector.dispatchEvent({type:'select',selected:[],deselected:deselected})
            this.geofenceSelector.getFeatures().clear()
        }
        let features = geofence_id ? [this.source_geofence.getFeatureById(geofence_id.toString())] : []
        if (features.length) {
            this.geofenceSelector.getFeatures().extend(features)
            this.geofenceSelector.dispatchEvent({type:'select',selected:features,deselected:[]})
        }
    }

    selectTrack(track_id) {
        this.trackSelector.getFeatures().clear()
        if (!track_id) {
            this.trackSelector.dispatchEvent({type:'select',selected:[], deselected:[]})
        }
        let feature = this.source_task.getFeatureById(track_id)
        if (!feature) {
            console.log('feature not found.')
        }
        this.trackSelector.getFeatures().extend([feature])
        this.trackSelector.dispatchEvent({type:'select',selected:[feature], deselected:[]})
        let coord = feature.get('centroid').coordinates
        this.flyTo(...coord, _ => _)
    }

    selectTask(task_id) {
        this.taskSelector.getFeatures().clear()
        if (!task_id) {
            this.taskSelector.dispatchEvent({type:'select',selected:[], deselected:[]})
        }
        let feature = this.source_task.getFeatureById(task_id)
        if (!feature) {
            console.log('feature not found.')
        }
        this.taskSelector.getFeatures().extend([feature])
        this.taskSelector.dispatchEvent({type:'select',selected:[feature], deselected:[]})
        let coord = feature.get('centroid').coordinates
        this.flyTo(...coord, _ => _)
    }

    selectRoute(route_id) {
        this.routeSelector.getFeatures().clear()
        if (!route_id) {
            this.routeSelector.dispatchEvent({type:'select',selected:[], deselected:[]})
        }
        let feature = this.source_route.getFeatureById(route_id)
        if (!feature) {
            console.log('feature not found.')
        }
        this.routeSelector.getFeatures().extend([feature])
        this.routeSelector.dispatchEvent({type:'select',selected:[feature], deselected:[]})
    }

    selectBaseitem(baseitem_id) {
        this.baseitemSelector.getFeatures().clear()
        let features = baseitem_id ? [this.source_base.getFeatureById(baseitem_id.toString())] : []
        this.baseitemSelector.getFeatures().extend(features)
        this.baseitemSelector.dispatchEvent({type:'select',selected:features, deselected:[]})
    }

    plotOwnUav(data) {
        let x = olProj.transform([data.lon, data.lat], 'EPSG:4326', 'EPSG:3857')
        if (this.own_uav) {
            this.own_uav.getGeometry().setCoordinates(x)
            this.own_uav.set('hdg',data.hdg)
            return
        }
        // initial feature
        let featureGEOJSON = {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: x
            },
            properties: {
                uxv_type:'own_uav',
                hdg: data.hdg
            }
        }
        this.own_uav = (new GeoJSON()).readFeature(featureGEOJSON)
        this.source_beacon.addFeature(this.own_uav)
    }

    plotWaypoint(coordinates) {
        if (this.waypoint) {
            this.waypoint.getGeometry().setCoordinates(coordinates)
            return
        }
        // initial feature
        let featureGEOJSON = {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: coordinates
            }
        }
        this.waypoint = (new GeoJSON()).readFeature(featureGEOJSON)
        this.source_waypoint.addFeature(this.waypoint)
    }

    plotOtherBeacon(data) {
        // console.debug('map: plot other beacon:', data)
        let codename = data.codename || data.username
        let coord = olProj.transform([
            data.content.lon,
            data.content.lat
        ], 'EPSG:4326', 'EPSG:3857')
        if (!this.source_beacon.getFeatureById(codename)) {
            let geoJson = {
                "type": "Feature",
                "geometry": {
                    "type": "Point",
                    "coordinates": coord
                },
                "properties": data
            }
            let feature = (new GeoJSON()).readFeature(geoJson)
            feature.setId(codename)
            this.source_beacon.addFeature(feature)
            return
        }

        let geom = new olGeom.Point(coord)
        this.source_beacon.getFeatureById(codename).setGeometry(geom)
        this.source_beacon.getFeatureById(codename).setProperties(data)
    }

    plotAis(data) {
        // console.log('ais:',data)
        this.source_ais.clear()
        let geojson = {
            type: 'FeatureCollection',
            features: data
        }
        this.source_ais.addFeatures((new GeoJSON()).readFeatures(geojson))
    }

    plotAdsb(data) {
        // console.log('adsb:',data)
        this.source_adsb.clear()
        let geojson = {
            type: 'FeatureCollection',
            features: data
        }
        this.source_adsb.addFeatures((new GeoJSON()).readFeatures(geojson))
    }

    resetRoute() {
        var oldRouteFeatures = []

        this.source_geofence.forEachFeature(feature => {
            var featureType = feature.getProperties().type
            if (featureType == 'point' || featureType == 'line') {
                // console.log(feature)
                oldRouteFeatures.push(feature)
            }
        })

        oldRouteFeatures.forEach(feature => {
            this.source_geofence.removeFeature(feature)
        })

        console.log('Reset Route')

    }

    createPoint(point) {
        let geom = new olGeom.Point(olProj.transform([point.lon, point.lat], 'EPSG:4326', 'EPSG:3857'))
        let feature = new ol.Feature()

        feature.setId('P_' + point.id)
        feature.setGeometry(geom)
        feature.setProperties({
            'type': 'point'
        })
        return feature
    }

    createLine(startingPoint, endingPoint, id) {
        var geom = new LineString([startingPoint.getCoordinates(), endingPoint.getCoordinates()])
        var feature = new ol.Feature()

        feature.setStyle(
            new Style({
                stroke: new Stroke({
                    color: 'black',
                    width: 2
                })
            }))

        feature.setId('L_' + id)
        feature.setGeometry(geom)
        feature.setProperties({
            'type': 'line'
        })
        return feature
    }

    styleFunction(feature) {
        var geometry = feature.getGeometry()
        var styles = [
            // linestring
            new Style({
                stroke: new Stroke({
                    color: 'rgb(0,110,146)',
                    width: 3,
                }),
            })]

        geometry.forEachSegment(function (start, end) {
            var dx = end[0] - start[0]
            var dy = end[1] - start[1]
            var rotation = Math.atan2(dy, dx)
            // arrows
            styles.push(
                new Style({
                    geometry: new Point(end),
                    image: new Icon({
                        src: wp,
                        anchor: [0.75, 0.5],
                        rotateWithView: true,
                        rotation: -rotation
                    }),
                })
            )
        })
        return styles
    }

    deinit() {
        console.log('disposing map...')
        this.raster.dispose()
        this.layer_geofence.dispose()
        this.layer_nfz.dispose()
        this.layer_openseamap.dispose()
        this.map.dispose()
    }
}
