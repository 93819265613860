import React, { Fragment } from 'react'

import '../../style.css'
import './planner.css'

let getCalendarWeek = x => {
    if (!x) {
        return null
    }
    let date_0 = new Date(x.getFullYear(), 0, 1)
    let no_of_days = Math.floor( (x - date_0) / 86400000 ) // 24*60*60*1000
    return Math.ceil(no_of_days/7)
}

export class Planner extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            targetYear : null,
            targetMonth : null,
            firstRenderDateOffset : null,
            numOfWeeksToRender : null,
            selectedDate : null,
        }
    }

    onChange = e => {
        this.setState({[e.target.name]:e.target.value})
    }

    setYearMonth(year,month) { // where month can be any integer
        let firstMonthDate = new Date(Date.UTC(year, month, 1))  // first of target month
        let lastMonthDate = new Date(Date.UTC(year, month+1, 0)) // end of target month
        let numOfWeeksToRender = Math.ceil( (firstMonthDate.getUTCDay() + lastMonthDate.getUTCDate()) / 7 )
        this.setState({
            targetYear : firstMonthDate.getUTCFullYear(),
            targetMonth : firstMonthDate.getUTCMonth(),
            firstRenderDateOffset : 1 - firstMonthDate.getUTCDay(),
            numOfWeeksToRender: numOfWeeksToRender
        })
        this.props.onSetYearMonth?.(firstMonthDate.getUTCFullYear(),firstMonthDate.getUTCMonth())
    }

    unselectDate() {
        this.setState({selectedDate:null})
    }

    componentDidMount() {
        let time = this.props.presetTimestamp ? this.props.presetTimestamp : new Date()
        this.setYearMonth(time.getUTCFullYear(),time.getUTCMonth())
        this.setState({selectedDate: time})

        let start_at = new Date(time.getFullYear(), time.getMonth(), 1).toISOString()
        let end_at  = new Date(time.getFullYear(), time.getMonth()+1, 0).toISOString()
        fetch(PUBLIC_PATH_ROOT + 'api/missions_in_timerange', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({ token: this.props.credentials.token, start_at: start_at, end_at: end_at})
        }).then(res => res.json())
        .then(res => {
            if (res.success) {
                console.log('missions_in_timerange:', res)
                this.setState({
                    isLoadingMissions: false,
                    missions: res.missions
                })
                return
            }
            throw res.reason
        })
        .catch(err => console.error(err))
    }

    render =  _ => (
        <div class='scheduler'>
        <table>
            <thead>
                <tr><td colSpan='8'>{new Date(Date.UTC(this.state.targetYear, this.state.targetMonth, 1)).toLocaleString('default',{year:'numeric',month:'long'})}</td></tr>
                <tr>
                {[...'#SMTWTFS'].map( (x,i) =>
                    <th key={i} day={i-1}>{x}</th>
                )}
                </tr>
            </thead>
            <tbody>
                { Array( 5 || this.state.numOfWeeksToRender).fill().map((_,i) => <Fragment key={i}>
                    <tr>
                        <td>
                            { getCalendarWeek(new Date(Date.UTC(this.state.targetYear, this.state.targetMonth, this.state.firstRenderDateOffset + i * 7 ))) }
                        </td>
                        {Array(7).fill().map( (_ ,j) => {
                            let d = new Date(Date.UTC(this.state.targetYear, this.state.targetMonth, this.state.firstRenderDateOffset + i * 7 + j ))
                            return <td
                                class='scheduler_day_td'
                                key={i*7 + j}
                                inactive={ d.getUTCMonth() == this.state.targetMonth ? 'false' : 'true'}
                                is_selected={ this.state.selectedDate?.toISOString().substring(0, 10) == d.toISOString().substring(0, 10) ? 'true' : 'false'}
                                day={j}
                                onClick={ _ => {
                                    this.setState({selectedDate:d})
                                }}>
                                {d.getUTCDate()}
                                <div class='scheduler_day_notification'>11</div>
                            </td>
                        })}
                    </tr>
                    { (getCalendarWeek(this.state.selectedDate) == getCalendarWeek(new Date(Date.UTC(this.state.targetYear, this.state.targetMonth, this.state.firstRenderDateOffset + i * 7 ))) ) && 
                        <tr>
                            <td colSpan='8'>Found Tasks</td>
                        </tr>
                    }
                </Fragment>)}
                <tr>
                    <td colSpan='8'>
                        <button
                            class='calender-navigate-button'
                            onClick={ _ => this.setYearMonth(this.state.targetYear - 1,this.state.targetMonth)}
                        >&lt;&lt;</button>
                        <button
                            class='calender-navigate-button'
                            onClick={ _ => this.setYearMonth(this.state.targetYear,this.state.targetMonth-1)}
                        >&lt;</button>
                        <input
                            type='date'
                            value={this.state.selectedDate?.toISOString().substring(0, 10) ?? ''}
                            onChange={ e => {
                                let d = new Date(e.target.value)
                                console.log(d.toISOString())
                                this.setState({selectedDate:d, targetYear: d.getUTCFullYear(), targetMonth: d.getUTCMonth()})
                            }}
                        />
                        <button
                            class='calender-navigate-button'
                            onClick={ _ => this.setYearMonth(this.state.targetYear,this.state.targetMonth+1)}
                        >&gt;</button>
                        <button
                            class='calender-navigate-button'
                            onClick={ _ => this.setYearMonth(this.state.targetYear+1,this.state.targetMonth)}
                        >&gt;&gt;</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>)
}

class TaskList extends React.Component {
    constructor(props) {
        super(props)
    }

    render = _ => (
        <div class='scheduler_tasklist'>
            <table>
                <thead>
                    <tr><th>For day {this.props.selectedDate ?? 'dd/mm/yyyy'}</th></tr>
                </thead>
                <tbody> { ['a','b','c'].map(x => <tr class='table-card full-width'>
                    {x}
                </tr>)}</tbody>
            </table>
        </div>
    )
}