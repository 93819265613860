import React, { Fragment } from 'react'
import './trafficViewer.css'
import '../../style.css'
import * as olProj from 'ol/proj'

export class ControlPanel extends React.Component {

    constructor(props) {
        super(props)
    }

    render = _ =>
        <div
            class={ 'controlPanel' + (this.props.isFullscreen ? ' fullscreen' : '')}
        >
            <button
                key='interactions'
                class={ this.props.layerVisibilities['interactions'] ? 'button-toggle-normal active':'button-toggle-normal'}
                onClick={ _ => {
                    let newVisibilities = JSON.parse(JSON.stringify(this.props.layerVisibilities))
                    newVisibilities['interactions'] = !newVisibilities['interactions']
                    this.props.setLayerVisibilities(newVisibilities)
                }}
            >Interactions</button>
            <button
                key='traffic'
                class={ this.props.layerVisibilities['traffic'] ? 'button-toggle-normal active':'button-toggle-normal'}
                onClick={ _ => {
                    let newVisibilities = JSON.parse(JSON.stringify(this.props.layerVisibilities))
                    newVisibilities['traffic'] = !newVisibilities['traffic']
                    this.props.setLayerVisibilities(newVisibilities)
                }}
            >Traffic</button>
            <button
                key='uxv'
                class={ this.props.layerVisibilities['uxv'] ? 'button-toggle-normal active':'button-toggle-normal'}
                onClick={ _ => {
                    let newVisibilities = JSON.parse(JSON.stringify(this.props.layerVisibilities))
                    newVisibilities['uxv'] = !newVisibilities['uxv']
                    this.props.setLayerVisibilities(newVisibilities)
                }}
            >UXV</button>
            <button
                key='geofence'
                class={ this.props.layerVisibilities['geofence'] ? 'button-toggle-normal active':'button-toggle-normal'}
                onClick={ _ => {
                    let newVisibilities = JSON.parse(JSON.stringify(this.props.layerVisibilities))
                    newVisibilities['geofence'] = !newVisibilities['geofence']
                    this.props.setLayerVisibilities(newVisibilities)
                }}
            >Geofence layer</button>
            <button
                key='base'
                class={ this.props.layerVisibilities['base'] ? 'button-toggle-normal active':'button-toggle-normal'}
                onClick={ _ => {
                    let newVisibilities = JSON.parse(JSON.stringify(this.props.layerVisibilities))
                    newVisibilities['base'] = !newVisibilities['base']
                    this.props.setLayerVisibilities(newVisibilities)
                }}
            >Base layer</button>
            <button
                key='nfz'
                class={ this.props.layerVisibilities['nfz'] ? 'button-toggle-normal active':'button-toggle-normal'}
                onClick={ _ => {
                    let newVisibilities = JSON.parse(JSON.stringify(this.props.layerVisibilities))
                    newVisibilities['nfz'] = !newVisibilities['nfz']
                    this.props.setLayerVisibilities(newVisibilities)
                }}
            >NFZ layer</button>
            <button
                key='task'
                class={ this.props.layerVisibilities['task'] ? 'button-toggle-normal active':'button-toggle-normal'}
                onClick={ _ => {
                    let newVisibilities = JSON.parse(JSON.stringify(this.props.layerVisibilities))
                    newVisibilities['task'] = !newVisibilities['task']
                    this.props.setLayerVisibilities(newVisibilities)
                }}
            >Task layer</button>
            <button
                key='poi'
                class={ this.props.layerVisibilities['poi'] ? 'button-toggle-normal active':'button-toggle-normal'}
                onClick={ _ => {
                    let newVisibilities = JSON.parse(JSON.stringify(this.props.layerVisibilities))
                    newVisibilities['poi'] = !newVisibilities['poi']
                    this.props.setLayerVisibilities(newVisibilities)
                }}
            >POI layer</button>
            <button
                key='ortho'
                class={ this.props.layerVisibilities['ortho'] ? 'button-toggle-normal active':'button-toggle-normal'}
                onClick={ _ => {
                    let newVisibilities = JSON.parse(JSON.stringify(this.props.layerVisibilities))
                    newVisibilities['ortho'] = !newVisibilities['ortho']
                    this.props.setLayerVisibilities(newVisibilities)
                }}
            >Orthophoto layer</button>
            <button
                key='elevation'
                class={ this.props.layerVisibilities['elevation'] ? 'button-toggle-normal active':'button-toggle-normal'}
                onClick={ _ => {
                    let newVisibilities = JSON.parse(JSON.stringify(this.props.layerVisibilities))
                    newVisibilities['elevation'] = !newVisibilities['elevation']
                    this.props.setLayerVisibilities(newVisibilities)
                }}
            >Elevation layer</button>
        </div>
}

export class Viewer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            // location query
            locationQueryTimerId: null,
            foundLocations: [],
            locationQuery: null,
            layer_visibilities: {
                geofence:this.props.map.layer_geofence.getVisible(),
                nfz:this.props.map.layer_nfz.getVisible(),
                task:this.props.map.layer_task.getVisible(),
                poi:this.props.map.layer_poi.getVisible(),
                base:this.props.map.layer_base.getVisible(),
                traffic:this.props.map.layer_adsb.getVisible(),
                uxv:this.props.map.layer_beacon.getVisible(),
                ortho:this.props.map.orthophoto_raster.getVisible(),
                interactions: true,
                elevation: false
            },
            isFullscreen: false
        }
    }

    componentDidMount() {

        this.props.map.map.setTarget(this.mapContainer)

        this.setState({
            isLoadingPayloads: true,
            isLoadingUxvs: true,
            uxvs: null,
            payloads: null,
        })

        fetch(PUBLIC_PATH_ROOT + 'auth/payloads', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({ token: this.props.credentials.token })
        }).then(res => res.json())
        .then(res => {
            if (res.success) {
                let payloads = res.payloads
                this.setState({
                    isLoadingPayloads: false,
                    payloads: payloads
                })
                return
            }
            console.log('account: get payloads failed, reason:', res.reason)
        })
        .catch(err => {
            console.log('account: get payloads: error:',err)
        })

        this.props.map.layer_ais.setVisible(true)
        this.props.map.layer_adsb.setVisible(true)
        this.props.map.layer_beacon.setVisible(true)
        this.props.map.beaconSelector.setActive(true)
        this.props.map.tooltipHoverSelector.setActive(true)
        this.props.map.locationMarker.setActive(true)
        this.props.map.props.onMoveEnd = x => {
            if (this.traffic_timer_id) {
                console.log('skip traffic query.')
                return
            }
            this.traffic_timer_id = setTimeout(_ => {
                clearTimeout(this.traffic_timer_id)
                this.traffic_timer_id = null
            }, 500);

            console.log('fetching traffic data...:',x)
            fetch(PUBLIC_PATH_ROOT + `api/ais_past30min?xmin=${x.extent[0]}&ymin=${x.extent[1]}&xmax=${x.extent[2]}&ymax=${x.extent[3]}&srid=3857`).then(res => res.json())
            .then(res => {
                if (res.success) {
                    let ais_datas = res.ais_datas
                    // console.log('ais_datas:',res.ais_datas)
                    this.props.map.plotAis(ais_datas)
                    this.props.map.props.setState = s => {
                        this.setState({mapState: s})
                    }
                    return
                }
                throw res.reason
            })
            .catch(err => {
                console.log('ais_past30min error:',err)
            })

            fetch(PUBLIC_PATH_ROOT + `api/eezs?xmin=${x.extent[0]}&ymin=${x.extent[1]}&xmax=${x.extent[2]}&ymax=${x.extent[3]}&srid=3857`).then(res => res.json())
            .then(rs => {
                if (rs.success) {
                    let eezs = rs.eezs
                    console.log('eez:',rs.eezs)
                    this.props.map.plotEezs(eezs)
                    this.setState({eezs: eezs})
                    return
                }
                throw rs
            })
            .catch(err => {
                console.log('eez error:',err)
            })

            fetch(PUBLIC_PATH_ROOT +`api/sbs_past1min?xmin=${x.extent[0]}&ymin=${x.extent[1]}&xmax=${x.extent[2]}&ymax=${x.extent[3]}&srid=3857`)
            .then(res => res.json())
            .then(res => {
                if (res.success) {
                    let adsb_datas = res.adsb_datas
                    // console.log('adsb_data:',res.adsb_datas)
                    this.props.map.plotAdsb(adsb_datas)
                    this.props.map.props.setState = s => {
                        this.setState({mapState: s})
                    }
                    return
                }
                throw res.reason
            })
            .catch(err => {
                console.log('error:',err)
            })
        }

        this.props.map.props.onMarkerDrawEnd = feature => {
            // console.log('onMarkerDrawEnd:feature:', feature)
            this.setState({marked_coordinates: olProj.transform(feature.getGeometry().getCoordinates(), 'EPSG:3857', 'EPSG:4326')})
        }

        this.props.map.props.onBeaconSelected = feature => {
            // console.log('onBeaconSelected:feature:', feature)
            this.setState({beacon_selected: feature})
        }
    }

    componentWillUnmount() {
        this.props.map.props.setState = null
    }

    getDMS(x) {
        let d = Math.trunc(x)
        let m = Math.trunc(60 * Math.abs(x-d))
        let s = 3600 * Math.abs(x-d) - 60 * m
        return [d,m,s]
    }

    drawOwnBeacon(data) {
        console.log('draw own beacon')
        this.props.map.plotOwnUav(data)
    }

    drawOtherBeacon(data) {
        // console.debug('trafficViewer: draw other beacon: ', data)
        this.props.map.plotOtherBeacon(data)
    }

    onExtentHighlightUpdate(newExtentHighlightGeometry) {
        this.props.map.mapObject.getView().fit(newExtentHighlightGeometry)
    }

    handleLocationQueryChange(loc) {
        // remove timer
        if (this.state.locationQueryTimerId) clearTimeout(this.state.locationQueryTimerId)
        // query locations after timeout
        this.setState({
            locationQuery: loc,
            locationQueryTimerId: setTimeout( _ => {
                fetch(PUBLIC_PATH_ROOT + 'api/query_locations?querystring=' + loc, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json;charset=utf-8' },
                })
                .then(res => res.json())
                .then(res => {
                    if (res.success) {
                        let locations = res.locations
                        console.log('newMissiongPage: found locations:',res.locations)
                        this.setState({
                            foundLocations: locations,
                            locationQueryTimerId: null
                        }, _ => {
                            if (this.locationSelector) this.locationSelector.click()
                        })
                        return
                    }
                    console.log('newMissiongPage: found locations failed, reason:', res.reason)
                })
                .catch(err => {
                    console.log('newMissiongPage: found locations: error:',err)
                })
            }, 500)
        })
    }

    handleLocationClicked(loc) {
        let newLocationString = `${loc.name}, ${loc.country} ( lat: ${loc.centroid.coordinates[1]},lon: ${loc.centroid.coordinates[0]})`
        console.log('handleLocationClicked', newLocationString)
        this.setState({locationQuery: newLocationString})
        this.locationInput.value = newLocationString
        this.props.map.flyTo(...loc.centroid.coordinates, _ => {
            console.log('flyTo done.')
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.mapSize != this.props.mapSize) {
            this.props.map.updateSize()
        }
        if (prevProps.currPage != this.props.currPage) {
            this.setMapState()
        }
        this.props.map.layer_geofence.setVisible(this.state.layer_visibilities.geofence)
        this.props.map.layer_nfz.setVisible(this.state.layer_visibilities.nfz)
        this.props.map.layer_task.setVisible(this.state.layer_visibilities.task)
        this.props.map.layer_poi.setVisible(this.state.layer_visibilities.poi)
        this.props.map.layer_base.setVisible(this.state.layer_visibilities.base)
        this.props.map.layer_ais.setVisible(this.state.layer_visibilities.traffic)
        this.props.map.layer_adsb.setVisible(this.state.layer_visibilities.traffic)
        this.props.map.layer_beacon.setVisible(this.state.layer_visibilities.uxv)
        this.props.map.elevation_raster.setVisible(this.state.layer_visibilities.elevation)
        this.props.map.orthophoto_raster.setVisible(this.state.layer_visibilities.ortho)
        this.props.map.mouseWheelZoom.setActive(this.state.layer_visibilities.interactions)
        this.props.map.pinchZoom.setActive(this.state.layer_visibilities.interactions)
        this.props.map.dragPan.setActive(this.state.layer_visibilities.interactions)
    }

    render() {
        return (
            <div id='trafficViewer'>

                {/* location finder */}
                <input
                    ref={x => this.locationInput = x}
                    onFocus={ _ => this.setState({locationQuery: ''})}
                    onBlur={ _ => setTimeout(_ => this.setState({foundLocations:[]}),200)}
                    onChange={e => this.handleLocationQueryChange(e.target.value)}
                    value={this.state.locationQuery ?? ''}
                    placeholder={'🔍 search for locations...'}
                    id="location_finder"
                    type="text"
                    class='input-fullwidth'
                />
                { this.state.foundLocations.length > 0 &&
                <table class='table-normal' id='location-selector' ref={x=>this.locationSelector = x}><tbody>
                    {this.state.foundLocations.map( (x,i) =>
                    <tr key={i} onClick={ _ => this.handleLocationClicked(x)}>
                        <td>{`${x.name}, ${x.country} ( lat: ${x.centroid.coordinates[1]},lon: ${x.centroid.coordinates[0]})`}</td>
                    </tr>)}
                </tbody></table>
                }
                {/* location finder */}

                
                <div
                    ref={x=>this.mapContainer=x}
                    class={ 'mapContainer ' + this.props.mapSize + (this.state.isFullscreen ? ' fullscreen' : '')}
                />
                <div
                    class='mapTooltip'
                    style={{
                        left:`${((this.state.mapState?.event?.pageX - this.mapContainer?.getBoundingClientRect().left) || 0 )+ 10}px`,
                        top:`${((this.state.mapState?.event?.pageY - this.mapContainer?.getBoundingClientRect().top) || 0 ) + 10}px`,
                        opacity:this.state.mapState?.showTooltip ? 1:0
                    }}>
                    { this.state.mapState?.feature?.get('type') == 'adsb' && <Fragment>
                        <p>id: <code>{this.state.mapState?.feature?.getId() ?? 'id'}</code></p>
                        <p>pos: <code>{this.state.mapState?.coordinates?.[0].toFixed(3) ?? ''},{this.state.mapState?.coordinates?.[1].toFixed(3) ?? ''}</code></p>
                        <p>alt: <code>{this.state.mapState?.feature?.get('altitude') ?? ''}</code></p>
                        <p>hdg: <code>{this.state.mapState?.feature?.get('direction') ?? ''} deg</code></p>
                        <p>gs: <code>{this.state.mapState?.feature?.get('ground_speed') ?? ''} </code></p>
                    </Fragment>}
                    { this.state.mapState?.feature?.get('type') == 'ais' && <Fragment>
                        <p>id: <code>{this.state.mapState?.feature?.getId() ?? 'id'}</code></p>
                        <p>mmsi: <code>{this.state.mapState?.feature?.get('mmsi') ?? ''}</code></p>
                        <p>name: <code>{this.state.mapState?.feature?.get('name') ?? ''}</code></p>
                        <p>pos: <code>{this.state.mapState?.coordinates?.[0].toFixed(3) ?? ''},{this.state.mapState?.coordinates?.[1].toFixed(3) ?? ''}</code></p>
                        <p>hdg: <code>{this.state.mapState?.feature?.get('hdg_deg') ?? ''} deg</code></p>
                    </Fragment>}
                    { this.state.mapState?.feature?.get('user_role') == 'uxv' && <Fragment>
                        <p>id: <code>{this.state.mapState?.feature?.getId() ?? 'id'}</code></p>
                        <p>codename: <code>{this.state.mapState?.feature?.get('codename') ?? ''}</code></p>
                        <p>pos: <code>{this.state.mapState?.coordinates?.[0].toFixed(3) ?? ''},{this.state.mapState?.coordinates?.[1].toFixed(3) ?? ''}</code></p>
                        <p>alt: <code>{this.state.mapState?.feature?.getProperties().content?.alt?.toFixed(0) ?? '---'} m (msl)</code></p>
                        <p>hdg: <code>{this.state.mapState?.feature?.getProperties().content?.hdg?.toFixed(0) ?? '---'} deg</code></p>
                    </Fragment>}
                </div>
                
                <ControlPanel
                    layerVisibilities={this.state.layer_visibilities}
                    setLayerVisibilities={x => this.setState({layer_visibilities:x})}
                    isFullscreen={this.state.isFullscreen}
                />

                <div class='flex'>
                    <div class='container'>
                        <h3>Marked Location</h3>
                        <table class='table-normal'>
                            <thead><tr><th>Coordinates</th><th>Action</th></tr></thead>
                            <tbody>
                            {this.state.marked_coordinates ?
                                <tr>
                                    <td>{this.state.marked_coordinates?.[0]} <br/> {this.state.marked_coordinates?.[1]}</td>
                                    <td>
                                        <button
                                            class='button-normal'
                                            onClick={ _ => {
                                                console.log('set home position')
                                                fetch(PUBLIC_PATH_ROOT + 'api/set_home_location', {
                                                    method: 'POST',
                                                    headers: { 'Content-Type': 'application/json;charset=utf-8' },
                                                    body: JSON.stringify({
                                                        token: this.props.credentials.token,
                                                        x:this.state.marked_coordinates?.[0],
                                                        y:this.state.marked_coordinates?.[1],
                                                        srid:'4326'
                                                    })
                                                }).then(res => res.json())
                                                .then(res => {
                                                    throw res
                                                })
                                                .catch(err => {
                                                    console.log('set_home_location: error:',err)
                                                })
                                            }}
                                        >
                                            Mark as Home
                                        </button>
                                    </td>
                                </tr>
                            :
                                <tr><td colSpan='3'>No Marker</td></tr>
                            }
                            </tbody>
                        </table>
                    </div>
                
                    <div class='container'>
                        <h3>Marked UXV</h3>
                        <table class='table-normal'>
                        <thead><tr><th>Codename</th><th>Coordinates</th><th>Action</th></tr></thead>
                        <tbody>
                        {this.state.beacon_selected ?
                            <tr>
                                <td>{this.state.beacon_selected.codename}</td>
                                <td>{this.state.beacon_selected.content.lon} <br/> {this.state.beacon_selected.content.lat}</td>
                                <td>
                                    <button
                                        class='button-normal'
                                        onClick={ _ => this.props.routeUxvDetailPage(this.state.beacon_selected)}
                                    >
                                        Details
                                    </button>
                                </td>
                            </tr>
                        :
                            <tr><td colSpan='3'>No UXV Selected</td></tr>
                        }
                        </tbody>
                        </table>
                    </div>
                </div>

            </div>
        )
    }
}