// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#signalLabel {
    height: 100%;
    width: 100%;
    color: whitesmoke;
}
#streamVideoContainer {
    background-color: transparent;
}
#video {
    height: 20vh;
    width: 100%;
    border: 1px solid black;
    border:0;
    background-color: darkblue;
}

.remote-feed-video {
    width: 100%;
    border: 1px solid black;
    background-color: darkblue;
}`, "",{"version":3,"sources":["webpack://./src/components/live-view/live-view.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,iBAAiB;AACrB;AACA;IACI,6BAA6B;AACjC;AACA;IACI,YAAY;IACZ,WAAW;IACX,uBAAuB;IACvB,QAAQ;IACR,0BAA0B;AAC9B;;AAEA;IACI,WAAW;IACX,uBAAuB;IACvB,0BAA0B;AAC9B","sourcesContent":["#signalLabel {\n    height: 100%;\n    width: 100%;\n    color: whitesmoke;\n}\n#streamVideoContainer {\n    background-color: transparent;\n}\n#video {\n    height: 20vh;\n    width: 100%;\n    border: 1px solid black;\n    border:0;\n    background-color: darkblue;\n}\n\n.remote-feed-video {\n    width: 100%;\n    border: 1px solid black;\n    background-color: darkblue;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
