import React from 'react'
import '../../style.css'
import { Fragment } from 'react/cjs/react.production.min'

const goldCircle = <svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="gold"/></svg>
const greenCircle = <svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="green"/></svg>

export default class FleetStatusViewer extends React.Component {


    constructor(props) {
        super(props)
        this.state = {
            isLoadingRoles: true,
            isLoadingPayloads: true,
            isLoadingUxvs: true,
            isLoadingUsers: true,
            users: null,
            uxvs: null,
            uxv_add_token: null,
            uxv_add_token_message: null,
            uxv_add_state: 'idle',
            payloads: null,
            payload_add_token: null,
            payload_add_token_message: null,
            payload_add_state: 'idle',
            isEditing: false,
            use_cases: ['app1','app2'],
            user_roles: ['role1','role2'],
            data:{}
        }
        this.rowDict = {}
    }

    updateBeacon(beacon) {
        this.setState( s => {
            s.data[beacon.codename] = JSON.stringify(beacon)
            s.now = Date.now()
            // console.debug('s.data[beacon.codename]:',s.data[beacon.codename])
            return s
        })
    }

    parseTimestamp(str,now) {
        now = Date.now()
        let timestamp = Date.parse(str)
        let elapsedSec = Math.round((now - timestamp)/1000)
        if (elapsedSec < 20)
            return <span>
                <svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="green"/></svg>
                active, last seen {elapsedSec} seconds ago
            </span>
        else
            return <span>
                <svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="red"/></svg>
                inactive, last seen {elapsedSec} seconds ago
            </span>
    }

    centerTo(codename) {
        let data = JSON.parse(this.state.data[codename])
        let lat = data.lat ?? data.Latitude?.['Raw Data']
        let lon = data.lon ?? data.Longitude?.['Raw Data']
        if (!lat || !lon) return
        console.log('center to:', lon, lat)
        this.props.centerMapTo?.(lon,lat)
    }

    componentDidMount() {

        this.setState({
            isLoadingRoles: true,
            isLoadingPayloads: true,
            isLoadingUxvs: true,
            isLoadingUsers: true,
            users: null,
            uxvs: null,
            payloads: null,
            isEditing: false,
            use_cases: ['app1','app2'],
            user_roles: ['role1','role2'],
            data:{}
        })

        fetch(PUBLIC_PATH_ROOT + 'auth/payloads', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({ token: this.props.credentials.token })
        }).then(res => res.json())
        .then(res => {
            if (res.success) {
                let payloads = res.payloads
                this.setState({payloads: payloads})
                return
            }
            console.log('fleet: get payloads failed, reason:', res.reason)
        })
        .catch(err => {
            console.log('fleet: get payloads: error:',err)
        })

        fetch(PUBLIC_PATH_ROOT + 'auth/uxvs', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({ token: this.props.credentials.token })
        }).then(res => res.json())
        .then(res => {
            if (res.success) {
                let uxvs = res.uxvs
                this.setState({uxvs: uxvs})
                return
            }
            console.log('fleet: get uxvs failed, reason:', res.reason)
        })
        .catch(err => {
            console.log('fleet: get uxvs: error:',err)
        })
    }

    onChange = e => {
        this.setState({[e.target.name]:e.target.value})
    }

    addUxv = _ => {
        this.setState({uxv_add_state: 'waiting'}, _ => {
            fetch(PUBLIC_PATH_ROOT + 'auth/uxv_whitelist_add_user', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                body: JSON.stringify({
                    requestor_token: this.props.credentials.token,
                    uxv_token: this.state.uxv_add_token
                })
            })
            .then(res => res.json())
            .then(res => {
                if (res.success) {
                    alert('uxv add success')
                    this.setState({uxv_add_token:null})
                    return
                }
                throw res.reason
            })
            .catch( err => {
                alert('uxv add failed:'+err)
                console.log('add uxv failed:',err)
            })
            .finally( _ => {
                this.setState({uxv_add_state:'idle'})
            })
        })
    }

    addPayload = _ => {
        this.setState({payload_add_state: 'waiting'}, _ => {
            fetch(PUBLIC_PATH_ROOT + 'auth/payload_whitelist_add_user', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                body: JSON.stringify({
                    requestor_token: this.props.credentials.token,
                    payload_token: this.state.payload_add_token
                })
            })
            .then(res => res.json())
            .then(res => {
                if (res.success) {
                    alert('payload add success')
                    this.setState({payload_add_token:null})
                    return
                }
                throw res.reason
            })
            .catch( err => {
                alert('payload add failed:'+err)
                console.log('add payload failed:',err)
            })
            .finally( _ => {
                this.setState({payload_add_state:'idle'})
            })
        })
    }

    render() {
        return (
            <div id='fleetStatusViewer'>

            <h3>UXVs</h3>
            { !! this.state.uxvs ?
                <table class='table-card full-width'>
                <thead>
                    <tr><td colSpan='3'>
                    { this.state.uxv_add_state == 'idle' &&
                        <button
                            class='button-large'
                            onClick={_ => this.setState({uxv_add_state:'ready',uxv_add_token:null})}
                        >
                            Add UXV
                        </button>
                    }
                    </td></tr>
                    <tr>
                        <th>Codename</th>
                        <th>UXV Type</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.uxvs.length ? this.state.uxvs
                    .sort( (a,b) => {
                        let codename_order = a.codename < b.codename ? -1 : 1
                        // let has_beacon_order = (this.props.beacon[a.codename] ? 0 : 1) - this.props.beacon[b.codename] ? 0 : 1
                        return codename_order
                    })
                    .map(x => <Fragment key={x.id}>
                        <tr 
                            onClick={ _ => this.setState({focused_uxv_id: this.state.focused_uxv_id == x.id ? null : x.id})}
                        >
                            <td><code class='username'>{x.codename}</code></td>
                            <td>{x.uxv_type}</td>
                            <td> {
                                this.props.beacon[x.codename] ?
                                <span>{greenCircle} active </span>
                                :
                                <span> {goldCircle} waiting for data </span>
                            } </td>
                        </tr>
                        { this.state.focused_uxv_id == x.id &&
                            <tr
                                class='detail'
                            ><td colSpan='3'>
                                Beacon: {JSON.stringify(this.props.beacon[x.codename])}
                                <br/>
                                <button
                                    class='button-normal'
                                    onClick={ _ => this.props.routeUxvDetailPage(x)}
                                >Detail</button>
                            </td></tr>
                        }
                    </Fragment>)
                    :
                    <tr><td colSpan='3'>You have no accessible UXVs.</td></tr>
                    }
                    <tr><td colSpan='3'>
                    { this.state.uxv_add_state == 'ready' &&
                        <div>
                            <input
                                type='text'
                                name='uxv_add_token'
                                placeholder='paste the token here'
                                onChange={this.onChange}
                                value={this.state.uxv_add_token ?? ''}
                            />
                            <button
                                class='button-large'
                                onClick={this.addUxv}
                                disabled={!this.state.uxv_add_token}
                            >
                                Add UXV using Token
                            </button>
                            <button
                                class='button-large'
                                onClick={ _ => this.setState({uxv_add_state:'idle'})}
                            >
                                Cancel
                            </button>
                        </div>
                    }
                    </td></tr>
                </tbody>
                </table>
                :
                <div> Cannot Load UXV List </div>
            }


            <h3>Payloads</h3>
            { !! this.state.payloads ?
                <table class='table-card full-width'>
                    <thead>
                        <tr><td colSpan='3'>{ this.state.payload_add_state == 'idle' &&
                            <button
                                class='button-large'
                                onClick={_ => this.setState({payload_add_state:'ready',payload_add_token:null})}
                            >
                                Add Payload
                            </button>
                        }</td></tr>
                        <tr>
                            <th>Codename</th>
                            <th>Payload Type</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.payloads.length ? this.state.payloads.map(x => (
                            <tr
                                key={x.id}
                                onClick={ _ => this.props.routePayloadDetailPage(x)}
                            >
                                <td><code class='username'>{x.codename}</code></td>
                                <td>{x.payload_type}</td>
                                <td> {this.state.data[x.codename] ?
                                    this.parseTimestamp(JSON.parse(this.state.data[x.codename])['Time'],this.props.now)
                                    :
                                    <span>
                                        <svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="gold"/></svg> waiting for data
                                    </span>}
                                    <p>{JSON.stringify(this.props.beacon[x.id])}</p>
                                </td>
                            </tr>
                        ))
                        :
                        <tr><td colSpan='3'>You have no accessible Payloads.</td></tr>
                        }
                        <tr><td colSpan='3'>
                        { this.state.payload_add_state == 'ready' &&
                            <div>
                                <input
                                    type='text'
                                    name='payload_add_token'
                                    placeholder='paste the token here'
                                    onChange={this.onChange}
                                    value={this.state.payload_add_token ?? ''}
                                />
                                <button
                                    class='button-large'
                                    onClick={this.addPayload}
                                    disabled={!this.state.payload_add_token}
                                >
                                    Add Payload using Token
                                </button>
                                <button
                                    class='button-large'
                                    onClick={ _ => this.setState({payload_add_state:'idle'})}
                                >
                                    Cancel
                                </button>
                            </div>
                        }
                        </td></tr>
                    </tbody>
                </table>
                :
                <div> Cannot Load Payload List </div>
            }
            </div>
        )
    }
}