import React, { Fragment } from 'react'
import './uxvDetails.css'
import '../../style.css'
import * as d3 from 'd3'
import * as AmqpComponent from '../amqp/amqp'
import VideoRoomComponent from '../live-view/videoroom'
import * as MissionEditor from '../missionEditor/missionEditor'
import * as MissionComponent from '../mission/mission'


const isJsonString = str => {
    if (!str) return false
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export class LeftPanel extends React.Component {
    constructor(props) {
        super(props)
    }

    render = _ =>
        <Fragment>
            <h2>UXV Details for <code class='username'>{this.props.uxv.codename}</code></h2>
            <div class={this.props.currPage == 'about' ? 'selected' : ''}
                onClick={ _ => this.props.setCurrPage('about')}
            ><p>About</p></div>
            <div class={this.props.currPage == 'live' ? 'selected' : ''}
                onClick={ _ => this.props.setCurrPage('live')}
            ><p>Live Data { ( this.props.amqpState != 'disconnected' && this.props.currData) ?
                <svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="green"/></svg>
                :
                this.props.amqpState == 'disconnected' ?
                    <svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="red"/></svg>
                    :
                    <svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="gold"/></svg>
                }​
            </p></div>
            <div class={this.props.currPage == 'historic' ? 'selected' : ''}
                onClick={ _ => this.props.setCurrPage('historic')}
            ><p>Historic Data</p></div>
            <div class={this.props.currPage == 'amqp' ? 'selected' : ''}
                onClick={ _ => this.props.setCurrPage('amqp')}
            ><p>Amqp Status</p></div>
            <div class={this.props.currPage == 'settings' ? 'selected' : ''}
                onClick={ _ => this.props.setCurrPage('settings')}
            ><p>UXV Settings</p></div>
            <div class={this.props.currPage == 'upload' ? 'selected' : ''}
                onClick={ _ => this.props.setCurrPage('upload')}
            ><p>Upload Rawdata</p></div>
            <div onClick={ _ => this.props.routePreviousPage()}><p>Back</p></div>
        </Fragment>
}

export class RightPanel extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            visibilities:{},
            currData:null,
            dataCount:null,
            whitelist:null,
            whitelist_state:'idle',
            whitelist_token:null,
            rawdata_formats:null,
            temp_rawdata_format_content:null,
            temp_rawdata_format_description:null,
            rawdata_format_state:'idle',
            selected_upload_rawdata_format_id: null,
            temp_upload_rawdatas:null,
            temp_rawdata_timestamp_conversion_script:null,
            temp_rawdata_sample: null,
            highlighted_timerange: null,
            historic_rawdata_statistics: null,
            historic_rawdata_query_date_range:{start:null,end:null},
            historic_rawdatas:null,
            historic_rawdatas_filtered:null,
            historic_rawdatas_loading: false,
            show_historic_rawdatas:false,
            historic_rawdata_pagination_id:1,
            historic_chart_visibilities: null,
            historic_chart_layer_visibilities: {heatmap:true,idw:false},
            conversionFormula:null
        }
        this.datas = []
        console.log('uxv detail page: codename:', this.props.uxv)
        window.history.pushState(null,`/uxv_details/${this.props.uxv.id}`,`/uxv_details/${this.props.uxv.id}`)
        window.onpopstate = _ => this.props.routePreviousPage()
        this.props.setCurrPage('live')
        // this.props.startConsumeBeacon()
    }

    updateCurrPage = _ => {
        this.props.setCurrPage(this.props.currPage)
    }

    setCurrPage = page => {
        this.setState({currPage:page},this.updateCurrPage)
    }

    componentDidMount() {
        console.log('uxv:', this.props.uxv)
        this.props.map.layer_beacon.setVisible(true)
        this.datas = []
        fetch(PUBLIC_PATH_ROOT + 'auth/uxv_whitelist',{
            method: 'POST',
            headers: {'Content-Type': 'application/jsoncharset=utf-8'},
            body: JSON.stringify({token:this.props.credentials.token})
        })
        .then( res => res.json())
        .then( rs => {
            if (rs.success) {
                this.setState({whitelist: rs.whitelist})
            }
            else throw 'request failed'
        })
        .catch( err => {
            console.log('uxv detail page: request failed:',err)
        })
        // data formats
        // this.fetchDataFormats()
        fetch(PUBLIC_PATH_ROOT + `api/get_uxv_missions/${this.props.uxv.id}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({ token: this.props.credentials.token })
        })
        .then(res => res.json())
        .then(res => {
            if (!res.success) throw res.reason
            console.log('mission:',res.mission)
            this.setState({
                currPage: 'general',
                mission: res.mission,
            }, this.updateCurrPage)

            res.mission.geofences?.map( geofence => {
                this.map.plotGeofence(geofence)
            })

            res.mission.baseitems?.map( baseitem => {
                this.map.plotBaseitem(baseitem)
            })

            res.mission.itinerary?.map( task => {
                this.map.plotTask(task)
            })

            res.mission.routes?.map( route => {
                this.map.plotRoute(route)
            })

            if (res.mission.extent)
                this.map.zoomTo(res.mission.extent)
        })
        .catch(err => {
            console.log('get mission error0:',err)
        })
    }

    componentDidUpdate(prevProps, _) {
        if (prevProps.currPage != 'historic' && this.props.currPage == 'historic') {
            this.fetchHistoricDataStatistics()
        }
    }

    fetchDataFormats() {
        fetch(PUBLIC_PATH_ROOT + 'api/uxv_rawdata_formats',{
            method: 'POST',
            headers: {'Content-Type': 'application/jsoncharset=utf-8'},
            body: JSON.stringify({token:this.props.credentials.token,uxv_id: this.props.uxv.id})
        })
        .then( res => res.json())
        .then( rs => {
            if (rs.success) {
                this.setState({rawdata_formats: rs.uxv_rawdata_formats})
            }
            else throw 'request failed'
        })
        .catch( err => {
            console.log('uxv detail page: uxv_rawdata_formats: failed:',err)
        })
    }

    fetchHistoricDataStatistics(){
        fetch(PUBLIC_PATH_ROOT + 'api/uxv_rawdata_statistics',{
            method: 'POST',
            headers: {'Content-Type': 'application/jsoncharset=utf-8'},
            body: JSON.stringify({token:this.props.credentials.token,uxv_id: this.props.uxv.id})
        })
        .then( res => res.json())
        .then( rs => {
            if (! rs.success) throw 'request failed'
            let first = rs.results?.[0].date_trunc
            let last = rs.results?.[rs.results.length-1].date_trunc
            this.setState({historic_rawdata_statistics: rs.results, historic_rawdata_query_date_range:{start:first,end:last}})
            this.plotStatsChart(rs.results)
        })
        .catch( err => {
            console.log('uxv detail page: uxv_rawdata_statistics: failed:',err)
        })
    }

    plotStatsChart(data) {

        // init the basic DOM elements such as svg, axis, trendline
        let chartArea = d3.select(this.statsChart).append('div')
        .attr('class','statsChartArea')
        .style('width','100%')
        .style('height','100%')

        let chartAreaRect = chartArea.node().getBoundingClientRect()
        let margin = {top: 20, right: 80, bottom: 30, left: 60}
        let plotAreaHeight = chartAreaRect.height - margin.top - margin.bottom
        let plotAreaWidth = chartAreaRect.width - margin.left - margin.right

        let getX = x => new Date(x['date_trunc']) // data -> value
        let getY = x => Number(x['count'])
        let scaleX = d3.scaleTime()
        .range([margin.left,margin.left + plotAreaWidth])
        let drawXAxis = d3.axisBottom(scaleX).ticks(6)
        let scaleY = d3.scaleLinear()
        .range([margin.top + plotAreaHeight, margin.top])
        let drawYAxis = d3.axisLeft(scaleY).ticks(6)

        // add the graph canvas to the body of the webpage
        let svg = chartArea.append('svg')
        .attr('width','100%')
        .attr('height','100%')
        // draw x axis
        svg.append("g")
        .attr("class", "xAxis")
        .attr("transform", `translate(0,${margin.top + plotAreaHeight})`)
        .call(drawXAxis)
        // draw y axis
        svg.append("g")
        .attr("class", "yAxis")
        .attr("transform", `translate(${margin.left},0)`)
        .call(drawYAxis)

        // draw graph
        // update domain
        scaleX.domain([d3.min(data, getX), d3.max(data, getX)])
        scaleY.domain([d3.min(data, getY), d3.max(data, getY)])

        //redraw axis
        svg.select('.xAxis').call(drawXAxis)
        svg.select('.yAxis').call(drawYAxis)

        // draw data points
        svg.selectAll('.dot')
        .data(data, x => x['date_trunc']) // use time as the data key
        .join(
            enter => enter.append('circle')
                .attr('class', 'dot ')
                .attr('r', 2)
                .attr('fill', 'blue'),
            update => update,
            exit => exit
                .remove()
        )
        .attr('cx', x => scaleX(getX(x)))
        .attr('cy', x => scaleY(getY(x)))

        // draw trendline function
        let drawLine = d3.line()
        .defined(x => !!getY(x)) // only plot points with valid y values
        .x(x => scaleX(getX(x)))
        .y(x => scaleY(getY(x)))

        // add brush
        let brushFunc = d3.brushX()
        .extent( [ [margin.left,margin.top], [margin.left+plotAreaWidth,margin.top+plotAreaHeight] ] )
        .on("end", e => {
            if (!e.selection) return
            let selectedTimeRange = e.selection.map(x=> scaleX.invert(x))
            console.log('selected range:',selectedTimeRange)
            selectedTimeRange[1].setDate(selectedTimeRange[1].getDate()+1)
            this.setState({highlighted_timerange:selectedTimeRange})
        })
        // draw brush
        svg.selectAll('.brush')
        .data([true])
        .enter()
        .append('g')
        .attr("class", "brush")
        .call(brushFunc)

        svg.append('path')
        .attr('class','trendline')
        .attr('d', drawLine(data))
        .style('stroke-width', 1)
        .style('stroke', 'blue')
        .style('fill', 'none')

    }


    updateOtherBeacon(beacon) {
        if ( beacon.codename != this.props.uxv.codename) {
            return
        }
        console.log('update beacon:', beacon.codename, this.props.uxv.codename)
        this.setState({beacon:beacon, currData:beacon})
        this.props.setCurrData(beacon)
        this.props.map.plotOtherBeacon(beacon)
    }

    parseTimestamp(str) {
        let timestamp = new Date(str)
        return timestamp.toISOString()
    }

    resetSensorVisibilities() {
        let sensorTypes = Object.entries(JSON.parse(this.state.currData)).filter(x => typeof x[1] == 'object' && 'Raw Data' in x[1]).map(x => x[0])
        let checked = Object.fromEntries([...this.sensorTable.getElementsByClassName('visibilityCheckbox')].map(x => [x.name,x.checked]))
        this.setState({visibilities:checked}, _ => {
            this.allChart.updateGraph(sensorTypes, this.datas, this.state.visibilities)
        })
    }

    viewOne(sensorType) {
        let obj = Object.fromEntries(Object.entries(this.state.visibilities).map( x => x[0]==sensorType ? [x[0],true]: [x[0],false]))
        let sensorTypes = Object.entries(JSON.parse(this.state.currData)).filter(x => typeof x[1] == 'object' && 'Raw Data' in x[1]).map(x => x[0])
        this.setState({visibilities:obj}, _ => {
            console.log('updating graph with data:', this.datas)
            this.allChart.updateGraph(sensorTypes, this.datas, obj)
        })
    }

    removeUserFromWhitelist = user_id => {
        // fetch()
    }

    addUserToWhitelist = _ => {
        this.setState({whitelist_state:'waiting', whitelist_token: null}, _ =>{
            fetch(PUBLIC_PATH_ROOT + 'auth/share_uxv',{
                method: 'POST',
                headers: {'Content-Type': 'application/jsoncharset=utf-8'},
                body: JSON.stringify({token:this.props.credentials.token})
            })
            .then( res => res.json())
            .then( res => {
                if (res.success) {
                    this.setState({whitelist_token:res.token})
                } else throw res.reason
            })
            .catch( err => {
                console.log('share uxv failed:',err)
                alert('share uxv failed:' + err)
            })
            .finally( _ => {
                this.setState({whitelist_state:'idle'})
            })
        })
    }

    addDataFormat = _ => {
        console.log('adding data format...')
        this.setState({rawdata_format_state:'waiting'}, _ =>{
            fetch(PUBLIC_PATH_ROOT + 'api/create_uxv_rawdata_format',{
                method: 'POST',
                headers: {'Content-Type': 'application/jsoncharset=utf-8'},
                body: JSON.stringify({
                    token:this.props.credentials.token,
                    rawdata_format_content:this.state.temp_rawdata_format_content,
                    rawdata_format_description:this.state.temp_rawdata_format_description
                })
            })
            .then(res => res.json())
            .then(res => {
                if (res.success) {
                    console.log('success')
                    return this.fetchDataFormats()
                } else throw res.reason
            })
            .catch( err => {
                console.log('create data format failed:',err)
                alert('create data format failed:'+err)
            })
            .finally( _ => {
                this.setState({rawdata_format_state:'idle'})
            })
        })
    }

    onChange = e => {
        this.setState({ [e.target.name] : e.target.value })
    }

    searchHistoricRawdata = _ => {
        fetch(PUBLIC_PATH_ROOT + 'api/get_all_uxv_rawdata_in_timerange', {
            method: 'POST',
            headers: {'Content-Type': 'application/jsoncharset=utf-8'},
            body: JSON.stringify({
                token:this.props.credentials.token,
                uxv_id: this.props.uxv.id,
                start: this.state.historic_rawdata_query_date_range.start,
                end: this.state.historic_rawdata_query_date_range.end,
                page_id: this.state.historic_rawdata_pagination_id
            })
        })
        .then( res => res.json())
        .then( res => {
            if (res.success) {
                console.log('results:',res.results)
                this.setState({historic_rawdatas:res.results})
            } else throw res.reason
        })
        .catch( err => {
            console.log('search historic rawdata failed:',err)
            alert('search historic rawdata failed:' + err)
        })
    }

    fetchHistoricRawdata = _ => {
        let start = new Date(this.state.highlighted_timerange?.[0] ?? this.state.historic_rawdata_query_date_range.start)
        let end = new Date(this.state.highlighted_timerange?.[1] ?? this.state.historic_rawdata_query_date_range.end)
        end.setDate(end.getDate()+1)
        this.setState({historic_rawdatas_loading: true}, _ => {
            fetch(PUBLIC_PATH_ROOT + 'api/get_all_uxv_rawdata_in_timerange', {
                method: 'POST',
                headers: {'Content-Type': 'application/jsoncharset=utf-8'},
                body: JSON.stringify({
                    token:this.props.credentials.token,
                    uxv_id: this.props.uxv.id,
                    start: start.toISOString(),
                    end: end.toISOString()
                })
            })
            .then( res => res.json())
            .then( res => {
                if (!res.success) throw res.reason
                let historic_rawdatas = res.results.sort( (a,b) => a.timestamp.localeCompare(b.timestamp))
                let sensorTypes = Object.keys(historic_rawdatas[0].content)
                let visibilities = {}
                sensorTypes.map( x => visibilities[x] = true)
                this.setState({
                    historic_rawdatas:historic_rawdatas,
                    historic_chart_visibilities:visibilities
                })
            })
            .catch( err => {
                console.log('get all historic rawdata failed:',err)
                alert('get all historic rawdata failed:' + err)
            })
            .finally( _ => this.setState({historic_rawdatas_loading:false}))
        })
    }

    uploadRawdatas = _ => {
        console.log('rawdata_format_id:',this.state.selected_upload_rawdata_format_id)
        fetch(PUBLIC_PATH_ROOT + 'api/upload_uxv_rawdata', {
            method: 'POST',
            headers: {'Content-Type': 'application/jsoncharset=utf-8'},
            body: JSON.stringify({
                token:this.props.credentials.token,
                rawdata_format_id: this.state.selected_upload_rawdata_format_id,
                rawdatas: this.state.temp_upload_rawdatas
            })
        })
        .then( res => res.json())
        .then( res => {
            if (res.success) {
                this.setState({whitelist_token:res.token})
            } else throw res.reason
        })
        .catch( err => {
            console.log('share uxv failed:',err)
            alert('share uxv failed:' + err)
        })
        .finally( _ => {
            this.setState({whitelist_state:'idle'})
        })
    }

    convertData() {
        console.log('converting...')
        console.log('formula:',this.state.conversionFormula)
        let conversionFunction = new Function('x','"use strict"; return ' + this.state.conversionFormula)
        console.log('conversionFunction:',conversionFunction)
        this.historicChart.updateGraph(['NO','SO','NO2','SO2','CO2','pm25','pm10'],this.state.historic_rawdatas.map(x => conversionFunction(x)).sort( (a,b) => a.Time.localeCompare(b.Time)),{NO:true,SO:true,NO2:true,SO2:true,CO2:true,pm25:true,pm10:true})
    }

    convertTimestamp(sampleJson, conversionScript) {
        try {
            let sample = JSON.parse(sampleJson)
            let func = new Function('x','"use strict"; return ' + conversionScript)
            let output = JSON.stringify(func(sample))
            return output
        } catch (e) {
            return 'Error: ' + e
        }
    }

    render() {
        return (
            <div id='uxvDetailPage'>
                {this.props.currPage == 'about' && <div>
                    <h3>Uxv Information</h3>
                    <table class='table-normal full-width'><tbody>
                        <tr><th>Codename</th><td><code class='username'>{this.props.uxv.codename}</code></td></tr>
                        <tr><th>Manufacturer</th><td>{this.props.uxv.manufacturer}</td></tr>
                        <tr><th>Uxv Type</th><td>{this.props.uxv.uxv_type}</td></tr>
                        <tr><th>Email</th><td>{this.props.uxv.email}</td></tr>
                        <tr><th>Phone</th><td>{this.props.uxv.phone}</td></tr>
                        <tr><th>Registered</th><td>{this.props.uxv.created_at}</td></tr>
                    </tbody></table>
                </div>}

                {this.props.currPage == 'live' && <div>
                    <AmqpComponent.Detail
                        amqpState={this.props.amqpState}
                        amqpMsg={this.props.amqpMsg}
                        beacon={
                            Object.fromEntries(
                                Object.entries(this.props.beacon)
                                .filter( x => x[0] == this.props.uxv.codename)
                            )
                        }
                        connectAmqpServer={ this.props.connectAmqpServer }
                    />
                    <h3 ref={x => this.liveStatusSection = x}>Live Status</h3>
                    { this.state.currData ?
                        <p><svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="green"/></svg> active</p>
                        :
                        this.props.amqpState == 'disconnected' ?
                            <p><svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="red"/></svg> disconnected</p>
                            :
                            <p><svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="yellow"/></svg> waiting for data</p>
                    }

                    {/* <div
                        ref={x=> {
                            this.mapContainer=x
                            this.props.map.map.setTarget(this.mapContainer)
                            this.props.map.updateSize()
                        }}
                        class={ 'mapContainer ' + (this.props.mapSize ?? 'medium') + (this.state.isFullscreen ? ' fullscreen' : '')}
                    /> */}

                    <h3>Current Check-list</h3>
                    <table class='table-normal'>

                    </table>

                    <h3>Current Beacon Values</h3>
                    { this.state.beacon ?
                        <table class='table-normal full-width'>
                            <thead>
                            <tr><th>Lat</th><th>Lon</th></tr>
                            </thead>
                            <tbody>
                            <tr><td>{this.state.beacon.content.lat}</td><td>{this.state.beacon.content.lon}</td></tr>
                            </tbody>
                        </table>
                        :
                        <p>No Data Availilable</p>
                    }

                    <div>
                        <MissionEditor.Viewer
                            map={this.props.map}
                            mapSize={'medium'}
                            ref={x=> this.missionEditor = x}
                            readOnly={false}
                            editMode={true}
                            onState={state => console.log('MissionEditor: state:',state)}
                            currPage='itinerary'
                            setTasks={tasks => this.handleTaskSet(tasks)}
                            onTaskSelected={task => this.setState({selected_task_id: task.get('id')})}
                            onTaskDeselected={ _ => this.setState({selected_task_id: null})}
                        />

                        <h3>Deployed Mission</h3>
                        <MissionComponent.Missionlist
                            api_name={`get_deployed_uxv_missions/${this.props.uxv.id}`}
                            credentials={this.props.credentials}
                            routeMissionDetailPage={mission_id => this.props.routeMissionDetailPage(mission_id)}
                            routeNewMissionPage={this.props.routeNewMissionPage}
                        />
                    </div>
                    
                    <button
                        class='button-normal'
                        onClick={ _ => {
                            console.log('creating uxv mission...')
                            fetch(PUBLIC_PATH_ROOT + `api/create_uxv_mission/${this.props.uxv.id}`, {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                                body: JSON.stringify({
                                    token: this.props.credentials.token,
                                    use_case_id: e.target.use_case_id.value,
                                    name: e.target.name.value,
                                    description: e.target.description.value
                                })
                            }).then(res => res.json())
                            .then(res => {
                                if (res.success) {
                                    let mission = res.mission
                                    console.log('newMissiongPage: mission created:',mission)
                                    this.props.routeMissionDetailPage(mission.id)
                                }
                                console.log('newMissiongPage: mission create failed, reason:', res.reason)
                            })
                            .catch(err => {
                                console.log('newMissiongPage: mission create error:',err)
                            })
                        }}
                    >Create Solo Mission</button>

                    <h3>Interrupt Mission</h3>
                    <button
                        class='button-normal'
                    >
                        Interrupt Mission
                    </button>
                    <button
                        class='button-normal'
                    >Edit Waypoints</button>
                    <button
                        class='button-normal'
                    >Send Waypoints</button>


                    <button
                        class='button-normal'
                    >Go to Point</button>
                    <button
                        class='button-normal'
                    >Hold Position</button>
                    <button
                        class='button-normal'
                    >Update Waypoints</button>
                    <button
                        class='button-normal'
                    >Return to Home</button>
                    <button
                        class='button-normal'
                    >Land</button>

                    <h3>Video Stream</h3>
                    <button
                        class='button-normal'
                        onClick={ _ => {
                            fetch(PUBLIC_PATH_ROOT + 'live/uxv_start_stream',{
                                method: 'POST',
                                headers: {'Content-Type': 'application/jsoncharset=utf-8'},
                                body: JSON.stringify({token:this.props.credentials.token,codename:this.props.uxv.codename})
                            })
                            .then( res => res.json())
                            .then( rs => {
                                if (rs.success) {
                                    console.log('start stream request sent.')
                                }
                                else throw 'request failed'
                            })
                            .catch( err => {
                                console.log('uxv detail page: request failed:',err)
                            })
                        }}
                    >Request On-board Streaming</button>
                    <button
                        class='button-normal'
                        onClick={ _ => {
                            fetch(PUBLIC_PATH_ROOT + 'live/uxv_start_stream_bbox',{
                                method: 'POST',
                                headers: {'Content-Type': 'application/jsoncharset=utf-8'},
                                body: JSON.stringify({token:this.props.credentials.token,codename:this.props.uxv.codename})
                            })
                            .then( res => res.json())
                            .then( rs => {
                                if (rs.success) {
                                    console.log('start stream request sent.')
                                }
                                else throw 'request failed'
                            })
                            .catch( err => {
                                console.log('uxv detail page: request failed:',err)
                            })
                        }}
                    >Request On-board Streaming with B-Box</button>
                    <VideoRoomComponent
                        credentials={this.props.credentials}
                        janusRoomId={`uxv_${this.props.uxv.id}`}
                        janusUsername={this.props.credentials.username ||
                            this.props.credentials.codename
                        }
                    />

                </div>}


                { this.props.currPage=='settings' && <div>
                    <h3 ref={x=>this.settingsSection=x}>UXV Settings</h3>
                    <h3>Conversion Formula</h3>
                    <textarea
                        type='text'
                        name='conversionFormula'
                        value={this.state.conversionFormula ?? ''}
                        onChange={this.onChange}
                    />

                    <div>
                        <h3>Whitelist</h3>
                        <table class='table-normal full-width'>
                            <thead><tr><th>Usernames</th><th>Actions</th></tr></thead>
                            <tbody>
                            { this.state.whitelist != null ?
                                this.state.whitelist.length ?
                                    this.state.whitelist.map( x =>
                                        <tr key={x.id}>
                                            <td>{x.username}</td>
                                            <td><button className='button-normal' onClick={this.removeUserFromWhitelist}>Remove</button></td>
                                        </tr>
                                    )
                                :
                                <tr><td colSpan='2'>Whitelist is empty</td></tr>
                            :
                                <tr><td colSpan='2'>Whitelist not accessible</td></tr>
                            }
                            </tbody>
                        </table>

                        { this.state.whitelist_state =='idle' && this.state.whitelist_token == null &&
                            <button
                                class='button-large'
                                onClick={this.addUserToWhitelist}
                            >
                                Add User to Whitelist
                            </button>
                        }
                        { this.state.whitelist_token &&
                            <div>
                                <input ref={x=>this.whitelist_token = x} readOnly value={this.state.whitelist_token}/>
                                <p>share this token to another user to access this uxv.</p>
                                <button
                                    class='button-large'
                                    onClick={ _ => {
                                        this.whitelist_token.focus()
                                        this.whitelist_token.select()
                                        if (document.execCommand('copy')) {
                                            alert('token copied to clipboard')
                                        }
                                    }}
                                >
                                    Copy Token to Clipboard
                                </button>
                                <button
                                    class='button-large'
                                    onClick={ _ => this.setState({whitelist_state:'idle',whitelist_token:null})}
                                >
                                    Finish
                                </button>
                            </div>
                        }

                    </div>
                </div>}


                {this.props.currPage=='upload' && <div>
                    <h3>Rawdata Formats</h3>
                    <table class='table-normal full-width'>
                        <thead>
                            <tr><th>ID</th><th>Content</th><th>Description</th></tr>
                        </thead>
                        <tbody>
                        {   this.state.rawdata_formats != null ?
                            this.state.rawdata_formats.length ?
                                this.state.rawdata_formats.map( (x,i) =>
                                    <tr key={i}><td>{x.id}</td><td>{x.content.join(',')}</td><td>{x.description}</td></tr>
                                )
                            :
                                <tr><td colSpan='3'>Rawdata Format List is Empty</td></tr>
                        :
                            <tr><td colSpan='3'>Rawdata Formats Not Accesible</td></tr>
                        }
                        </tbody>
                    </table>
                    { this.state.rawdata_format_state == 'ready' && <div>
                        <label for='temp_rawdata_format_content'>Specify Rawdata Format. This must be a JSON array of strings that are identical to the keys of your data.</label>
                        <input
                            name='temp_rawdata_format_content'
                            placeholder='["Temperature","Pressure"]'
                            type='text'
                            value={this.state.temp_rawdata_format_content ?? ''}
                            onChange={this.onChange}
                        />
                        <label for='temp_rawdata_timestamp_conversion_script'>JavaScript code that returns the timestamp from rawdata</label>
                        <textarea
                            name='temp_rawdata_timestamp_conversion_script'
                            placeholder='x.Time'
                            value={this.state.temp_rawdata_timestamp_conversion_script ?? ''}
                            onChange={this.onChange}
                        />
                        <label for='temp_rawdata_format_description'>Description or Remarks</label>
                        <textarea
                            name='temp_rawdata_format_description'
                            placeholder='temperature sensor changed to brand B'
                            value={this.state.temp_rawdata_format_description ?? ''}
                            onChange={this.onChange}
                        />

                        <label for='temp_rawdata_sample'>Paste your Rawdata Sample JSON object here to test:</label>
                        <textarea
                            name='temp_rawdata_sample'
                            placeholder='paste your json object here'
                            value={this.state.temp_rawdata_sample ?? ''}
                            onChange={this.onChange}
                        />
                        <p>Timestamp parsed: {this.convertTimestamp(this.state.temp_rawdata_sample,this.state.temp_rawdata_timestamp_conversion_script)}</p>
                        <p>Valid: { isJsonString(this.state.temp_rawdata_format_content) && JSON.parse(this.state.temp_rawdata_format_content).every?.( x=> typeof x == 'string' && !!x ) && JSON.parse(this.state.temp_rawdata_format_content).length > 0 ? 'true' : 'false'}</p>
                        <button
                            class='button-large'
                            disabled={this.state.rawdata_format_state != 'ready' || !this.state.temp_rawdata_format_content}
                            onClick={this.addDataFormat}
                        >
                            Create Rawdata Format
                        </button>
                        <button
                            class='button-large'
                            onClick={ _ => this.setState({rawdata_format_state:'idle',temp_rawdata_format_content:null})}
                        >
                            Cancel
                        </button>
                    </div>}
                    { this.state.rawdata_format_state == 'idle' && this.props.credentials.user_role == 'uxv' &&
                        <button
                            class='button-large'
                            onClick={ _ => this.setState({rawdata_format_state:'ready'})}
                        >
                            Add Rawdata Format
                        </button>
                    }

                    { this.props.credentials.user_role == 'uxv' && <div>
                        <h3>Upload Raw Data</h3>
                        <label for='selected_upload_rawdata_format_id'>Select Rawdata Format</label>
                        <select
                            name='selected_upload_rawdata_format_id'
                            value={this.state.selected_upload_rawdata_format_id ?? ''}
                            onChange={this.onChange}
                        >
                            <option value=''>Select Rawdata Format</option>
                            { this.state.rawdata_formats && this.state.rawdata_formats.map( (x,i) =>
                                <option key={i} value={x.id}>{x.id + ':' + x.content}</option>
                            )}
                        </select>
                        { this.state.selected_upload_rawdata_format_id != null && <div>
                            <label for='temp_upload_rawdatas'>Rawdatas</label>
                            <textarea
                                name='temp_upload_rawdatas'
                                onChange={this.onChange}
                                placeholder='[{"timestamp":"2021-06-14T01:30:39.611Z","content":{"a":123}},{"timestamp":"2021-06-14T01:31:39.611Z","content":{"a":234}}]'
                            />
                            <button
                                onClick={this.uploadRawdatas}
                                className='button-large'
                                disabled={!this.state.selected_upload_rawdata_format_id || !isJsonString(this.state.temp_upload_rawdatas)}
                            >Upload Rawdata</button>
                        </div> }
                    </div>}
                </div>}
            </div>
        )
    }
}
