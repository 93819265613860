import React from 'react'
import '../../style.css'
import * as MissionEditor from '../missionEditor/missionEditor'
import { Fragment } from 'react/cjs/react.production.min'

export class GeofenceCreator extends React.Component {

    constructor(props) {
        super(props)
        if (this.props.existing_geofence) console.log('existing geofence:',this.props.existing_geofence)
        this.state = {
            geofence_type: this.props.currPage == 'edit_geofence' ? 'custom' :  null,
            geofence_name: this.props.existing_geofence?.name ?? null,
            geofence_callsign: this.props.existing_geofence?.callsign ?? null,
            geofence_description: this.props.existing_geofence?.description ?? null,
            lonlat: this.props.existing_geofence?.centroid.coordinates ?? null
        }
        // this.props.map.plotTempGeofence(this.props.existing_geofence.polygon)
    }

    componentDidMount() {
    }

    deleteGeofenceitem = geofence_id => {
        console.log('delete geofence...')
        fetch(PUBLIC_PATH_ROOT + 'api/delete_geofence',{
            method: 'POST',
            body: JSON.stringify({token:this.props.credentials.token,geofence_id:geofence_id})
        })
        .then(rs=>rs.json())
        .then(rs=>{
            if (!rs.success) throw rs.reason
            this.setState({existing_geofence:null}, _ => this.props.routePreviousPage())
        })
        .catch(err=>console.log('err:',err))
    }


    updateGeofenceitem = geofence_id => {
        console.log('updating geofence...')
        Promise.resolve()
        .then( _ => {
            let polygon = this.props.map.getGeofenceGeoJson(geofence_id)
            if (!polygon) throw 'geoJson missing'
            return fetch(PUBLIC_PATH_ROOT + 'api/update_geofence',{
                method:'POST',
                body: JSON.stringify({
                    token:this.props.credentials.token,
                    geofence_id: geofence_id,
                    polygon: polygon
                })
            })
        })
        .then(rs=>rs.json())
        .then(rs=>{
            if (!rs.success) throw rs.reason
            this.props.routePreviousPage()
        })
        .catch(err=>console.log('err:',err))
    }

    createGeofenceitem = _ => {
        console.log('creating geofence...')
        Promise.resolve()
        .then( _ => {
            let polygon
            if (this.state.geofence_type == 'custom') {
                polygon = this.props.map.getTempGeofenceitemGeoJson()
            }
            if (this.state.geofence_type == 'existing') {
                polygon = this.props.map.getSelectedGeofenceGeoJson()
            }
            if (!polygon) throw 'geoJson missing'
            return fetch(PUBLIC_PATH_ROOT + 'api/create_geofence',{
                method:'POST',
                body: JSON.stringify({
                    token:this.props.credentials.token,
                    mission_id: this.props.mission_id,
                    polygon: polygon
                })
            })
        })
        .then(rs=>rs.json())
        .then(rs=>{
            if (!rs.success) throw rs.reason
            console.log('geofence:',rs.geofence)

            this.props.routePreviousPage()
        })
        .catch(err=>console.log('err:',err))
    }


    render() {

        return <div>
            <h3>New Geofence</h3>

            <label>Geofence Type</label>
            <select
                value={this.state.geofence_type ?? 'null'}
                onChange={ e => {
                    this.props.map.eraseAllOsmPois()
                    this.setState({geofence_type:e.target.value}, _ => this.props.map.triggerMoveEnd())
                }}
            >
                <option disable='true' hidden value={null}>Select Geofence Type</option>
                <option value='existing'>Existing Geofence</option>
                <option value='custom'>Custom Geofence</option>
            </select>
            
            { ['edit_geofence','add_geofence'].includes(this.props.currPage) && this.state.geofence_type && <Fragment>
                <label>Geofence Location</label>

                { this.state.geofence_type == 'custom' && <MissionEditor.Viewer
                    map={this.props.map}
                    mapSize={'medium'}
                    ref={x=> this.missionEditor = x}
                    currPage={'add_geofence'}
                    initMapState={this.props.initMapState}
                    editMode
                />}

                { this.state.geofence_type == 'existing' && <Fragment>
                    <MissionEditor.Viewer
                        map={this.props.map}
                        mapSize={'medium'}
                        ref={x=> this.missionEditor = x}
                        currPage='search_geofence'
                        initMapState={this.props.initMapState}
                        onGeofenceSelected={ x => {
                            console.log('selected:', x)
                            this.setState({selectedGeofence:x})
                        }}
                        onGeofenceDeselected={ _ => {
                            this.setState({selectedGeofence:null})
                        }}
                        onMapMoveEnd={ x =>{
                            fetch(PUBLIC_PATH_ROOT + `api/query_geofences?xmin=${x.extent[0]}&ymin=${x.extent[1]}&xmax=${x.extent[2]}&ymax=${x.extent[3]}&srid=3857`)
                            .then(rs=>rs.json())
                            .then(rs=>{
                                console.log('found geofences:', rs)
                                if (!rs.success) throw rs.reason
                                rs.geofences.map( x => this.props.map.plotGeofence(x))
                            })
                            .catch(err=>console.log('err:',err))
                        }}
                    />

                </Fragment>}

                <div className="input-field">
                    <label>Callsign</label>
                    <input
                        class='input-fullwidth'
                        value={this.state.geofence_callsign ?? ''}
                        onChange={e => this.setState({geofence_callsign: e.target.value})}
                    />
                </div>
                <div className="input-field">
                    <label>Name</label>
                    <input
                        class='input-fullwidth'
                        value={this.state.geofence_name ?? ''}
                        onChange={e => this.setState({geofence_name: e.target.value})}
                    />
                </div>

                <div className="input-field">
                    <label>Description</label>
                    <input
                        class='input-fullwidth'
                        value={this.state.geofence_description ?? ''}
                        onChange={e => this.setState({geofence_description: e.target.value})}
                    />
                </div>

                { this.props.existing_geofence &&
                    <button class='button-large' onClick={_ => this.deleteGeofenceitem(this.props.existing_geofence.id)}>Delete Geofence Item</button>
                }
                { this.props.existing_geofence ?
                    <button class='button-large' onClick={ _ => this.updateGeofenceitem(this.props.existing_geofence.id)}>Update Geofence Item</button>
                    :
                    <button class='button-large' onClick={ this.createGeofenceitem}>Create Geofence Item</button>
                }
            </Fragment>}
            <button class='button-large' onClick={ this.props.routePreviousPage}>Cancel</button>
        </div>
    }

}