import React, { Component } from 'react'
import './register.css'
import '../../style.css'

export default class RegisterUserComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            given_name: '',
            family_name: '',
            username: '',
            phone: '',
            company: '',
            email: '',
            password: '',
            confirmPassword: '',
        }
    }

    checkState = () => {}

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value })
        this.checkState()
    }

    checkUserName(new_username) {
        let username = new_username.toLowerCase()
        let regex = /^$|^[a-z]+[0-9]*$/
        console.log('register: trying username:',username)
        if (regex.test(username)){
            this.setState({username:username})
        } else {
            console.log('register: invalid username')
        }
    }

    onSubmit = e => {
        console.log('register: submit')
        e.preventDefault()
        const newUser = {
            given_name: this.state.given_name,
            family_name: this.state.family_name,
            username: this.state.username,
            email: this.state.email,
            phone: this.state.phone,
            company: this.state.company,
            password: this.state.password,
            confirmPassword: this.state.confirmPassword
        }

        if (newUser.password == newUser.confirmPassword) {
            this.setState({state:'loading'}, _ => {
                fetch(PUBLIC_PATH_ROOT + 'auth/register_user', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8',
                    },
                    body: JSON.stringify(newUser)
                })
                .then(res => res.json())
                .then(data=> {
                    if (data.success) {
                        console.log('register: success')
                        alert('Register success. An activation link is sent to the email.')
                        this.goToLogin()
                    }
                    else
                        throw data.reason
                })
                .catch(err => {
                    console.log('register: error:', err)
                    alert('register: error:' + JSON.stringify(err))
                    this.setState({state:'idle'})
                })
            })
        }
        else
            alert("Password confirmation doesn't match'")
    }

    goToLogin = _ => this.props.routeLoginPage()

    render() {
        return (
            <div className='registerContainer'>
            <div className='formContainer'>
                {/* <h4><b>Register New User</b></h4> */}
                <form autoComplete='off' onSubmit={this.onSubmit}>
                <div>
                    <label for='given_name'>Given Name*</label>
                    <input
                        class='input-fullwidth'
                        required
                        onChange={this.onChange}
                        value={this.state.given_name}
                        placeholder='Max'
                        id='given_name'
                        type='text'
                    />
                </div>
                <div>
                    <label for='family_name'>Family Name*</label>
                    <input
                        class='input-fullwidth'
                        required
                        onChange={this.onChange}
                        value={this.state.family_name}
                        placeholder='Mustermann'
                        id='family_name'
                        type='text'
                    />
                </div>
                <div>
                    <label for='username'>Username*</label>
                    <input
                        class='input-fullwidth'
                        required
                        onChange={e => this.checkUserName(e.target.value)}
                        value={this.state.username}
                        placeholder='maxmustermann'
                        id='username'
                        type='text'
                    />
                </div>
                <div>
                    <label for='company'>Company</label>
                    <input
                        class='input-fullwidth'
                        onChange={this.onChange}
                        value={this.state.company}
                        placeholder='fraunhofer'
                        id='company'
                        type='text'
                    />
                </div>
                <div>
                    <label for='email'>Email*</label>
                    <input
                        class='input-fullwidth'
                        required
                        onChange={this.onChange}
                        value={this.state.email}
                        placeholder='max_mustermann@cml.fraunhofer.de'
                        id='email'
                        type='email'
                    />
                </div>
                <div>
                    <label for='phone'>Phone</label>
                    <input
                        class='input-fullwidth'
                        maxLength={16}
                        onChange={this.onChange}
                        value={this.state.phone}
                        placeholder='+12345678900'
                        id='phone'
                        type='text'
                    />
                </div>
                <div>
                    <label for='password'>Password*</label>
                    <input
                        class='input-fullwidth'
                        required
                        onChange={this.onChange}
                        value={this.state.password}
                        id='password'
                        type='password'
                    />
                </div>
                <div>
                    <label for='confirmPassword'>Confirm Password*</label>
                    <input
                        class='input-fullwidth'
                        required
                        onChange={this.onChange}
                        value={this.state.confirmPassword}
                        id='confirmPassword'
                        type='password'
                    />
                </div>
                <button
                    id='registerButton'
                    type='submit'
                    className='button-large fullWidthButton'>
                    Sign Up
                </button>
            </form>
            </div>
            </div>
        )
    }
}
