import React from 'react'
import {QRCode} from 'qrcodejs/qrcode'
import './qrcode.css'

export class Qrcode extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        new QRCode(this.marker,{
            text: this.props.url,
            width: 128,
            height: 128,
            // colorDark : "black",
            colorDark : "rgb(86, 185, 197)",
            colorLight : "rgba(0, 0, 0, 0)",
            correctLevel : QRCode.CorrectLevel.H
        })
    }

    render () {
        return (
            <div class='qrcode' ref={x => this.marker = x}></div>
        )
    }
}