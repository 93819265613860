import {Style,Stroke,Circle as CircleStyle,Fill,Icon} from 'ol/style'
import * as olStyle from 'ol/style'
import * as olGeom from 'ol/geom'
import uavImg from '../../../assets/images/icon_uav.svg'
import uavHighlightedImg from '../../../assets/images/icon_uav_highlighted.svg'
import usvImg from '../../../assets/images/icon_usv.svg'
import usvHighlightedImg from '../../../assets/images/icon_usv_highlighted.svg'
import userImg from '../../../assets/images/icon_user.svg'
import userHighlightedImg from '../../../assets/images/icon_user_highlighted.svg'
import waypointImg from '../../../assets/images/icon_waypoint.svg'
import waypointHighlightedImg from '../../../assets/images/icon_waypoint_highlighted.svg'
import poiImg from '../../../assets/images/icon_poi.svg'
import bridgeImg from '../../../assets/images/icon_bridge.svg'
import bridgeHighlightedImg from '../../../assets/images/icon_bridge_highlighted.svg'
import aerowayImg from '../../../assets/images/icon_aeroway.svg'
import aerowayHighlightedImg from '../../../assets/images/icon_aeroway_highlighted.svg'
import quayImg from '../../../assets/images/icon_quay.svg'
import quayHighlightedImg from '../../../assets/images/icon_quay_highlighted.svg'
import lockImg from '../../../assets/images/icon_lock.svg'
import lockHighlightedImg from '../../../assets/images/icon_lock_highlighted.svg'
import vertiportImg from '../../../assets/images/icon_vertiport.svg'
import uxvStationImg from '../../../assets/images/icon_dock.svg'
import shipImg from '../../../assets/images/icon_ship.svg'
import airlinerImg from '../../../assets/images/icon_airliner.svg'
import waypointArrowImg from '../../../assets/images/icon_waypoint_arrow.svg'

export var symbols = {
  ship: {
    'icon-src': shipImg,
    'icon-width': 18,
    'icon-height': 18,
    'icon-color': 'brown',
    'icon-rotate-with-view': false,
    'icon-rotation': [ '*', ['get','hdg_deg'],0.017453],
    'icon-displacement': [0.5, 0.5],
    'icon-opacity': 0.7
  },
  airliner: {
    'icon-src': airlinerImg,
    'icon-width': 20,
    'icon-height': 20,
    'icon-color': 'brown',
    'icon-rotate-with-view': false,
    'icon-rotation': [ '*', ['get','direction'],0.017453],
    'icon-displacement': [10, 10],
    'icon-opacity': 0.7
  },
  ship_: {
    symbolType: 'image',
    src: shipImg,
    size: 18,
    color: 'brown',
    rotateWithView: false,
    rotation: ['get','hdg_deg'],
    offset: [0.5, 0.5],
    opacity: 0.7
  },
  airliner_: {
    symbolType: 'image',
    src: airlinerImg,
    size: [20,20],
    color: 'brown',
    rotateWithView: false,
    rotation: ['get','direction'],
    offset: [10, 10],
    opacity: 0.7,
  }
}

export var webgl_styles =  {
  traffic_track_beacon: [
    {
      filter: ['==', ["get", "traffic_type"], "adsb"],
      style: {
        'circle-radius': 5,
        'circle-fill-color': 'red',
        'circle-stroke-color': 'white',
        'circle-stroke-width': 0.5,
      }
    },
    {
      else:true,
      style: {
        'circle-radius': 5,
        'circle-fill-color': 'blue',
        'circle-stroke-color': 'white',
        'circle-stroke-width': 0.5,
      }
    }
  ]
}

export var styles = {
    Point: new Style({
      image: new CircleStyle({
        radius: 5,
        fill: null,
        stroke: new Stroke({color: 'red', width: 1})
      })
    }),
    Ship: new Style({
      image: new CircleStyle({
        radius: 4,
        fill: new Fill({color: 'rgb(21,255,0', width: 5}),
        stroke: new Stroke({color: 'rgb(177,255,177)', width: 2})
      })
    }),
    ShipGray: new Style({
      image: new CircleStyle({
        radius: 3,
        fill: new Fill({color: 'gray', width: 1}),
        stroke: new Stroke({color: 'gray', width: 1})
      })
    }),
    LineString: new Style({
      stroke: new Stroke({
        color: 'green',
        width: 3
      })
    }),
    SensorData: new Style({
      fill: new Fill({
        color: 'rgb(255,191,0)',
        width: 9
      }),
      stroke: new Stroke({
        color: 'rgb(255,191,0)',
        width: 9
      })
    }),
    MultiLineString: new Style({
      stroke: new Stroke({
        color: 'green',
        width: 3
      })
    }),
    MultiPoint: new Style({
      image: new CircleStyle({
        radius: 5,
        fill: null,
        stroke: new Stroke({color: 'red', width: 1})
      })
    }),
    MultiPolygon: new Style({
      stroke: new Stroke({
        color: 'yellow',
        width: 1
      }),
      fill: new Fill({
        color: 'rgba(255, 255, 0, 0.1)'
      })
    }),
    Base_old: feature => [
      new Style({
        geometry: feature.getGeometry().getInteriorPoint(),
        image: new CircleStyle({
          radius: 12,
          fill: new Fill({color: 'rgba(200, 200, 200, 0.8)'}),
          stroke: new Stroke({color: 'rgba(0, 0, 200, 0.8)', width: 5})
        })
      }),
      new Style({
        image: new CircleStyle({
          radius: 12,
          fill: new Fill({color: 'rgba(0, 0, 100, 0.4)'}),
          stroke: new Stroke({color: 'rgba(0, 0, 200, 0.8)', width: 2})
        }),
        text: new olStyle.Text({
          text: feature.get?.('callsign') ?? 'B',
          fill: new Fill({color: 'rgba(0, 0, 100, 0.4)'}),
          stroke: new Stroke({color: 'rgba(0, 0, 200, 0.8)', width: 1}),
          overflow: true
        })
      })
    ],
    Base: feature => new Style({
      geometry: new olGeom.Point(feature.get('centroid').coordinates).transform('EPSG:4326','EPSG:3857'),
      image: new Icon({
          src: {'uav_pad':vertiportImg,'usv_station':uxvStationImg}[feature.get('baseitem_type') ?? 'uav_pad'],
          anchor: [0.5,1],
          scale: 0.4
      })
    }),
    Route: feature => {
      let segmentStyles = []
      feature.getGeometry().forEachSegment( (start,end) => {
        let dx = end[0] - start[0]
        let dy = end[1] - start[1]
        let rotation = Math.atan2(dy, dx)
        segmentStyles.push(
          new Style({
            geometry: new olGeom.Point(start),
            image: new Icon({
              src: waypointArrowImg,
              anchor: [0.5, 0.5],
              rotateWithView: true,
              rotation: -rotation,
              scale: 0.6
            })
          })
        )
        segmentStyles.push(
          new Style({
            geometry: new olGeom.Point([0.5*(start[0]+end[0]),0.5*(start[1]+end[1])]),
            text: new olStyle.Text({
              text: [feature.get?.('codename')  ?? feature.get?.('uxv_id')  ?? '---' , 'bold 10px sans-serif'],
              // offsetX: 20,
              offsetY: -12,
              rotation: Math.abs(-rotation) > (Math.PI/2) ? -rotation+Math.PI : -rotation,
              fill: new Fill({color: 'rgba(255, 102, 0, 1)'}),
              stroke: new Stroke({color: 'white', width: 2}),
              overflow: true
            })
          })
        )
      })
      return [
        new Style({
          stroke: new Stroke({
            color: '#ffcc33',
            width: 2
          })
        }),
        ...segmentStyles
      ]
    },
    route_focused: feature => {
      let segmentStyles = []
      feature.getGeometry().forEachSegment( (start,end) => {
        let dx = end[0] - start[0]
        let dy = end[1] - start[1]
        let rotation = Math.atan2(dy, dx)
        segmentStyles.push(
          new Style({
            geometry: new olGeom.Point(start),
            image: new Icon({
              src: waypointArrowImg,
              anchor: [0.5, 0.5],
              rotateWithView: true,
              rotation: -rotation,
              scale: 0.6
            }),
            zIndex: 100
          })
        )
        segmentStyles.push(
          new Style({
            geometry: new olGeom.Point([0.5*(start[0]+end[0]),0.5*(start[1]+end[1])]),
            text: new olStyle.Text({
              text: [feature.get?.('codename')  ?? '---' , 'bold 10px sans-serif'],
              // offsetX: 20,
              offsetY: -12,
              rotation: Math.abs(-rotation) > (Math.PI/2) ? -rotation+Math.PI : -rotation,
              fill: new Fill({color: 'blue'}),
              stroke: new Stroke({color: 'white', width: 2}),
              overflow: true
            }),
            zIndex: 100
          })
        )
      })
      return [
        new Style({
          stroke: new Stroke({
            color: 'blue',
            width: 2
          }),
          zIndex: 100
        }),
        ...segmentStyles
      ]
    },
    waypoint: feature => [
      new Style({
        geometry: feature.getGeometry().getInteriorPoint(),
        image: new Icon(({
            src: waypointImg,
            scale: 0.3,
            anchor: [0.5,0.9],
            rotation: (feature.getProperties()?.hdg ?? 0) * (Math.PI / 180),
            opacity: 0.7
        }))
      }),
      new Style({
        text: new olStyle.Text({
          offsetY: -18,
          text: feature.get('row_id').toString() ?? 'error',
          font: 'bold 12px sans-serif',
          fill: new Fill({color: 'rgba(255, 102, 0, 0.7)'}),
          overflow: true
        })
      })
    ],
    waypoint_focused: feature => [
      new Style({
        geometry: feature.getGeometry().getInteriorPoint(),
        image: new Icon(({
            src: waypointHighlightedImg,
            scale: 0.3,
            anchor: [0.5,0.9],
            rotation: (feature.getProperties()?.hdg ?? 0) * (Math.PI / 180),
            opacity: 1
        })),
        zIndex: 100
      }),
      new Style({
        text: new olStyle.Text({
          offsetY: -18,
          text: feature.get('row_id').toString() ?? 'error',
          font: 'bold 12px sans-serif',
          fill: new Fill({color: 'rgba(0, 0, 255, 1)'}),
          overflow: true
        }),
        zIndex: 100
      })
    ],
    Task: feature => [
      new Style({
        geometry: feature.getGeometry().getInteriorPoint(),
        image: new CircleStyle({
          radius: 12,
          fill: new Fill({color: 'rgba(255, 102, 0, 0.2)'}),
          stroke: new Stroke({color: 'rgba(255, 102, 0, 0.8)', width: 5})
        })
      }),
      new Style({
        image: new CircleStyle({
          radius: 12,
          fill: new Fill({color: 'rgba(0, 0, 100, 0.4)'}),
          stroke: new Stroke({color: 'rgba(0, 0, 200, 0.8)', width: 2})
        }),
        text: new olStyle.Text({
          text: feature.get('row_id').toString() ?? 'error',
          fill: new Fill({color: 'rgba(255, 102, 0, 0.4)'}),
          stroke: new Stroke({color: 'rgba(0, 0, 0, 0.8)', width: 2}),
          overflow: true
        })
      })
    ],
    Baseitem_focused: feature => [
      new Style({
        geometry: feature.getGeometry().getInteriorPoint(),
        image: new CircleStyle({
          radius: 20,
          fill: new Fill({color: 'rgba(0, 0, 0, 0)'}),
          stroke: new Stroke({color: 'rgba(0, 100, 0, 0.8)', width: 5})
        }),
        zIndex: 100
      }),
      new Style({
        text: new olStyle.Text({
          scale: 2,
          text: feature.get?.('callsign') ?? 'B',
          fill: new Fill({color: 'rgba(0, 0, 100, 0.4)'}),
          stroke: new Stroke({color: 'rgba(0, 0, 200, 0.8)', width: 1}),
          overflow: true
        }),
        stroke: new Stroke({
          color: 'blue',
          width: 1
        }),
        fill: new Fill({
          color: 'rgba(255, 255, 0, 0.9)'
        }),
        zIndex: 100
      })
    ],
    Task_focused: feature => [
      new Style({
        geometry: feature.getGeometry().getInteriorPoint(),
        image: new CircleStyle({
          radius: 20,
          fill: new Fill({color: 'rgba(0, 0, 0, 0)'}),
          stroke: new Stroke({color: 'rgba(0, 100, 0, 0.8)', width: 5})
        }),
        zIndex: 99
      }),
      new Style({
        text: new olStyle.Text({
          scale: 2,
          text: feature.get?.('timeline_id') ?? 'error',
          fill: new Fill({color: 'rgba(0, 0, 100, 0.4)'}),
          stroke: new Stroke({color: 'rgba(0, 0, 200, 0.8)', width: 2}),
          overflow: true
        }),
        stroke: new Stroke({
          color: 'blue',
          width: 1
        }),
        fill: new Fill({
          color: 'rgba(255, 255, 0, 0.9)'
        }),
        zIndex: 99
      })
    ],
    Task_edited: feature => new Style({
      image: new CircleStyle({
        radius: 12,
        fill: new Fill({color: 'rgba(0, 0, 100, 0.4)'}),
        stroke: new Stroke({color: 'rgba(0, 0, 200, 0.8)', lineDash: [0.5,0.5], width: 1})
      }),
      text: new olStyle.Text({
        text: feature.get('timeline_id') ?? 'c',
        fill: new Fill({color: 'rgba(0, 0, 100, 0.4)'}),
        stroke: new Stroke({color: 'rgba(0, 0, 200, 0.8)', width: 2}),
        overflow: true
      })
    }),
    Task_temp: feature => [
      new Style({
        geometry: feature.getGeometry().getInteriorPoint(),
        image: new CircleStyle({
          radius: 12,
          fill: new Fill({color: 'rgba(200, 200, 200, 0.8)'}),
          stroke: new Stroke({color: 'rgba(0, 0, 200, 0.8)', width: 5})
        })
      }),
      new Style({
        text: new olStyle.Text({
          text: 'T',
          fill: new Fill({color: 'rgba(0, 0, 100, 0.4)'}),
          stroke: new Stroke({color: 'rgba(0, 0, 200, 0.8)', width: 2}),
          overflow: true
        }),
        stroke: new Stroke({
          color: 'blue',
          width: 1
        }),
        fill: new Fill({
          color: 'rgba(255, 255, 0, 0.9)'
        })
      })
    ],
    uxv_path: feature => {
      let segmentStyles = []
      feature.getGeometry().forEachSegment( (start,end) => {
        let dx = end[0] - start[0]
        let dy = end[1] - start[1]
        let rotation = Math.atan2(dy, dx)
        segmentStyles.push(
          new Style({
            geometry: new olGeom.Point([0.5*(start[0]+end[0]),0.5*(start[1]+end[1])]),
            text: new olStyle.Text({
              // text: [feature.get?.('codename')  ?? '---' , 'bold 10px sans-serif'],
              // offsetX: 20,
              offsetY: -12,
              rotation: Math.abs(-rotation) > (Math.PI/2) ? -rotation+Math.PI : -rotation,
              fill: new Fill({color: 'rgba(255, 102, 0, 1)'}),
              stroke: new Stroke({color: 'white', width: 2}),
              overflow: true
            })
          })
        )
      })
      return [
        new Style({
          stroke: new Stroke({
            color: '#ffcc33',
            width: 2
          })
        }),
        ...segmentStyles
      ]
    },
    uxv_path_focused: feature => {
      let segmentStyles = []
      feature.getGeometry().forEachSegment( (start,end) => {
        let dx = end[0] - start[0]
        let dy = end[1] - start[1]
        let rotation = Math.atan2(dy, dx)
        segmentStyles.push(
          new Style({
            geometry: new olGeom.Point([0.5*(start[0]+end[0]),0.5*(start[1]+end[1])]),
            text: new olStyle.Text({
              // text: [feature.get?.('codename')  ?? '---' , 'bold 10px sans-serif'],
              // offsetX: 20,
              offsetY: -12,
              rotation: Math.abs(-rotation) > (Math.PI/2) ? -rotation+Math.PI : -rotation,
              fill: new Fill({color: 'blue'}),
              stroke: new Stroke({color: 'white', width: 2}),
              overflow: true
            }),
            zIndex: 100
          })
        )
      })
      return [
        new Style({
          stroke: new Stroke({
            color: 'blue',
            width: 2
          }),
          zIndex: 100
        }),
        ...segmentStyles
      ]
    },
    track_beacon : feature => null,
    track_beacon_focused: feature =>
      new Style({
        image: new CircleStyle({
          radius: 12,
          fill: new Fill({color: 'yellow'}),
          stroke: new Stroke({color: 'blue', width: 1})
        }),
        text: new olStyle.Text({
          text: feature.get('uxv_id') ?? 'error',
          fill: new Fill({color: 'yellow'}),
          stroke: new Stroke({color: 'blue', width: 2}),
          overflow: true
        })
      })
    ,
    uxv_path: feature => {
      let segmentStyles = []
      feature.getGeometry().forEachSegment( (start,end) => {
        let dx = end[0] - start[0]
        let dy = end[1] - start[1]
        let rotation = Math.atan2(dy, dx)
        segmentStyles.push(
          new Style({
            geometry: new olGeom.Point([0.5*(start[0]+end[0]),0.5*(start[1]+end[1])]),
            text: new olStyle.Text({
              // text: [feature.get?.('codename')  ?? '---' , 'bold 10px sans-serif'],
              // offsetX: 20,
              offsetY: -12,
              rotation: Math.abs(-rotation) > (Math.PI/2) ? -rotation+Math.PI : -rotation,
              fill: new Fill({color: 'rgba(255, 102, 0, 1)'}),
              stroke: new Stroke({color: 'white', width: 2}),
              overflow: true
            })
          })
        )
      })
      return [
        new Style({
          stroke: new Stroke({
            color: '#ffcc33',
            width: 2
          })
        }),
        ...segmentStyles
      ]
    },
    uxv_path_focused: feature => {
      let segmentStyles = []
      feature.getGeometry().forEachSegment( (start,end) => {
        let dx = end[0] - start[0]
        let dy = end[1] - start[1]
        let rotation = Math.atan2(dy, dx)
        segmentStyles.push(
          new Style({
            geometry: new olGeom.Point([0.5*(start[0]+end[0]),0.5*(start[1]+end[1])]),
            text: new olStyle.Text({
              // text: [feature.get?.('codename')  ?? '---' , 'bold 10px sans-serif'],
              // offsetX: 20,
              offsetY: -12,
              rotation: Math.abs(-rotation) > (Math.PI/2) ? -rotation+Math.PI : -rotation,
              fill: new Fill({color: 'blue'}),
              stroke: new Stroke({color: 'white', width: 2}),
              overflow: true
            }),
            zIndex: 100
          })
        )
      })
      return [
        new Style({
          stroke: new Stroke({
            color: 'blue',
            width: 2
          }),
          zIndex: 100
        }),
        ...segmentStyles
      ]
    },
    traffic_path: feature => {
      let segmentStyles = []
      feature.getGeometry().forEachSegment( (start,end) => {
        let dx = end[0] - start[0]
        let dy = end[1] - start[1]
        let rotation = Math.atan2(dy, dx)
        segmentStyles.push(
          new Style({
            geometry: new olGeom.Point([0.5*(start[0]+end[0]),0.5*(start[1]+end[1])]),
            text: new olStyle.Text({
              // text: [feature.get?.('codename')  ?? '---' , 'bold 10px sans-serif'],
              // offsetX: 20,
              offsetY: -12,
              rotation: Math.abs(-rotation) > (Math.PI/2) ? -rotation+Math.PI : -rotation,
              fill: new Fill({color: {'adsb':'orange','ais':'blue'}[feature.get('traffic_type')]}),
              stroke: new Stroke({color: 'white', width: 2}),
              overflow: true
            })
          })
        )
      })
      return [
        new Style({
          stroke: new Stroke({
            color: {'adsb':'orange','ais':'blue'}[feature.get('traffic_type')],
            width: 2
          })
        }),
        ...segmentStyles
      ]
    },
    traffic_track_beacon :  feature => {
      // console.log('traffic_track_beacon:',feature)
      return null
      if (feature.get('traffic_type')=='adsb') return (
        new Style({
          image: new CircleStyle({
            radius: 12,
            fill: new Fill({color: 'red'}),
            stroke: new Stroke({color: 'green', width: 1})
          }),
          text: new olStyle.Text({
            text: feature.get('ident') ?? 'error',
            fill: new Fill({color: 'red'}),
            stroke: new Stroke({color: 'green', width: 2}),
            overflow: true
          })
        })
      )
      if (feature.get('traffic_type')=='ais') return (
        new Style({
          image: new CircleStyle({
            radius: 12,
            fill: new Fill({color: 'red'}),
            stroke: new Stroke({color: 'green', width: 1})
          }),
          text: new olStyle.Text({
            text: feature.get('mmsi') ?? 'error',
            fill: new Fill({color: 'red'}),
            stroke: new Stroke({color: 'green', width: 2}),
            overflow: true
          })
        })
      )
    },
    traffic_track_beacon_focused: feature => {
      if (feature.get('traffic_type')=='adsb') return
        new Style({
          image: new CircleStyle({
            radius: 12,
            fill: new Fill({color: 'red'}),
            stroke: new Stroke({color: 'green', width: 1})
          }),
          text: new olStyle.Text({
            text: feature.get('ident') ?? 'error',
            fill: new Fill({color: 'red'}),
            stroke: new Stroke({color: 'green', width: 2}),
            overflow: true
          })
        })
      if (feature.get('traffic_type')=='ais') return
        new Style({
          image: new CircleStyle({
            radius: 12,
            fill: new Fill({color: 'red'}),
            stroke: new Stroke({color: 'green', width: 1})
          }),
          text: new olStyle.Text({
            text: feature.get('mmsi') ?? 'error',
            fill: new Fill({color: 'red'}),
            stroke: new Stroke({color: 'green', width: 2}),
            overflow: true
          })
        })
    }
    ,
    Poi_temp: feature => new Style({
      image: new Icon({
          src: poiImg,
          anchor: [0.5,1],
          scale: 0.4
      })
    }),
    User: _ => new Style({
      image: new CircleStyle({
        radius: 6,
        fill: new Fill({color: 'rgba(100, 100, 255, 1)'}),
        stroke: new Stroke({color: 'rgba(255, 255, 255, 1)', width: 2})
      })
    }),
    Baseitem_temp: feature => [
      new Style({
        geometry: feature.getGeometry().getInteriorPoint(),
        image: new CircleStyle({
          radius: 12,
          fill: new Fill({color: 'rgba(200, 200, 200, 0.8)'}),
          stroke: new Stroke({color: 'rgba(0, 0, 200, 0.8)', width: 5})
        })
      }),
      new Style({
        text: new olStyle.Text({
          text: 'B',
          fill: new Fill({color: 'rgba(0, 0, 100, 0.4)'}),
          stroke: new Stroke({color: 'rgba(0, 0, 200, 0.8)', width: 2}),
          overflow: true
        }),
        stroke: new Stroke({
          color: 'blue',
          width: 1
        }),
        fill: new Fill({
          color: 'rgba(255, 255, 0, 0.9)'
        })
      })
    ],
    OsmPoi: (feature,resolution) => {
      if (resolution > 15) {
        return new Style({
          geometry: new olGeom.Point(feature.get('centroid').coordinates).transform('EPSG:4326','EPSG:3857'),
          image: new CircleStyle({
            radius: 4,
            fill: new Fill({color: 'rgba(255, 102, 0, 1)'}),
            stroke: new Stroke({color: 'rgba(255, 255, 255, 1)', width: 1})
        })})
      }
      if (feature.getProperties()['poi_type'] == 'bridge') {
        return new Style({
          geometry: new olGeom.Point(feature.get('centroid').coordinates).transform('EPSG:4326','EPSG:3857'),
          image: new Icon({
              src: bridgeImg,
              anchor: [0.5,1],
              scale: 0.4
          })
        })
      }
      if (feature.getProperties()['poi_type'] == 'aeroway') {
        let x = new Style({
          geometry: new olGeom.Point(feature.get('centroid').coordinates).transform('EPSG:4326','EPSG:3857'),
          image: new Icon({
              src: aerowayImg,
              anchor: [0.5,1],
              scale: 0.4
          })
        })
        if ( ['ST_Polygon','ST_MultiPolygon'].includes(feature.get('geom_type'))) {
          let y = new Style({
            geometry: new olGeom.Polygon(feature.get('geom').coordinates).transform('EPSG:4326','EPSG:3857'),
            stroke: new Stroke({
              color: 'green',
              lineDash: [1,5],
              width: 1.5
            }),
            fill: new Fill({
              color: 'rgba(0, 255, 0, 0.1)'
            })
          })
          return [x,y]
        }
        return x
      }
      if (feature.getProperties()['poi_type'] == 'quay') {
        return new Style({
          geometry: new olGeom.Point(feature.get('centroid').coordinates).transform('EPSG:4326','EPSG:3857'),
          image: new Icon({
              src: quayImg,
              anchor: [0.5,1],
              scale: 0.4
          })
        })
      }
      if (feature.getProperties()['poi_type'] == 'lock') {
        return new Style({
          geometry: new olGeom.Point(feature.get('centroid').coordinates).transform('EPSG:4326','EPSG:3857'),
          image: new Icon({
              src: lockImg,
              anchor: [0.5,1],
              scale: 0.4
          })
        })
      }
      return new Style({
        image: new CircleStyle({
          radius: 12,
          fill: new Fill({color: 'rgba(255, 255, 10, 0.4)'}),
          stroke: new Stroke({color: 'rgba(255, 255, 20, 0.8)', width: 2})
        }),
        text: new olStyle.Text({
          text: feature.getId()?.toString(),
          fill: new Fill({color: 'rgba(255, 255, 100, 0.4)'}),
          stroke: new Stroke({color: 'rgba(255, 255, 200, 0.8)', width: 2})
        })
      })
    },
    Osm_poi_focused: (feature,resolution) => {
      if (feature.getProperties()['poi_type'] == 'bridge') {
        return new Style({
          geometry: new olGeom.Point(feature.get('centroid').coordinates).transform('EPSG:4326','EPSG:3857'),
          image: new Icon({
              src: bridgeHighlightedImg,
              anchor: [0.5,1],
              scale: 0.4
          }),
          zIndex: 100
        })
      }
      if (feature.getProperties()['poi_type'] == 'aeroway') {
        let x = new Style({
          geometry: new olGeom.Point(feature.get('centroid').coordinates).transform('EPSG:4326','EPSG:3857'),
          image: new Icon({
              src: aerowayHighlightedImg,
              anchor: [0.5,1],
              scale: 0.4
          }),
          zIndex: 100
        })
        if ( ['ST_Polygon','ST_MultiPolygon'].includes(feature.get('geom_type'))) {
          let y = new Style({
            geometry: new olGeom.Polygon(feature.get('geom').coordinates).transform('EPSG:4326','EPSG:3857'),
            stroke: new Stroke({
              color: 'blue',
              width: 2.5
            }),
            fill: new Fill({
              color: 'rgba(0, 255, 0, 0.1)'
            }),
            zIndex: 100
          })
          return [x,y]
        }
        return x
      }
      if (feature.getProperties()['poi_type'] == 'quay') {
        return new Style({
          geometry: new olGeom.Point(feature.get('centroid').coordinates).transform('EPSG:4326','EPSG:3857'),
          image: new Icon({
              src: quayHighlightedImg,
              anchor: [0.5,1],
              scale: 0.4
          }),
          zIndex: 100
        })
      }
      if (feature.getProperties()['poi_type'] == 'lock') {
        return new Style({
          geometry: new olGeom.Point(feature.get('centroid').coordinates).transform('EPSG:4326','EPSG:3857'),
          image: new Icon({
              src: lockHighlightedImg,
              anchor: [0.5,1],
              scale: 0.4
          }),
          zIndex: 100
        })
      }
      return new Style({
        image: new CircleStyle({
          radius: 12,
          fill: new Fill({color: 'rgba(255, 255, 10, 0.4)'}),
          stroke: new Stroke({color: 'rgba(255, 255, 20, 0.8)', width: 2})
        }),
        text: new olStyle.Text({
          text: feature.getId()?.toString(),
          fill: new Fill({color: 'rgba(255, 255, 100, 0.4)'}),
          stroke: new Stroke({color: 'rgba(255, 255, 200, 0.8)', width: 2})
        })
      })
    },
    Poi_edited: feature => new Style({
      image: new CircleStyle({
        radius: 12,
        fill: new Fill({color: 'rgba(255, 255, 10, 0.4)'}),
        stroke: new Stroke({color: 'rgba(0, 0, 20, 0.8)', lineDash: [3,3], width: 4})
      }),
      text: new olStyle.Text({
        text: feature.getId()?.toString(),
        fill: new Fill({color: 'rgba(255, 255, 10, 0.4)'}),
        stroke: new Stroke({color: 'rgba(255, 255, 20, 0.8)', width: 2})
      })
    }),
    Geofence_temp: new Style({
      stroke: new Stroke({
        color: 'blue',
        lineDash: [1,5],
        width: 1.5
      }),
      fill: new Fill({
        color: 'rgba(0, 255, 0, 0.1)'
      })
    }),
    Geofence: new Style({
      stroke: new Stroke({
        color: 'green',
        lineDash: [1,5],
        width: 1.5
      }),
      fill: new Fill({
        color: 'rgba(0, 255, 0, 0.1)'
      })
    }),
    Geofence_focused: new Style({
      stroke: new Stroke({
        color: 'green',
        width: 3
      }),
      fill: new Fill({
        color: 'rgba(0, 100, 0, 0.4)'
      }),
      zIndex: 100
    }),
    Geofence_edited: new Style({
      stroke: new Stroke({
        color: 'green',
        lineDash: [8,8],
        width: 3
      }),
      fill: new Fill({
        color: 'rgba(200, 255, 200, 0.4)'
      })
    }),
    Polygon: new Style({
      stroke: new Stroke({
        color: 'black',
        lineDash: [2,5],
        width: 3
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 255, 0.1)'
      })
    }),
    GeometryCollection: new Style({
      stroke: new Stroke({
        color: 'magenta',
        width: 2
      }),
      fill: new Fill({
        color: 'magenta'
      }),
      image: new CircleStyle({
        radius: 10,
        fill: null,
        stroke: new Stroke({
          color: 'magenta'
        })
      })
    }),
    Circle: new Style({
      stroke: new Stroke({
        color: 'red',
        width: 2
      }),
      fill: new Fill({
        color: 'rgba(255,0,0,0.2)'
      })
    }),
    own_uav: feature => new Style({
        image: new Icon(({
            src: uavImg,
            anchor: [0.5,0.1],
            color: 'rgba(0,255,0,1)',
            scale: 0.4,
            rotation: (feature.getProperties()?.hdg ?? 0) * (Math.PI / 180),
            opacity: 1
        }))
    }),
    beacon: feature => {
      switch (feature.get('user_role')) {
      case 'uxv': 
        return [
          new Style({
            image: new Icon(({
                src: ({uav:uavImg,usv:usvImg})[feature.get('uxv_type')],
                color: 'yellow',
                scale: 0.4,
                anchor: [0.5,0.1],
                rotation: (feature.getProperties().content?.hdg ?? 0) * (Math.PI / 180),
                opacity: 0.7
            }))
          }),
          new Style({
            text: new olStyle.Text({
              text: [feature.get?.('codename') ?? '---' , 'bold 12px sans-serif'],
              offsetX: 20,
              offsetY: -20,
              fill: new Fill({color: 'black'}),
              stroke: new Stroke({color: 'white', width: 1}),
              overflow: true
            })
          })
        ]
      case 'admin':
      case 'user':
        return new Style({
          image: new Icon(({
              src: userImg,
              color: 'yellow',
              scale: 0.4,
              anchor: [0.5,0.5],
              rotation: (feature.getProperties()?.hdg ?? 0) * (Math.PI / 180),
              opacity: 0.7
          }))
        })
      }
    },
    beacon_focused: feature => {
      switch (feature.get('user_role')) {
      case 'uxv':
        return new Style({
          image: new Icon(({
              src: ({uav:uavHighlightedImg,usv:usvHighlightedImg})[feature.getProperties()['uxv_type']],
              scale: 0.4,
              anchor: [0.5,0.1],
              rotation: (feature.getProperties().content?.hdg ?? 0) * (Math.PI / 180),
              opacity: 1
          })),
          zIndex: 100
        })
      case 'admin':
      case 'user':
        return new Style({
          image: new Icon(({
              src: userHighlightedImg,
              scale: 0.4,
              anchor: [0.5,0.5],
              rotation: (feature.getProperties()?.hdg ?? 0) * (Math.PI / 180),
              opacity: 1
          }))
        })
      }
      console.error('unhandled userrole:',feature.get('user_role'))
    },
    other_payload: _ => new Style({
        image: new CircleStyle({
            radius: 5,
            fill: new Fill({color: 'yellow', width: 2}),
            stroke: new Stroke({color: 'yellow', width: 2})
        })
    })
  }

