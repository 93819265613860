// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#routeEditor .mapContainer {
    height: 12vh;
}

#liveView{
    height: 12vh;
}

#missionDetailContainer {
    padding: 20px;
    left:10%;
    margin-left: auto;
    margin-right: auto;
}

.flex {
    display: flex;
    flex-flow: wrap;
    gap: 8%;
}

.mapContainer {
    height: 40vw;
    width: 100%;
}

.mapContainer.small {
    height: 200px;
    width: 200px;
}
.mapContainer.medium {
    height: 400px;
    width: 100%
}

.mapContainer.small.fullscreen {
    position: absolute;
    height: 100%;
    width: 100%;
}
.mapContainer.medium.fullscreen {
    position: absolute;
    height: 100%;
    width: 100%;
}

tr.highlighted {
    border: blue 3px solid;
}

.missionDetailNavButton {
    position: relative;
}
.missionDetailCounter {
    position:absolute;
    font-size: 10px;
    right: 10px;
    top:2px;
    min-width: 10px;
    text-align: center;
    padding: 2px;
    border-radius: 4px;
    border: 0.5px solid rgb(11, 106, 130);
    /* background-color: rgb(11, 106, 130); */
    color: rgb(11, 106, 130);;
}`, "",{"version":3,"sources":["webpack://./src/components/mission/missionDetails.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,QAAQ;IACR,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,OAAO;AACX;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,aAAa;IACb,YAAY;AAChB;AACA;IACI,aAAa;IACb;AACJ;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;AACf;AACA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;AACtB;AACA;IACI,iBAAiB;IACjB,eAAe;IACf,WAAW;IACX,OAAO;IACP,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,qCAAqC;IACrC,yCAAyC;IACzC,wBAAwB;AAC5B","sourcesContent":["#routeEditor .mapContainer {\n    height: 12vh;\n}\n\n#liveView{\n    height: 12vh;\n}\n\n#missionDetailContainer {\n    padding: 20px;\n    left:10%;\n    margin-left: auto;\n    margin-right: auto;\n}\n\n.flex {\n    display: flex;\n    flex-flow: wrap;\n    gap: 8%;\n}\n\n.mapContainer {\n    height: 40vw;\n    width: 100%;\n}\n\n.mapContainer.small {\n    height: 200px;\n    width: 200px;\n}\n.mapContainer.medium {\n    height: 400px;\n    width: 100%\n}\n\n.mapContainer.small.fullscreen {\n    position: absolute;\n    height: 100%;\n    width: 100%;\n}\n.mapContainer.medium.fullscreen {\n    position: absolute;\n    height: 100%;\n    width: 100%;\n}\n\ntr.highlighted {\n    border: blue 3px solid;\n}\n\n.missionDetailNavButton {\n    position: relative;\n}\n.missionDetailCounter {\n    position:absolute;\n    font-size: 10px;\n    right: 10px;\n    top:2px;\n    min-width: 10px;\n    text-align: center;\n    padding: 2px;\n    border-radius: 4px;\n    border: 0.5px solid rgb(11, 106, 130);\n    /* background-color: rgb(11, 106, 130); */\n    color: rgb(11, 106, 130);;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
