import React, {Fragment} from 'react'
import * as MissionComponent from '../mission/mission'
import * as AccountComponent from '../account/account'
import * as ChatComponent from '../chat/chat'
import * as AmqpComponent from '../amqp/amqp'
import * as PlannerComponent from '../planner/planner'
import LiveviewComponent from '../live-view/live-view'
import * as TrafficComponent from './trafficViewer'
import FleetStatusViewer from './fleetStatusViewer'
import * as JanusComponent from '../janus/janus'
import * as DatasetComponent from '../dataset/dataset'
import './dashboard.css'
import '../../style.css'
import project_logo_full from '../../../assets/images/webui_logo_full.svg'
import { Qrcode } from '../qrcode/qrcode'

export class LeftPanel extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        console.log('this.logo:',this.logo)
        this.logo?.scrollIntoView()
    }

    render = _ =>
        <Fragment>
            <input
                ref={x => this.locationInput = x}
                onFocus={ _ => this.setState({settingQuery: ''})}
                onBlur={ _ => setTimeout(_ => this.setState({settingQuery:null}),200)}
                onChange={e => this.setState({settingQuery:e.target.value})}
                value={this.state.settingQuery ?? ''}
                placeholder={'🔍 search settings...'}
                id="settings_finder"
                type="text"
                class='input-fullwidth'
            />
            <br/>
            <br/>
            <img ref={x => this.logo = x} style={{width:'80px'}} src={project_logo_full} alt="Logo"/> <br/>
            <h2>Dashboard for <code class='username'>{this.props.credentials.username || this.props.credentials.codename}</code></h2>
            <div class={this.props.currPage == 'live_map' ? 'selected' : ''}
                onClick={ _ => this.props.setCurrPage('live_map')}
            ><p>Live Map</p></div>
            <div class={this.props.currPage == 'mission_status' ? 'selected' : ''}
                onClick={ _ => this.props.setCurrPage('mission_status')}
            ><p>Missions</p></div>
            <div class={this.props.currPage == 'fleet_status' ? 'selected' : ''}
                onClick={ _ => this.props.setCurrPage('fleet_status')}
            ><p>Fleet</p></div>
            <div class={this.props.currPage == 'planner' ? 'selected' : ''}
                onClick={ _ => this.props.setCurrPage('planner')}
            ><p>Planner</p></div>
            <div class={this.props.currPage == 'chat' ? 'selected' : ''}
                onClick={ _ => this.props.setCurrPage('chat')}
            ><p>Chat</p></div>
            <div class={this.props.currPage == 'checklists' ? 'selected' : ''}
                onClick={ _ => this.props.setCurrPage('checklists')}
            ><p>Checklists</p></div>
            <div class={this.props.currPage == 'historic_data' ? 'selected' : ''}
                onClick={ _ => this.props.setCurrPage('historic_data')}
            ><p>Historic Data</p></div>
            <div class={this.props.currPage == 'dataset' ? 'selected' : ''}
                onClick={ _ => this.props.setCurrPage('dataset')}
            ><p>Datasets</p></div>
            <div class={this.props.currPage == 'account' ? 'selected' : ''}
                onClick={ _ => this.props.setCurrPage('account')}
            ><p>Accounts</p></div>
            <div class={this.props.currPage == 'settings' ? 'selected' : ''}
                onClick={ _ => this.props.setCurrPage('settings')}
            ><p>Settings</p></div>
            <div onClick={ _ => this.props.routePreviousPage()}>Logout</div>

            <Qrcode url={window.location.href} />
        </Fragment>
}

export class RightPanel extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
        window.history.pushState(null,'dashboard','/dashboard')
        window.onpopstate = () => {
            console.log('route to nothing.')
            window.history.pushState(null,'dashboard','/dashboard')
        }
        this.props.setCurrPage('mission_status')

        //create a map object once only
        // this.map = new MissionMap.Map({rasterStyle:'tile'})
    }

    fetchMission(mission_id) {
        fetch(PUBLIC_PATH_ROOT + `api/mission/${mission_id}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({ token: this.props.credentials.token })
        })
        .then(res => res.json())
        .then(res => {
            if (!res.success) throw res.reason
            console.log('mission:',res.mission)
            this.setState({
                currPage: 'general',
                mission: res.mission,
            }, this.updateCurrPage)

            res.mission.geofences?.map( geofence => {
                this.props.map.plotGeofence(geofence)
            })

            res.mission.baseitems?.map( baseitem => {
                this.props.map.plotBaseitem(baseitem)
            })

            res.mission.itinerary?.map( task => {
                this.props.map.plotTask(task)
            })

            res.mission.routes?.map( route => {
                console.log('route:', route)
                this.props.map.plotRoute(route)
            })

            if (res.mission.geofence_extent) {
                this.props.map.zoomTo(res.mission.geofence_extent)
            } else if (res.mission.task_extent) {
                this.props.map.zoomTo(res.mission.task_extent)
            }
        })
        .catch(err => {
            console.log('get mission error0:',err)
        })
    }

    updateMapSize() {
        this.props.map.updateSize()
    }

    updateOtherBeacon(data) {
        // console.log('dashboard: incoming beacon:', data)
        this.trafficViewer?.drawOtherBeacon(data)
    }

    updateOwnBeacon(data) {
        // console.log('dashboard: incoming own beacon:', data)
        this.ownViewer?.drawOwnBeacon(data)
    }

    updateWarning(data) {
        this.setState({collision_risks: data.content.filter( x => x.risk_type == 'collision')})
    }

    render() {return (
        <div id='dashboardContainer'>

            { this.props.currPage == 'chat' && <div>
                <h2>Chat</h2>
                <ChatComponent.FriendList
                    credentials={this.props.credentials}
                />
            </div>}

            { this.props.currPage == 'mission_status' && <div>
                <h2>Mission Status</h2>
                <MissionComponent.Missionlist
                    credentials={this.props.credentials}
                    routeMissionDetailPage={mission_id => this.props.routeMissionDetailPage(mission_id)}
                    routeNewMissionPage={this.props.routeNewMissionPage}
                />
            </div>}

            { this.props.currPage == 'fleet_status' && <div>
                <h2>
                    Fleet Status
                </h2>
                <FleetStatusViewer
                    ref={x => this.fleetViewer = x}
                    now={this.props.now}
                    credentials={this.props.credentials}
                    routePayloadDetailPage={payload => this.props.routePayloadDetailPage(payload)}
                    routeUxvDetailPage={uxv => this.props.routeUxvDetailPage(uxv)}
                    beacon={this.props.beacon}
                />
            </div>}

            { this.props.currPage == 'own_view' && <div>
                <h2>
                Own View
                </h2>
                <OwnViewer
                    ref={x => this.ownViewer = x}
                    publishOwnWaypoint={this.props.publishOwnWaypoint}
                />
            </div>}

            { this.props.currPage == 'historic_data' && <div>
                <h2>
                    Historic Data View
                </h2>
            </div> }
            
            { this.props.currPage == 'dataset' && <div>
                <h2>
                    Datasets Browser
                </h2>
                <DatasetComponent.DatasetList
                    credentials={this.props.credentials}
                    now={this.props.now}
                    has_create_dataset={false}
                    has_filter_dataset={false}
                    // on_focused={ dataset_id => this.fetchData(mission_id)}
                    dataset_name={'shipspotter'}
                    routeDatasetDetailPage={mission_id => this.props.routeDatasetDetailPage(mission_id)}
                />
            </div> }

            { this.props.currPage == 'live_map' && <div>
                <h2>Warnings and Notifications</h2>
                <p>Collision Risks</p>
                <table class='table-normal'>
                    <thead>
                        <tr>
                            <th>UXV</th>
                            <th>Risk Type</th>
                            <th>Objects</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.collision_risks?.map( (x,i) => <tr key={i}>
                        <td><code class='codename'>{x.uxv_id}</code></td>
                        <td>{x.risk_type}</td>
                        <td>{x.objects.map( (o,j) => <span key={j}>
                            {o.type} : {o.id}
                        </span>)
                        }</td>
                        </tr>)}
                    </tbody>
                </table>
                <p>Weather-related Risks</p>
                <p>Harzard Reports</p>
                <h2>Active Missions</h2>
                <MissionComponent.Missionlist
                    credentials={this.props.credentials}
                    has_create_mission={false}
                    has_filter_mission={false}
                    on_focused={ mission_id => this.fetchMission(mission_id)}
                    api_name={'checked_in_missions'}
                    routeMissionDetailPage={mission_id => this.props.routeMissionDetailPage(mission_id)}
                    routeNewMissionPage={this.props.routeNewMissionPage}
                />

                <h2>
                    Current Traffic View
                </h2>
                <TrafficComponent.Viewer
                    ref={x => this.trafficViewer = x}
                    map={this.props.map}
                    mapSize={'medium'}
                    credentials={this.props.credentials}
                    routeUxvDetailPage={uxv => this.props.routeUxvDetailPage(uxv)}
                />
            </div>}

            { this.props.currPage == 'planner' && <div>
                <h2>
                    Planner
                </h2>
                <PlannerComponent.Planner
                    ref={x => this.planner = x}
                    credentials={this.props.credentials}
                />
            </div>}

            {this.props.currPage == 'account' &&
                <AccountComponent.MainPage
                    credentials={this.props.credentials}
                />
            }

            {this.props.currPage == 'settings' && <div style={{padding:'20px'}}>
                <AccountComponent.Simple credentials={this.props.credentials}/>
                <hr/>
                <h2>AMQP Gateway Status</h2>
                <AmqpComponent.Detail
                    amqpState={this.props.amqpState}
                    amqpMsg={this.props.amqpMsg}
                    beacon={this.props.beacon}
                    connectAmqpServer={this.props.connectAmqpServer}
                    disconnectAmqpServer={this.props.disconnectAmqpServer}
                    credentials={this.props.credentials}
                />
                <hr/>
                <h2>Janus Gateway Status</h2>
                <JanusComponent.Settings
                    credentials={this.props.credentials}
                />
            </div>}
            { false && <LiveviewComponent/>}

        </div>
    )}
}
