import React from 'react'
import * as d3 from 'd3'
import * as DialComponent from '../dial/dial'
import '../../style.css'

export class ControlPanel extends React.Component {
    constructor(props) {
        super(props)
        this.state={
            multiplot: true,
            brushActive: false,
            lastUpdated: null
        }
    }

    render = _ => <div id='historicChartControlPanel'>
        <button
            key='one'
            class={ this.state.multiplot ? 'button-toggle-normal active':'button-toggle-normal'}
            onClick={ _ => {
                if (this.state.multiplot) {
                    // limit to one sensor
                    let newVisibilities = {}
                    Object.keys(this.props.visibilities).map( (x,i) => {
                        newVisibilities[x] = i == 0 ? true : false
                    })
                    this.props.setVisibilities(newVisibilities)
                }
                this.setState({multiplot:!this.state.multiplot})
            }}
        >Multi Plot</button>
        { this.state.multiplot &&
            <button
                key='all'
                class={ Object.values(this.props.visibilities).every(x => x) ? 'button-toggle-normal active':'button-toggle-normal'}
                onClick={ _ => {
                    let newVisibilities = {}
                    let visible = Object.values(this.props.visibilities).every(x => x) ? false : true
                    Object.keys(this.props.visibilities).map( x => newVisibilities[x] = visible)
                    this.props.setVisibilities(newVisibilities)
                }}
            >All</button>
        }
        {
            Object.entries(this.props.visibilities).map( x =>
            <button
                key={x[0]}
                onClick={ _ => {
                    let newVisibilities = {}
                    if (this.state.multiplot) {
                        Object.entries(this.props.visibilities).map( y => {
                            newVisibilities[y[0]] = y[0] == x[0] ? !y[1] : y[1]
                        })
                    } else {
                        Object.entries(this.props.visibilities).map( y => {
                            newVisibilities[y[0]] = y[0] == x[0] ? true : false
                        })
                    }
                    this.props.setVisibilities(newVisibilities)
                }}
                class={x[1] ? 'button-toggle-normal active':'button-toggle-normal'}
            >{x[0]}</button>
        )}
        <div></div>
        { this.state.brushActive && <DialComponent.Dial labelName='X Offset'/> }
        { this.state.brushActive && <DialComponent.Dial labelName='X Width'/> }
        <div></div>
        <button
            class={ this.state.brushActive ? 'button-toggle-normal active':'button-toggle-normal'}
            onClick={ _ => {
                this.setState({brushActive: true})
            }}
        >Brush</button>
    </div>
}

export class Chart extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.visibilities) != JSON.stringify(prevProps.visibilities)) {
            this.updateGraph(this.props.historic_rawdatas, this.props.visibilities)
        }
    }

    componentDidMount() {
        // init the basic DOM elements such as svg, axis, trendline
        let chartAreaRect = this.chartArea.getBoundingClientRect()
        let margin = {top: 20, right: 60, bottom: 30, left: 60}
        let plotAreaHeight = chartAreaRect.height - margin.top - margin.bottom
        let plotAreaWidth = chartAreaRect.width - margin.left - margin.right

        let getX = x => new Date(x['timestamp']) // data -> value
        let getY = (x, sensorType) => x.content[sensorType]
        let getYunit = (x, sensorType) => x[sensorType]?.['Units']

        let scaleX = d3.scaleTime()
        .range([margin.left,margin.left + plotAreaWidth])
        let drawXAxis = d3.axisBottom(scaleX).ticks(6)

        let scaleY = d3.scaleLinear()
        .range([margin.top + plotAreaHeight, margin.top])
        let drawYAxis = d3.axisLeft(scaleY).ticks(6)

        // add the graph canvas to the body of the webpage
        this.svg = d3.select(this.chartArea).append('svg')
        .attr('width','100%')
        .attr('height','100%')

        // draw x axis
        this.svg.append('g')
        .attr('class', 'xAxis')
        .attr('transform', `translate(0,${margin.top + plotAreaHeight})`)
        .call(drawXAxis)

        // g to store plots
        this.dataG = this.svg.append('g')
        .attr('class', 'data-g')

        // add brush
        let brushFunc = d3.brushX()
        .extent( [ [margin.left,0], [margin.left+plotAreaWidth,margin.top+plotAreaHeight+margin.bottom] ] )
        .on("end", e => {
            if (!e.selection) return
            let selectedTimeRange = e.selection.map(x=> scaleX.invert(x).toISOString())
            let filteredData = this.props.historic_rawdatas.filter( x => x.timestamp < selectedTimeRange[1] && x.timestamp > selectedTimeRange[0] )
            filteredData.forEach( x => {
                x.idw_weight = (x.content['NO']-30)*10
            })
            this.props.onDataFiltered?.(filteredData)
            this.chunkedData = null
            this.minXVal = null
            this.maxXVal = null
            // 
            // this.svg.select('.brush')
            // .call(brushFunc.clear)
            console.log('end')
        })

        // draw tooltip
        this.tooltip = d3.select(this.chartArea).append('div')
        .attr('class','tooltip')

        this.svg.updateGraph = (data, visibilities, setDomainY=null) => {

            let sensorTypes = Object.keys(visibilities)

            // count X pixels available
            if (!this.minXVal) {
                [this.minXVal, this.maxXVal] = [d3.min(data, getX), d3.max(data, getX)]
            }
            let [minXVal, maxXVal] = [this.minXVal, this.maxXVal]

            if (!this.chunkedData) {

                let chunkWidth = Math.trunc((maxXVal.getTime() - minXVal.getTime()) / plotAreaWidth)

                // assign data to chunks
                let labeledData = data.map( x => {
                    let time = getX(x)
                    x.chunkId = Math.trunc((time.getTime() - minXVal.getTime()) / chunkWidth)
                    return x
                })

                this.chunkedData = [...Array(Math.trunc(plotAreaWidth)).keys()].map( (_,i) => {
                    let chunk = labeledData.filter( x => x.chunkId == i )
                    let summary = {
                        chunkId: i,
                        Time: new Date(minXVal.getTime() + i*chunkWidth),
                    }
                    if (chunk.length==0) {
                        sensorTypes.map( st => {
                            summary[st] = null
                        })
                        return summary
                    }
                    sensorTypes.map( st => {
                        summary[st] = {
                            min: Math.min(...chunk.map(d => getY(d,st))),
                            max: Math.max(...chunk.map(d => getY(d,st)))
                        }
                    })
                    return summary
                })
            }
            let chunkedData = this.chunkedData

            // plot Y axis if it is multiplot
            let isMultiPlot = Object.values(visibilities).filter(x=>x==true).length > 1
            this.svg.selectAll('.yAxis')
                .data( isMultiPlot ? [] : ['yAxis'], _ => 'yAxis')
                .join(
                    enter => enter.append('g')
                        .attr('class', 'yAxis')
                        .attr('transform', `translate(${margin.left},0)`)
                        ,
                    update => update,
                    exit => exit.remove()
                )
                .call(drawYAxis)

            // generate color-space points
            let colorPoints = this.generateCircular2Dpoints(sensorTypes.length, 80)


            // update domain
            scaleX.domain([minXVal, maxXVal])

            sensorTypes.forEach( (sensorType,i) => {

                if (!setDomainY) {
                    let maxY = Math.max(...chunkedData.filter(x=>x[sensorType]).map(x => x[sensorType].max))
                    let minY = Math.min(...chunkedData.filter(x=>x[sensorType]).map(x => x[sensorType].min))
                    scaleY.domain([minY , maxY])
                } else
                    scaleY.domain(setDomainY)

                // update axis
                this.svg.select('.xAxis').call(drawXAxis)
                if (visibilities[sensorType] && !isMultiPlot) // plot Y axis only for single plot
                    this.svg.select('.yAxis').call(drawYAxis)

                // draw area function
                let drawArea = d3.area()
                .x0(x => scaleX(x.Time))
                .y0(x => scaleY( x[sensorType] ? x[sensorType].min : 0))
                .y1(x => scaleY( x[sensorType] ? x[sensorType].max : 0))

                // draw data areas
                this.dataG.selectAll('.area.' + sensorType)
                .data(visibilities[sensorType] ? [sensorType] : [], _ => sensorType) // use sensorType as the data key
                .join(
                    enter => {
                        let g = enter.append('g')
                        .attr('class','area ' + sensorType)
                        // area
                        g.append('path')
                        .style('fill', d3.lab(50,colorPoints[i][0],colorPoints[i][1],0.7))
                        .attr('d', drawArea(chunkedData))
                        .on('mouseover', _ => {
                            console.log('mouseover')
                            d3.select(this.tooltip).style('opacity',0.9).html(`<p>${sensorType}</p>`)})
                        .on('mousemove', e =>
                            d3.select(this.tooltip)
                            .style('top', `${e.pageY  - this.chartArea.getBoundingClientRect().top - 10}px`)
                            .style('left',`${e.pageX  - this.chartArea.getBoundingClientRect().left + 10}px`)
                        )
                        .on('mouseout', _ => d3.select(this.tooltip).style("opacity", 0))
                        // legend
                        g.append('text')
                        .attr('class', 'legend')
                        .attr('x',margin.left + plotAreaWidth + 3)
                        .attr('y', scaleY(chunkedData[chunkedData.length - 1][sensorType]?.max ?? 0)) // move label close to last data point
                        .style('fill', d3.lab(50,colorPoints[i][0],colorPoints[i][1]))
                        .style('font-size','10px')
                        .text(sensorType)
                    },
                    _ => {
                        // update areas
                        d3.select('.area.'+sensorType+ ' path')
                        .attr('d', drawArea(chunkedData))
                        // update legend
                        d3.select('.area.'+sensorType+ ' text')
                        .attr('y', scaleY(chunkedData[chunkedData.length - 1][sensorType]?.max ?? 0)) // move label close to last data point
                    },
                    exit => exit
                        .remove()
                )

                // draw data points if not area
                this.dataG.selectAll('.dot.' + sensorType)
                .data(visibilities[sensorType] ? chunkedData.filter(x => x[sensorType] && x[sensorType].min == x[sensorType].max) : [], x => [sensorType, x.Time])
                .join(
                    enter => enter.append('circle')
                        .attr('class', 'dot ' + sensorType)
                        .attr('r', 2)
                        .attr('fill', d3.lab(50,colorPoints[i][0],colorPoints[i][1]))
                        .attr('cx', x => scaleX(x.Time))
                        .attr('cy', x => scaleY(x[sensorType].max))
                    ,
                    _ => {
                        d3.select('.dot.'+ sensorType)
                        .attr('cx', x => scaleX(x.Time))
                        .attr('cy', x => scaleY(x[sensorType].max))
                    },
                    exit => exit
                        .remove()
                )
            })

            // draw brush
            this.svg.selectAll('.brush')
            .data([true])
            .enter()
            .append('g')
            .attr("class", "brush")
            .on('click', e => {
                let brushG = this.svg.select('.brush')._groups[0][0]
                console.log(e.target, brushG)
                if (e.target == brushG) {
                    console.log('clicked inside')
                } else {
                    console.log('clicked outside')
                }
            })
            .on('dblclick', e => {
                this.chunkedData = null
                this.minXVal = null
                this.maxXVal = null
                this.updateGraph(this.props.historic_rawdatas, this.props.visibilities)
                this.props.onDataFiltered?.(this.props.historic_rawdatas)
                g.call(brushFunc.clear)
            })
            .call(brushFunc)
        }

        if (this.props.historic_rawdatas) {
            this.updateGraph(this.props.historic_rawdatas ,this.props.visibilities)
            this.props.onDataFiltered?.(this.props.historic_rawdatas)
        }
    }

    generateCircular2Dpoints(n, r) {
        let k = 2 * Math.PI / n
        let points = [...Array(n).keys()].map( x=> [r * Math.sin(k * x), r * Math.cos(k * x)])
        return points
    }

    updateGraph(data, visibilities) {
        if (data.length > 0) this.svg.updateGraph(data, visibilities)
    }

    render() {
        return <div id='historicChartArea' ref={x => this.chartArea = x}/>
    }

}