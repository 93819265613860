import React, { Fragment } from 'react'
import * as modelviewer from '@google/model-viewer'
import './modelViewer.css'

export class ModelViewer extends React.Component {

    constructor(props) {
        super(props)
        this.modelViewer = new modelviewer.ModelViewerElement()
        console.log('props:',this.props)
        Object.entries(this.props).map( x => {
            this.modelViewer.setAttribute(x[0],x[1])
        })
    }

    componentDidMount() {
        this.container.appendChild( this.modelViewer )
    }

    render() {
        return (
            <div id='model-viewer-container' ref={x => this.container = x}> 
            </div>
        )
    } 

}