import React, { Fragment } from 'react'
import './live-view.css'
import { Janus } from 'janus-gateway'

const janusOpaqueId = "janus_opaqueId_" + Janus.randomString(12)
var assignedPrivateId

class VideoRoomComponent extends React.Component {

    constructor(props) {
        super(props)
        this.ownFeedHandle
        this.ownVideo
        this.otherFeedHandleDict = {}
        this.otherVideoDict = {}
        this.state = {
            state : 'loading',
            pubHandleId: null,
            assignedPeerId: null,
            assignedPrivateId: null,
            otherPublishers: [],
            showDetailSettings: false
        }
        console.log('PUBLIC_PATH_ROOT:',PUBLIC_PATH_ROOT)
    }

    componentDidMount() {
        console.log('live-view: mounted')
        console.log('janusRoomId:',this.props.janusRoomId)
        console.log('janusUsername:',this.props.janusUsername)
        Janus.init({
            debug:'all',
            callback: _ => {
                this.setState({state:'loaded'})
            }
        })
    }

    componentDidUpdate( prevProps, prevState) {
        if ( (prevState.state == 'loading') && (this.state.state == 'loaded')) {
            this.createJanusSession(this.props.credentials.token)
            return
        }
        if ((prevState.state == 'loaded') && (this.state.state == 'sessionCreated')) {
            this.attachJanusPluginPublisher(this.janus,janusOpaqueId)
            return
        }
        if ((prevState.state == 'sessionCreated') && (this.state.state == 'pluginAttached')) {
            this.registerPublisher(this.publisherHandle,this.props.janusRoomId,this.props.janusUsername,this.props.credentials.token)
            return
        }
        if ( this.props.autoPublishOwnFeed && (prevState.state == 'pluginAttached') && (this.state.state == 'publisherRegistered')) {
            this.publishOwnFeed(this.publisherHandle)
            return
        }

    }

    registerPublisher = (handle,roomId,username,token) => {
        console.log('registerPublisher:',roomId,username,token )
        if (!token) {
            let pin = prompt('enter room pin:')
            return this.setState({pin:pin}, _ => {
                handle.send({ message: {
                    request: "join",
                    room: roomId,
                    ptype: "publisher",
                    pin: this.state.pin,
                    display: username
                }})
            })
        }
        handle.send({ message: {
            request: "join",
            room: roomId,
            ptype: "publisher",
            display: username,
            token: token
        }})
    }

    publishOwnFeed = pluginHandle => {
        console.log('janus: publishing own feed...')
        navigator.mediaDevices.enumerateDevices()
        .then(devices => {
            console.log('devices:',devices)
            let send_audio = confirm('send audio?')
            let send_video = confirm('send video?')
            // let deviceId
            // if (send_video) {
            //     let cam_id = prompt(`select camera id: (0-${devices.filter(x => x.kind == 'videoinput').length-1}) for (${devices.filter(x => x.kind == 'videoinput').map(x=>x.label).join(',')})`)
            //     deviceId = devices[cam_id].deviceId
            //     console.log('selected deviceId for video:',deviceId)
            // }
            
            pluginHandle.createOffer({
                tracks: [
                    {
                        type:'audio',
                        capture: send_audio,
                        recv: false
                    },
                    {   
                        type:'video',
                        capture: send_video,
                        recv: false,
                        simulcast: false
                    }
                        
                    //     audioRecv: false,
                    // videoRecv: false,
                    // audioSend: send_audio,
                    // videoSend: send_video,
                    // video: {
                    //     deviceId: {
                    //         exact: deviceId
                    //     }
                    // }
                ],
                
                success: jsep => {
                    console.log("janus: publisher SDP offer created", jsep)
                    pluginHandle.send({
                        message: {
                            request: "configure",
                            audio: send_audio,
                            video: send_video
                            // audiocodec: '',
                            // videoCodec: ''
                        },
                        jsep: jsep
                    })
                },
                error: err => {
                    console.log("janus: WebRTC error:", err.message)
                }
            })
        })
    }

    unpublishOwnFeed = pluginHandle => {
        pluginHandle.send({ message: { request: "unpublish" } })
    }

    handleNewPublisherPluginHandle = handle => {
        this.publisherHandle = handle
        this.setState({pubHandleId:this.publisherHandle.getId()})
        console.log("janus: plugin attached:(" + this.publisherHandle.getPlugin() + ", id=" + this.publisherHandle.getId() + ")")
    }


    handlePublisherSDP = (msg,jsep, pluginHandle) => {
        if (!jsep) return
        console.log("Handling SDP as well...", jsep)
        pluginHandle.handleRemoteJsep({ jsep: jsep })
        // Check if any of the media we wanted to publish has
        // been rejected (e.g., wrong or unsupported codec)
        var audio = msg["audio_codec"]
        let mystream = this.ownFeedHandle
        if(mystream && mystream.getAudioTracks() && mystream.getAudioTracks().length > 0 && !audio) {
            // Audio has been rejected
            toastr.warning("Our audio stream has been rejected, viewers won't hear us")
        }
        var video = msg["video_codec"]
        if(mystream && mystream.getVideoTracks() && mystream.getVideoTracks().length > 0 && !video) {
            // Video has been rejected
            console.log('video stream rejected')
            // Hide the webcam video
        }
    }

    handleSubscriberSDP = (id, jsep) => {
        let pluginHandle = this.otherFeedHandleDict[id]
        console.log("janus: subscriber handling SDP offered by remote publisher:", jsep)
        // Answer and attach
        pluginHandle.createAnswer({
            jsep: jsep,
            // Add data:true here if you want to subscribe to datachannels as well
            // (obviously only works if the publisher offered them in the first place)
            track: { audioSend: false, videoSend: false },	// We want recvonly audio/video
            success: jsep => {
                console.log("janus: local SDP answer created:", jsep)
                pluginHandle.send({
                    message: {
                        request: "start",
                        room: this.props.janusRoomId
                    },
                    jsep: jsep 
                })
            },
            error: err => {
                console.error("janus: WebRTC error:", err)
            }
        })
    }

    newRemoteFeed = (janus, opaqueId, id, display, audio, video) => {
        // A new feed has been published, create a new plugin handle and attach to it as a subscriber
        janus.attach({
            plugin: "janus.plugin.videoroom",
            opaqueId: opaqueId,
            success: pluginHandle => {
                this.otherFeedHandleDict[id] = pluginHandle
                this.otherFeedHandleDict[id].simulcastStarted = false
                console.log('handle:', pluginHandle, "Plugin attached! (", pluginHandle.getPlugin(),", id=" ,  pluginHandle.getId(), ")")
                console.log("  -- This is a subscriber")
                // We wait for the plugin to send us an offer
                pluginHandle.videoCodec = video
                pluginHandle.send({ message: {
                    request: "join",
                    room: this.props.janusRoomId,
                    pin: this.state.pin,
                    ptype: "subscriber",
                    feed: id,
                    private_id: assignedPrivateId
                }})
            },
            error: err => {
                console.error("janus: err:  -- Error attaching plugin...", err)
                delete this.otherFeedHandleDict[id]
                this.otherVideoDict[id].load()
            },
            onmessage: (message,jsep) => {
                this.handleSubscriberMessage(id,message)
                if (jsep) this.handleSubscriberSDP(id,jsep)
            },
            iceState: state => {
                console.log("ICE state of this WebRTC PeerConnection (feed #" + id + ") changed to " + state)
            },
            webrtcState: isConnected => {
                console.log(`janus: subscriber ${id} webrtcState: isConnected:`,isConnected)
            },
            onlocaltrack: (track, on) => {}, // The subscriber stream is recvonly, we don't expect anything here
            onremotetrack: (track, mid, on, metadata) => {
                console.log("Remote feed #" + id + ", track:", track, 'mid:', mid, 'on:', on , 'metadata:',metadata)
                console.log('plugin:',this.otherFeedHandleDict[id])
                let stream = new MediaStream([track])
                this.otherVideoDict[id].srcObject = stream
                let videoTracks = stream.getVideoTracks()
                if(!videoTracks || videoTracks.length === 0) {
                    console.log('janus: no remote video')
                }
                let audioTracks = stream.getAudioTracks()
                if(!audioTracks?.length > 0) {
                    console.log('janus: no remote audio')
                }
            },
            oncleanup: _ => {
                console.log(" ::: Got a cleanup notification (remote feed " + id + ") :::")
                delete this.otherFeedHandleDict[id]
                this.otherVideoDict[id].load()
            }
        })
    }

    handleSubscriberMessage = (id, msg) => {
        let subPuginHandle = this.otherFeedHandleDict[id]
        Janus.debug(`janus: subscriber ${id} got a message`, msg)
        if(msg.error) {
            console.log('janus: error:',msg.error)
            return
        }
        if(msg.videoroom == "attached") {
            // Subscriber created and attached
            subPuginHandle.rfid = msg.id
            subPuginHandle.rfdisplay = msg.display
            return
        }
        if(msg.videoroom == "event") {
            // Check if we got a simulcast-related event from this publisher
            let substream = msg.substream
            let temporal = msg.temporal
            if((substream !== null && substream !== undefined) || (temporal !== null && temporal !== undefined)) {
                if(!subPuginHandle.simulcastStarted) {
                    subPuginHandle.simulcastStarted = true
                    // Add some new buttons
                    // addSimulcastButtons(subPuginHandle.rfindex, subPuginHandle.videoCodec === "vp8" || subPuginHandle.videoCodec === "h264")
                }
                // We just received notice that there's been a switch, update the buttons
                // updateSimulcastButtons(subPuginHandle.rfindex, substream, temporal)
            }
            return
        }
        console.log('janus: unhandled subscriber message:', id, msg)
    }

    leaveRemoteFeed = (id) => {
        this.otherFeedHandleDict[id].send({
            message: {'request':'leave'}
        })
    }

    handlePublisherMessage = (msg,pubPluginHandle) => {
        console.log('janus: handlePublisherMessage:',msg)
        switch(msg.videoroom) {
        case "joined":
            console.log("janus: joined room:" + msg.room + " with ID " + msg.id)
            this.setState({
                state:'publisherRegistered',
                assignedPeerId:msg.id,
                assignedPrivateId:msg.private_id,
                // otherPublishers:msg.publishers
            })
            break
        case "destroyed":
            console.log("The room has been destroyed!")
            return
        case 'event':
            console.log('event msg:', msg)
            break
        default:
            console.log('error: unhandled event:',msg.videoroom)
            return
        }
        if(msg.publishers) {
            console.log('janus: publisher: new publishers joined:',msg.publishers)
            return this.setState({otherPublishers: this.state.otherPublishers.concat(msg.publishers)}, _ => {
                this.state.otherPublishers.forEach( x => {
                    if (!x.is_existing) {
                        x.is_existing = true
                        this.newRemoteFeed(this.janus, janusOpaqueId, x.id, x.display, x.audio_codec, x.video_codec)
                    }
                })
            })
        }
        if(msg.leaving) { // One of the publishers has gone away?
            let publisherLeaving = msg.leaving
            console.log("janus: publisher leaving: " + publisherLeaving)
            return
        }
        if(msg.unpublished) {
            // One of the publishers has unpublished?
            let unpublished = msg.unpublished
            console.log("janus: publisher unpublished: " + unpublished)
            if(unpublished === 'ok') {
                // That's us
                pubPluginHandle.hangup()
                return
            }
            this.setState({otherPublishers:this.state.otherPublishers.filter(x=>x.id != msg.unpublished)})
            return
        }
        if(msg.error) {
            if(msg.error_code == 426) {
                // This is a "no such room" error: give a more meaningful description
                console.log(
                    "Apparently room does not exist... Do you have an updated janus.plugin.videoroom.jcfg configuration file? If not, make sure you copy the details of room from that sample in your current configuration file, then restart Janus and try again."
                )
                return
            }
            console.log('janus: err:',msg.error)
            return
        }
        console.log('janus: unhandled message:', msg)
    }

    attachJanusPluginPublisher = (janus,opaqueId) => {
        janus.attach({
            plugin: "janus.plugin.videoroom",
            opaqueId: opaqueId,
            success: pluginHandle => {
                this.handleNewPublisherPluginHandle(pluginHandle)
                this.setState({state:'pluginAttached'})
            },
            error: err => console.log('janus: attach: error:',err),
            consentDialog: on => console.log('janus: attach: consentDialog',on),
            iceState: state => console.log('janus: iceState:', state),
            webrtcState: isConnected => {
                console.log('janus: webrtcState: isConnected:',isConnected)
                this.setState({state: isConnected ? 'publishing' : 'publisherRegistered'})
            },
            mediaState: (medium, receiving, mid) => {
                console.log('janus: mediaState: medium, receiving, mid: ',medium, receiving,mid)
            },
            slowLink: state => {
                console.log('janus: slowLink: state:', state)
            },
            onmessage: (message,jsep) => {
                this.handlePublisherMessage(message,this.publisherHandle)
                if (jsep) this.handlePublisherSDP(message,jsep,this.publisherHandle)
            },
            onlocaltrack: (track, on) => {
                console.log('onlocaltrack:', track, on)
                let ownStream = new MediaStream([track])
                this.ownFeedHandle = ownStream
                // this.ownVideo.srcObject = ownStream
                console.log("Created local stream:", ownStream, 'tracks:', ownStream.getTracks(), 'videotracks:',ownStream.getVideoTracks())
                Janus.attachMediaStream(this.ownVideo, ownStream) // push stream to Janus server
            },
            onremotetrack: _ => {}, // publisher do not receive streams
            ondataopen: _ => console.log('janus: ondataopen'),
            ondata: _ => console.log('janus: ondata'),
            oncleanup: _ => {
                console.log('janus: subscriber: oncleanup')
                delete this.ownFeedHandle
            },
            ondetached: _ => console.log('janus: subscriber: detached')
        })
    }

    createJanusSession = token => {
        console.log('janus: creating session...')
        this.janus = new Janus({
            token: token,
            server: PUBLIC_PATH_ROOT + 'janus_tunnel/janus',
            iceServers:[
                 {
                     urls:["stun:turn.uxv.services:443"],
                 },
                {
                    urls:["turn:turn.uxv.services:443?transport=tcp"], // firefox not working, chrome ok
                    username:"turn_user_rapid",
                    credential:"turn_password_rapid"
                }
            ],
            // iceTransportPolicy:"relay", // not working, need fixing!
            success: _ => {
                this.setState({state:'sessionCreated'})
            },
            error: err => console.log('Janus: error:',err),
            destroyed: console.log('Janus: destroyed')
        })
    }

    destroyJanusSession = _ => {
        this.janus?.destroy()
    }

    render() {
        // console.log('live-view: state:', this.state)
        return (
            <div id='streamVideoContainer'>
                <button
                    class='button-normal'
                    onClick={_ => this.setState({showDetailSettings:!this.state.showDetailSettings})}
                >Janus Settings</button>
                { this.state.showDetailSettings && <Fragment>
                    <p>janusServerUrl: {PUBLIC_PATH_ROOT}janus </p>
                    <p>janusRoomId: {this.props.janusRoomId}</p>
                    <p>janusUsername: {this.props.janusUsername}</p>
                    <p>status: {this.state.state}</p>
                    <p>publisherPluginHandleId: {this.state.pubHandleId}</p>
                    <p>assignedPeerId:{this.state.assignedPeerId}</p>
                    <p>assignedPrivateId:{this.state.assignedPrivateId}</p>
                    <p>otherPublishers:{JSON.stringify(this.state.otherPublishers)}</p>
                    <div
                        type='submit'
                        id='signalLabel'
                    >
                        Stream
                    </div>
                    <video
                        id='video'
                        ref={x=>this.ownVideo = x}
                        controls
                        autoPlay
                        muted
                        playsInline
                        title='Live View'
                    >
                        {/* <source src='/video' type="video/mp4"/> */}
                    </video>
                    <button
                        class='button-large'
                        onClick={_ => this.createJanusSession(this.props.credentials.token)}
                        disabled={this.state.state != 'loaded'}
                    >
                        create Janus Session
                    </button>
                    <button
                        class='button-large'
                        onClick={_ => this.attachJanusPluginPublisher(this.janus,janusOpaqueId)}
                        disabled={this.state.state != 'sessionCreated'}
                    >
                        attach Janus Plugin
                    </button>
                    <button
                        class='button-large'
                        onClick={_ => this.registerPublisher(this.publisherHandle,this.props.janusRoomId,this.props.janusUsername,this.props.credentials.token)}
                        disabled={this.state.state != 'pluginAttached'}
                    >
                        register as Publisher
                    </button>
                    <button
                        class='button-large'
                        onClick={_ => this.publishOwnFeed(this.publisherHandle)}
                        disabled={this.state.state != 'publisherRegistered'}
                    >
                        publish Own Video
                    </button>
                    <button
                        class='button-large'
                        onClick={_ => this.unpublishOwnFeed(this.publisherHandle)}
                        disabled={this.state.state != 'publishing'}
                    >
                        unpublish Own Video
                    </button>
                    <button
                        class='button-large'
                        onClick={_ => this.destroyJanusSession()}
                        disabled={this.state.state == 'loaded'}
                    >
                        stop Janus Session
                    </button>
                </Fragment>}

                <table id='otherPublishers'><tbody>
                    <tr>
                        {/* <td>Publisher ID</td> */}
                        <td>Preview</td>
                        <td>Actions</td>
                    </tr>
                    { this.state.otherPublishers.length==0 ? <tr><td colSpan='3'>No Remote Feed Available</td></tr> :
                        this.state.otherPublishers.map(x =>
                            <tr key={x.id}>
                                {/* <td>{x.id}</td> */}
                                <td>
                                    <video
                                        id={`video_${x.id}`}
                                        class='remote-feed-video'
                                        ref={y => this.otherVideoDict[x.id] = y}
                                        autoPlay
                                        controls
                                        playsInline
                                        title={x.id}
                                    >
                                    </video>
                                </td>
                                <td>
                                    <button
                                        class='button-large'
                                        disabled={ x.is_existing == true}
                                        onClick={_ => this.newRemoteFeed(this.janus, janusOpaqueId, x.id, x.display, x.audio_codec, x.video_codec)}
                                    >
                                        Connect Feed
                                    </button>
                                    <button
                                        class='button-large'
                                        onClick={_ => this.leaveRemoteFeed(x.id)}
                                    >
                                        Hang Up
                                    </button>
                                </td>
                            </tr>
                        )
                    }
                </tbody></table>
            </div>
        )
    }

    componentWillUnmount() {
        console.log('closing janus connections...')
        this.destroyJanusSession()
    }
}

export default VideoRoomComponent
