import React,{Fragment} from 'react'
import './missionDetails.css'
import * as Task from './task'
import * as Base from './base'
import * as Poi from './poi'
import * as Geofence from './geofence'
import * as MissionEditor from '../missionEditor/missionEditor'
import * as MissionMap from '../map/map'
import * as Sora from '../sora/sora'
import * as WhitelistComponent from '../whitelist/whitelist'
import * as UxvComponents from '../uxv/uxv'
import * as olProj from 'ol/proj'
import { TimePicker } from '../timepicker/timepicker'
import { Qrcode } from '../qrcode/qrcode'
import * as ModelViewerComponent from '../modelViewer/modelViewer'
import { PotreeViewer } from '../potreeViewer/potreeViewer'

const redCircle = <svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="red" /></svg>
const goldCircle = <svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="gold" /></svg>
const greenCircle = <svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="green" /></svg>

export class LeftPanel extends React.Component {
    constructor(props) {
        super(props)
    }

    render = _ => {
        // console.log('missiondetail:',this.props.missionDetail)
        return <div>
            <h2>Mission Details for <code class='mission_name'>{this.props.missionDetail?.name}</code></h2>
            { this.props.currPage == 'loading' &&
                <p>Loading...</p>
            }
            { this.props.currPage == 'select_use_case' &&
                <p class='selected'>Select Use Case</p>
            }
            { ['loading', 'select_use_case'].includes(this.props.currPage) ||
                <Fragment>
                    <div class={'missionDetailNavButton' + (this.props.currPage == 'general' ? ' selected' : '')}
                        onClick={ _ => this.props.setCurrPage('general')}
                    >
                        <p>General</p>
                    </div>
                    <div class={'missionDetailNavButton' + (this.props.currPage == 'itinerary' ? ' selected' : '')}
                        onClick={ _ => this.props.setCurrPage('itinerary')}
                    >
                        <p>Itinerary</p>
                        <div class='missionDetailCounter'>{this.props.missionDetail?.itinerary?.length ?? 0}</div>
                    </div>
                    <div class={'missionDetailNavButton' + (this.props.currPage == 'geofence' ? ' selected' : '')}
                        onClick={ _ => this.props.setCurrPage('geofence')}
                    >
                        <p>Geofence</p>
                        <div class='missionDetailCounter'>{this.props.missionDetail?.geofences?.length ?? 0}</div>
                    </div>
                    <div class={'missionDetailNavButton' + (this.props.currPage == 'base' ? ' selected' : '')}
                        onClick={ _ => this.props.setCurrPage('base')}
                    >
                        <p>Base</p>
                        <div class='missionDetailCounter'>{this.props.missionDetail?.baseitems?.length ?? 0}</div>
                    </div>
                    <div class={'missionDetailNavButton' + (this.props.currPage == 'nfz' ? ' selected' : '')}
                        onClick={ _ => this.props.setCurrPage('nfz')}
                    >
                        <p>No-fly-zone</p>
                        <div class='missionDetailCounter'>{this.props.missionDetail?.nfzs?.length ?? 0}</div>
                    </div>
                    <div class={'missionDetailNavButton' + (this.props.currPage == 'poi' ? ' selected' : '')}
                        onClick={ _ => this.props.setCurrPage('poi')}
                    >
                        <p>Point-of-interest </p>
                        <div class='missionDetailCounter'>{this.props.missionDetail?.pois?.length ?? 0}</div>
                    </div>
                    <div class={'missionDetailNavButton' + (this.props.currPage == 'sora' ? ' selected' : '')}
                        onClick={ _ => this.props.setCurrPage('sora')}
                    >
                        <p>Risks</p>
                        <div class='missionDetailCounter'>{this.props.missionDetail?.sora?.length ?? 0}</div>
                    </div>
                    <div class={'missionDetailNavButton' + (this.props.currPage == 'review' ? ' selected' : '')}
                        onClick={ _ => this.props.setCurrPage('review')}
                    >
                        <p>Review and Submit</p>
                    </div>
                    <div class={'missionDetailNavButton' + (this.props.currPage == 'deploy' ? ' selected' : '')}
                        onClick={ _ => this.props.setCurrPage('deploy')}
                    >
                        <p>Deployment</p>
                    </div>
                    <div class={'missionDetailNavButton' + (this.props.currPage == 'result' ? ' selected' : '')}
                        onClick={ _ => this.props.setCurrPage('result')}
                    >
                        <p>Results</p>
                    </div>
                </Fragment>
            }
            <p onClick={ _ => this.props.routePreviousPage()}>Cancel</p>
            <Qrcode url={window.location.href}/>
        </div>}
}

export class RightPanel extends React.Component {

    updateCurrPage = _ => {
        this.props.setCurrPage(this.state.currPage)
        this.props.setMissionDetail(this.state.mission)
        this.missionEditor?.setState({currPage:this.state.currPage})
    }
    routePage(page) {
        this.setState({currPage: page}, this.updateCurrPage)
    }

    constructor(props) {
        super(props)
        this.state = {
            currPage: 'loading',
            mission: null
        }

        //create a map object once only
        this.map = new MissionMap.Map()

        this.map.eraseAllTasks()
        this.map.eraseAllBaseitems()
        if (this.props.mission_id) {
            this.fetchMission('general')
            this.fetchMissionUxvTracks()
            this.fetchMissionAdsbTracks()
            this.fetchMissionAisTracks()
        }
    }

    fetchMissionAdsbTracks() {
        fetch(PUBLIC_PATH_ROOT + `api/mission_adsb_tracks`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({
                token: this.props.credentials.token,
                mission_id:this.props.mission_id
            })
        })
        .then(res => res.json())
        .then(res => {
            if (!res.success) throw res.reason
            console.log('mission_adsb_tracks:',res.adsb_tracks.length)
            res.adsb_tracks.map( adsb_track => {
                this.map.plotTrafficTrack('adsb',adsb_track)
                this.map.plotTrafficTrackBeacons('adsb',adsb_track)
            })
            // this.missionEditor.setSeekTrackBeacon(first_timestamp.toISOString())
        })
        .catch(err => {
            console.log('err:',err)
        })
    }
    fetchMissionAisTracks() {
        fetch(PUBLIC_PATH_ROOT + `api/mission_ais_tracks`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({
                token: this.props.credentials.token,
                mission_id:this.props.mission_id
            })
        })
        .then(res => res.json())
        .then(res => {
            if (!res.success) throw res.reason
            console.log('mission_ais_tracks:',res.ais_tracks.length)
            res.ais_tracks.map( ais_track => {
                this.map.plotTrafficTrack('ais',ais_track)
                this.map.plotTrafficTrackBeacons('ais',ais_track)
            })
            // this.missionEditor.setSeekTrackBeacon(first_timestamp.toISOString())
        })
        .catch(err => {
            console.log('err:',err)
        })
    }
    fetchMissionUxvTracks() {
        fetch(PUBLIC_PATH_ROOT + `api/mission_uxv_tracks`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({
                token: this.props.credentials.token,
                mission_id:this.props.mission_id
            })
        })
        .then(res => res.json())
        .then(res => {
            if (!res.success) throw res.reason
            console.log('mission_uxv_track:',res.uxv_tracks)
            let first_timestamp = new Date(Math.min(...res.uxv_tracks.map(x => new Date(x.start_at))))
            console.log('first_timestamp:', first_timestamp)
            res.uxv_tracks.map( uxv_track => {
                this.map.plotUxvTrack(uxv_track)
                this.map.plotTrackBeacons(uxv_track)
            })
            this.missionEditor.setSeekTrackBeacon(first_timestamp.toISOString())
        })
        .catch(err => {
            console.log('err:',err)
        })
    }

    fetchMission(next_page) {
        fetch(PUBLIC_PATH_ROOT + `api/mission/${this.props.mission_id}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({ token: this.props.credentials.token })
        })
        .then(res => res.json())
        .then(res => {
            if (!res.success) throw res.reason
            console.log('mission:',res.mission)
            this.setState({
                currPage: next_page ?? this.state.currPage,
                mission: res.mission,
            }, this.updateCurrPage)

            res.mission.geofences?.map( geofence => {
                this.map.plotGeofence(geofence)
            })

            res.mission.baseitems?.map( baseitem => {
                this.map.plotBaseitem(baseitem)
            })

            res.mission.itinerary?.map( task => {
                this.map.plotTask(task)
            })

            res.mission.routes?.map( route => {
                // console.log('route:', route)
                this.map.plotRoute(route)
            })

            res.mission.pois?.map( poi => {
                this.map.plotPoi(poi)
            })

            if (res.mission.geofence_extent) {
                this.map.zoomTo(res.mission.geofence_extent)
            } else if (res.mission.task_extent) {
                this.map.zoomTo(res.mission.task_extent)
            }
        })
        .catch(err => {
            console.log('get mission error0:',err)
        })
    }

    deployUxvAll() {
        this.setState({settings_state:'waiting', uxvlist_token: null}, _ => {
            fetch(PUBLIC_PATH_ROOT + `api/deploy_uxv_mission_all`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                body: JSON.stringify({
                    token: this.props.credentials.token,
                    mission_id: this.props.mission_id
                })
            })
            .then(res => res.json())
            .then( res => {
                console.log('res:',res)
                if (res.success) {
                    this.setState({settings_state:'idle'}, _ => this.fetchMission())
                } else throw res.reason
            })
            .catch( err => {
                console.log('err:',err)
                this.setState({settings_state:'idle'}, _ => this.fetchMission())
            })
        })
    }

    planSwarm() {
        fetch(PUBLIC_PATH_ROOT + 'api/plan_swarm', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({
                token: this.props.credentials.token,
                mission_id: this.props.mission_id
            })
        })
        .then(res => res.json())
        .then(res => {
            if (!res.success) throw res.reason
            let swarm_mission = JSON.parse(res.swarm_mission)
            this.setState({
                swarm_mission:swarm_mission
            }, this.updateCurrPage)

            console.log('swarm mission:',swarm_mission)
            this.map.source_route.clear()
            swarm_mission.swarm_mission.map( x => {
                this.map.plotSwarmRoute?.(x)
            })
        })
        .catch(err => {
            console.log('err:',err)
        })
    }

    updateGeofences() {
        this.map.eraseAllGeofences()
        if (this.props.mission_id) {
            fetch(PUBLIC_PATH_ROOT + `api/mission/${this.props.mission_id}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                body: JSON.stringify({ token: this.props.credentials.token })
            })
            .then(res => res.json())
            .then(res => {
                if (!res.success) throw res.reason
                this.setState({
                    mission: res.mission,
                    selected_geofence_id: null
                }, this.updateCurrPage)

                res.mission.geofences?.map( geofence => {
                    console.log('geofence:',geofence)
                    this.map.plotGeofence(geofence)
                })
            })
            .catch(err => {
                console.log('get mission error1:',err)
            })
        }
    }

    updateBaseitems() {
        this.map.eraseAllBaseitems()
        if (this.props.mission_id) {
            fetch(PUBLIC_PATH_ROOT + `api/mission/${this.props.mission_id}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                body: JSON.stringify({ token: this.props.credentials.token })
            })
            .then(res => res.json())
            .then(res => {
                if (!res.success) throw res.reason
                this.setState({
                    mission: res.mission,
                    selected_baseitem_id: null
                }, this.updateCurrPage)

                res.mission.baseitems?.map( baseitem => {
                    console.log('baseitem:',baseitem)
                    this.map.plotBaseitem(baseitem)
                })
            })
            .catch(err => {
                console.log('get mission error2:',err)
            })
        }
    }

    updateTasks() {
        this.map.eraseAllTasks()
        if (this.props.mission_id) {
            fetch(PUBLIC_PATH_ROOT + `api/mission/${this.props.mission_id}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                body: JSON.stringify({ token: this.props.credentials.token })
            })
            .then(res => res.json())
            .then(res => {
                if (!res.success) throw res.reason
                this.setState({
                    mission: res.mission,
                    selected_task_id: null
                }, this.updateCurrPage)

                res.mission.itinerary?.map( task => {
                    this.map.plotTask(task)
                })
                res.mission.routes?.map( route => {
                    this.map.plotRoute(route)
                })
            })
            .catch(err => {
                console.log('get mission error3:',err)
            })
        }
    }

    updatePois() {
        this.map.eraseAllPois()
        if (this.props.mission_id) {
            fetch(PUBLIC_PATH_ROOT + `api/mission/${this.props.mission_id}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                body: JSON.stringify({ token: this.props.credentials.token })
            })
            .then(res => res.json())
            .then(res => {
                if (!res.success) throw res.reason
                this.setState({
                    mission: res.mission,
                    selected_poi_id: null
                }, this.updateCurrPage)

                res.mission.pois?.map( poi => {
                    this.map.plotPoi(poi)
                })
            })
            .catch(err => {
                console.log('get mission error4:',err)
            })
        }
    }

    updateMapSize() {
        this.map?.updateSize()
    }

    componentDidMount() {
        window.history.pushState(null,`/mission/${this.props.mission_id}`,`/mission/${this.props.mission_id}`)
        this.map.locationMarker.setActive(true)
        this.map.props.onMarkerDrawEnd = feature => {
            // console.log('onMarkerDrawEnd:feature:', feature)
            this.setState({marked_coordinates: olProj.transform(feature.getGeometry().getCoordinates(), 'EPSG:3857', 'EPSG:4326')})
        }
        this.setState({currPage:'general'}, _ => this.updateCurrPage())
    }

    componentWillUnmount() {

    }

    render(){
        return (
        <div id='missionDetailContainer' ref={ x=> this.missionDetailContainer = x}>

            {/* title section */}
            { this.state.currPage == 'loading' && <h2>Loading...</h2> }
            { this.state.currPage == 'general' && <h2>General Infomation</h2> }
            { this.state.currPage == 'base' && <h2>Edit Base</h2> }
            { this.state.currPage == 'geofence' && <h2>Edit Geofence</h2> }
            { this.state.currPage == 'nfz' && <h2>Edit No-fly-zone</h2> }
            { this.state.currPage == 'poi' && <h2>Edit Point-of-interest</h2> }
            { this.state.currPage == 'itinerary' && <h2>Edit Itinerary</h2> }
            { this.state.currPage == 'sora' && <h2>Specific Operation Risk Assessment</h2> }
            { this.state.currPage == 'review' && <h2>Summary of Mission</h2> }

            {/* contents */}
            { this.state.currPage == 'general' && <div class='flex'>

                <table class='table-normal container'>
                    <tbody>
                        <tr><th>ID</th><td>{this.state.mission?.id}</td></tr>
                        <tr>
                            <th>Name</th>
                            {this.state.is_mission_edit ?
                            <td><input
                                class='input-fullwidth'
                                name='name'
                                placeholder='name of mission'
                                value={this.state.temp_mission_name ?? ''}
                                onChange={ e => this.setState({temp_mission_name:e.target.value})}
                            /></td>
                            :
                            <td><code class='mission_name'>{this.state.mission?.name}</code></td>
                            }
                        </tr>
                        <tr><th>Use Case</th><td>{this.state.mission?.use_case.description}</td></tr>
                        <tr>
                            <th>Description</th>
                            {this.state.is_mission_edit ?
                            <td><textarea
                                name='description'
                                placeholder='description of mission'
                                value={this.state.temp_mission_description ?? ''}
                                onChange={ e => {
                                    // console.log('e:',e)
                                    this.setState({temp_mission_description:e.target.value})
                                }}
                            /></td>
                            :
                            <td>{this.state.mission?.description}</td>
                            }
                        </tr>
                        <tr><th>Start At</th><td>{this.state.mission?.start_at}</td></tr>
                        <tr><th>End At</th><td>{this.state.mission?.end_at}</td></tr>
                        <tr><td colSpan='2'>
                            { this.state.is_mission_edit || <button
                                class='button-normal'
                                onClick={ _ =>
                                    this.setState({
                                        is_mission_edit:true,
                                        temp_mission_description:this.state.mission?.description,
                                        temp_mission_name: this.state.mission?.name
                                    })
                                }
                            >Edit</button>}
                            { this.state.is_mission_edit && <Fragment>
                            <button
                                class='button-normal'
                                onClick={ _ => {
                                    fetch(PUBLIC_PATH_ROOT + 'api/edit_mission', {
                                        method: 'POST',
                                        headers: { 'Content-Type': 'application/json;charset=utf-8' },
                                        body: JSON.stringify({
                                            token: this.props.credentials.token,
                                            mission_id:this.props.mission_id,
                                            name: this.state.temp_mission_name,
                                            description: this.state.temp_mission_description
                                        })
                                    })
                                    .then(res => res.json())
                                    .then(res => {
                                        if (!res.success) throw res
                                        this.setState({is_mission_edit:false})
                                        this.fetchMission()
                                    })
                                    .catch(err => {
                                        console.log('err:',err)
                                    })
                                }}
                            >Confirm</button>
                            <button
                                class='button-normal'
                                onClick={_ => this.setState({is_mission_edit:false})}
                            >Cancel</button>
                            </Fragment>}
                        </td></tr>
                    </tbody>
                </table>
                
                <div class='container'>
                    <h3>ConOps</h3>
                    <textarea value={this.state.mission?.conops}/>
                    <details>
                    <summary>More...</summary>
                    <p>
                        Concept of Operation. Describe the type of operation, its scope(s), its main characteristics and the hazards it poses to third parties on ground, in the air and on critical infrastructure (as defined by the Authority)
                    </p>
                    </details>
                </div>

                <div class='container'>
                    <h3>Users</h3>
                    <WhitelistComponent.Whitelist
                        credentials={this.props.credentials}
                        resource_type={'mission'}
                        resource_id={this.props.mission_id}
                    />
                </div>

                <div class='container'>
                    <h3>UXVs</h3>
                    <UxvComponents.UxvList
                        credentials={this.props.credentials}
                        uxvs={this.state.mission?.uxvs}
                        mission_id={this.props.mission_id}
                        on_uxvlist_update={ _ => this.fetchMission()}
                    />
                </div>

                <div class='container'>
                    <h3>Action</h3>
                    <button
                        className='button-normal'
                        onClick={ _ => {
                            fetch(PUBLIC_PATH_ROOT + `api/set_mission_is_archived`, {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                                body: JSON.stringify({ token: this.props.credentials.token, mission_id:this.props.mission_id, is_archived:true})
                            })
                            .then(res => res.json())
                            .then(res => {
                                if (!res.success) throw res
                                this.props.routePreviousPage()
                            })
                            .catch(err => {
                                console.log('err:',err)
                            })
                        }}
                    >Archive Mission</button>
                </div>

                <div class='container'><MissionEditor.Viewer
                    map={this.map}
                    mapSize={'medium'}
                    ref={x=> this.missionEditor = x}
                    currPage='general'
                />
                </div>
                
                

            </div>}

            { this.state.currPage == 'base' && <div>
                <MissionEditor.Viewer
                    map={this.map}
                    mapSize={'medium'}
                    ref={x=> this.missionEditor = x}
                    currPage='base'
                    onBaseitemSelected={baseitem_id => this.setState({selected_baseitem_id: baseitem_id})}
                    onBaseitemDeselected={ _ => this.setState({selected_baseitem_id: null})}
                />
                <table class='table-normal full-width'>
                    <thead>
                        <tr><th>Index</th><th>Callsign</th><th>Name</th><th>Type</th><th>Actions</th></tr>
                    </thead>
                    <tbody>
                        { this.state.mission?.baseitems ?
                            this.state.mission?.baseitems.map( (x,i) =>
                                <tr key={i} class={this.state.selected_baseitem_id == x.id ? 'highlighted' : ''}>
                                    <td>{x.id}</td>
                                    <td>{x.callsign}</td>
                                    <td>{x.name}</td>
                                    <td>{x.baseitem_type}</td>
                                    <td>
                                        { this.state.selected_baseitem_id==null &&
                                            <button
                                                className='button-normal'
                                                onClick={ _ => this.map.selectBaseitem(x.id) }
                                            >Select</button>
                                        }
                                        { this.state.selected_baseitem_id==x.id &&
                                            <button
                                                className='button-normal'
                                                onClick={ _ => this.setState({currPage:'baseitem_detail'}) }
                                            >Detail</button>
                                        }
                                        { this.state.selected_baseitem_id==x.id &&
                                            <button
                                                className='button-normal'
                                                onClick={ _ => this.map.selectBaseitem(null) }
                                            >Cancel</button>
                                        }
                                    </td>
                                </tr>
                            )
                        :
                            <tr><td colSpan='5'>Base Item List Empty</td></tr>
                        }
                        <tr><td colSpan='5'>
                            <button
                                disabled= {this.state.selected_task_id != null}
                                class='button-normal'
                                onClick={_ => this.setState({currPage:'add_base'})}
                            >
                                Add Base
                            </button>
                        </td></tr>
                    </tbody>
                </table>
            </div>}

            { this.state.currPage == 'geofence' && <div>
                <MissionEditor.Viewer
                    map={this.map}
                    mapSize={'medium'}
                    ref={x=> this.missionEditor = x}
                    currPage='geofence'
                    onGeofenceSelected={geofence_id => this.setState({selected_geofence_id: geofence_id})}
                    onGeofenceDeselected={ _ => this.setState({selected_geofence_id: null})}
                />
                <table class='table-normal full-width'>
                <thead>
                    <tr><th>ID</th><th>UXV</th><th>Geofence Type</th><th>End Time</th><th>Actions</th></tr>
                </thead>
                <tbody>
                    { this.state.mission?.geofences ?
                        this.state.mission?.geofences.map( (x,i) =>
                            <tr key={i} class={this.state.selected_geofence_id == x.id ? 'highlighted' : ''}>
                                <td>{x.id}</td>
                                <td><code class='username'>{x.uxv?.codename}</code>{x.uxv?.uxv_type}</td>
                                <td>{x.geofence_type}</td>
                                <td>{x.end_at}</td>
                                <td>
                                    { this.state.selected_geofence_id==null &&
                                        <button
                                            className='button-normal'
                                            onClick={ _ => this.map.selectGeofence(x.id) }
                                        >Select</button>
                                    }
                                    { this.state.selected_geofence_id==x.id &&
                                        <button
                                            className='button-normal'
                                            onClick={ _ => this.setState({currPage:'geofence_detail'}) }
                                        >Detail</button>
                                    }
                                    { this.state.selected_geofence_id==x.id &&
                                        <button
                                            className='button-normal'
                                            onClick={ _ => this.map.selectGeofence(null) }
                                        >Cancel</button>
                                    }
                                </td>
                            </tr>
                        )
                    :
                        <tr><td colSpan='5'>Geofence List Empty</td></tr>
                    }
                    <tr><td colSpan='5'>
                        <button
                            disabled= {this.state.selected_geofence_id != null}
                            class='button-normal'
                            onClick={_ => this.setState({currPage:'add_geofence'})}
                        >
                            Add Geofence
                        </button>
                    </td></tr>
                </tbody>
                </table>
            </div>}
            { this.state.currPage=='add_geofence' && <div>
                <Geofence.GeofenceCreator
                    currPage={'add_geofence'}
                    initMapState={'no-area-focused'}
                    mission_id={this.props.mission_id}
                    credentials={this.props.credentials}
                    routePreviousPage={ _ => {
                        this.setState({currPage:'geofence'})
                        this.updateGeofences()
                    }}
                    map={this.map}
                />
            </div>}
            { this.state.currPage=='geofence_detail' && <div>
                <Geofence.GeofenceCreator
                    currPage={'edit_geofence'}
                    initMapState={'area-focused'}
                    existing_geofence = {this.state.mission.geofences.find(x => x.id == this.state.selected_geofence_id)}
                    mission_id={this.props.mission_id}
                    credentials={this.props.credentials}
                    routePreviousPage={ _ => {
                        this.setState({currPage:'geofence'})
                        this.updateGeofences()
                    }}
                    map={this.map}
                />
            </div>}


            { this.state.currPage == 'poi' && <div>
                <MissionEditor.Viewer
                    map={this.map}
                    mapSize={'medium'}
                    ref={x=> this.missionEditor = x}
                    currPage='poi'
                    onPoiSelected={poi_id => this.setState({selected_poi_id: poi_id})}
                    onPoiDeselected={ _ => this.setState({selected_poi_id: null})}
                />
                <table class='table-normal full-width'>
                    <thead>
                        <tr><td colSpan='5'>
                            <button
                                disabled= {this.state.selected_poi_id != null}
                                class='button-normal'
                                onClick={_ => this.setState({currPage:'add_poi'})}
                            >
                                Add POI
                            </button>
                        </td></tr>
                        <tr><th>Index</th><th>Callsign</th><th>Name</th><th>Type</th><th>Actions</th></tr>
                    </thead>
                    <tbody>
                        { this.state.mission?.pois ?
                            this.state.mission?.pois.map( (x,i) =>
                                <tr key={i} class={this.state.selected_poi_id == x.id ? 'highlighted' : ''}>
                                    <td>{x.id}</td>
                                    <td>{x.callsign}</td>
                                    <td>{x.name}</td>
                                    <td>{x.poi_type}</td>
                                    <td>
                                        { this.state.selected_poi_id==null &&
                                            <button
                                                className='button-normal'
                                                onClick={ _ => this.map.selectPoi(x.id) }
                                            >Select</button>
                                        }
                                        { this.state.selected_poi_id==x.id &&
                                            <button
                                                className='button-normal'
                                                onClick={ _ => this.setState({currPage:'baseitem_detail'}) }
                                            >Detail</button>
                                        }
                                        { this.state.selected_poi_id==x.id &&
                                            <button
                                                className='button-normal'
                                                onClick={ _ => this.map.selectPoi(null) }
                                            >Cancel</button>
                                        }
                                    </td>
                                </tr>
                            )
                        :
                            <tr><td colSpan='5'>POI Item List Empty</td></tr>
                        }
                    </tbody>
                </table>
            </div>}

            { this.state.currPage=='add_poi' && <div>
                <Poi.PoiCreator
                    mission_id={this.props.mission_id}
                    credentials={this.props.credentials}
                    routePreviousPage={ _ => {
                        this.setState({currPage:'poi'})
                        this.updatePois()
                    }}
                    map={this.map}
                />
            </div>}
            

            { this.state.currPage == 'itinerary' && <div>
                <MissionEditor.Viewer
                    map={this.map}
                    mapSize={'medium'}
                    ref={x=> this.missionEditor = x}
                    readOnly={false}
                    editMode={true}
                    onState={state => console.log('MissionEditor: state:',state)}
                    currPage='itinerary'
                    setTasks={tasks => this.handleTaskSet(tasks)}
                    onTaskSelected={task_id => this.setState({selected_task_id: task_id})}
                    onTaskDeselected={ _ => this.setState({selected_task_id: null})}
                />
                <button
                    class='button-normal'
                    onClick={ _ => {
                        
                    }}
                >Next Task</button>

                <button
                    class='button-normal'
                    onClick={ _ => {
                        this.planSwarm()
                    }}
                >Plan for Swarm</button>

                <hr/>
                {/* <label>Tasks Filter</label>
                <select
                    value={this.state.selected_filter_uxv_id}
                    onChange={ e =>{
                        if (e.target.value == '') {
                            return this.setState({selected_filter_uxv_id: null})
                        }
                        this.setState({selected_filter_uxv_id: e.target.value})
                    }}
                >
                    <option value=''>All</option>
                    { this.state.mission?.uxvs?.map( x =>
                        <option key={x.id} value={x.id}>{x.codename}</option>
                    )}
                </select> */}
                {/* <hr/> */}

                <table class='table-normal full-width'>
                    <thead>
                        <tr><td colSpan='6'>
                            <button
                                disabled= {this.state.selected_task_id != null}
                                class='button-normal'
                                onClick={ _ => {
                                    this.props.scrollToTop()
                                    this.setState({currPage:'add_task'})
                                }}
                            >
                                Add Task
                            </button>
                        </td></tr>
                        <tr><th>Step</th><th>UXV</th><th>Task Type</th><th>End Time</th><th>Time Offset</th><th>Actions</th></tr>
                    </thead>
                    <tbody>
                        { this.state.mission?.itinerary ?
                            this.state.mission?.itinerary
                            .filter(x =>
                                this.state.selected_filter_uxv_id ? this.state.selected_filter_uxv_id == x.uxv?.id : true)
                            .map( x =>
                                <tr key={x.row_id} class={this.state.selected_task_id == x.id ? 'highlighted' : ''}>
                                    <td>{x.row_id}</td>
                                    <td><code class='username'>{x.uxv?.codename}</code> ({x.uxv?.uxv_type})</td>
                                    <td>{x.task_type}</td>
                                    <td>{x.key_time}</td>
                                    <td>T+{(Date.parse(x.key_time) - Date.parse(this.state.reference_time))/ 60000}</td>
                                    <td>
                                        { this.state.selected_task_id==null &&
                                            <button
                                                className='button-normal'
                                                onClick={ _ => {
                                                    if (!x.checkpoint_polygon) {
                                                        this.setState({selected_task_id: x.id})
                                                        return
                                                    }
                                                    this.map.selectTask(x.id)
                                                }}
                                            >Select</button>
                                        }
                                        { this.state.selected_task_id==x.id &&
                                            <button
                                                className='button-normal'
                                                onClick={ _ => this.setState({currPage:'task_detail'}) }
                                            >Detail</button>
                                        }
                                        { this.state.selected_task_id==x.id &&
                                            <button
                                                className='button-normal'
                                                onClick={ _ => this.map.selectTask(null) }
                                            >Cancel</button>
                                        }
                                    </td>
                                </tr>
                            )
                        :
                            <tr><td colSpan='6'>Itinerary Empty</td></tr>
                        }
                    </tbody>
                </table>
                <hr/>
                <p><label>Reference Time</label></p>
                <TimePicker
                    ref={x => this.timePicker=x}
                    showTz={false}
                    presetTimestamp={this.state.mission?.itinerary?.[0].key_time}
                    onUpdate={ x => {
                        let ref_time = new Date(Date.parse(this.state.mission?.itinerary?.[0].key_time))
                        ref_time.setHours(x.hour)
                        ref_time.setMinutes(x.minute)
                        this.setState({reference_time: ref_time})
                    }}
                />
            </div>}

            { this.state.currPage=='add_task' && <div>
                <Task.TaskCreator
                    itinerary={this.state.mission?.itinerary}
                    mission_id={this.props.mission_id}
                    credentials={this.props.credentials}
                    routePreviousPage={ _ => {
                        this.setState({currPage:'itinerary'})
                        this.updateTasks()
                    }}
                    map={this.map}
                />
            </div>}

            { this.state.currPage=='task_detail' && <div>
                <Task.TaskCreator
                    itinerary={this.state.mission?.itinerary}
                    existing_task = {this.state.mission.itinerary.find(x => x.id == this.state.selected_task_id)}
                    mission_id={this.props.mission_id}
                    credentials={this.props.credentials}
                    routePreviousPage={ _ => {
                        this.setState({currPage:'itinerary'})
                        this.updateTasks()
                    }}
                    map={this.map}
                />
            </div>}

            { this.state.currPage=='add_base' && <div>
                <Base.BaseCreator
                    mission_id={this.props.mission_id}
                    credentials={this.props.credentials}
                    routePreviousPage={ _ => {
                        this.setState({currPage:'base'})
                        this.updateBaseitems()
                    }}
                    map={this.map}
                />
            </div>}

            { this.state.currPage=='baseitem_detail' && <div>
                <Base.BaseCreator
                    existing_baseitem = {this.state.mission.baseitems.find(x => x.id == this.state.selected_baseitem_id)}
                    mission_id={this.props.mission_id}
                    credentials={this.props.credentials}
                    routePreviousPage={ _ => {
                        this.setState({currPage:'base'})
                        this.updateBaseitems()
                    }}
                    map={this.map}
                />
            </div>}

            { this.state.currPage=='sora' && <div>
                <Sora.Sora
                    mission_id={this.props.mission_id}
                    credentials={this.props.credentials}
                />
            </div>}

            { this.state.currPage=='deploy' && <div>
                <h3>User Check in Status</h3>
                
                <h3>Vehicle Mount Status</h3>
                <button
                    class='button-normal'
                    onClick={ _ => this.deployUxvAll()}
                >
                    Deploy All UXVs
                </button>
                <UxvComponents.UxvList
                    credentials={this.props.credentials}
                    uxvs={this.state.mission?.uxvs}
                    mission_id={this.props.mission_id}
                    on_uxvlist_update={ _ => this.fetchMission()}
                />

                {/* <h3>Deploy Mission</h3>
                <label>Deploy UXV</label>
                <select
                    value={this.state.selected_filter_uxv_id}
                    onChange={ e =>{
                        if (e.target.value == '') {
                            return this.setState({selected_filter_uxv_id: null})
                        }
                        this.setState({selected_filter_uxv_id: e.target.value})
                    }}
                >
                    <option value=''>All</option>
                    { this.state.mission?.uxvs?.map( x =>
                        <option key={x.id} value={x.id}>{x.codename}</option>
                    )}
                </select>
                { this.state.selected_filter_uxv_id && <Fragment>
                    <label>Itinerary</label>
                    <table class='table-normal full-width'>
                        <thead>
                            <tr><th>Step</th><th>UXV</th><th>Task Type</th><th>End Time</th><th>Time Offset</th></tr>
                        </thead>
                        <tbody>
                            { this.state.mission?.itinerary ? <Fragment>
                                {this.state.mission?.itinerary
                                .filter(x =>
                                    this.state.selected_filter_uxv_id ? this.state.selected_filter_uxv_id == x.uxv?.id : true)
                                .map( x =>
                                    <tr key={x.row_id} class={this.state.selected_task_id == x.id ? 'highlighted' : ''}>
                                        <td>{x.row_id}</td>
                                        <td><code class='username'>{x.uxv?.codename}</code> ({x.uxv?.uxv_type})</td>
                                        <td>{x.task_type}</td>
                                        <td>{x.key_time}</td>
                                        <td>{ (Date.parse(x.key_time) - Date.parse(this.state.reference_time))/ 60000 || ''}</td>
                                    </tr>
                                )}
                                <tr>
                                    <td colSpan='5'>
                                        <button
                                            class='button-normal'
                                            onClick={ _ => {
                                                let filtered_itinerary = this.state.mission?.itinerary
                                                .filter(x => this.state.selected_filter_uxv_id ? this.state.selected_filter_uxv_id == x.uxv?.id : true)
                                                this.deployItinerary(filtered_itinerary)
                                            }}
                                        >Deploy Mission</button>
                                    </td>
                                </tr>
                                </Fragment>
                            :
                                <tr><td colSpan='6'>Itinerary Empty</td></tr>
                            }
                        </tbody>
                    </table>
                </Fragment>} */}
            </div>}

            { this.state.currPage=='review' && <div>
                <PotreeViewer/>
            </div>}
            

            { this.state.currPage=='result' && (
                <ModelViewerComponent.ModelViewer
                    alt="3d_model"
                    src={`${PUBLIC_PATH_ROOT}3d/harburg_lock.glb`}
                    ar=''
                    ar-placement="wall"
                    // environment-image="shared-assets/environments/moon_1k.hdr"
                    // poster="shared-assets/models/NeilArmstrong.webp"
                    shadow-intensity="1"
                    camera-controls
                    touch-action="pan-y"
                    min-camera-orbit='auto auto 1m'
                    max-camera-orbit='auto auto 100%'
                />
            )}


        </div>)
    }
}
