import React, { Fragment } from 'react'
import '../../style.css'
import { TimePicker } from '../timepicker/timepicker'
import Calender from '../calender/calender'

export class ControlPanel extends React.Component {

    constructor(props) {
        super(props)
    }

    render = _ =>
        <div
            class={ 'controlPanel' + (this.props.isFullscreen ? ' fullscreen' : '')}
        >
            <button
                key='interactions'
                class={ this.props.layerVisibilities['interactions'] ? 'button-toggle-normal active':'button-toggle-normal'}
                onClick={ _ => {
                    let newVisibilities = JSON.parse(JSON.stringify(this.props.layerVisibilities))
                    newVisibilities['interactions'] = !newVisibilities['interactions']
                    this.props.setLayerVisibilities(newVisibilities)
                }}
            >Interactions</button>
            <button
                key='traffic'
                class={ this.props.layerVisibilities['traffic'] ? 'button-toggle-normal active':'button-toggle-normal'}
                onClick={ _ => {
                    let newVisibilities = JSON.parse(JSON.stringify(this.props.layerVisibilities))
                    newVisibilities['traffic'] = !newVisibilities['traffic']
                    this.props.setLayerVisibilities(newVisibilities)
                }}
            >Traffic</button>
            <button
                key='uxv'
                class={ this.props.layerVisibilities['uxv'] ? 'button-toggle-normal active':'button-toggle-normal'}
                onClick={ _ => {
                    let newVisibilities = JSON.parse(JSON.stringify(this.props.layerVisibilities))
                    newVisibilities['uxv'] = !newVisibilities['uxv']
                    this.props.setLayerVisibilities(newVisibilities)
                }}
            >UXV</button>
            <button
                key='geofence'
                class={ this.props.layerVisibilities['geofence'] ? 'button-toggle-normal active':'button-toggle-normal'}
                onClick={ _ => {
                    let newVisibilities = JSON.parse(JSON.stringify(this.props.layerVisibilities))
                    newVisibilities['geofence'] = !newVisibilities['geofence']
                    this.props.setLayerVisibilities(newVisibilities)
                }}
            >Geofence layer</button>
            <button
                key='base'
                class={ this.props.layerVisibilities['base'] ? 'button-toggle-normal active':'button-toggle-normal'}
                onClick={ _ => {
                    let newVisibilities = JSON.parse(JSON.stringify(this.props.layerVisibilities))
                    newVisibilities['base'] = !newVisibilities['base']
                    this.props.setLayerVisibilities(newVisibilities)
                }}
            >Base layer</button>
            <button
                key='nfz'
                class={ this.props.layerVisibilities['nfz'] ? 'button-toggle-normal active':'button-toggle-normal'}
                onClick={ _ => {
                    let newVisibilities = JSON.parse(JSON.stringify(this.props.layerVisibilities))
                    newVisibilities['nfz'] = !newVisibilities['nfz']
                    this.props.setLayerVisibilities(newVisibilities)
                }}
            >NFZ layer</button>
            <button
                key='task'
                class={ this.props.layerVisibilities['task'] ? 'button-toggle-normal active':'button-toggle-normal'}
                onClick={ _ => {
                    let newVisibilities = JSON.parse(JSON.stringify(this.props.layerVisibilities))
                    newVisibilities['task'] = !newVisibilities['task']
                    this.props.setLayerVisibilities(newVisibilities)
                }}
            >Task layer</button>
            <button
                key='route'
                class={ this.props.layerVisibilities['route'] ? 'button-toggle-normal active':'button-toggle-normal'}
                onClick={ _ => {
                    let newVisibilities = JSON.parse(JSON.stringify(this.props.layerVisibilities))
                    newVisibilities['route'] = !newVisibilities['route']
                    this.props.setLayerVisibilities(newVisibilities)
                }}
            >Route layer</button>
            <button
                key='poi'
                class={ this.props.layerVisibilities['poi'] ? 'button-toggle-normal active':'button-toggle-normal'}
                onClick={ _ => {
                    let newVisibilities = JSON.parse(JSON.stringify(this.props.layerVisibilities))
                    newVisibilities['poi'] = !newVisibilities['poi']
                    this.props.setLayerVisibilities(newVisibilities)
                }}
            >POI layer</button>
            <button
                key='ortho'
                class={ this.props.layerVisibilities['ortho'] ? 'button-toggle-normal active':'button-toggle-normal'}
                onClick={ _ => {
                    let newVisibilities = JSON.parse(JSON.stringify(this.props.layerVisibilities))
                    newVisibilities['ortho'] = !newVisibilities['ortho']
                    this.props.setLayerVisibilities(newVisibilities)
                }}
            >Orthophoto layer</button>
        </div>
}

export class Viewer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            mapState: this.props.initMapState ?? 'no-area-focused',
            layer_visibilities: {
                interactions: true,
                geofence:this.props.map.layer_geofence.getVisible(),
                nfz:this.props.map.layer_nfz.getVisible(),
                task:this.props.map.layer_task.getVisible(),
                route:this.props.map.layer_route.getVisible(),
                poi:this.props.map.layer_poi.getVisible(),
                base:this.props.map.layer_base.getVisible(),
                traffic:this.props.map.layer_adsb.getVisible(),
                uxv:this.props.map.layer_beacon.getVisible(),
                ortho:this.props.map.orthophoto_raster.getVisible()
            },
            isFullscreen: false
        }
        this.props.map.props.onClick = null
        this.props.map.props.onPoiSelected= point => this.handlePoiSelected(point)
        this.props.map.props.onPoiDeselected=_ => this.handlePoiDeselected()
        this.props.map.props.onOsmPoiSelected= x => this.handleOsmPoiSelected(x)
        this.props.map.props.onOsmPoiDeselected=_ => this.handleOsmPoiDeselected()
        this.props.map.props.onTaskSelected=(task_id,uxv_id) => this.handleTaskSelected(task_id,uxv_id)
        this.props.map.props.onTaskDeselected=_ => this.handleTaskDeselected()
        this.props.map.props.onBaseitemSelected=baseitem_id => this.handleBaseitemSelected(baseitem_id)
        this.props.map.props.onBaseitemDeselected=_ => this.handleBaseitemDeselected()
        this.props.map.props.onGeofenceDrawEnd= feature => this.handleGeofenceDrawEnd(feature)
        this.props.map.props.onGeofenceSelected=geofence_id => this.handleGeofenceSelected(geofence_id)
        this.props.map.props.onGeofenceDeselected=_ => this.handleGeofenceDeselected()
        this.props.map.props.editor=this.editor
        this.props.map.props.isFullscreen=this.state.isFullscreen
        this.props.map.layer_temp.setVisible(false)
        this.props.map.props.onMoveEnd = x => {
            this.props.onMapMoveEnd?.(x)
        }
    }

    componentDidMount() {
        this.props.map.map.setTarget(this.mapContainer)
        // if existing area is imported, add area polygon and zoom to extent
        // if (this.props.import != null) {
        //     console.log('importing...')
        //     this.props.map.plotArea(this.props.import)
        //     this.setState({ mapState: 'has-area', areas: [this.props.import]},this.setMapState)
        // }
        this.props.map.eraseAllOsmPois()
        this.editor.addEventListener('fullscreenchange', _ => {
            if (!document.fullscreenElement) {
                this.setState({isFullscreen:false})
            }
        })
        this.setMapState()
    }

    setMapState = _ => {
        this.props.map.deactivateAllInteractions()
        this.props.map.dimLayers()
        this.props.map.layer_geofence.setZIndex(0)
        this.props.map.layer_base.setZIndex(0)
        this.props.map.layer_task.setZIndex(0)
        this.props.map.layer_nfz.setZIndex(0)
        this.props.map.layer_poi.setZIndex(0)
        this.setState({showLocationFinder:false})

        if (this.props.currPage == 'general') {
            this.props.map.layer_uxv_track.setOpacity(1)
            this.props.map.layer_uxv_track.setZIndex(50)
            this.props.map.layer_track_beacon.setOpacity(1)
            this.props.map.layer_track_beacon.setZIndex(100)
            this.props.map.layer_traffic_track_beacon.setOpacity(1)
            this.props.map.layer_traffic_track_beacon.setZIndex(100)
            this.props.map.activateTrackBeaconSelectInteraction()
            // this.props.map.activateTrafficTrackBeaconSelectInteraction()
            this.props.map.activateTrackSelectInteraction()
            return
        }
        if (this.props.currPage == 'geofence') {
            this.props.map.layer_geofence.setOpacity(1)
            this.props.map.layer_geofence.setZIndex(100)
            console.log('this.state.mapState:',this.state.mapState)
            switch (this.state.mapState) {
            case 'drawing-area':
                this.props.map.acivateGeofenceDrawingInteraction()
                return
            case 'no-area-focused':
            case 'area-focused':
                this.props.map.activateGeofenceSelectInteraction()
                return
            case 'editing-area':
                this.props.map.activateGeofenceEdit()
                return
            default:
                console.log('error geofence:', this.state.mapState)
                return
            }
        }
        if (this.props.currPage == 'search_geofence') {
            this.props.map.layer_geofence.setOpacity(1)
            this.props.map.layer_geofence.setZIndex(100)
            switch (this.state.mapState) {
            case 'no-area-focused':
            case 'area-focused':
                this.props.map.activateGeofenceSelectInteraction()
                return
            default:
                console.log('error geofence')
                return
            }
        }
        if (this.props.currPage == 'add_geofence') {
            this.props.map.layer_geofence.setOpacity(1)
            this.props.map.layer_geofence.setZIndex(100)
            switch (this.state.mapState) {
            case 'drawing-area':
                this.props.map.acivateGeofenceDrawingInteraction()
                return
            case 'no-area-focused':
            case 'area-focused':
                this.props.map.activateGeofenceSelectInteraction()
                return
            case 'editing-area':
                this.props.map.activateGeofenceEdit()
                return
            default:
                console.log('error geofence')
                return
            }
        }
        if (this.props.currPage == 'edit_geofence') {
            this.props.map.layer_geofence.setOpacity(1)
            this.props.map.layer_geofence.setZIndex(100)
            switch (this.state.mapState) {
            case 'drawing-area':
                this.props.map.acivateGeofenceDrawingInteraction()
                return
            case 'editing-area':
                this.props.map.activateGeofenceEdit()
                return
            case 'area-focused':
                return
            default:
                console.log('error geofence', this.state.mapState)
                return
            }
        }
        if (this.props.currPage == 'poi') {
            this.props.map.layer_poi.setOpacity(1)
            this.props.map.layer_poi.setZIndex(100)
            switch (this.state.mapState) {
            case 'drawing-poi':
                this.props.map.activatePoiDrawingInteraction()
                return
            case 'editing-poi':
                this.props.map.activatePoiEdit()
                return
            case 'no-area-focused':
            case 'poi-focused':
                this.props.map.activatePoiSelectInteraction()
                return
            default:
                console.log('error poi')
                return
            }
        }
        if (this.props.currPage == 'add_poi') {
            this.props.map.layer_poi.setOpacity(1)
            this.props.map.layer_poi.setZIndex(100)
            this.props.map.layer_poi.setVisible(true)
            this.props.map.layer_temp.setVisible(true)
            this.props.map.osmPoiSelector.setActive(true)
            this.props.map.props.onClick = lonlat => {
                console.log('plotTempPoiMarker')
                this.props.map.plotTempPoiMarker(lonlat)
                this.props.onClick?.(lonlat)
            }
            return
        }
        if (this.props.currPage == 'add_task') {
            this.props.map.layer_task.setOpacity(1)
            this.props.map.layer_task.setZIndex(100)
            this.props.map.props.onClick = lonlat => {
                this.props.map.plotTempTaskMarker(lonlat)
                this.props.onClick(lonlat)
            }
            this.props.map.layer_temp.setVisible(true)
            return
        }
        if (this.props.currPage == 'add_baseitem') {
            this.props.map.layer_base.setOpacity(1)
            this.props.map.layer_base.setZIndex(100)
            this.props.map.props.onClick = lonlat => {
                this.props.map.plotTempBaseItemMarker(lonlat)
                this.props.onClick(lonlat)
            }
            this.props.map.layer_temp.setVisible(true)
            return
        }
        if (this.props.currPage == 'base') {
            this.props.map.layer_base.setOpacity(1)
            this.props.map.layer_base.setZIndex(100)
            switch (this.state.mapState) {
            case 'no-area-focused':
            case 'baseitem-focused':
                this.props.map.activateBaseitemSelectInteraction()
                return
            default:
                console.log('error base')
                return
            }
        }
        if (this.props.currPage == 'itinerary') {
            this.props.map.layer_task.setOpacity(1)
            this.props.map.layer_task.setZIndex(100)
            switch (this.state.mapState) {
            case 'drawing-task':
                this.props.map.activateTaskDrawingInteraction()
                return
            case 'editing-task':
                this.props.map.activateTaskEdit()
                return
            case 'no-area-focused':
            case 'task-focused':
                this.props.map.activateTaskSelectInteraction()
                return
            default:
                console.error('error itinerary')
                return
            }
        }
        if (this.props.currPage == 'add_capture_photo_task') {
            this.props.map.layer_task.setOpacity(1)
            this.props.map.layer_task.setZIndex(100)
            this.props.map.layer_poi.setOpacity(1)
            this.props.map.layer_poi.setZIndex(100)
            this.props.map.layer_poi.setVisible(true)
            this.props.map.layer_temp.setVisible(true)
            this.props.map.osmPoiSelector.setActive(true)
            this.props.map.props.onClick = lonlat => {
                console.log('plotTempPoiMarker')
                this.props.map.plotTempPoiMarker(lonlat)
                this.props.onClick?.(lonlat)
            }
            return
        }
        console.error('unhandled page:', this.props.currPage)
    }

    addArea() {
        this.setState({ mapState: 'drawing-area' },this.setMapState)
    }

    addTask() {
        console.assert(this.state.mapState == 'no-area-focused')
        this.setState({ mapState: 'drawing-task'},this.setMapState)
    }

    addPoi() {
        console.assert(this.state.mapState == 'no-area-focused')
        this.setState({ mapState: 'drawing-poi'},this.setMapState)
    }


    handleOsmPoiSelected(x) {
        console.assert(['no-area-focused','poi-focused'].includes(this.state.mapState))
        this.props.onOsmPoiSelected?.(x)
        this.setState({ mapState: 'poi-focused'},this.setMapState)
    }

    handleOsmPoiDeselected() {
        console.assert(this.state.mapState == 'poi-focused')
        this.props.onOsmPoiDeselected?.()
        this.setState({ mapState: 'no-area-focused'},this.setMapState)
    }

    handleTaskSelected(task_id,uxv_id) {
        console.assert(['no-area-focused','task-focused'].includes(this.state.mapState))
        this.props.onTaskSelected?.(task_id)
        this.props.map.selectRoute(uxv_id)
        this.setState({ mapState: 'task-focused', task_id: task_id},this.setMapState)
    }

    handleTaskDeselected() {
        console.assert(this.state.mapState == 'task-focused')
        this.props.onTaskDeselected?.()
        this.props.map.selectRoute()
        this.setState({ mapState: 'no-area-focused'},this.setMapState)
    }

    handleBaseitemSelected(baseitem_id) {
        console.assert(['no-area-focused','baseitem-focused'].includes(this.state.mapState))
        this.props.onBaseitemSelected?.(baseitem_id)
        this.setState({ mapState: 'baseitem-focused', baseitem_id: baseitem_id},this.setMapState)
    }

    handleBaseitemDeselected() {
        console.assert(this.state.mapState == 'baseitem-focused')
        this.props.onBaseitemDeselected?.()
        this.setState({ mapState: 'no-area-focused'},this.setMapState)
    }

    handleGeofenceDrawEnd(feature) {
        console.log('draw end')
        console.assert(this.state.mapState == 'drawing-area')
        this.setState({ mapState: 'no-area-focused', temp_geofence: feature},this.setMapState)
    }

    handleGeofenceSelected(geofence_id) {
        console.log('selected geofence:',geofence_id)
        console.assert(['no-area-focused','area-focused'].includes(this.state.mapState))
        this.props.onGeofenceSelected?.(geofence_id)
        this.setState({ mapState: 'area-focused', geofence_id: geofence_id},this.setMapState)
    }

    handleGeofenceDeselected() {
        console.assert(this.state.mapState == 'area-focused')
        this.props.onGeofenceDeselected?.()
        this.setState({ mapState: 'no-area-focused' },this.setMapState)
    }

    editGeofence() {
        console.assert(this.state.mapState == 'area-focused')
        this.props.map.startEditGeofence()
        this.setState({mapState: 'editing-area'}, this.setMapState)
    }

    editPoi() {
        console.assert(this.state.mapState == 'poi-focused')
        this.props.map.startEditPoi()
        this.setState({mapState: 'editing-poi'}, this.setMapState)
    }


    deleteGeofence() {
        this.props.map.deactivateAllInteractions()
        this.props.map.eraseSelectedArea()
        this.setState({mapState: 'no-area-focused', temp_geofence: null}, this.setMapState)
    }

    deleteTask() {
        this.props.map.deactivateAllInteractions()
        this.props.map.eraseSelectedTask()
        let tasks = this.props.map.source_task.getFeatures().map( x => ({
            id: x.getId(),
            geom: x.getGeometry().clone().transform('EPSG:3857','EPSG:4326').getCoordinates()
        }))
        this.props.setTasks(tasks)
        this.setState({ mapState: 'no-area-focused' }, this.setMapState)
    }

    deletePoi() {
        this.props.map.deactivateAllInteractions()
        this.props.map.eraseSelectedPoi()
        this.setState({ mapState: 'no-area-focused' }, this.setMapState)
    }

    geofenceDone() {
        if (this.state.mapState == 'drawing-area') {
            this.props.map.finishGeofenceDraw()
            this.setState({mapState:'area-focused'},this.setMapState)
            return
        }
        if (this.state.mapState == 'area-focused') {
            this.props.map.clearGeofenceSelection()
            this.setState({mapState:'no-area-focused'},this.setMapState)
            return
        }
        if (this.state.mapState == 'editing-area') {
            this.props.map.finishEditGeofence()
            this.setState({mapState: 'area-focused'}, this.setMapState)
            return
        }
    }

    taskDone() {
        if (this.state.mapState == 'drawing-task') {
            this.setState({mapState:'no-area-focused'},this.setMapState)
            return
        }
        if (this.state.mapState == 'task-focused') {
            this.props.map.clearTaskSelection()
            this.setState({mapState:'no-area-focused'},this.setMapState)
            return
        }

    }
    poiDone() {
        if (this.state.mapState == 'drawing-poi') {
            this.setState({mapState:'no-area-focused'},this.setMapState)
            return
        }
        if (this.state.mapState == 'poi-focused') {
            this.props.map.clearPoiSelection()
            this.setState({mapState:'no-area-focused'},this.setMapState)
            return
        }
        if (this.state.mapState == 'editing-poi') {
            this.props.map.finishEditPoi()
            this.setState({mapState: 'poi-focused'}, this.setMapState)
            return
        }
    }

    handleLocationQueryChange(loc) {
        // remove timer
        if (this.state.locationQueryTimerId) clearTimeout(this.state.locationQueryTimerId)
        // query locations after timeout
        this.setState({
            locationQuery: loc,
            locationQueryTimerId: setTimeout( _ => {
                fetch(PUBLIC_PATH_ROOT + 'api/query_locations?querystring=' + loc, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json;charset=utf-8' },
                })
                .then(res => res.json())
                .then(res => {
                    if (res.success) {
                        let locations = res.locations
                        console.log('newMissiongPage: found locations:',res.locations)
                        this.setState({
                            foundLocations: locations,
                            locationQueryTimerId: null
                        }, _ => {
                            if (this.locationSelector) this.locationSelector.click()
                        })
                        return
                    }
                    console.log('newMissiongPage: found locations failed, reason:', res.reason)
                })
                .catch(err => {
                    console.log('newMissiongPage: found locations: error:',err)
                })
            }, 200)
        })
    }

    handleLocationClicked(x) {
        let lat = x.centroid.coordinates[1]
        let lon = x.centroid.coordinates[0]
        let newLocationString = `${x.name}, ${x.country}`
        console.log('handleLocationClicked', newLocationString)
        this.setState({locationQuery: newLocationString})
        this.locationInput.value = newLocationString
        this.props.map.zoomTo( x.geom, _ => {
            console.log('flyTo done.')
        })
    }

    showTrackBeacons(datetime) {
        this.props.map.selectTrackBeacons(datetime)
        this.props.map.selectTrafficTrackBeacons(datetime)
    }

    setSeekTrackBeacon(timestamp) {
        this.datePicker.setFromUtcStr(timestamp)
        this.timePicker.setFromUtcStr(timestamp)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.mapSize != this.props.mapSize) {
            this.props.map.updateSize()
        }
        if (prevProps.currPage != this.props.currPage) {
            this.setMapState()
        }
        this.props.map.layer_geofence.setVisible(this.state.layer_visibilities.geofence),
        this.props.map.layer_nfz.setVisible(this.state.layer_visibilities.nfz),
        this.props.map.layer_task.setVisible(this.state.layer_visibilities.task),
        this.props.map.layer_route.setVisible(this.state.layer_visibilities.route),
        this.props.map.layer_poi.setVisible(this.state.layer_visibilities.poi)
        this.props.map.layer_base.setVisible(this.state.layer_visibilities.base)
        this.props.map.layer_ais.setVisible(this.state.layer_visibilities.traffic)
        this.props.map.layer_adsb.setVisible(this.state.layer_visibilities.traffic)
        this.props.map.layer_beacon.setVisible(this.state.layer_visibilities.uxv)
        this.props.map.orthophoto_raster.setVisible(this.state.layer_visibilities.ortho)
        this.props.map.mouseWheelZoom.setActive(this.state.layer_visibilities.interactions)
        this.props.map.pinchZoom.setActive(this.state.layer_visibilities.interactions)
        this.props.map.dragPan.setActive(this.state.layer_visibilities.interactions)
    }

    render() {
        return (
            <div id='missionEditor' ref={x=> this.editor = x}>

                { this.state.showLocationFinder && <Fragment>
                <input
                    ref={x => this.locationInput = x}
                    onFocus={ _ => this.setState({locationQuery: ''})}
                    onBlur={ _ => setTimeout(_ => this.setState({foundLocations:null}),200)}
                    onChange={e => this.handleLocationQueryChange(e.target.value)}
                    value={this.state.locationQuery ?? ''}
                    placeholder={'🔍 search for locations...'}
                    id="location_finder"
                    type="text"
                    class='input-fullwidth'
                />
                { this.state.foundLocations?.length > 0 &&
                <table class='table-normal' id='location-selector' ref={x=>this.locationSelector = x}><tbody>
                    {this.state.foundLocations.map( (x,i) =>
                    <tr key={i} onClick={ _ => this.handleLocationClicked(x)}>
                        <td>{`${x.name}, ${x.country} ( lat: ${x.centroid.coordinates[1]},lon: ${x.centroid.coordinates[0]})`}</td>
                    </tr>)}
                </tbody></table>
                }
                </Fragment>}


                <div ref={x=>this.mapContainer=x} class={ 'mapContainer ' + this.props.mapSize + (this.state.isFullscreen ? ' fullscreen' : '')}>

                </div>
                <ControlPanel
                    layerVisibilities={this.state.layer_visibilities}
                    setLayerVisibilities={x => this.setState({layer_visibilities:x})}
                    isFullscreen={this.state.isFullscreen}
                />
                <div id="missionEditorWidth"
                    class={ this.state.isFullscreen ? 'fullscreen' : ''}
                >
                    { this.state.isFullscreen ?
                        <button
                            id='fullscreen-button'
                            className='button-normal'
                            onClick={ _ => {
                                document.exitFullscreen()
                                this.setState({isFullscreen: false})}
                            }
                        >
                            Exit Full Screen
                        </button>
                    :
                        <button
                            id='fullscreen-button'
                            className='button-small'
                            onClick={ _ => {
                                this.editor.requestFullscreen()
                                this.setState({isFullscreen: true})}
                            }
                        >
                            Full Screen
                        </button>
                    }
                    { this.props.currPage == 'general' && <div>                        
                        <p>Time</p>
                        <TimePicker
                            ref={x => this.timePicker=x}
                            showTz={false}
                            coarseStepSizeMinute={5}
                            fineStepSizeSecond={2}
                            fineStepSizeMinute={0}
                            onSnap={ x => {
                                // console.log('timepicker snap:', x)
                                let timestamp = this.datePicker.state.selectedDate.toISOString().slice(0,10) + 'T' + x.timeString
                                this.showTrackBeacons(timestamp)
                            }}
                            onUpdate={x => {
                                // console.log('timepicker update:', x)
                            }}
                            onDayChanged={ x => { this.datePicker.incrementDays(x)}}
                        />
                        <Calender
                            ref={x=>this.datePicker=x}
                            onUpdate={x => {
                                console.log('calender update:', x)
                            }}
                        />
                    </div>}
                    { this.props.currPage == 'search_geofence' && <div>
                        <button
                            className='button-large'
                            onClick={_ => this.props.map.eraseAllGeofences()}
                        >
                            Clear Geofences
                        </button>
                        {/* <button
                            disabled={!['area-focused'].includes(this.state.mapState)}
                            className='button-large'
                            onClick={_ => this.geofenceDone()}
                        >
                            Use Geofence
                        </button> */}
                            
                    </div>}
                    { (!this.props.readOnly) && this.props.editMode && this.props.currPage == 'add_geofence' && <div>
                        <button
                            disabled={this.state.mapState != 'no-area-focused' || this.state.temp_geofence != null}
                            className='button-large'
                            onClick={_ => this.addArea()}
                        >
                            Add Area
                        </button>
                        <button
                            disabled={'area-focused' != this.state.mapState}
                            className='button-large'
                            onClick={_ => this.editGeofence()}
                        >
                            Edit Area
                        </button>
                        <button
                            disabled={this.state.mapState != 'area-focused'}
                            className='button-large'
                            onClick={_ => this.deleteGeofence()}
                        >
                            Delete Area
                        </button>
                        <button
                            disabled={!['drawing-area','area-focused','editing-area'].includes(this.state.mapState)}
                            className='button-large'
                            onClick={_ => this.geofenceDone()}
                        >
                            Done
                        </button>
                    </div>}
                    { (!this.props.readOnly) && this.props.editMode && this.props.currPage == 'edit_geofence' && <div>
                        <button
                            disabled={'area-focused' != this.state.mapState}
                            className='button-large'
                            onClick={_ => this.editGeofence()}
                        >
                            Edit Area
                        </button>
                        <button
                            disabled={!['editing-area'].includes(this.state.mapState)}
                            className='button-large'
                            onClick={_ => this.geofenceDone()}
                        >
                            Done
                        </button>
                    </div>}
                    { this.props.currPage == 'itinerary' && <div>

                    </div>}
                    { (!this.props.readOnly) && this.props.editMode && this.props.currPage == 'poi' && <div>
                        <button
                            className='button-large'
                            disabled={this.state.mapState != 'no-area-focused'}
                            onClick={_ => this.addPoi()}
                        >Add POI</button>
                        <button
                            key={2}
                            disabled={'poi-focused' != this.state.mapState}
                            className='button-large'
                            onClick={_ => this.editPoi()}
                        >
                            Edit POI
                        </button>
                        <button
                            key={3}
                            disabled={this.state.mapState != 'poi-focused'}
                            className='button-large'
                            onClick={_ => this.deletePoi()}
                        >
                            Delete POI
                        </button>
                        <button
                            key={4}
                            disabled={!['drawing-poi','poi-focused','editing-poi'].includes(this.state.mapState)}
                            className='button-large'
                            onClick={_ => this.poiDone()}
                        >
                            Done
                        </button>
                    </div>}
                </div>
            </div>
        )
    }

}