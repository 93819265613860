// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#payloadDetailPage {
    padding: 10px 30px;
}

#sensorBlock {
    width: 48%;
    float: left;
}

#liveDataContainer {
    display: inline-block; /* respect childrens size */
    width: 100%;
}

#chartBlock {
    position: sticky;
    width: 48%;
    margin: 0 0 0 1vw;
    float: right;
    top: 0px;
}

.chartArea {
    width: 100%;
    height: 50vh;
    background-color: lightblue;
}

#sensorDetailTable th {
    position: sticky;
    top: 0;
}

#search-result-container {
    max-height: 500px;
    overflow-y: scroll
}


#payloadDetailPage {
    width: 100%;
    box-sizing: border-box;
}

#payloadMapContainer {
    max-width: 100%;
    height: 300px;
    width: 400px;
    display: inline-block;
    background-color: white;
}
#historicChartArea {
    max-width: 100%;
    width: 400px;
    height: 300px;
    background-color: lightblue;
    display: inline-block;
}

#statsChart {
    background-color: lightblue;
    width: 400px;
    max-width: 100%;
    height: 300px;
}`, "",{"version":3,"sources":["webpack://./src/components/payload/payloadDetails.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI,qBAAqB,EAAE,2BAA2B;IAClD,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,iBAAiB;IACjB,YAAY;IACZ,QAAQ;AACZ;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,2BAA2B;AAC/B;;AAEA;IACI,gBAAgB;IAChB,MAAM;AACV;;AAEA;IACI,iBAAiB;IACjB;AACJ;;;AAGA;IACI,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,aAAa;IACb,YAAY;IACZ,qBAAqB;IACrB,uBAAuB;AAC3B;AACA;IACI,eAAe;IACf,YAAY;IACZ,aAAa;IACb,2BAA2B;IAC3B,qBAAqB;AACzB;;AAEA;IACI,2BAA2B;IAC3B,YAAY;IACZ,eAAe;IACf,aAAa;AACjB","sourcesContent":["#payloadDetailPage {\n    padding: 10px 30px;\n}\n\n#sensorBlock {\n    width: 48%;\n    float: left;\n}\n\n#liveDataContainer {\n    display: inline-block; /* respect childrens size */\n    width: 100%;\n}\n\n#chartBlock {\n    position: sticky;\n    width: 48%;\n    margin: 0 0 0 1vw;\n    float: right;\n    top: 0px;\n}\n\n.chartArea {\n    width: 100%;\n    height: 50vh;\n    background-color: lightblue;\n}\n\n#sensorDetailTable th {\n    position: sticky;\n    top: 0;\n}\n\n#search-result-container {\n    max-height: 500px;\n    overflow-y: scroll\n}\n\n\n#payloadDetailPage {\n    width: 100%;\n    box-sizing: border-box;\n}\n\n#payloadMapContainer {\n    max-width: 100%;\n    height: 300px;\n    width: 400px;\n    display: inline-block;\n    background-color: white;\n}\n#historicChartArea {\n    max-width: 100%;\n    width: 400px;\n    height: 300px;\n    background-color: lightblue;\n    display: inline-block;\n}\n\n#statsChart {\n    background-color: lightblue;\n    width: 400px;\n    max-width: 100%;\n    height: 300px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
