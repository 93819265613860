import React from 'react'

import {LoginComponent, decodeToken} from './login/login'
import RegisterComponent from './register/register'
import * as DashboardComponent from './dashboard/dashboard'
import * as PayloadDetailComponent from './payload/payloadDetails'
import * as UxvDetailComponent from './uxv/uxvDetails'
import * as MissionDetailComponent from './mission/missionDetails'
import * as DatasetComponent from './dataset/dataset'
import ResetPasswordComponent from './reset-password/resetPassword'
import VerifyEmailComponent from './verify-email/verifyEmail'
// import VerifyPhoneComponent from './verify-phone/verifyPhone'
// import RegisterTotpComponent from './totp/totp'

import * as AmqpComponent from './amqp/amqp'
import * as AccountComponent from './account/account'
import * as NewMissionComponent from './mission/newMissionPage'
import * as MissionMap from './map/map'
import '../style.css'
import './app.css'

const green_circle = <svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="green"/></svg>
const red_circle = <svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="red"/></svg>

import project_logo_full from '../../assets/images/webui_logo_full.svg'

class App extends React.Component {

    constructor() {
        super()
        this.splashscreenComponent = null
        this.ws = null // websocket
        this.timerId = null
        this.state = {
            now: Date.now(),
            currPage:'loading',
            amqpState: 'disconnected',
            amqpMsg: null,
            credentials: null,
            hasAlert: false,
            isExtendingToken: false,
            showLeftPanel: false,
            showServerStatus: false,
            beacon: {}
        }
        //create a map object once only
        this.map = new MissionMap.Map({rasterStyle:'tile'})

        window.onpopstate = () => console.log('back button pressed')
    }

    extendToken = _ => {
        this.setState({
            hasAlert:false,
            isExtendingToken: true
        }, _=>{
            console.log('extending token...')
            fetch( PUBLIC_PATH_ROOT + 'auth/renew_token', {
                method: 'POST',
                headers: {'Content-Type': 'application/jsoncharset=utf-8'},
                body: JSON.stringify({token:this.state.credentials.token})
            })
            .then(res => res.json())
            .then(rs => {
                if (!rs.success) throw rs
                localStorage.setItem('webui_token', rs.token)
                this.setState(s => {
                    s.credentials.token = rs.token
                    s.credentials.expiresAt = rs.expiresAt
                    s.isExtendingToken = false
                    return s
                })
            })
            .catch( err => {
                console.log('app: token renew failed:', err)
                this.setState({isExtendingToken: false})
            })
        })
    }

    // infinite timer
    timerLoop = _ => {
        this.setState({now: Date.now()})
        this.timerId = setTimeout(this.timerLoop, 10000)

        let isNearExpire = new Date(Date.now() + 5*60000) > new Date(this.state.credentials?.expiresAt)
        let isExpired = new Date(Date.now()) > new Date(this.state.credentials?.expiresAt)

        // console.log('this.state.hasAlert, this.state.isExtendingToken, isNearExpire, isExpired:',this.state.hasAlert,this.state.isExtendingToken,isNearExpire, isExpired)
        if (!this.state.hasAlert && !this.state.isExtendingToken && isNearExpire && !isExpired) {
            this.setState({
                hasAlert: true,
                alertMessage: 'token expires in 5 minute, continue this session?',
                alertAction: this.extendToken
            })
        }
        if (isExpired) {
            this.setState({
                hasAlert: true,
                alertMessage: 'session expired. please login again',
                alertAction: _ => {
                    this.setState({hasAlert: false})
                    this.handleLogout()
                }
            })
        }
    }

    componentDidMount = _ => {
        // console.log('prefix:',PUBLIC_PATH_ROOT)
        this.timerLoop()
        this.app.scrollTo(0,0)
        // check if specific services requested
        // console.log('current location:',window.location)

        // console.log('query:',window.location.search)
        let query = new URLSearchParams(window.location.search)
        let action = query.get('action')
        if (action == 'verify_email') {
            console.log('verifying email...')
            let verifyToken = query.get('token')
            console.log('token:', verifyToken)
            this.setState({currPage: 'verifyEmail', verifyToken: verifyToken})
            return
        }
        if (action == 'reset_password') {
            console.log('resetting password...')
            let verifyToken = query.get('token')
            this.setState({currPage: 'resetPassword', verifyToken: verifyToken})
            return
        }

        // check token
        let token = localStorage.getItem('webui_token')
        if (token) {
            console.log('app: token found. decoding...')
            decodeToken(token)
            .then( credentials => {
                credentials.token = token
                credentials.expiresAt = new Date(credentials.exp * 1000).toISOString()
                let isNearExpire = new Date(Date.now() + 1*60000) > new Date(credentials.expiresAt)
                if (isNearExpire) throw 'token expiring soon. refuse login'
                this.handleLoginSuccess(credentials, this.routePage)
            })
            .catch( err => {
                console.log('app: decode: error', err)
                localStorage.setItem('webui_token', '') // reset localStorage
                this.setState({currPage:'login'})
            })
        } else {
            console.log('app: no token saved.')
            this.setState({currPage:'login'})
        }

    }

    routePath = _ => {
        //route page
        console.log('routing path:',window.location.pathname)
        if (/^\/mission\/[0-9]+$/.test(window.location.pathname)){
            this.routeMissionDetailPage(window.location.pathname.split('/')[2])
            return
        }
        if (/^\/uxv_details\/[0-9]+$/.test(window.location.pathname)){
            let uxv_id = window.location.pathname.split('/')[2]
            fetch(PUBLIC_PATH_ROOT + `auth/uxv/${uxv_id}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                body: JSON.stringify({ token: this.state.credentials.token })
            }).then(res => res.json())
            .then(res => {
                if (!res.success) throw res
                console.log('get uxv:',res)
                this.routeUxvDetailPage(res.uxv)
            })
            .catch(err => {
                console.log('get uxv error:',err)
            })
            return
        }
        this.setState({currPage:'dashboard',isExtendingToken:false})
    }

    componentDidUpdate( prevProps,prevState ) {
        if (prevState.showLeftPanel != this.state.showLeftPanel) {
            this.missionDetailRightPanel?.updateMapSize()
            this.dashboardComponent?.updateMapSize()
        }
    }


    routePage = page => {
        this.setState({currPage: page})
        this.app.scrollTo(0,0)
    }

    routeMissionDetailPage = missionId => {
        console.log('missionId:',missionId)
        this.setState({currPage:'missionDetail', arg:missionId })
        this.app.scrollTo(0,0)
    }

    routePayloadDetailPage = x => {
        this.setState({currPage:'payloadDetail',arg:x})
        this.app.scrollTo(0,0)
    }

    routeDashboardPage = x => {
        this.setState({currPage:'dashboard',arg:x})
        this.app.scrollTo(0,0)
    }

    routeUxvDetailPage = x => {
        this.setState({currPage:'uxvDetail',arg:x})
        this.app.scrollTo(0,0)
    }

    handleLoginSuccess = credentials => {
        console.debug('app: login success with credentials,', credentials)
        localStorage.setItem('webui_token', credentials.token)
        this.setState({credentials: credentials,showLeftPanel:true},this.routePath)
        console.log('app: connecting to amqp-end server...')
        this.connectAmqpServer()
        this.app.scrollTo(0,0)
    }

    handleMount(mountedRobot) {
        this.setState(state => {
            state.credentials.mountedRobot = mountedRobot
            return state
        })
    }

    publishOwnWaypoint(coordinates) {
        console.log('publishing own waypoint...')
        if (this.state.amqpState != 'authenticated') return
        this.ws.send(JSON.stringify({type:'publish_own_waypoint', waypoint: coordinates}))
    }

    disconnectAmqpServer = _ => {
        this.ws?.close()
    }

    connectAmqpServer = _ => {
        console.log('connect amqp server')
        this.ws = new WebSocket('wss://' + location.host + PUBLIC_PATH_ROOT + 'live/')
        this.ws.onerror = err => console.log('ws error:', err)
        this.ws.onopen = _ => {
            this.setState({amqpState:'authenticating'})
            console.log('authenticating amqp-end with token')
            let message = { type: 'authentication', token: this.state.credentials.token}
            this.ws.send(JSON.stringify(message))
            this.map.props.onUserMarkerPlot = coord => {
                if (!coord) return
                // console.debug('publish user marker coord:',coord)
                this.ws.send(JSON.stringify({type:'publish_user_beacon', lon:coord.longitude, lat:coord.latitude, alt: coord.altitude,hdg:coord.heading}))
            }
        }
        this.ws.onclose = _ => {
            this.setState({amqpState:'disconnected'})
            console.log('app: amqp: disconnected')
        }
        this.ws.onmessage = e => {
            // console.debug('data:', e.data)
            let msg = JSON.parse(e.data)
            // console.debug('msg:', msg)
            this.setState({amqpMsg: msg})
            switch (msg.type){
            case 'update_state':
                this.setState({amqpState: msg.state})
                if (msg.state == 'authenticated') {
                    this.ws.send(JSON.stringify({type:'consume_own_beacon'}))
                    this.ws.send(JSON.stringify({type:'consume_other_beacon'}))
                    this.ws.send(JSON.stringify({type:'consume_own_response'}))
                    this.ws.send(JSON.stringify({type:'consume_warning'}))
                }
                return
            case 'update_self_beacon':
                this.dashboardComponent?.updateOwnBeacon(msg)
                return
            case 'update_self_response':
                this.dashboardComponent?.updateOwnResponse(msg)
                this.payloadDetailRightPanel?.updateOwnResponse(msg)
            case 'update_other_beacon':
                this.dashboardComponent?.updateOtherBeacon(msg)
                this.payloadDetailRightPanel?.updateOtherBeacon(msg)
                this.uxvDetailRightPanel?.updateOtherBeacon(msg)
                this.setState( s => {
                    s.beacon[msg.codename] = msg
                    return s
                })
                return
            case 'update_uxv_beacon':
                this.uxvDetailRightPanel?.updateOtherBeacon(msg)
                return
            case 'mission_warning':
                console.log('mission_warning:', msg)
                this.dashboardComponent?.updateWarning?.(msg)
                return
            default:
            }
        }
    }

    handleLogout = _ => {
        console.log('app: logout')
        localStorage.setItem('webui_token', '')
        this.setState({credentials: null,currPage:'login', showLeftPanel: false})
        this.ws?.close()
        this.app.scrollTo(0,0)
    }

    componentWillUnmount() {
        console.log('exiting app...')
        this.ws?.close()
    }

    render() {
        return (
            <div id='app' ref={x=>this.app=x}>
                <div id='background'/>

                { ['login','register','resetPassword','verifyEmail'].includes(this.state.currPage) && <div
                    ref={this.titleRef}
                    id='app-title'
                    class='show-title-animation'
                >
                    <img src={project_logo_full} alt="Logo"/> <br/>
                    <b>UXV Service Layer</b> <br/>
                    <b>Unmanned Traffic Management Interface</b>
                </div>
                }
                {this.state.currPage == 'register' &&
                    <RegisterComponent
                        routeLoginPage={ _ => this.routePage('login')}
                    />
                }
                {this.state.currPage == 'login' &&
                    <LoginComponent
                        routeRegisterPage={ _ => this.routePage('register')}
                        routeResetPasswordPage={ _ => this.routePage('resetPassword')}
                        routeVerifyEmailPage={ _ => this.routePage('verifyEmail')}
                        // routeVerifyPhonePage={ _ => this.routePage('verifyPhone')}
                        // routeTotpPage={ _ => this.routePage('registerTotp')}
                        onLoginSuccess={this.handleLoginSuccess}
                    />
                }
                {this.state.currPage == 'resetPassword' &&
                    <ResetPasswordComponent
                        routeLoginPage={ _ => this.routePage('login')}
                        token={this.state.verifyToken}
                    />
                }
                {this.state.currPage == 'verifyEmail' &&
                    <VerifyEmailComponent
                        routeLoginPage={ _ => this.routePage('login')}
                        token={this.state.verifyToken}
                    />
                }
                {this.state.currPage == 'verifyPhone' &&
                    <VerifyPhoneComponent
                        routeLoginPage={ _ => this.routePage('login')}
                        sms_code={this.state.verifySmsCode}
                    />
                }
                {/* { this.state.currPage == 'registerTotp' &&
                    <RegisterTotpComponent
                        routeLoginPage={ _ => this.routePage('login')}
                    />
                } */}


                { this.state.showLeftPanel && <div class='left-panel'>
                    { this.state.currPage == 'dashboard' &&
                        <DashboardComponent.LeftPanel
                            currPage={this.state.dashboardPageCurrPage}
                            setCurrPage={ page => this.setState({dashboardPageCurrPage: page})}
                            amqpState={this.state.amqpState}
                            routePreviousPage={this.handleLogout}
                            credentials={this.state.credentials}
                        />
                    }
                    { this.state.currPage == 'account' && <div>
                        <h2>Manage Accounts</h2>
                        <p onClick={ _ => this.routePage('dashboard') }>Back</p>
                    </div>}
                    { this.state.currPage == 'payloadDetail' &&
                        <PayloadDetailComponent.LeftPanel
                            currPage={this.state.payloadDetailPageCurrPage}
                            setCurrPage={ page => this.setState({payloadDetailPageCurrPage: page})}
                            currData={this.state.payloadDetailPageCurrData}
                            amqpState={this.state.amqpState}
                            payload={this.state.arg}
                            credentials={this.state.credentials}
                            routePreviousPage={ _ => this.routePage('dashboard')}
                        />
                    }
                    { this.state.currPage == 'uxvDetail' &&
                        <UxvDetailComponent.LeftPanel
                            currPage={this.state.uxvDetailPageCurrPage}
                            setCurrPage={page => {
                                console.log('page:',page)
                                this.setState({uxvDetailPageCurrPage:page})
                            }}
                            currData={this.state.uxvDetailPageCurrData}
                            amqpState={this.state.amqpState}
                            uxv={this.state.arg}
                            credentials={this.state.credentials}
                            routePreviousPage={ _ => this.routePage('dashboard')}
                        />
                    }
                    { this.state.currPage == 'settings' && <div>
                        <h2>Settings</h2>
                        <p onClick={ _ => this.routePage('dashboard') }>Back</p>
                    </div>}
                    { this.state.currPage == 'newMission' && <div>
                        <NewMissionComponent.LeftPanel
                            currPage={this.state.newMissionPageCurrPage}
                            setCurrPage={ page => this.newMissionRightPanel.routePage(page)}
                            routePreviousPage={ _ => this.routePage('dashboard')}
                        />
                    </div>}
                    { this.state.currPage == 'missionDetail' && <div>
                        <MissionDetailComponent.LeftPanel
                            currPage={this.state.missionDetailCurrPage}
                            setCurrPage={ page => this.missionDetailRightPanel.routePage(page)}
                            missionId={this.state.arg}
                            missionDetail={this.state.missionDetail}
                            routePreviousPage={ _ => this.routePage('dashboard')}
                        />
                    </div>}
                    { this.state.currPage == 'dataset' && <div>
                        <DatasetComponent.LeftPanel
                            currPage={this.state.datasetDetailCurrPage}
                            setCurrPage={ page => this.datasetRightPanel.routePage(page)}
                            missionId={this.state.arg}
                            missionDetail={this.state.datasetDetail}
                            routePreviousPage={ _ => this.routePage('dashboard')}
                        />
                    </div>}
                </div>}

                {this.state.hasAlert &&
                    <div id='alert-box-container'>
                        <div id='alert-box'>
                            <p>{this.state.alertMessage}</p>
                            <button className='button-large' onClick={_ => this.state.alertAction()}>OK</button>
                        </div>
                    </div>
                }

                { ['dashboard','payloadDetail','account','settings','newMission','missionDetail','uxvDetail'].includes(this.state.currPage) &&
                    <div class='navbar top' style={{paddingLeft:this.state.showLeftPanel ? '200px': 0}}>
                        <div onClick={ _ => {
                            if (this.state.serverStatusDropdownTimerId)
                                clearTimeout(this.state.serverStatusDropdownTimerId)
                            this.setState({
                                showServerStatus: !this.state.showServerStatus,
                                serverStatusDropdownTimerId: setTimeout( _ => this.setState({showServerStatus:false}),2000)
                            })
                        }}>
                            <span>Server&nbsp;
                                <AmqpComponent.Status
                                    amqpState={this.state.amqpState}
                                    amqpMsg={this.state.amqpMsg}
                                    beacon={this.state.beacon}
                                    iconOnly
                                />
                                <AccountComponent.Status
                                    credentials={this.state.credentials}
                                    iconOnly
                                />
                            </span>
                            { this.state.showServerStatus && <div class='dropdown-container'>
                                <AmqpComponent.Status
                                    amqpState={this.state.amqpState}
                                    amqpMsg={this.state.amqpMsg}
                                    beacon={this.state.beacon}
                                />
                                <AccountComponent.Status credentials={this.state.credentials}/>
                            </div>}
                        </div>
                        <div class='navbar-separator'/>
                        <code id='current-time'>{navigator.language} {new Date(this.state.now).toLocaleString('default',{month:'numeric',day:'numeric',hour:'2-digit',minute:'2-digit',hour12:false})}</code>
                        <div class='navbar-separator'/>
                        <AccountComponent.NavBar onClick={ _ => console.log('navbar')} credentials={this.state.credentials}/>
                    </div>
                }

                <div class='right-panel' style={{
                    paddingLeft: this.state.showLeftPanel ? '200px' : 0
                }}>
                    {this.state.currPage == 'dashboard' &&
                        <DashboardComponent.RightPanel
                            ref={x => this.dashboardComponent = x}
                            now={this.state.now}
                            map={this.map}
                            currPage={this.state.dashboardPageCurrPage}
                            setCurrPage={ page => this.setState({dashboardPageCurrPage: page})}
                            credentials={this.state.credentials}
                            amqpState={this.state.amqpState}
                            amqpMsg={this.state.amqpMsg}
                            beacon={this.state.beacon}
                            connectAmqpServer={this.connectAmqpServer}
                            disconnectAmqpServer={this.disconnectAmqpServer}
                            logoutHandler={this.handleLogout}
                            routeNewMissionPage={ _ => this.routePage('newMission')}
                            routeMissionDetailPage={missionId => this.routeMissionDetailPage(missionId)}
                            publishOwnWaypoint={ x => this.publishOwnWaypoint(x)}
                            routePayloadDetailPage={payload => this.routePayloadDetailPage(payload)}
                            routeUxvDetailPage={uxv => this.routeUxvDetailPage(uxv)}
                        />
                    }
                    {this.state.currPage == 'newMission' &&
                        <NewMissionComponent.RightPanel
                            ref={x => this.newMissionRightPanel = x}
                            setCurrPage={ page => this.setState({newMissionPageCurrPage: page})}
                            credentials={this.state.credentials}
                            routePreviousPage={_ => this.routePage('dashboard')}
                        />
                    }
                    {this.state.currPage == 'missionDetail' &&
                        <MissionDetailComponent.RightPanel
                            ref={x=>this.missionDetailRightPanel = x}
                            currPage={this.state.missionDetailCurrPage}
                            setCurrPage={ page => this.setState({missionDetailCurrPage: page})}
                            setMissionDetail={ x => this.setState({missionDetail: x})}
                            mission_id={this.state.arg}
                            routePreviousPage={this.routeDashboardPage}
                            credentials={this.state.credentials}
                            scrollToTop={ _ => this.app.scrollTo(0,0)}
                        />
                    }
                    {this.state.currPage == 'payloadDetail' &&
                        <PayloadDetailComponent.RightPanel
                            ref={x => this.payloadDetailRightPanel = x }
                            currPage={this.state.payloadDetailPageCurrPage}
                            setCurrPage={ page => this.setState({payloadDetailPageCurrPage: page})}
                            setCurrData={ data => this.setState({payloadDetailPageCurrData: data})}
                            payload={this.state.arg}
                            credentials={this.state.credentials}
                            amqpState={this.state.amqpState}
                            amqpMsg={this.state.amqpMsg}
                            connectAmqpServer={this.connectAmqpServer}
                            disconnectAmqpServer={this.disconnectAmqpServer}
                            routePreviousPage={ _ => this.routePage('dashboard')}
                        />
                    }
                    {this.state.currPage == 'uxvDetail' &&
                        <UxvDetailComponent.RightPanel
                            ref={x => {this.uxvDetailRightPanel = x }}
                            map={this.map}
                            currPage={this.state.uxvDetailPageCurrPage}
                            setCurrPage={ page => this.setState({uxvDetailPageCurrPage: page})}
                            setCurrData={ data => this.setState({uxvDetailPageCurrData: data})}
                            uxv={this.state.arg}
                            credentials={this.state.credentials}
                            amqpState={this.state.amqpState}
                            amqpMsg={this.state.amqpMsg}
                            beacon={this.state.beacon}
                            connectAmqpServer={this.connectAmqpServer}
                            routePreviousPage={ _ => this.routePage('dashboard')}
                            routeMissionDetailPage={ mission_id => this.routeMissionDetailPage(mission_id)}
                            startConsumeBeacon={ _ => {
                                this.ws.send(JSON.stringify({type:'consume_uxv_beacon',codename:this.state.arg.codename}))
                            }}
                            
                        />
                    }
                    {this.state.currPage == 'dataset' &&
                        <DatasetComponent.RightPanel
                            ref={x => this.datasetRightPanel = x}
                            setCurrPage={ page => this.setState({datasetPageCurrPage: page})}
                            credentials={this.state.credentials}
                            routePreviousPage={_ => this.routePage('dashboard')}
                        />
                    }
                    <div id='acknowledgement'><i>The project leading to this application has received funding from European Union’s Horizon 2020 research and innovation programme under grant agreement No. 814893.</i></div>
                </div>

                { ['dashboard','payloadDetail','account','settings','newMission','missionDetail','uxvDetail'].includes(this.state.currPage) && <div>
                    <div id='navbar-bottom-spacer'></div>,
                    <div class='navbar bottom' style={{paddingLeft:this.state.showLeftPanel ? '200px': 0}}>
                        { this.state.showLeftPanel &&
                            <div class='button'
                                onClick={_=>this.setState({showLeftPanel:false})}>
                                    <svg height="12" width="12"><polygon points='2,6 10,2 10,10' fill="white"/></svg>
                                    Hide
                            </div>
                        }
                        { this.state.showLeftPanel ||
                            <div class='button' onClick={_=>this.setState({showLeftPanel:true})}>Menu</div>
                        }
                        <div class='navbar-separator'/>
                        <div><div class='button'
                            onClick={ _=> {
                                this.app.scrollTo(0,0)
                                this.setState({
                                    currPage: 'dashboard',
                                    dashboardPageCurrPage: 'live_map'
                                })}
                            }>Map</div></div>
                    </div>
                </div>}
            </div>
        )
    }
}

export default App
