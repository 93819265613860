// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip {
    padding: 10px;
    background-color: whitesmoke;
    border-radius: 5px;
    position: absolute;
    display: inline-block;
    opacity: 0;
    pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/components/payload/chart.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,4BAA4B;IAC5B,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;IACrB,UAAU;IACV,oBAAoB;AACxB","sourcesContent":[".tooltip {\n    padding: 10px;\n    background-color: whitesmoke;\n    border-radius: 5px;\n    position: absolute;\n    display: inline-block;\n    opacity: 0;\n    pointer-events: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
