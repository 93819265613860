import React from 'react'

import './resetPassword.css'
import '../../style.css'

export default class ResetPasswordComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            state:'idle',
            has_token: this.props.token ? 'true' : null,
            user_type : null,
            username : null,
            token: this.props.token,
            email : null
        }
        window.history.pushState(null,'reset_password','#register_password')
        window.onpopstate = () => this.props.routeLoginPage()
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    setType = type => {
        console.log('type:',type)
        this.setState({user_type : type})
    }

    goToLogin = _ => this.props.routeLoginPage()

    requestReset = _ => {
        this.setState({
            state: 'sending'
        }, _ => {
            fetch(PUBLIC_PATH_ROOT + 'auth/request_password_reset', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify({
                    user_type: this.state.user_type,
                    username: this.state.username,
                    email: this.state.email,
                })
            })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    alert('Request for password reset successful. Instructions will be sent by email.')
                    return this.props.routeLoginPage()
                }
                throw data.reason
            })
            .catch( err => {
                console.log('err:', err)
                alert('Request failed, reason: ' + err)
                this.setState({state: 'idle'})
            })
        })
    }

    resetPassword = e => {
        e.preventDefault()

        if (this.state.password != this.state.confirmPassword) {
            alert('password not match')
            return
        }

        this.setState({
            state: 'resetting'
        }, _ => {
            fetch(PUBLIC_PATH_ROOT + 'auth/reset_password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify({
                    token:this.state.token,
                    password:this.state.password,
                    confirmPassword:this.state.password
                })
            })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    alert('Password successfully resetted.')
                    window.location.href = PUBLIC_PATH_ROOT;
                    return this.props.routeLoginPage()
                }
                throw data.reason
            })
            .catch( err => {
                console.log('err:', err)
                alert('Request failed, reason: ' + err)
                this.setState({state: 'idle'})
            })
        })
    }

    render() {
        return (
            <div id='resetPasswordContainer'>
                <div className='formContainer'>

                    <div id='titleContainer'>
                        <h2><b>Reset Password</b></h2>
                    </div>

                    <label>Token</label>
                    <div id='resetPasswordType' className='radioContainer'>
                        <div className='radioItem'>
                            <div>
                                <input type='radio' id='hasToken' name='has_token' value={false}
                                    onClick={this.onChange}
                                />
                                <label>I don't have a token</label>
                            </div>
                        </div>
                        <div className='radioSeparater'/>
                        <div className='radioItem'>
                            <div>
                                <input type='radio' id='noToken' name='has_token' value={true}
                                    onClick={this.onChange}
                                />
                                <label>I have a token</label>
                            </div>
                        </div>
                    </div>

                    { this.state.has_token == 'false' && <div>
                        <label>User Type</label>
                        <div id='resetPasswordType' className='radioContainer'>
                            <div className='radioItem'>
                                <div>
                                    <input type='radio' id='user' name='user_type' value='user'
                                        onClick={e => this.setType(e.target.value)}
                                    />
                                    <label>User</label>
                                </div>
                            </div>
                            <div className='radioSeparater'/>
                            <div className='radioItem'>
                                <div>
                                    <input type='radio' id='uxv' name='user_type' value='uxv'
                                        onClick={e => this.setType(e.target.value)}
                                    />
                                    <label>UXV</label>
                                </div>
                            </div>
                            <div className='radioSeparater'/>
                            <div className='radioItem'>
                                <div>
                                    <input type='radio' id='payload' name='user_type' value='payload'
                                        onClick={e => this.setType(e.target.value)}
                                    />
                                    <label>Payload</label>
                                </div>
                            </div>
                        </div>
                    </div>}

                    { this.state.user_type && this.state.has_token == 'false' ? <div>
                        <div>
                            <label for='username'>Username</label>
                            <input
                                required
                                class='input-fullwidth'
                                value={this.state.username ?? ''}
                                onChange={this.onChange}
                                placeholder='max_mustermann'
                                name='username'
                                type='text'
                            />
                        </div>
                        <div>
                            <label for='email'>Email</label>
                            <input
                                required
                                class='input-fullwidth'
                                value={this.state.email ?? ''}
                                onChange={this.onChange}
                                placeholder='max_mustermann@cml.fraunhofer.de'
                                name='email'
                                type='email'
                            />
                        </div>
                        <button
                            id='resetPasswordButton'
                            onClick={this.requestReset}
                            disabled={!this.state.email || !this.state.username || this.state.state != 'idle'}
                            className='button-large fullWidthButton'>
                            {this.state.state == 'sending' ? 'Sending...' : 'Request Confirmation Email'}
                        </button>
                    </div>: <div></div>}

                    { this.state.has_token == 'true' &&
                    <form onSubmit={this.resetPassword}>
                        <fieldset disabled={this.state.state == 'resetting'}>
                            <label for='username'>Token</label>
                            <input
                                required
                                class='input-fullwidth'
                                value={this.state.token ?? ''}
                                onChange={this.onChange}
                                placeholder='token'
                                name='token'
                                type='text'
                            />
                            <label for='password'>New Password</label>
                            <input
                                required
                                class='input-fullwidth'
                                onChange={this.onChange}
                                value={this.state.password ?? ''}
                                name='password'
                                type='password'
                            />
                            <label for='confirmPassword'>Confirm New Password</label>
                            <input
                                required
                                class='input-fullwidth'
                                onChange={this.onChange}
                                value={this.state.confirmPassword ?? ''}
                                name='confirmPassword'
                                type='password'
                            />

                            <button
                                required
                                className='button-large fullWidthButton'
                                disabled={ ! (this.state.state =='idle' && this.state.token && this.state.password && this.state.confirmPassword) }
                                onClick={this.resetPassword}
                            >
                                {this.state.state == 'resetting' ? 'Resetting' : 'Reset'}
                            </button>
                        </fieldset>
                    </form>}
                </div>

                <div className='formContainer'>
                    <button
                        id = 'cancelButton'
                        className='button-large fullWidthButton'
                        onClick={ _ => this.goToLogin()}>
                        Back to Login
                    </button>
                </div>
            </div>
        )
    }
}