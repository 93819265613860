import React from 'react'
import './dial.css'
import '../../style.css'

export class Dial extends React.Component {

    constructor(props) {
        super(props)
        this.dialBody = null
        this.state = {debug:''}
        this.angle = null
        this.value = 0
    }

    rotateDial(angle) {
        let isSnapped = Math.abs(angle % 12) < 5
        let snappedAngle = Math.round(angle / 12) * 12
        let displayedAngle = isSnapped ? snappedAngle : angle
        this.dialBody.style.transform = 'rotate('+displayedAngle+'deg)'

        if (!this.angle) {
            this.angle = snappedAngle
            return
        }

        if ( snappedAngle - this.angle == 12 ) {
            this.value += 1
            this.props.onSnap?.({increment: 1, value:this.value})
        }
        else if ( snappedAngle - this.angle == -12 ) {
            this.value -= 1
            this.props.onSnap?.({increment: -1, value: this.value})
        }

        this.setState({
            debug:this.value + ' ' + snappedAngle,
        })
        this.angle = snappedAngle
    }

    onEnter = _ => {
        this.setState({debug:'onEnter'})
        let rect = this.dialBody.getBoundingClientRect()
        this.clientCenterX = rect.x + rect.width * 0.5
        this.clientCenterY = rect.y + rect.height * 0.5
    }

    onMouseMove = e => {
        if (e.buttons != 1) return
        e.preventDefault()
        // console.log('e.buttons:',e.buttons)
        let x = e.clientX - this.clientCenterX
        let y = e.clientY - this.clientCenterY
        let angle = Math.atan2(y,x)*180/Math.PI
        this.rotateDial(angle)
    }

    onTouchMove = e => {
        e.preventDefault()
        let x = e.touches[0].clientX - this.clientCenterX
        let y = e.touches[0].clientY - this.clientCenterY
        let angle = Math.atan2(y,x)*180/Math.PI
        this.rotateDial(angle)
    }

    onEnd = _ => {
        let adjustedAngle = Math.round(this.angle / 12) * 12
        this.dialBody.style.transform = 'rotate('+adjustedAngle+'deg)'
        this.props.onEnd?.()
    }

    componentDidMount() {
        this.dialBody.onmousemove = e => this.onMouseMove(e)
        this.dialBody.onmouseenter = e => this.onEnter(e)
        this.dialBody.onmouseleave = _ => this.onEnd()
        this.dialBody.onmouseup = _ => this.onEnd()
        this.dialBody.ontouchstart = e => this.onEnter(e)
        this.dialBody.ontouchmove = e => this.onTouchMove(e)
        this.dialBody.ontouchend = _ => this.onEnd()
    }

    render() {
        return <div className='dialContainer'>
            <div className='dialBase'>
                <div
                    className='dialBody'
                    ref={x=> this.dialBody = x}
                >
                </div>
            </div>
        </div>
    }
}