import React from 'react'
import '../../style.css'
import * as MissionEditor from '../missionEditor/missionEditor'

export class BaseCreator extends React.Component {

    constructor(props) {
        super(props)
        if (this.props.existing_baseitem) console.log('existing baseitem:',this.props.existing_baseitem)
        this.state = {
            baseitem_types: null,
            baseitem_type: this.props.existing_baseitem?.baseitem_type ?? null,
            baseitem_precedence: this.props.existing_baseitem?.precedence ?? null,
            baseitem_name: this.props.existing_baseitem?.name ?? null,
            baseitem_callsign: this.props.existing_baseitem?.callsign ?? null,
            baseitem_description: this.props.existing_baseitem?.description ?? null,
            lonlat: this.props.existing_baseitem?.centroid.coordinates ?? null
        }
        this.props.map.plotTempBaseMarker?.(this.props.existing_baseitem?.centroid.coordinates)
    }

    componentDidMount() {
    }

    deleteBaseitem = baseitem_id => {
        console.log('delete baseitem...')
        fetch(PUBLIC_PATH_ROOT + 'api/delete_baseitem',{
            method: 'POST',
            body: JSON.stringify({token:this.props.credentials.token,baseitem_id:baseitem_id})
        })
        .then(rs=>rs.json())
        .then(rs=>{
            if (!rs.success) throw rs.reason
            this.setState({existing_baseitem:null}, _ => this.props.routePreviousPage())
        })
        .catch(err=>console.log('err:',err))
    }

    createBaseitem = _ => {
        console.log('creating baseitem...')
        Promise.resolve()
        .then( _ => {
            let polygon = this.props.map.getTempBaseitemGeoJson()
            if (!this.state.baseitem_type) throw 'baseitem_type missing'
            if (!this.state.baseitem_precedence) throw 'baseitem_precedence missing'
            if (!polygon) throw 'geoJson missing'
            return fetch(PUBLIC_PATH_ROOT + 'api/create_baseitem',{
                method:'POST',
                body: JSON.stringify({
                    token:this.props.credentials.token,
                    mission_id: this.props.mission_id,
                    baseitem_type: this.state.baseitem_type,
                    precedence: this.state.baseitem_precedence,
                    polygon: polygon
                })
            })
        })
        .then(rs=>rs.json())
        .then(rs=>{
            if (!rs.success) throw rs.reason
            console.log('baseitem:',rs.baseitem)
            this.props.routePreviousPage()
        })
        .catch(err=>console.log('err:',err))
    }


    render() {

        return <div>
            <h3>New Base Item</h3>

            <label>Base Item Type</label>
            <select
                value={this.state.baseitem_type ?? 'null'}
                onChange={ e => this.setState({baseitem_type:e.target.value})}
            >
                <option disable='true' hidden value={null}>Select Infrastructure Type</option>
                <option value='ops_center'>Operations Center</option>
                <option value='uav_pad' title='An exact location on land at which a UAV is inserted and extracted'>UAV Pad</option>
                <option value='usv_station' title='An exact location at water at which a USV is inserted and extracted'>USV Station</option>
            </select>

            <label>Base Item Precedence</label>
            <select
                value={this.state.baseitem_precedence ?? 'null'}
                onChange={ e => this.setState({baseitem_precedence:e.target.value})}
            >
                <option disable='true' hidden value={null}>Select Preference</option>
                <option value='0' title='ideal for mission'>Primary</option>
                <option value='1' title='less idea characteristics, but still usable'>Secondary</option>
            </select>

            <label>Base Item Location</label>
            <MissionEditor.Viewer
                map={this.props.map}
                mapSize={'small'}
                ref={x=> this.missionEditor = x}
                currPage='add_baseitem'
                onClick= { x => {
                    this.setState({lonlat:x})
                }}
            />
            <table class='table-normal'>
                <thead>
                    <tr><th>Lon</th><th>Lat</th><th>Radius</th></tr>
                </thead>
                <tbody>
                    <tr><td>{this.state.lonlat?.[0]}</td><td>{this.state.lonlat?.[1]}</td><td>2</td></tr>
                </tbody>
            </table>


            { this.props.existing_baseitem &&
                <button class='button-large' onClick={_ => this.deleteBaseitem(this.props.existing_baseitem.id)}>Delete Base Item</button>
            }
            { this.props.existing_baseitem ?
                <button class='button-large' onClick={this.createBaseitem}>Overwrite Base Item</button>
                :
                <button class='button-large' onClick={this.createBaseitem}>Create Base Item</button>
            }

            <button class='button-large' onClick={this.props.routePreviousPage}>Cancel</button>
        </div>
    }

}