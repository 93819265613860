// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sort-filter-button {
    cursor: pointer;
}

.sort-filter-button.asc {
    
}

.sort-filter-button.desc {
    
}

.sort-filter-button.filtered {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/sortFilterButton/sortFilterButton.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".sort-filter-button {\n    cursor: pointer;\n}\n\n.sort-filter-button.asc {\n    \n}\n\n.sort-filter-button.desc {\n    \n}\n\n.sort-filter-button.filtered {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
