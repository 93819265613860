import React, { Component } from 'react'
import './register.css'
import '../../style.css'

export default class RegisterUxvComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            state : 'idle',
            codename: '',
            uxv_type: '',
            manufacturer: '',
            phone: '',
            company: '',
            email: '',
            password: '',
            confirmPassword: '',
        }
    }

    checkState = () => {}

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value })
        this.checkState()
    }

    checkCodeName(new_codename) {
        let codename = new_codename.toLowerCase()
        let regex = /^$|^[a-z]+[0-9]*$/
        console.log('register: trying codename:',codename)
        if (regex.test(codename)){
            this.setState({codename:codename})
        } else {
            console.log('register: invalid codename')
        }
    }

    onSubmit = e => {
        
        e.preventDefault()
        const newUxv = {
            uxv_type: this.state.uxv_type,
            manufacturer: this.state.manufacturer,
            codename: this.state.codename,
            email: this.state.email,
            phone: this.state.phone,
            company: this.state.company,
            password: this.state.password,
            confirmPassword: this.state.confirmPassword
        }
        console.log('register: submit:', newUxv)
        if (newUxv.password == newUxv.confirmPassword) {
            this.setState({state:'loading'}, _ => {
                fetch(PUBLIC_PATH_ROOT + 'auth/register_uxv', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8',
                    },
                    body: JSON.stringify(newUxv)
                })
                .then(res => res.json())
                .then(data=> {
                    if (data.success) {
                        console.log('register: success')
                        alert('Register success. An activation link is sent to the email.')
                        this.goToLogin()
                    }
                    else throw data.reason
                })
                .catch(err => {
                    alert('register: error:' + JSON.stringify(err))
                    console.log('register: err:',err)
                    this.setState({state:'idle'})
                })
            })
        }
        else
            alert("Password confirmation doesn't match'")
    }

    goToLogin = _ => this.props.routeLoginPage()

    render() {
        return (
            <div className='registerContainer'>
            <div className='formContainer'>
                {/* <h4><b>Register UXV</b></h4> */}
                <form autoComplete='off' onSubmit={this.onSubmit}>
                <div>
                    <label>Vehicle Type*</label>
                    <select
                        class='input-fullwidth'
                        required
                        onChange={this.onChange}
                        value={this.state.uxv_type}
                        id='uxv_type'
                    >
                        <option value=''>None</option>
                        <option id='uav' value='uav'>UAV (Unmanned Aerial Vehicle)</option>
                        <option id='usv' value='usv'>USV (Unmanned Surface Vehicle)</option>
                        <option id='uuv' value='uuv'>UUV (Unmanned Underwater Vehicle)</option>
                    </select>
                </div>
                <div>
                    <label for='manufacturer'>Manufacturer*</label>
                    <input
                        class='input-fullwidth'
                        onChange={this.onChange}
                        value={this.state.manufacturer}
                        placeholder='Manufacturer'
                        id='manufacturer'
                        type='text'
                    />
                </div>
                <div>
                    <label for='codename'>Codename*</label>
                    <input
                        class='input-fullwidth'
                        required
                        onChange={e => this.checkCodeName(e.target.value)}
                        value={this.state.codename}
                        placeholder='uxv00'
                        id='codename'
                        type='text'
                    />
                </div>
                <div>
                    <label for='company'>Company</label>
                    <input
                        class='input-fullwidth'
                        onChange={this.onChange}
                        value={this.state.company}
                        placeholder='fraunhofer'
                        id='company'
                        type='text'
                    />
                </div>
                <div>
                    <label for='email'>Email*</label>
                    <input
                        class='input-fullwidth'
                        required
                        onChange={this.onChange}
                        value={this.state.email}
                        placeholder='max_mustermann@cml.fraunhofer.de'
                        id='email'
                        type='email'
                    />
                </div>
                <div>
                    <label for='phone'>Phone</label>
                    <input
                        class='input-fullwidth'
                        onChange={this.onChange}
                        value={this.state.phone}
                        placeholder='+123 4567 8900'
                        id='phone'
                        type='text'
                    />
                </div>
                <div>
                    <label for='password'>Password*</label>
                    <input
                        class='input-fullwidth'
                        required
                        onChange={this.onChange}
                        value={this.state.password}
                        id='password'
                        type='password'
                    />
                </div>
                <div>
                    <label for='confirmPassword'>Confirm Password*</label>
                    <input
                        class='input-fullwidth'
                        required
                        onChange={this.onChange}
                        value={this.state.confirmPassword}
                        id='confirmPassword'
                        type='password'
                    />
                </div>
                <button
                    id='registerButton'
                    type='submit'
                    className='button-large fullWidthButton'
                    disabled={this.state.state!='idle'}
                >
                    {this.state.state == 'idle' ? 'Sign Up' : 'Loading'}
                </button>
            </form>
            </div>
            </div>
        )
    }
}