import React from 'react'
import { Qrcode } from '../qrcode/qrcode'
import { SortFilterButton } from '../sortFilterButton/sortFilterButton'

export class DatasetList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    
    componentDidMount() {
        this.queryDataset(this.props.dataset_name ?? 'shipspotter')
    }
    
    setSort(sort_func) {
        let sorted_datasets = this.state.datasets
        .filter( x => this.state.datasetQuery ? new RegExp(`${this.state.datasetQuery}`,'i').test(JSON.stringify(x)) : true )
        .sort( (a,b) => sort_func?.(a,b) ?? new Date(a.start_at) < new Date(b.start_at) )
        this.setState({ sorted_datasets : sorted_datasets})
    }
    
    queryDataset(dataset_name) {
        console.log('dataset: datasetlist: fetching datasets...')
        fetch(PUBLIC_PATH_ROOT + 'api/' + dataset_name, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({ token: this.props.credentials.token })
        })
        .then(res => res.json())
        .then(res => {
            console.log('datasets:', res.datasets)
            if (res.success)
                this.setState({
                    datasets: res.datasets,
                    sorted_datasets: res.datasets,
                })
            else throw res.reason
        })
        .catch(err => console.log(`get ${api_name} err:`, err))
    }
    
    render = _ => {
        return (
            <div id='dataset-list-container'>
                <table class='table-card full-width'>
                    <thead>
                        { (this.props.has_filter_dataset ?? true) &&<tr><th colSpan='6'>
                        <input
                            ref={x => this.locationInput = x}
                            onFocus={ _ => this.setState({datasetQuery: ''})}
                            onBlur={ _ => setTimeout(_ => this.setState({datasetQuery:null}),200)}
                            onChange={e => this.setState({datasetQuery:e.target.value})}
                            value={this.state.datasetQuery ?? ''}
                            placeholder={'🔍 filter datasets...'}
                            id="dataset_finder"
                            type="text"
                            class='input-fullwidth'
                        />
                        </th></tr>}
                        <tr>
                            <th
                                class='sortable'
                                onClick={ _ => this.setSort((a,b) => new Date(a.created_at) < new Date(b.created_at))}
                            >Time Created <SortFilterButton sort_direction='asc' has_filter={true}/></th>
                            <th
                                class='sortable'
                                onClick={ _ => this.setSort((a,b) => new Date(a.edited_at) < new Date(b.edited_at))}
                            >last edited at</th>
                            <th
                                class='sortable'
                                onClick={ _ => this.setSort((a,b) => new Date(a.edited_by) < new Date(b.edited_by))}
                            >last edited by</th>
                            <th
                                class='sortable'
                                onClick={ _ => this.setSort((a,b) => a.name < b.name)}
                            >Name</th>
                            <th
                                class='sortable'
                                onClick={ _ => this.setSort((a,b) => a.state < b.state)}
                            >Status</th>
                        </tr>
                    </thead>
                    <tbody>
                    { this.state.datasets == null ?
                        <tr><td colSpan='7'>Datasets List Not Accessible</td></tr>
                    :
                        this.state.sorted_datasets?.length ? this.state.sorted_datasets
                        .map( (x,i) => ! x.is_hidden && <Fragment key={i}>
                            <tr
                                class={'summary' + this.state.focused_card_id == i ? ' focused' : ' '}
                                onClick={ _ => this.setState({
                                    focused_card_id: this.state.focused_card_id == i ? null : i
                                }, _ => {
                                    this.props.on_focused?.(x.id)
                                })}
                            >
                                <td>{new Date(x.created_at).toLocaleString([],{
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                    hour: undefined,
                                    minute: undefined}) ?? ''}</td>
                                <td>{new Date(x.start_at ?? x.created_at).toLocaleString([],{
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                    hour: undefined,
                                    minute: undefined}) ?? ''}</td>
                                <td><code class='dataset_name'>{x.name ?? ''}</code></td>
                                <td title={x.use_case?.description}>{x.use_case?.name ?? ''}</td>
                                <td>
                                {
                                    (   y => {
                                        if (y.checked_in_uxvs)
                                            return <Fragment>Active {green_circle}</Fragment>
                                        return <Fragment>Unknown {grey_circle}</Fragment>
                                    }) (x)
                                }
                                </td>
                            </tr>
                            { this.state.focused_card_id == i &&
                                <tr
                                    class='detail'
                                >
                                    <td colSpan='4'>
                                        Dataset ID: <span class='codename'>{x.id}</span>&emsp;
                                        Requested By: 
                                            &nbsp;
                                            <span>
                                                <img class='avatar' height='24px' width='24px' src={x.requested_by?.avatar_image_url} alt="avatar"/>
                                                &nbsp;
                                                <code class='username'>{x.requested_by?.username}</code>
                                            </span>&emsp;
                                        <button
                                            class='button-normal'
                                            onClick={ _ => this.props.routeDatasetDetailPage(x.id)}
                                        >
                                            Detail
                                        </button>
                                        <button
                                            // key={this.state.datasets[i].is_hidden}
                                            class='button-normal'
                                            onClick={ _ => {
                                                this.setState(s => {
                                                    s.datasets.find(m => m.id == x.id).is_hidden = true
                                                    s.focused_card_id = null
                                                    return s
                                                })
                                            }}
                                        >
                                            Hide
                                        </button>
                                    </td>
                                </tr>
                            }
                        </Fragment>)
                        :
                        <tr><td colSpan='6'>No Datasets Available</td></tr>
                    }
                    
                    </tbody>
                </table>
            </div>
        )
    }
    
        
}

export class LeftPanel extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {}
    }
    
    render = _ =>
        <Fragment>
            <input
                ref={x => this.locationInput = x}
                onFocus={ _ => this.setState({settingQuery: ''})}
                onBlur={ _ => setTimeout(_ => this.setState({settingQuery:null}),200)}
                onChange={e => this.setState({settingQuery:e.target.value})}
                value={this.state.settingQuery ?? ''}
                placeholder={'🔍 search settings...'}
                id="settings_finder"
                type="text"
                class='input-fullwidth'
            />
            <br/>
            <br/>
            <h2>Annotator for <code class='username'>{this.props.credentials.username || this.props.credentials.codename}</code></h2>
            <div class={this.props.currPage == 'browse' ? 'selected' : ''}
                onClick={ _ => this.props.setCurrPage('browse')}
            ><p>Browse</p></div>
            <div class={this.props.currPage == 'dataset_status' ? 'selected' : ''}
                onClick={ _ => this.props.setCurrPage('dataset_status')}
            ><p>Editor</p></div>
            <Qrcode url={window.location.href} />
        </Fragment>
}


export class RightPanel extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {}
        window.history.pushState(null,'dataset','/dataset')
        window.onpopstate = () => {
            console.log('route to nothing.')
            window.history.pushState(null,'dataset','/dataset')
        }
        this.props.setCurrPage('browse')
    }
    
    render = _ =>
        <Fragment>
            <input
                ref={x => this.locationInput = x}
                onFocus={ _ => this.setState({settingQuery: ''})}
                onBlur={ _ => setTimeout(_ => this.setState({settingQuery:null}),200)}
                onChange={e => this.setState({settingQuery:e.target.value})}
                value={this.state.settingQuery ?? ''}
                placeholder={'🔍 search settings...'}
                id="settings_finder"
                type="text"
                class='input-fullwidth'
            />
            <br/>
            <br/>
            <h2>Annotator for <code class='username'>{this.props.credentials.username || this.props.credentials.codename}</code></h2>
            <div class={this.props.currPage == 'browse' ? 'selected' : ''}
                onClick={ _ => this.props.setCurrPage('browse')}
            ><p>Browse</p></div>
            <div class={this.props.currPage == 'dataset_status' ? 'selected' : ''}
                onClick={ _ => this.props.setCurrPage('dataset_status')}
            ><p>Editor</p></div>
            <Qrcode url={window.location.href} />
        </Fragment>
}
 
