import React, {Fragment} from 'react'
import './sortFilterButton.css'

const funnel_icon = <svg height="12" width="12"><polygon points='0,0 12,0 8,4 8,12 4,10 4,4' fill="rgb(0,110,146)"/></svg>

export class SortFilterButton extends React.Component {

    constructor(props) {
        super(props)
    }

    render(){
        return (
            <span
                class='sort-filter-button'
            >{this.props.name} {{'asc': '↥' , 'desc': '↧' , null:''}[this.props.sort_direction]} {this.props.has_filter && funnel_icon}</span>
        )
    }
}