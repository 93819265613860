import React, {Fragment} from 'react'
import * as Potree from '../potree/Potree.js'
import "regenerator-runtime/runtime.js"

export class PotreeViewer extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        console.log('potree viewer mounted.')
        this.viewer = new Potree.Viewer(
            document.getElementById("potree_render_area"),
            document.getElementById("potree_container")
        )
        let viewer = this.viewer
        viewer.setEDLEnabled(true)
		viewer.setFOV(60)
		viewer.setPointBudget(100000000)
		viewer.loadSettingsFromURL()
		viewer.setDescription('')
		
		viewer.loadGUI( _ => {viewer.setLanguage('en')})

		// Load and add point cloud to scene
        Potree.loadPointCloud('https://uxv.services/3d/test01/metadata.json')
        .then( pointcloud => {
            console.log('app: loaded')
            let material = pointcloud.material;
            
            // material.activeAttributeName = 'rgba'
            material.activeAttributeName = 'depth'
            material.size = 0.01;
            // material.minSize = 0.001;
            // material.maxSize = 0.1
			// material.pointSizeType = Potree.PointSizeType.ADAPTIVE;
            material.pointSizeType = Potree.PointSizeType.FIXED;
			
			viewer.scene.addPointCloud(pointcloud);
			viewer.fitToScreen();
            console.log('viewer:',viewer)
        })
        .catch( err => console.log('app: err:',err))
		// Potree.loadPointCloud('/lion_takanawa/cloud.js', "Lion", e => {
        // Potree.loadPointCloud('https://uxv.services/3d/test01/metadata.json', "test01", e => {
        // // Potree.loadPointCloud('/output_00/metadata.json', "test", e => {
        //     console.log('app: loaded')
		// 	let pointcloud = e.pointcloud
        //     console.log('pointcloud:', e.pointcloud)
        //     let material = pointcloud.material;
            
        //     material.activeAttributeName = 'rgba'
        //     material.size = 1;
        //     // material.minSize = 0.02;
		// 	material.pointSizeType = Potree.PointSizeType.ADAPTIVE;
			
		// 	viewer.scene.addPointCloud(pointcloud);
		// 	viewer.fitToScreen();
        // })
        // .then( res => console.log('app: load result:', res))
        // .catch( err => console.log('app: err:',err))
    }

    render(){
        return (
            <div id="potree_container">
                <div id="potree_render_area"/>
            </div>
        )
    }
}
