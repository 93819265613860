import React, {Fragment} from 'react'
import { Qrcode } from '../qrcode/qrcode'
import './totp.css'
import '../../style.css'

export default class RegisterTotpComponent extends React.Component {
    constructor(props) {
        super(props)
        console.log('authenticator page, token:',this.props.token)
        this.state = {
            state:'idle',
            totp_url: null
        }
        window.history.pushState(null,'register_totp','#register_totp')
        window.onpopstate = () => this.props.routeLoginPage()
    }

    componentDidMount() {
        this.requestRegisterTotp()
    }

    goToLogin = _ => this.props.routeLoginPage()

    requestRegisterTotp = _ => {
        this.setState({
            state: 'sending'
        }, _ => {
            fetch(PUBLIC_PATH_ROOT + 'auth/request_register_totp', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify({
                    preauth_token: this.props.preauth_token
                })
            })
            .then(res => res.json())
            .then(rs => {
                // console.log('rs:',rs)
                if (!rs.success) {
                    throw rs
                }
                this.setState({totp_url: rs.totp_url})
                // return this.props.routeLoginPage()
            })
            .catch( err => {
                console.log('err:', err)
                alert('Request failed, reason: ' + JSON.stringify(err))
                this.setState({state: 'idle'})
            })
        })
    }

    confirmTotp() {
        this.setState({
            state: 'sending'
        }, _ => {
            fetch(PUBLIC_PATH_ROOT + 'auth/verify_totp_registration', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify({
                    preauth_token: this.props.preauth_token,
                    totp_code: this.state.totp_code
                })
            })
            .then(res => res.json())
            .then(rs => {
                // console.log('rs:',rs)
                if (!rs.success) {
                    throw rs
                }
                alert('Request for TOTP registration successful.')
                return this.props.routeLoginPage()
            })
            .catch( err => {
                console.log('err:', err)
                alert('Request failed, reason: ' + err)
                this.setState({state: 'idle'})
            })
        })
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    render () {
        return (
            <div id='registerTotpContainer'>
                <div className='formContainer'>

                <div id='titleContainer'>
                    <h2><b>TOTP (Time-based One Time Password) Registration</b></h2>
                </div>

                { this.state.totp_url && <Fragment>
                    <p>Step 1: Scan the QRCode from an Authenticator App.</p>
                    <Qrcode url={this.state.totp_url}/>
                    <p>Step 2: Enter the 6-digit TOTP Code to confirm.</p>
                    <label for='totp_code'>TOTP Code</label>
                    <input
                        required
                        class='input-fullwidth'
                        value={this.state.totp_code ?? ''}
                        onChange={this.onChange}
                        placeholder='000000'
                        name='totp_code'
                        type='text'
                    />
                    <button
                        className='button-large fullWidthButton'
                        onClick={ _ => this.confirmTotp()}>
                        Confirm the new TOTP key
                    </button>
                </Fragment>}

                <button
                    id = 'cancelButton'
                    className='button-large fullWidthButton'
                    onClick={ _ => this.goToLogin()}>
                    Go Back to Login
                </button>
                </div>
            </div>
        )
    }
}