import React from 'react'
import * as d3 from 'd3'
import './chart.css'

export default class PayloadChart extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {

        // init the basic DOM elements such as svg, axis, trendline
        let chartAreaRect = this.chartArea.getBoundingClientRect()
        let margin = {top: 20, right: 80, bottom: 30, left: 60}
        let plotAreaHeight = chartAreaRect.height - margin.top - margin.bottom
        let plotAreaWidth = chartAreaRect.width - margin.left - margin.right

        let getX = x => new Date(x['Time']) // data -> value
        let getY = (x, sensorType) => x[sensorType]?.['Raw Data']
        let getYunit = (x, sensorType) => x[sensorType]?.['Units']
        
        let scaleX = d3.scaleTime()
        .range([margin.left,margin.left + plotAreaWidth])
        let drawXAxis = d3.axisBottom(scaleX).ticks(6)

        let scaleY = d3.scaleLinear()
        .range([margin.top + plotAreaHeight, margin.top])
        let drawYAxis = d3.axisLeft(scaleY).ticks(6)

        // add the graph canvas to the body of the webpage
        this.svg = d3.select(this.chartArea).append('svg')
        .attr('width','100%')
        .attr('height','100%')

        // draw x axis
        this.svg.append('g')
        .attr('class', 'xAxis')
        .attr('transform', `translate(0,${margin.top + plotAreaHeight})`)
        .call(drawXAxis)

        // draw tooltip
        this.tooltip = d3.select(this.chartArea).append('div')
        .attr('class','tooltip')

        this.svg.updateGraph = (sensorTypes, data, visibilities, setDomainY=null) => {

            let isMultiPlot = Object.values(visibilities).filter(x=>x==true).length > 1
            this.svg.selectAll('.yAxis')
                .data( isMultiPlot ? [] : ['yAxis'], _ => 'yAxis')
                .join(
                    enter => enter.append('g')
                        .attr('class', 'yAxis')
                        .attr('transform', `translate(${margin.left},0)`)
                        ,
                    update => update,
                    exit => exit.remove()
                )
                .call(drawYAxis)
            
            // generate color points
            let colorPoints = this.generateCircular2Dpoints(sensorTypes.length, 80)

            sensorTypes.forEach( (sensorType,i) => {

                // update domain
                scaleX.domain([d3.min(data, getX), d3.max(data, getX)])
                if (!setDomainY)
                    scaleY.domain([ Math.min(...data.map(x => getY(x, sensorType))), Math.max(...data.map(x => getY(x, sensorType)))])
                else
                    scaleY.domain(setDomainY)

                // update axis
                this.svg.select('.xAxis').call(drawXAxis)
                if (visibilities[sensorType] && !isMultiPlot) // plot Y axis only for single plot
                    this.svg.select('.yAxis').call(drawYAxis)

                
                // draw trendline function
                let drawLine = d3.line()
                .defined(x => !!getY(x, sensorType)) // only plot points with valid y values
                .x(x => scaleX(getX(x)))
                .y(x => scaleY(getY(x, sensorType)))

                this.svg.selectAll('.trendline.' + sensorType)
                .data(visibilities[sensorType] ? [sensorType] : [], _ => sensorType)
                .join(
                    enter => {
                        let g = enter.append('g')
                        .attr('class','trendline ' + sensorType)
                        // trendline
                        g.append('path')
                        .style('stroke-width', 1)
                        .style('stroke', d3.lab(50,colorPoints[i][0],colorPoints[i][1]))
                        .style('fill', 'none')
                        .on('mouseover', _ => this.tooltip.style('opacity',0.9).html(`<p>${sensorType}</p>`))
                        .on('mousemove', e => {
                            this.tooltip
                            .style('top', `${ e.pageY  - this.props.chartBlock.getBoundingClientRect().top - 10}px`)
                            .style('left',`${ e.pageX  - this.props.chartBlock.getBoundingClientRect().left + 10}px`)
                        })
                        .on('mouseout', _ => this.tooltip.style('opacity', 0))
                        // legend
                        g.append('text')
                        .attr('class', 'legend')
                        .attr('x',margin.left + plotAreaWidth + 3)
                        .attr('y', scaleY(getY(data[data.length - 1], sensorType))) // move label close to last data point
                        .style('fill', d3.lab(50,colorPoints[i][0],colorPoints[i][1]))
                        .text(sensorType)
                    },
                    update => update,
                    exit => exit
                        .remove()
                )
                this.svg.selectAll('.trendline.' + sensorType + ' path')
                .attr('d', drawLine(data))

                // move label close to last data point)
                this.svg.selectAll('.trendline.' + sensorType + ' text')
                .attr('y', scaleY(getY(data[data.length - 1], sensorType)))

                // draw data points
                this.svg.selectAll('.dot.' + sensorType)
                .data(visibilities[sensorType] ? data : [], x => [sensorType, x['Time']]) // use time + sensorType as the data key
                .join(
                    enter => enter.append('circle')
                        .attr('class', 'dot ' + sensorType)
                        .attr('r', 2)
                        .attr('fill', d3.lab(50,colorPoints[i][0],colorPoints[i][1]))
                        .on('mouseover', (e,x) => {
                            this.tooltip.style('opacity',0.9).html(`<p>${sensorType} <br> ${getX(x)} <br> ${getY(x,sensorType)} ${getYunit(x,sensorType)}</p>`)
                        })
                        .on('mousemove', e => {
                            this.tooltip
                            .style('top', `${ e.pageY  - this.props.chartBlock.getBoundingClientRect().top - 10}px`)
                            .style('left',`${ e.pageX  - this.props.chartBlock.getBoundingClientRect().left + 10}px`)
                        })
                        .on('mouseout', _ => this.tooltip.style('opacity', 0))
                        ,
                    update => update,
                    exit => exit
                        .remove()
                )
                .attr('cx', x => scaleX(getX(x)))
                .attr('cy', x => scaleY(getY(x, sensorType)))
            })
        }
    }

    generateCircular2Dpoints(n, r) {
        let k = 2 * Math.PI / n
        let points = [...Array(n).keys()].map( x=> [r * Math.sin(k * x), r * Math.cos(k * x)])
        return points
    }

    updateGraph(sensorTypes, data, visibilities) {
        if (data.length > 0) this.svg.updateGraph(sensorTypes, data, visibilities)
    }

    render() {
        return <div class='chartArea' ref={x => this.chartArea = x}/>
    }
}
