import React from 'react'
import '../../style.css'
import './chat.css'
import avatar from '../../../assets/images/avatar.svg'

const cross = (<svg width='10px' height='10px'>
    <line x1="0" y1="0" x2="10" y2="10" stroke="white" strokeWidth="2"/>
    <line x1="10" y1="0" x2="0" y2="10" stroke="white" strokeWidth="2"/>
</svg>)

export class FriendList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            state:'idle',
            selectedUsers:[],
            history:null
        }
    }

    componentDidMount() {
        this.updateChatroomList()
    }

    handleUserQueryChange(x) {
        // remove timer
        if (this.state.userQueryTimerId) clearTimeout(this.state.userQueryTimerId)
        // query users after timeout
        this.setState({
            userQuery: x,
            userQueryTimerId: setTimeout( _ => {
                fetch(PUBLIC_PATH_ROOT + 'auth/query_users', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json;charset=utf-8' },
                    body: JSON.stringify({ token: this.props.credentials.token, querystring:x})
                })
                .then(res => res.json())
                .then(res => {
                    if (res.success) {
                        let users = res.users
                        console.log('found users:',res.users)
                        this.setState({
                            foundUsers: users,
                            userQueryTimerId: null
                        }, _ => {
                            if (this.userSelector) this.userSelector.click()
                        })
                        return
                    }
                    throw res.reason
                })
                .catch(err => {
                    console.log('found users: error:',err)
                })
            }, 200)
        })
    }

    handleUserClicked(user) {
        console.log('user clicked:',user)
        this.setState(s => {
            s.selectedUsers.push(user)
            s.state = 'idle'
            s.userQuery = null
            return s
        }, this.updateChatroomList)
    }

    updateChatroomList = _ => {
        fetch(PUBLIC_PATH_ROOT + 'api/query_chatroom_by_member_uids',{
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({
                token: this.props.credentials.token,
                member_uids: [this.props.credentials.id, ...this.state.selectedUsers.map(x => x.id)]
            })
        })
        .then( rs => rs.json())
        .then( rs => {
            if (!rs.success) throw rs.reason
            console.log('chatrooms:',rs.chatrooms)
            this.setState({foundChatrooms:rs.chatrooms})
        })
        .catch( err => {
            console.log('err:',err)
        })
    }

    createChat = _ => {
        fetch(PUBLIC_PATH_ROOT + 'api/create_chatroom',{
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({
                token: this.props.credentials.token,
                member_uids: [this.props.credentials.id, ...this.state.selectedUsers.map(x => x.id)]
            })
        })
        .then( rs => rs.json())
        .then( rs => {
            if (!rs.success) throw rs.reason
            this.setState({selectedChat: rs.chatroom_id},this.fetchChatHistory)
        })
        .catch( err => {
            console.log('err:',err)
        })
    }

    fetchChatHistory = _ => {
        fetch(PUBLIC_PATH_ROOT + 'api/chat_history',{
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({
                token: this.props.credentials.token,
                chatroom_id: this.state.selectedChat.id
            })
        })
        .then( rs => rs.json())
        .then( rs => {
            if (!rs.success) throw rs.reason
            console.log('history:',rs.chat_messages)
            this.setState({chatHistory: rs.chat_messages})
        })
        .catch( err => {
            console.log('err:',err)
        })
    }

    render = _ => <div id='friendListContainer'>

        <h3>Recipients</h3>
        <div class='user-card-box'>
            { this.state.selectedUsers.map( (x,i) =>
                <div key={i} class='user-card'>
                    <img class='avatar' src={avatar} width='30px'/>
                    {x.username}
                    <button
                        class='button-cross'
                        onClick={ _ =>
                            this.setState(
                                {
                                    state:'idle',
                                    selectedUsers: this.state.selectedUsers.splice(i+1,1)
                                },
                                this.updateChatroomList
                            )
                        }
                    >{cross}</button>
                </div>
            )}
            { this.state.state == 'idle' && <div>
                <button
                    class='button-normal'
                    onClick={ _ => {
                        this.setState({state:'adding_recipient'})
                    }}
                >
                    Add Recipients
                </button>
            </div>}
        </div>

        
        { this.state.state == 'adding_recipient' && <div>
            <input
                ref={x => this.userInput = x}
                onFocus={ _ => this.setState({userQuery: ''})}
                onBlur={ _ => setTimeout(_ => this.setState({foundUsers:null}),200)}
                onChange={e => this.handleUserQueryChange(e.target.value)}
                value={this.state.userQuery ?? ''}
                placeholder={'🔍 Find Recipients'}
                id="user"
                type="text"
                class='input-fullwidth'
            />
            { this.state.foundUsers?.length == 0 &&
                <div class='search-dropdown'>
                    <table class='table-card' id='user-selector' ref={x=>this.userSelector = x}>
                        <thead><tr><th>No Users Found</th></tr></thead>
                    </table>
                    <hr/>
                </div>
            }
            { this.state.foundUsers?.length > 0 &&
                <div class='search-dropdown'>
                    <table class='table-card' id='user-selector' ref={x=>this.userSelector = x}>
                        <thead><tr><th>Users Found</th></tr></thead>
                        <tbody>
                            {this.state.foundUsers.map( (x,i) =>
                            <tr key={i}>
                                <td onClick={ _ => this.handleUserClicked(x)}><img class='avatar' src={avatar} width='20px'/> {`${x.username}`}</td>
                            </tr>)}
                        </tbody>
                    </table>
                    <hr/>
                </div>
            }
        </div>}

        { this.state.foundChatrooms != null && <h3>Available Chats</h3>}
        { this.state.foundChatrooms?.length == 0 &&
            <div class='search-dropdown'>
                <table class='table-card' id='user-selector'>
                    <thead><tr><th>No Chats Found</th></tr></thead>
                    <tbody>
                        <tr onClick={this.createChat}><td>
                            <p>Start a new Chat with {this.state.selectedUsers.length} Users</p>
                        </td></tr>
                    </tbody>
                </table>
                <hr/>
            </div>
        }
        { this.state.foundChatrooms?.length > 0 &&
            <div class='search-dropdown'>
                <table class='table-card' id='user-selector' ref={x=>this.chatroomSelector = x}>
                    <thead><tr><th>Chats Found</th></tr></thead>
                    <tbody>
                        {this.state.foundChatrooms.map( (x,i) =>
                        <tr key={i}>
                            <td
                                onClick={ _ => {
                                    console.log('clicked')
                                    this.setState({selectedChat: x},this.fetchChatHistory)   
                                }}
                            >
                                <div>
                                    Participants: { x.members.map(x => x.username).join(',') }
                                </div>
                            </td>
                        </tr>)}
                    </tbody>
                </table>
                <hr/>
            </div>
        }

        { this.state.selectedChat && <div>
            <label>Participants</label>
            <div class='user-card-box'>
                { this.state.selectedChat.members.map( (x,i) =>
                    <div key={i} class='user-card'>
                        <img class='avatar' src={avatar} width='30px'/>
                        {x.username}
                    </div>
                )}
            </div>
        </div>}

        { this.state.chatHistory?.length >= 0 && <label>History</label>}
        { this.state.chatHistory?.length == 0 && 
            <div class='chat-history'>
                <div class='message-container'>
                    <span>
                        <i>The Conversation History is Empty</i>
                    </span>
                </div>
            </div>
        }
        { this.state.chatHistory?.length > 0 &&
            <div>
                <div class='chat-history'>
                    {this.state.chatHistory.map( (x,i) => 
                        <div key={i} class={'message-container ' + (x.author.id == this.props.credentials.id ? 'sent-message':'received-message')} >
                            <span class='message-created-at'>{new Date(x.created_at).toLocaleString()}</span>
                            <span class='message-author'>{x.author.username}</span>
                            <span class='message-content'>{x.message}</span>
                        </div>
                    )}
                </div>
            </div>
        }

        { this.state.selectedChat && <div>
            <label>Message:</label><br/>
            <input
                placeholder='type message to be sent...'
                value={this.state.message ?? ''}
                onChange={e => this.setState({message:e.target.value})}
            />
            <button
                class='button-normal'
                disabled={this.state.sendStatus == 'sending'}
                onClick={_ => {
                    this.setState({sendStatus: 'sending'})
                    fetch(PUBLIC_PATH_ROOT + 'api/send_message', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json;charset=utf-8' },
                        body: JSON.stringify({
                            token: this.props.credentials.token,
                            chatroom_id: this.state.selectedChat.id,
                            message: this.state.message
                        })
                    })
                    .then( rs => rs.json())
                    .then( rs => {
                        if (!rs.success) throw rs.reason
                        console.log('history:',rs.chat_messages)
                        this.setState({
                            message: null,
                            sendStatus: 'idle',
                        }, this.fetchChatHistory)
                    })
                    .catch( err => {
                        console.log('err:',err)
                    })
                }}>
                Send
            </button>
        </div>}

    </div>
}
