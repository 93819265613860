// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `model-viewer {
    width:100%;
    height:600px;
}`, "",{"version":3,"sources":["webpack://./src/components/modelViewer/modelViewer.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;AAChB","sourcesContent":["model-viewer {\n    width:100%;\n    height:600px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
