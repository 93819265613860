// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    border-radius: 5px;
}

.card td {
    background-color: blueviolet;
}

.card td:first-child {
    border-radius: 10px 0 0 10px;
}

.card td:last-child {
    border-radius: 0 10px 10px 0;
}

.sortable {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/mission/mission.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".card {\n    border-radius: 5px;\n}\n\n.card td {\n    background-color: blueviolet;\n}\n\n.card td:first-child {\n    border-radius: 10px 0 0 10px;\n}\n\n.card td:last-child {\n    border-radius: 0 10px 10px 0;\n}\n\n.sortable {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
