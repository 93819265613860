// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mapContainer {
    height: 40vw;
    max-width: 100%;
}

.mapContainer.small {
    height: 200px;
    width: 200px;
}
.mapContainer.medium {
    height: 400px;
    width: 800px;
}

.mapContainer.small.fullscreen {
    position: absolute;
    height: 100%;
    width: 100%;
}
.mapContainer.medium.fullscreen {
    position: absolute;
    height: 100%;
    width: 100%;
}

#newMissionContainer {
    padding: 20px;
    left:10%;
    margin-left: auto;
    margin-right: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/mission/newMissionPage.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,YAAY;AAChB;AACA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;AACf;AACA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,aAAa;IACb,QAAQ;IACR,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".mapContainer {\n    height: 40vw;\n    max-width: 100%;\n}\n\n.mapContainer.small {\n    height: 200px;\n    width: 200px;\n}\n.mapContainer.medium {\n    height: 400px;\n    width: 800px;\n}\n\n.mapContainer.small.fullscreen {\n    position: absolute;\n    height: 100%;\n    width: 100%;\n}\n.mapContainer.medium.fullscreen {\n    position: absolute;\n    height: 100%;\n    width: 100%;\n}\n\n#newMissionContainer {\n    padding: 20px;\n    left:10%;\n    margin-left: auto;\n    margin-right: auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
