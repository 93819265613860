// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#dashboardContainer {
    padding: 20px;
    left:10%;
    margin-left: auto;
    margin-right: auto;
    /* max-width: 500px; */
}

#formContainer {
    position: flex;
    width: 45%;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    padding: 10px 40px;
    margin-left: auto;
    margin-right: auto;
}
nav .brand-logo {
    font-size: 4.1rem !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/dashboard/dashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,QAAQ;IACR,iBAAiB;IACjB,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,cAAc;IACd,UAAU;IACV,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,4BAA4B;AAChC","sourcesContent":["#dashboardContainer {\n    padding: 20px;\n    left:10%;\n    margin-left: auto;\n    margin-right: auto;\n    /* max-width: 500px; */\n}\n\n#formContainer {\n    position: flex;\n    width: 45%;\n    margin-left: auto;\n    margin-right: auto;\n    max-width: 500px;\n    padding: 10px 40px;\n    margin-left: auto;\n    margin-right: auto;\n}\nnav .brand-logo {\n    font-size: 4.1rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
