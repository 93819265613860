import React, {Fragment} from 'react'

export class Whitelist extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            whitelist:null,
            whitelist_state:'idle',
            whitelist_token:null,
            settings_state:'idle'
        }
        if (!['mission','payload','uxv'].includes(this.props.resource_type)) {
            console.error(`unhandled resource_type ${this.props.resouce_type}`)
        }
    }

    componentDidMount() {
        this.updateWhitelist(this.props.resource_type,this.props.resource_id)
    }

    removeUserFromWhitelist = user_id => {
        // fetch()
    }

    whitelistAddUser = (user_id, resource_type , resource_id) => {

        this.setState({settings_state:'waiting', whitelist_token: null}, _ => {
            fetch( PUBLIC_PATH_ROOT + `auth/whitelist_add_user`,{
                method: 'POST',
                headers: {'Content-Type': 'application/jsoncharset=utf-8'},
                body: JSON.stringify({token:this.props.credentials.token,user_id:user_id,resource_type:resource_type, resource_id:resource_id})
            })
            .then( res => res.json())
            .then( res => {
                if (res.success) {
                    this.setState({settings_state:'idle',foundUsers:null,userQuery:null,selected_user_id:null},this.updateWhitelist)
                } else throw res.reason
            })
            .catch( err => {
                console.log('whitelistAddUser failed:',err)
                alert('whitelistAddUser failed:' + err)
                this.setState({settings_state:'idle'})
            })
        })
    }

    shareResource = _ => {
        this.setState({settings_state:'waiting', whitelist_token: null}, _ =>{
            fetch( PUBLIC_PATH_ROOT + 'auth/share_resource',{
                method: 'POST',
                headers: {'Content-Type': 'application/jsoncharset=utf-8'},
                body: JSON.stringify({token:this.props.credentials.token,resource_type:this.props.resource_type,resource_id: this.props.resource_id})
            })
            .then( res => res.json())
            .then( res => {
                if (res.success) {
                    this.setState({whitelist_token:res.token, settings_state:'sharing_resource'})
                } else throw res.reason
            })
            .catch( err => {
                console.log('share resource failed:',err)
                alert('share resource failed:' + err)
                this.setState({settings_state:'idle'})
            })
        })
    }

    handleUserQueryChange(user) {
        // remove timer
        if (this.state.userQueryTimerId) clearTimeout(this.state.userQueryTimerId)
        // query users after timeout
        this.setState({
            userQuery: user,
            userQueryTimerId: setTimeout( _ => {
                fetch( PUBLIC_PATH_ROOT + 'auth/query_users', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json;charset=utf-8' },
                    body: JSON.stringify({ token: this.props.credentials.token, querystring:user})
                })
                .then(res => res.json())
                .then(res => {
                    if (res.success) {
                        let users = res.users
                        console.log('newMissiongPage: found users:',res.users)
                        this.setState({
                            foundUsers: users,
                            userQueryTimerId: null
                        }, _ => {
                            if (this.userSelector) this.userSelector.click()
                        })
                        return
                    }
                    console.log('found users failed, reason:', res.reason)
                })
                .catch(err => {
                    console.log('found users: error:',err)
                })
            }, 200)
        })
    }

    updateWhitelist = (resource_type,resource_id) => {
        fetch( PUBLIC_PATH_ROOT + `auth/whitelist`,{
            method: 'POST',
            headers: {'Content-Type': 'application/jsoncharset=utf-8'},
            body: JSON.stringify({token:this.props.credentials.token,resource_type:resource_type,resource_id:resource_id})
        })
        .then( res => res.json())
        .then( rs => {
            if (rs.success) {
                this.setState({whitelist: rs.whitelist})
            }
            else throw rs
        })
        .catch( err => {
            console.log('payload detail page: request failed:',err)
        })
    }

    render(){
        // console.log('whitelist:', this.state.whitelist)
        return ( <div>
            { ['idle','adding_whitelist_user','sharing_resource'].includes(this.state.settings_state) && <div>
                <table class='table-normal'>
                    <thead><tr><th>Username</th><th>Role</th><th>Action</th></tr></thead>
                    <tbody>
                    { this.state.whitelist != null ? (
                        this.state.whitelist.length ?
                            this.state.whitelist.map( x =>
                                <tr key={x.id}>
                                    <td><code class='username'>{x.username}</code></td>
                                    <td><code class='rolename'>{x.user_role}</code></td>
                                    <td><button className='button-normal' onClick={this.removeUserFromWhitelist}>Remove</button></td>
                                </tr>
                            )
                        :
                            <tr><td colSpan='3'>Whitelist is empty</td></tr>
                        )
                    :
                        <tr><td colSpan='3'>Whitelist not accessible</td></tr>
                    }
                    </tbody>
                </table>

                { this.state.settings_state =='idle' && this.state.whitelist_token == null && ['uxv','payload'].includes(this.props.resource_id) &&
                    <button
                        class='button-large'
                        onClick={this.shareResource}
                    >
                        Share this {this.props.resouce_type}...
                    </button>
                }
                { this.state.settings_state == 'sharing_resource' &&
                    <div>
                        <input ref={x=>this.whitelist_token = x} readOnly value={this.state.whitelist_token}/>
                        <p>share this token to another user to access this resource.</p>
                        <button
                            class='button-large'
                            onClick={ _ => {
                                this.whitelist_token.focus()
                                this.whitelist_token.select()
                                if (document.execCommand('copy')) {
                                    alert('token copied to clipboard')
                                }
                            }}
                        >
                            Copy Token to Clipboard
                        </button>
                        <button
                            class='button-large'
                            onClick={ _ => this.setState({settings_state:'idle',whitelist_token:null})}
                        >
                            Finish
                        </button>
                    </div>
                }
                { this.state.settings_state =='idle' && this.state.whitelist_token == null &&
                    <button
                        class='button-large'
                        onClick={ _ => this.setState({settings_state:'adding_whitelist_user'})}
                    >
                        Add User to Whitelist...
                    </button>
                }
                { this.state.settings_state == 'adding_whitelist_user' &&
                    <div>
                        <h4>Search User</h4>
                        <input
                            ref={x => this.userInput = x}
                            onFocus={ _ => this.setState({userQuery: null})}
                            onBlur={ _ => setTimeout(_ => this.setState({foundUsers:null}),200)}
                            onChange={e => this.handleUserQueryChange(e.target.value)}
                            value={this.state.userQuery ?? ''}
                            placeholder={'🔍 Type username here'}
                            id="user_query"
                            type="text"
                        /><br/>
                        { this.state.foundUsers?.length > 0 &&
                        <table class='table-normal' id='user-selector' ref={x=>this.userSelector = x}><tbody>
                            {this.state.foundUsers?.map( (x,i) =>
                            <tr
                                key={i}
                                onClick={ _ => {
                                    this.setState({userQuery: x.username,selected_user_id: x.id})
                                    this.userInput.value = x.username
                                }}
                            >
                                <td><code class='username'>{`${x.username}`}</code></td>
                            </tr>)}
                        </tbody></table>
                        }
                        <button
                            class='button-large'
                            disabled={ !this.state.selected_user_id }
                            onClick={ _ => this.whitelistAddUser(this.state.selected_user_id,this.props.resource_type,this.props.resource_id)}
                        >
                            Add User
                        </button>
                        <button
                            class='button-large'
                            onClick={ _ => this.setState({settings_state:'idle',userQuery:null,foundUsers:null,selected_user_id:null})}
                        >
                            Finish
                        </button>
                    </div>
                }
            </div>}
        </div>
    )}
}