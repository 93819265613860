import React, {Fragment} from 'react'

export class Settings extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        this.updateRooms()
    }

    updateRooms() {
        fetch( PUBLIC_PATH_ROOT + 'janus_tunnel/list_room',  {
            method: 'POST',
            headers: {'Content-Type': 'application/jsoncharset=utf-8'},
            body: JSON.stringify({token:this.props.credentials.token})
        })
        .then(res => res.json())
        .then(rs => {
            if (!rs.success) throw rs
            this.setState({rooms: rs.rooms})
        })
        .catch( err => {
            console.log('err:', err)
        })
    }

    render() {
        console.log(this.state.rooms)
        return (
            <div id='janusSettings'>
                <table class='table-normal'>
                    <thead>
                        <tr><th>Room Id</th><th>Details</th><th>Actions</th></tr>
                    </thead>
                    <tbody> { this.state.rooms?.map( x =>
                        <tr key={x.room}>
                            <td>{x.room}</td>
                            <td>{JSON.stringify(x)}</td>
                            <td>
                                <div class='button-normal'
                                    onClick={ _=> {}}
                                >Kick User</div>
                                <div class='button-normal'
                                    onClick={ _=> {
                                        fetch( PUBLIC_PATH_ROOT + 'janus_tunnel/destroy_room',  {
                                            method: 'POST',
                                            headers: {'Content-Type': 'application/jsoncharset=utf-8'},
                                            body: JSON.stringify({token:this.props.credentials.token, room_id: x.room})
                                        })
                                        .then(res => res.json())
                                        .then(rs => {
                                            if (!rs.success) throw rs
                                            this.updateRooms()
                                        })
                                        .catch( err => {
                                            console.log('err:', err)
                                        })
                                    }}
                                >Destroy Room</div>
                            </td>
                        </tr>
                    )
                    }</tbody>
                </table>
            </div>
        )
    }

}