import React from 'react'

import './verifyEmail.css'
import '../../style.css'

export default class VerifyEmailComponent extends React.Component {
    constructor(props) {
        super(props)
        console.log('verify email page, token:',this.props.token)
        this.state = {
            state:'idle',
            has_token: this.props.token ? 'true' : null,
            user_type : null,
            username : null,
            token: this.props.token
        }
        window.history.pushState(null,'verify_email','#verify_email')
        window.onpopstate = () => this.props.routeLoginPage()
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    goToLogin = _ => this.props.routeLoginPage()

    verifyEmail = e => {
        e.preventDefault()

        this.setState({
            state: 'verifying'
        }, _ => {
            fetch(PUBLIC_PATH_ROOT + 'auth/verify_email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify({
                    token:this.state.token
                })
            })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    alert('Email successfully verified.')
                    window.location.href = PUBLIC_PATH_ROOT;
                    return this.props.routeLoginPage()
                }
                throw data.reason
            })
            .catch( err => {
                console.log('err:', err)
                alert('Request failed, reason: ' + err)
                this.setState({state: 'idle'})
            })
        })
    }

    requestVerify = _ => {
        this.setState({
            state: 'sending'
        }, _ => {
            fetch(PUBLIC_PATH_ROOT + 'auth/request_verification_email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify({
                    user_type: this.state.user_type,
                    username: this.state.username,
                    email: this.state.email,
                })
            })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    alert('Request for email verification successful. Instructions will be sent by email.')
                    return this.props.routeLoginPage()
                }
                throw data.reason
            })
            .catch( err => {
                console.log('err:', err)
                alert('Request failed, reason: ' + err)
                this.setState({state: 'idle'})
            })
        })
    }

    render() {
        return (
            <div id='verifyEmailContainer'>
                <div className='formContainer'>

                    <div id='titleContainer'>
                        <h2><b>Email Verification</b></h2>
                    </div>

                    <label>Token</label>
                    <div id='resetPasswordType' className='radioContainer'>
                        <div className='radioItem'>
                            <div>
                                <input type='radio' checked={this.state.has_token=='false'} name='has_token' value='false'
                                    onClick={this.onChange}
                                />
                                <label>I don't have a token</label>
                            </div>
                        </div>
                        <div className='radioSeparater'/>
                        <div className='radioItem'>
                            <div>
                                <input type='radio' checked={this.state.has_token=='true'} name='has_token' value='true'
                                    onClick={this.onChange}
                                />
                                <label>I have a token</label>
                            </div>
                        </div>
                    </div>

                    { this.state.has_token == 'false' && <div>
                        <label>User Type</label>
                        <div id='emailVerifyType' className='radioContainer'>
                            <div className='radioItem'>
                                <div>
                                    <input type='radio' name='user_type' value='user'
                                        onClick={this.onChange}
                                    />
                                    <label>User</label>
                                </div>
                            </div>
                            <div className='radioSeparater'/>
                            <div className='radioItem'>
                                <div>
                                    <input type='radio' name='user_type' value='uxv'
                                        onClick={this.onChange}
                                    />
                                    <label>UXV</label>
                                </div>
                            </div>
                            <div className='radioSeparater'/>
                            <div className='radioItem'>
                                <div>
                                    <input type='radio' name='user_type' value='payload'
                                        onClick={this.onChange}
                                    />
                                    <label>Payload</label>
                                </div>
                            </div>
                        </div>
                    </div>}

                    { this.state.user_type && this.state.has_token == 'false' && <div>
                        <div>
                            <label for='username'>Username</label>
                            <input
                                required
                                class='input-fullwidth'
                                value={this.state.username ?? ''}
                                onChange={this.onChange}
                                placeholder='max_mustermann'
                                name='username'
                                type='text'
                            />
                        </div>
                        <div>
                            <label for='email'>Email</label>
                            <input
                                required
                                class='input-fullwidth'
                                value={this.state.email ?? ''}
                                onChange={this.onChange}
                                placeholder='max_mustermann@cml.fraunhofer.de'
                                name='email'
                                type='email'
                            />
                        </div>
                        <button
                            id='requestVerifyButton'
                            onClick={this.requestVerify}
                            disabled={!this.state.email || !this.state.username || this.state.state != 'idle'}
                            className='button-large fullWidthButton'>
                            {this.state.state == 'sending' ? 'Sending...' : 'Request Verification Email'}
                        </button>
                    </div>}

                    { this.state.has_token == 'true' && <form onSubmit={this.verifyEmail}>
                        <fieldset disabled={this.state.state == 'verifying'}>
                            <label for='token'>Token</label>
                            <input
                                required
                                class='input-fullwidth'
                                value={this.state.token ?? ''}
                                onChange={this.onChange}
                                placeholder='token'
                                name='token'
                                type='text'
                            />

                            <button
                                id='submitButton'
                                className='button-large fullWidthButton'
                                type='submit'
                                disabled={!this.state.token}
                            >
                                 {this.state.state == 'verifying' ? 'Verifying' : 'Verify'}
                            </button>
                        </fieldset>
                    </form> }

                    <button
                        id = 'cancelButton'
                        className='button-large fullWidthButton'
                        onClick={ _ => this.goToLogin()}>
                        Go Back to Login
                    </button>
                </div>
                
            </div>
        )
    }
}