// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.poiMapTooltip {
    background-color:whitesmoke;
    position:absolute;
    display:inline-block;
    padding: 6px;
    border-radius: 5px;
    box-sizing: border-box;
    pointer-events: none;
}
.poiMapTooltip p {
    margin: 3px;
}`, "",{"version":3,"sources":["webpack://./src/components/mission/poi.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,iBAAiB;IACjB,oBAAoB;IACpB,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;IACtB,oBAAoB;AACxB;AACA;IACI,WAAW;AACf","sourcesContent":[".poiMapTooltip {\n    background-color:whitesmoke;\n    position:absolute;\n    display:inline-block;\n    padding: 6px;\n    border-radius: 5px;\n    box-sizing: border-box;\n    pointer-events: none;\n}\n.poiMapTooltip p {\n    margin: 3px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
