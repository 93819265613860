import React, { Component } from 'react'
import './register.css'
import '../../style.css'

export default class RegisterPayloadComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            state : 'isLoading',
            payload_types: [],
            codename: '',
            payload_type: '',
            manufacturer: '',
            phone: '',
            company: '',
            email: '',
            password: '',
            confirmPassword: '',
        }
    }

    componentDidMount() {
        fetch(PUBLIC_PATH_ROOT + 'api/payload_types', {method: 'GET'})
        .then(res => res.json())
        .then(res => {
            if (res.success) {
                let payload_types = res.payload_types
                console.log('payload types:', payload_types)
                this.setState({
                    state: 'idle',
                    payload_types: payload_types
                })
                return
            }
            console.log('account: get payloads failed, reason:', res.reason)
        })
        .catch(err => {
            console.log('account: get payloads: error:',err)
        })
    }

    checkState = () => {}

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value })
        this.checkState()
    }

    checkCodeName(new_codename) {
        let codename = new_codename.toLowerCase()
        let regex = /^$|^[a-z]+[0-9]*$/
        console.log('register: trying codename:',codename)
        if (regex.test(codename)){
            this.setState({codename:codename})
        } else {
            console.log('register: invalid codename')
        }
    }

    onSubmit = e => {
        e.preventDefault()
        const newPayload = {
            payload_type: this.state.payload_type,
            manufacturer: this.state.manufacturer,
            codename: this.state.codename,
            email: this.state.email,
            phone: this.state.phone,
            company: this.state.company,
            password: this.state.password,
            confirmPassword: this.state.confirmPassword
        }
        console.log('register: submit:', newPayload)
        if (newPayload.password == newPayload.confirmPassword) {
            this.setState({state:'loading'}, _ => {
                fetch(PUBLIC_PATH_ROOT + 'auth/register_payload', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8',
                    },
                    body: JSON.stringify(newPayload)
                })
                .then(res => res.json())
                .then(data=> {
                    if (data.success) {
                        console.log('register: success')
                        alert('Register success. An activation link is sent to the email.')
                        this.goToLogin()
                    }
                    else throw data.reason
                })
                .catch(err => {
                    alert('register: error:' + JSON.stringify(err))
                    console.log('register: err:',err)
                    this.setState({state:'idle'})
                })
            })
        }
        else
            alert("Password confirmation doesn't match'")
    }

    goToLogin = _ => this.props.routeLoginPage()

    render() {
        if ( this.state.state == 'isLoading')
            return <div className='registerContainer'>Loading...</div>
        return (
            <div className='registerContainer'>
            <div className='formContainer'>
                {/* <h4><b>Register UXV</b></h4> */}
                <form autoComplete='off' onSubmit={this.onSubmit}>
                <div>
                    <label>Payload Type</label>
                    <select
                        required
                        onChange={this.onChange}
                        value={this.state.payload_type}
                        id='payload_type'
                    >
                        <option value=''>None</option>
                        { this.state.payload_types.map( x =>
                            <option key={x.name} value={x.name}>{x.name}</option>
                        )}
                    </select>
                </div>
                {/* <div>
                    <label>Technology</label>
                    <select
                        required
                        onChange={this.onChange}
                        value={this.state.payload_type}
                        id='technology'
                    >
                        <option value=''>None</option>
                        <option id='ndir' value='ndir'>Non-Dispersive Infra-Red sensing (NDIR)</option>
                        <option id='nduv' value='nduv'>Non-Dispersive Ultra-Violet spectroscopy (NDUV)</option>
                        <option id='doas' value='doas'>Differential Optical Absorption Spectroscopy (DOAS)</option>
                        <option id='los' value='los'>Lambda Oxygen Sensor</option>
                        <option id='ec' value='ec'>Electro-Chemical (EC)</option>
                        <option id='smo' value='smo'>Semiconductor Metal Oxide (SMO)</option>
                        <option id='pel' value='pel'>Pellistor</option>
                        <option id='qcl' value='qcl'>Quantum Cascade Layer (QCL)</option>
                        <option id='cpc' value='cpc'>Condensation Particle Counter (CPC)</option>
                        <option id='dc' value='dc'>Diffusion Charging</option>
                        <option id='elpi' value='elpi'>Electrical Low Pressure Impactor</option>
                        <option id='rss' value='rss'>Resistive Soot Sensor</option>
                        <option id='os' value='os'>Optoacoustic Sensor</option>
                        <option id='lii' value='lii'>Laser Induced Incandescence</option>
                        <option id='tofms' value='tofms'>Time-of-flight Mass Spectrometer</option>
                        <option id='pms' value='pms'>Proton-transfer-reaction Mass Spectrometer</option>
                    </select>
                </div> */}
                <div>
                    <label for='manufacturer'>Manufacturer*</label>
                    <input
                        class='input-fullwidth'
                        required
                        onChange={this.onChange}
                        value={this.state.manufacturer}
                        placeholder='Manufacturer'
                        id='manufacturer'
                        type='text'
                    />
                </div>
                <div>
                    <label for='codename'>Codename*</label>
                    <input
                        class='input-fullwidth'
                        required
                        onChange={e => this.checkCodeName(e.target.value)}
                        value={this.state.codename}
                        placeholder='payload00'
                        id='codename'
                        type='text'
                    />
                </div>
                <div>
                    <label for='company'>Company</label>
                    <input
                        class='input-fullwidth'
                        onChange={this.onChange}
                        value={this.state.company}
                        placeholder='fraunhofer'
                        id='company'
                        type='text'
                    />
                </div>
                <div>
                    <label for='email'>Email*</label>
                    <input
                        class='input-fullwidth'
                        required
                        onChange={this.onChange}
                        value={this.state.email}
                        placeholder='max_mustermann@cml.fraunhofer.de'
                        id='email'
                        type='email'
                    />
                </div>
                <div>
                    <label for='phone'>Phone</label>
                    <input
                        class='input-fullwidth'
                        onChange={this.onChange}
                        value={this.state.phone}
                        placeholder='+123 4567 8900'
                        id='phone'
                        type='text'
                    />
                </div>
                <div>
                    <label for='password'>Password*</label>
                    <input
                        class='input-fullwidth'
                        required
                        onChange={this.onChange}
                        value={this.state.password}
                        id='password'
                        type='password'
                    />
                </div>
                <div>
                    <label for='confirmPassword'>Confirm Password*</label>
                    <input
                        class='input-fullwidth'
                        required
                        onChange={this.onChange}
                        value={this.state.confirmPassword}
                        id='confirmPassword'
                        type='password'
                    />
                </div>
                <button
                    id='registerButton'
                    type='submit'
                    className='button-large fullWidthButton'
                    disabled={this.state.state!='idle'}
                >
                    {this.state.state == 'idle' ? 'Sign Up' : 'Loading'}
                </button>
            </form>
            </div>
            </div>
        )
    }
}