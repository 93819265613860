// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formContainer {
    max-width: 500px;
    padding: 10px 40px;
    margin-left: auto;
    margin-right: auto;
}

#titleContainer {
    text-align: center;
    margin-bottom: 20px;
}

.fullWidthButton {
    width : 100%;
    border-radius: 3px;
    letter-spacing: 1.5px;
    margin-top: 1rem;
    padding-right: 11.250px;
    padding-left: 11.250px;
}

#cancelButton {
    margin-top: 120px;
}`, "",{"version":3,"sources":["webpack://./src/components/totp/totp.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,qBAAqB;IACrB,gBAAgB;IAChB,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".formContainer {\n    max-width: 500px;\n    padding: 10px 40px;\n    margin-left: auto;\n    margin-right: auto;\n}\n\n#titleContainer {\n    text-align: center;\n    margin-bottom: 20px;\n}\n\n.fullWidthButton {\n    width : 100%;\n    border-radius: 3px;\n    letter-spacing: 1.5px;\n    margin-top: 1rem;\n    padding-right: 11.250px;\n    padding-left: 11.250px;\n}\n\n#cancelButton {\n    margin-top: 120px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
