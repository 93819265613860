import React, {Fragment} from 'react'

const green_circle = <svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="green"></circle></svg>
const grey_circle = <svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="grey"></circle></svg>
const gold_circle = <svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="gold"></circle></svg>
const red_circle = <svg height="12" width="12"><circle cx="6" cy="6" r="6" fill="red"></circle></svg>

export class UxvList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            uxvlist:null,
            uxvlist_state:'idle',
            uxvlist_token:null,
            settings_state:'idle'
        }
    }

    componentDidMount() {
    }

    removeUxvFromuxvlist = uxv_id => {
        this.setState({settings_state:'waiting', uxvlist_token: null}, _ => {
            fetch(PUBLIC_PATH_ROOT + `api/mission_remove_uxv`,{
                method: 'POST',
                headers: {'Content-Type': 'application/jsoncharset=utf-8'},
                body: JSON.stringify({token:this.props.credentials.token,uxv_id:uxv_id, mission_id: this.props.mission_id})
            })
            .then( res => res.json())
            .then( res => {
                console.log('res:',res)
                if (res.success) {
                    this.setState({settings_state:'idle',foundUxvs:null,uxvQuery:null,selected_uxv_id:null}, this.props.on_uxvlist_update)
                } else throw res.reason
            })
            .catch( err => {
                console.log('removeUxvFromuxvlist failed:',err)
                alert('removeUxvFromuxvlist failed:' + err)
                this.setState({settings_state:'idle'})
            })
        })
    }

    mountUxv = uxv_id => {
        this.setState({settings_state:'waiting', uxvlist_token: null}, _ => {
            fetch(PUBLIC_PATH_ROOT + `api/mount_uxv`,{
                method: 'POST',
                headers: {'Content-Type': 'application/jsoncharset=utf-8'},
                body: JSON.stringify({token:this.props.credentials.token,uxv_id:uxv_id,operator_uid:this.props.credentials.id})
            })
            .then( res => res.json())
            .then( res => {
                console.log('res:',res)
                if (res.success) {
                    this.setState({settings_state:'idle'}, this.props.on_uxvlist_update)
                } else throw res.reason
            })
            .catch( err => {
                console.log('err:',err)
                this.setState({settings_state:'idle'})
            })
        })
    }

    unmountUxv = uxv_id => {
        this.setState({settings_state:'waiting', uxvlist_token: null}, _ => {
            fetch(PUBLIC_PATH_ROOT + `api/unmount_uxv`,{
                method: 'POST',
                headers: {'Content-Type': 'application/jsoncharset=utf-8'},
                body: JSON.stringify({token:this.props.credentials.token,uxv_id:uxv_id,operator_uid:this.props.credentials.id})
            })
            .then( res => res.json())
            .then( res => {
                console.log('res:',res)
                if (res.success) {
                    this.setState({settings_state:'idle'}, this.props.on_uxvlist_update)
                } else throw res.reason
            })
            .catch( err => {
                console.log('err:',err)
                this.setState({settings_state:'idle'}, this.props.on_uxvlist_update)
            })
        })
    }

    superviseUxv = uxv_id => {
        this.setState({settings_state:'waiting', uxvlist_token: null}, _ => {
            fetch(PUBLIC_PATH_ROOT + `api/supervise_uxv`,{
                method: 'POST',
                headers: {'Content-Type': 'application/jsoncharset=utf-8'},
                body: JSON.stringify({token:this.props.credentials.token,uxv_id:uxv_id,supervisor_uid:this.props.credentials.id})
            })
            .then( res => res.json())
            .then( res => {
                console.log('res:',res)
                if (res.success) {
                    this.setState({settings_state:'idle'}, this.props.on_uxvlist_update)
                } else throw res.reason
            })
            .catch( err => {
                console.log('err:',err)
                this.setState({settings_state:'idle'})
            })
        })
    }

    unsuperviseUxv = uxv_id => {
        this.setState({settings_state:'waiting', uxvlist_token: null}, _ => {
            fetch(PUBLIC_PATH_ROOT + `api/unsupervise_uxv`,{
                method: 'POST',
                headers: {'Content-Type': 'application/jsoncharset=utf-8'},
                body: JSON.stringify({token:this.props.credentials.token,uxv_id:uxv_id,supervisor_uid:this.props.credentials.id})
            })
            .then( res => res.json())
            .then( res => {
                console.log('res:',res)
                if (res.success) {
                    this.setState({settings_state:'idle'}, this.props.on_uxvlist_update)
                } else throw res.reason
            })
            .catch( err => {
                console.log('err:',err)
                this.setState({settings_state:'idle'}, this.props.on_uxvlist_update)
            })
        })
    }

    checkInUxv = (uxv_id) => {
        this.setState({settings_state:'waiting', uxvlist_token: null}, _ => {
            fetch(PUBLIC_PATH_ROOT + `api/check_in_uxv`,{
                method: 'POST',
                headers: {'Content-Type': 'application/jsoncharset=utf-8'},
                body: JSON.stringify({token:this.props.credentials.token,uxv_id:uxv_id,mission_id:this.props.mission_id})
            })
            .then( res => res.json())
            .then( res => {
                console.log('res:',res)
                if (res.success) {
                    this.setState({settings_state:'idle'}, this.props.on_uxvlist_update)
                } else throw res.reason
            })
            .catch( err => {
                console.log('err:',err)
                this.setState({settings_state:'idle'}, this.props.on_uxvlist_update)
            })
        })
    }

    checkOutUxv = (uxv_id) => {
        this.setState({settings_state:'waiting', uxvlist_token: null}, _ => {
            fetch(PUBLIC_PATH_ROOT + `api/check_out_uxv`,{
                method: 'POST',
                headers: {'Content-Type': 'application/jsoncharset=utf-8'},
                body: JSON.stringify({token:this.props.credentials.token,uxv_id:uxv_id})
            })
            .then( res => res.json())
            .then( res => {
                console.log('res:',res)
                if (res.success) {
                    this.setState({settings_state:'idle'}, this.props.on_uxvlist_update)
                } else throw res.reason
            })
            .catch( err => {
                console.log('err:',err)
                this.setState({settings_state:'idle'}, this.props.on_uxvlist_update)
            })
        })
    }

    deployUxv(uxv_id) {
        this.setState({settings_state:'waiting', uxvlist_token: null}, _ => {
            fetch(PUBLIC_PATH_ROOT + `api/deploy_uxv_mission`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                body: JSON.stringify({
                    token: this.props.credentials.token,
                    uxv_id: uxv_id,
                    mission_id: this.props.mission_id
                })
            })
            .then(res => res.json())
            .then( res => {
                console.log('res:',res)
                if (res.success) {
                    this.setState({settings_state:'idle'}, this.props.on_uxvlist_update)
                } else throw res.reason
            })
            .catch( err => {
                console.log('err:',err)
                this.setState({settings_state:'idle'}, this.props.on_uxvlist_update)
            })
        })
    }

    holdUxvPosition(uxv_id) {
        this.setState({settings_state:'waiting', uxvlist_token: null}, _ => {
            fetch(PUBLIC_PATH_ROOT + `api/hold_uxv_position`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                body: JSON.stringify({
                    token: this.props.credentials.token,
                    uxv_id: uxv_id,
                    mission_id: this.props.mission_id
                })
            })
            .then(res => res.json())
            .then( res => {
                console.log('res:',res)
                if (res.success) {
                    this.setState({settings_state:'idle'}, this.props.on_uxvlist_update)
                } else throw res.reason
            })
            .catch( err => {
                console.log('err:',err)
                this.setState({settings_state:'idle'}, this.props.on_uxvlist_update)
            })
        })
    }

    cancelUxvDeployment(uxv_id) {
        this.setState({settings_state:'waiting', uxvlist_token: null}, _ => {
            fetch(PUBLIC_PATH_ROOT + `api/cancel_uxv_deployment`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                body: JSON.stringify({
                    token: this.props.credentials.token,
                    uxv_id: uxv_id,
                    mission_id: this.props.mission_id
                })
            })
            .then(res => res.json())
            .then( res => {
                console.log('res:',res)
                if (res.success) {
                    this.setState({settings_state:'idle'}, this.props.on_uxvlist_update)
                } else throw res.reason
            })
            .catch( err => {
                console.log('err:',err)
                this.setState({settings_state:'idle'}, this.props.on_uxvlist_update)
            })
        })
    }

    missionAddUxv = (uxv_id) => {

        this.setState({settings_state:'waiting', uxvlist_token: null}, _ => {
            console.log('this.props.mission_id',this.props.mission_id)
            fetch( PUBLIC_PATH_ROOT + `api/mission_add_uxv`,{
                method: 'POST',
                headers: {'Content-Type': 'application/jsoncharset=utf-8'},
                body: JSON.stringify({token:this.props.credentials.token,uxv_id:uxv_id, mission_id: this.props.mission_id})
            })
            .then( res => res.json())
            .then( res => {
                if (res.success) {
                    this.setState({settings_state:'idle',foundUxvs:null,uxvQuery:null,selected_uxv_id:null}, this.props.on_uxvlist_update)
                } else throw res.reason
            })
            .catch( err => {
                console.log('uxvlistAddUxv failed:',err)
                alert('uxvlistAddUxv failed:' + err)
                this.setState({settings_state:'idle'})
            })
        })
    }

    handleUxvQueryChange(uxv) {
        // remove timer
        if (this.state.uxvQueryTimerId) clearTimeout(this.state.uxvQueryTimerId)
        // query uxvs after timeout
        this.setState({
            uxvQuery: uxv,
            uxvQueryTimerId: setTimeout( _ => {
                fetch( PUBLIC_PATH_ROOT + 'auth/query_uxvs', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json;charset=utf-8' },
                    body: JSON.stringify({ token: this.props.credentials.token, querystring:uxv})
                })
                .then(res => res.json())
                .then(res => {
                    if (res.success) {
                        let uxvs = res.uxvs
                        console.log('found uxvs:',res.uxvs)
                        this.setState({
                            foundUxvs: uxvs,
                            uxvQueryTimerId: null
                        }, _ => {
                            if (this.uxvSelector) this.uxvSelector.click()
                        })
                        return
                    }
                    console.log('found uxvs failed, reason:', res.reason)
                })
                .catch(err => {
                    console.log('found uxvs: error:',err)
                })
            }, 200)
        })
    }

    // updateMissionUxvlist = _ => {
    //     fetch( PUBLIC_PATH_ROOT + `api/mission_uxvs`,{
    //         method: 'POST',
    //         headers: {'Content-Type': 'application/jsoncharset=utf-8'},
    //         body: JSON.stringify({token:this.props.credentials.token,mission_id:this.props.mission_id})
    //     })
    //     .then( res => res.json())
    //     .then( rs => {
    //         if (rs.success) {
    //             this.props.on_uxvlist_update()
    //         }
    //         else throw 'request failed'
    //     })
    //     .catch( err => {
    //         console.log('request failed:',err)
    //     })
    // }

    render(){ return (
        <div>
            { ['idle','adding_uxvlist_uxv','sharing_resource'].includes(this.state.settings_state) && <div>
                <table class='table-card full-width'>
                    <thead><tr><th>Alias</th><th>Codename</th><th>Status</th></tr></thead>
                    <tbody>
                    { this.props.uxvs != null ?
                        this.props.uxvs.length ?
                            this.props.uxvs.map( (x,i) => <Fragment key={i}>
                                <tr
                                    key={i}
                                    class={'summary' + this.state.focused_card_id == i ? ' focused' : ' '}
                                    onClick={ _ => this.setState({
                                        focused_card_id: this.state.focused_card_id == i ? null : i
                                    })}
                                >
                                    <td><code class='codename'>{x.alias}</code></td>
                                    <td><code class='codename'>{x.codename}</code></td>
                                    <td>
                                        {( y => {
                                            return <Fragment>
                                                {y.mount_state == 'available' && y.supervise_state == 'available' && 'available'}
                                                { y.check_in_state == 'available' && <Fragment>
                                                    {{available:'',mounted:'mounted'}[y.mount_state]} &nbsp;
                                                    {{available:'',supervised:'supervised'}[y.supervise_state]}
                                                </Fragment>}
                                                {{available:'',checked_in:'checked_in'}[y.check_in_state]} &nbsp;
                                                {{available: [green_circle,grey_circle], mounted: [green_circle,green_circle]}[y.mount_state] ?? red_circle}
                                                {{available: grey_circle, supervised: green_circle}[y.supervise_state] ?? red_circle}
                                                {{available: grey_circle, checked_in: green_circle}[y.check_in_state] ?? red_circle}
                                                {{manual: grey_circle, deployed: green_circle}[y.deploy_state] ?? red_circle}
                                            </Fragment>
                                        }) (x)}
                                    </td>
                                </tr>
                                { this.state.focused_card_id == i &&
                                <tr class='detail'>
                                    <td colSpan='3'>
                                        <button className='button-normal' onClick={_ => this.showUxvDetail(x.id)}>Detail</button>
                                        { x.mount_state == 'available' && x.supervise_state=='available' && <button className='button-normal' onClick={_ => this.removeUxvFromuxvlist(x.id)}>Unlist</button>}
                                        { x.supervise_state == 'available' && <button className='button-normal' onClick={_ => this.superviseUxv(x.id)}>Supervise</button>}
                                        { x.supervise_state == 'supervised' && <button className='button-normal' onClick={_ => this.unsuperviseUxv(x.id)}>Unsupervise</button>}
                                        { x.mount_state == 'available' && <button className='button-normal' onClick={_ => this.mountUxv(x.id)}>Mount</button>}
                                        { x.mount_state == 'mounted' && <button className='button-normal' onClick={_ => this.unmountUxv(x.id)}>Unmount</button>}
                                        { (x.mount_state == 'mounted' || x.supervise_state == 'supervised') && (x.check_in_state!='checked_in') && <button className='button-normal' onClick={_ => this.checkInUxv(x.id)}>Check In</button>}
                                        { x.check_in_state == 'checked_in' && <button className='button-normal' onClick={_ => this.checkOutUxv(x.id)}>Check Out</button>}
                                        { x.check_in_state == 'checked_in' && x.deploy_state != 'deployed' && <button className='button-normal' onClick={_ => this.deployUxv(x.id)}>Deploy</button>}
                                        { x.deploy_state == 'deployed' && <button className='button-normal' onClick={_ => this.holdUxvPosition(x.id)}>Hold Position</button>}
                                        { x.deploy_state == 'deployed' && <button className='button-normal' onClick={_ => this.returnToHome(x.id)}>Return To Home</button>}
                                        { x.deploy_state == 'deployed' && <button className='button-normal' onClick={_ => this.cancelUxvDeployment(x.id)}>Cancel Deployment</button>}
                                    </td>
                                </tr>}
                            </Fragment>)
                        :
                        <tr><td colSpan='3'>uxvlist is empty</td></tr>
                    :
                        <tr><td colSpan='3'>uxvlist not accessible</td></tr>
                    }
                    </tbody>
                </table>
                { this.state.settings_state =='idle' && this.state.uxvlist_token == null &&
                    <button
                        class='button-large'
                        onClick={ _ => this.setState({settings_state:'adding_uxvlist_uxv'})}
                    >
                        Add Uxv to uxvlist...
                    </button>
                }
                { this.state.settings_state == 'adding_uxvlist_uxv' &&
                    <div>
                        <h4>Search Uxv</h4>
                        <input
                            ref={x => this.uxvInput = x}
                            onFocus={ _ => this.setState({uxvQuery: null})}
                            onBlur={ _ => setTimeout(_ => this.setState({foundUxvs:null}),200)}
                            onChange={e => this.handleUxvQueryChange(e.target.value)}
                            value={this.state.uxvQuery ?? ''}
                            placeholder={'🔍 Type codename here'}
                            id="uxv_query"
                            type="text"
                        />
                        { this.state.foundUxvs?.length > 0 &&
                        <table class='table-normal' id='uxv-selector' ref={x=>this.uxvSelector = x}><tbody>
                            {this.state.foundUxvs?.map( (x,i) =>
                            <tr
                                key={i}
                                onClick={ _ => {
                                    this.setState({uxvQuery: x.codename,selected_uxv_id: x.id})
                                    this.uxvInput.value = x.codename
                                }}
                            >
                                <td><code class='codename'>{`${x.codename}`}</code></td>
                            </tr>)}
                        </tbody></table>
                        }
                        <button
                            class='button-large'
                            disabled={ !this.state.selected_uxv_id }
                            onClick={ _ => this.missionAddUxv(this.state.selected_uxv_id)}
                        >
                            Add UXV
                        </button>
                        <button
                            class='button-large'
                            onClick={ _ => this.setState({settings_state:'idle',uxvQuery:null,foundUxvs:null,selected_uxv_id:null})}
                        >
                            Finish
                        </button>
                    </div>
                }
            </div>}
        </div>
    )}
}

export class MountedUxvList extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.queryMountedUxvs()
    }

    queryMountedUxvs() {
        fetch(PUBLIC_PATH_ROOT + 'api/get_mission_mounted_uxvs/' + this.props.mission_id, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            body: JSON.stringify({ token: this.props.credentials.token })
        })
        .then(res => res.json())
        .then(res => {
            console.log('uxvs:', res.uxvs)
            if (res.success)
                this.props.on_uxvlist_update(res.uxvs)
            else throw res.reason
        })
        .catch(err => console.log('get mission err:', err))
    }

    render() {
        return (
            <div>
            <table class='table-normal'>
                <thead><tr><th>Alias</th><th>Codename</th><th>Status</th><th>Actions</th></tr></thead>
                <tbody>
                { this.props.uxvs != null ?
                    this.props.uxvs.length ?
                        this.props.uxvs.map( (x,i) =>
                            <tr key={i}>
                                <td><code class='codename'>{x.alias}</code></td>
                                <td><code class='codename'>{x.codename}</code></td>
                                <td>
                                    {
                                        (   y => {
                                            if (!y.status)
                                                return <Fragment>unknown {grey_circle}</Fragment>
                                            if (y == 'ready')
                                                return <Fragment>ready {green_circle}</Fragment>
                                            return <Fragment>unhandled status {red_circle}</Fragment>
                                        }) (x)
                                    }
                                </td>
                                <td>
                                    <button className='button-normal' onClick={_ => _}>Detail</button>
                                    <button className='button-normal' onClick={_ => this.removeUxvFromuxvlist(x.id)}>Unlist</button>
                                </td>
                            </tr>
                        )
                    :
                    <tr><td colSpan='4'>uxvlist is empty</td></tr>
                :
                    <tr><td colSpan='4'>uxvlist not accessible</td></tr>
                }
                </tbody>
            </table>
            </div>
        )
    }
}